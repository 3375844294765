<template>
  <div>
    <div class="xxservice">
      <div class="headbox">
        <ul
          :class="type==item.id?'active':'ul1'"
          @click="setOption(item.id)"
          v-for="item in titleData"
          :key="item.id"
        >
          <li>
            <img :src="item.img" alt />
          </li>
          <li class="li2">{{item.name}}</li>
          <li class="li3">{{getShowState(item.state)}}</li>
        </ul>
      </div>
      <div class="details">
        <!-- 未办理 -->
        <div class="introduce" v-if="isHad == 1">
          <div class="nohave">您尚未办理业务</div>
          <div class="noselect">您当前未办理此业务 无法查询</div>
          <div v-if="type ==2 || type == 3" class="gopay" @click="goPay(type)">去下单</div>
        </div>
        <!-- 办理中 -->
        <div class="introduce" v-if="isHad == 2">
          <div
            v-for="item in titleData"
            :key="item.id"
            :style="item.id == type?'display:block':'display:none'"
            class="title_"
          >{{item.name}}办理</div>
          <!-- <div class="title">
            <span class="intro-title">档案办理流程进度</span>
          </div>-->
          <div class="item" v-for="item in Detail" :key="item.id">
            <div class="imgbox">
              <div class="step" :class="{step_active:item.active}"></div>
            </div>
            <div class="title" :class="{active:item.active}">{{item.liucheng}}</div>
            <div class="content" :class="{active:item.active}">{{item.miaoshu}}</div>
          </div>
        </div>
        <!-- 已完成 -->
        <div class="introduce" v-if="isHad == 3">
          <div class="money-introduce">
            <div class="money-cont">
              <template v-if="type ==2 || type ==3">
                <div class="money-statecont">
                  <div class="money-city">
                    <div class="city">存放城市:</div>
                    <div class="iscity">{{completeinfor.cityname}}</div>
                  </div>
                </div>
                <div class="money-statecont">
                  <div class="money-city">
                    <div class="city">到期日:</div>
                    <div
                      class="iscity"
                    >{{formatTime(type==3?completeinfor.duedate:completeinfor.expiringtime)}}</div>
                  </div>
                </div>
                <div class="money-statecont" v-if="type ==3" >
                  <div class="money-city" >
                    <div class="city" >派出所:</div>
                    <div class="iscity">{{completeinfor.policeoffice}}</div>
                  </div>
                </div>
                <div class="money-statecont" @click="goDeatil(type)">
                  <button>查看详情</button>
                </div>
              </template>
              <template v-if="type == 1">
                <div class="money-statecont">
                  <div class="money-city">
                    <div class="city">网签单位:</div>
                    <div class="iscity">{{completeinfor.copmpanyname}}</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/gallery/xxService.scss";
import { formatTime } from "@/lib/base";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      titleData: [
        {
          name: "网签服务",
          img: require("../../assets/views/gallery/xxService/bz1.png"),
          id: 1,
          state: ""
        },
        {
          name: "档案服务",
          img: require("../../assets/views/gallery/xxService/bz2.png"),
          id: 2
        },
        {
          name: "户口服务",
          img: require("../../assets/views/gallery/xxService/bz3.png"),
          id: 3,
          state: ""
        }
      ],
      isHad: 1, //是否办理了业务  //1未办理 2办理中 3已完成
      Detail: "", //流程信息
      type: 1, //请求业务类型
      completeinfor: "" //业务详情
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    //获取所有业务的状态
    this.getState(1);
    this.getState(2);
    this.getState(3);
  },
  methods: {
    //获取业务状态
    getState(type) {
      this.$siaxios
        .get(`/liucheng/getLiuchengInfo/${this.userInfo.id}/${type}`)
        .then(res => {
          let index = type - 1;
          if (res.length == 0) {
            //未办理
            this.titleData[index].state = 1;
          } else {
            this.type = type;
            if (res[res.length - 1].state == 2) {
              //已完成
              this.titleData[index].state = 3;
            } else {
              //办理中
              this.titleData[index].state = 2;
            }
            this.getBussinDetail();
          }
        });
    },
    //获取业务流程
    getBussinDetail() {
      this.$siaxios
        .get(`/liucheng/getLiuchengInfo/${this.userInfo.id}/${this.type}`)
        .then(res => {
          this.Detail = res;
          if (this.Detail.length == 0) {
            this.isHad = 1; //未办理
          } else {
            if (this.Detail[this.Detail.length - 1].state == 2) {
              this.isHad = 3; //已完成
              this.getFinishData();
            } else {
              this.isHad = 2; //待办理
              for (var i = 0; i < this.Detail.length; i++) {
                if (this.Detail[i].state == 2) {
                  this.Detail[i].active = true;
                }
              }
            }
          }
        });
    },
    //获取完成信息（档案、户口）
    getFinishData() {
      this.$siaxios
        .get(`mybusiness/getBusiness/${this.userInfo.id}`)
        .then(res => {
          if (this.type == 2 || this.type == 1) {
            this.completeinfor = res.sjgjArchives; //档案
          } else if (this.type == 3) {
            this.completeinfor = res.sjgjRegisteredResidence; //户口
          }
        });
    },
    //选项卡切换
    setOption(type) {
      this.type = type;
      this.getBussinDetail();
    },
    //去下单
    goPay(type) {
      let id,businessname;
      if (type == 1) {
        id = 39;
        businessname="网签手续办理"
      } else if (type == 2) {
        id = 41;
        businessname="高校毕业生档案"
      } else if (type == 3) {
        id = 42;
        businessname="高校毕业生户口"
      }
      this.$router.forward({path:'/creatorder/general',query:{id:id,businessname:businessname}})
    },
    //档案户口去查看详情
    goDeatil(type) {
      if (type == 2) {
        this.$router.push("/mine/archives");
      } else if (type == 3) {
        this.$router.push("/mine/residence");
      }
    },
    //状态判断
    getShowState(state) {
      if (state == 1) {
        return "未办理";
      } else if (state == 2) {
        return "办理中";
      } else {
        return "已完成";
      }
    },
    formatTime: formatTime
  }
};
</script>

<style>
</style>