<template>
  <div class="recordetail-index">
    <div class="re-per-con">
      <div class="title" v-if="ywtype == 1">档案{{title}}</div>
      <div class="title" v-if="ywtype == 2">户口{{title}}</div>
      <!--  档案  -->
      <template v-if="ywtype == 1">
        <!--  借阅-->
        <template v-if="type == 1">
          <div class="item">
            <div class="infos">
              <span class="lable">借阅方式：</span>
              <span class="idno">{{getNameByType(sjgjData.sendtype)}}</span>
            </div>
          </div>
          <div class="item">
            <div class="infos">
              <span class="lable">当前状态：</span>
              <span class="idno">{{getNameByState(sjgjArchives.state)}}</span>
            </div>
          </div>
          <div class="item">
            <div class="infos">
              <span class="lable">押金：</span>
              <span class="idno">{{sjgjData.borrowmoney}}</span>
            </div>
          </div>

          <!-- 邮寄 -->
          <template v-if="sjgjData.sendtype== 1">
            <div class="item">
              <div class="infos">
                <span class="lable">收件人：</span>
                <span class="idno">{{sjgjData.person}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">联系方式：</span>
                <span class="idno">{{sjgjData.persontel}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">邮寄地址：</span>
                <span class="idno">{{sjgjData.personadd}}</span>
              </div>
            </div>
            <!--   邮寄且状态为完成时-->
            <template v-if="sjgjArchives.state == 3">
              <div class="item">
                <div class="infos">
                  <span class="lable">邮寄单号：</span>
                  <span class="idno">{{sjgjData.number}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">快递名称：</span>
                  <span class="idno">{{sjgjData.express}}</span>
                </div>
              </div>
              <!-- <div class="item">
                <div class="infos">
                  <span class="lable">邮寄日期：</span>
                  <span class="idno">{{formatTime(sjgjData.time)}}</span>
                </div>
              </div>-->
            </template>
            <!--                        邮寄且状态为待办理时-->
            <!-- <template v-if="sjgjArchives.state == 1">
              <div class="item">
                <div class="infos">
                  <span class="lable">当前状态：</span>
                  <span class="idno">{{getNameByState(sjgjArchives.state)}}</span>
                </div>
              </div>
            </template>-->
            <!--                        邮寄且状态为待邮寄或者完成时-->
            <template v-if="sjgjArchives.state == 2 || sjgjArchives.state == 3">
              <div class="item">
                <div class="infos">
                  <span class="lable">借阅内容：</span>
                  <span class="idno">{{sjgjData.borrowcon}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">预计归还：</span>
                  <span class="idno">{{formatTime(sjgjData.returndate)}}</span>
                </div>
              </div>
            </template>
          </template>
          <!-- 自取时 -->
          <template v-if="sjgjData.sendtype == 0">
            <div class="item">
              <div class="infos">
                <span class="lable">自取日期：</span>
                <span class="idno">{{formatTime(sjgjData.time)}}</span>
              </div>
            </div>

            <div class="item">
              <div class="infos">
                <span class="lable">借阅内容：</span>
                <span class="idno">{{sjgjData.borrowcon}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">预计归还：</span>
                <span class="idno">{{formatTime(sjgjData.returndate)}}</span>
              </div>
            </div>
          </template>
          <!-- 他人代办 -->
          <template v-if="sjgjData.sendtype == 2">
            <div class="item">
              <div class="infos">
                <span class="lable">代办人：</span>
                <span class="idno">{{sjgjData.people}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">联系方式：</span>
                <span class="idno">{{sjgjData.peopletel}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">代办日期：</span>
                <span class="idno">{{formatTime(sjgjData.time)}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">借阅内容：</span>
                <span class="idno">{{sjgjData.borrowcon}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">预计归还：</span>
                <span class="idno">{{formatTime(sjgjData.returndate)}}</span>
              </div>
            </div>
          </template>
        </template>
        <!-- 归还 -->
        <template v-if="type == 2">
          <div class="item">
            <div class="infos">
              <span class="lable">当前状态：</span>
              <span class="idno">{{getNameByState(sjgjArchives.state)}}</span>
            </div>
          </div>
          <div class="item" v-if="sjgjData.sendtype == 3">
            <div class="infos">
              <span class="lable">归还日期：</span>
              <span class="idno">{{formatTime(sjgjData.returntime)}}</span>
            </div>
          </div>
          <div class="grayline"></div>
          <div class="re-per-con">
            <div class="title">材料归还邮寄信息</div>
            <div class="item">
              <div class="infos">
                <span class="lable">地址：</span>
                <span class="idno">青岛市市南区高雄路18号海洋大厦5层</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">收件人：</span>
                <span class="idno">中海智库人力资源业务部</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">收件电话：</span>
                <span class="idno">0532-85873655</span>
              </div>
            </div>
            <div class="grayline"></div>
            <div class="re-per-con">
              <div class="title">押金退还账号</div>
              <div class="item">
                <div class="infos">
                  <span class="lable">持卡人：</span>
                  <span class="idno">{{sjgjData.name}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">银行名称：</span>
                  <span class="idno">{{sjgjData.bank}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">银行卡号：</span>
                  <span class="idno">{{sjgjData.bankcard}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 提取 -->
        <template v-if="type == 3">
          <div class="item">
            <div class="infos">
              <span class="lable">提取方式：</span>
              <span class="idno">{{getNameByType(sjgjData.sendtype)}}</span>
            </div>
          </div>
          <div class="item">
            <div class="infos">
              <span class="lable">当前状态：</span>
              <span class="idno">{{getNameByState(sjgjArchives.state)}}</span>
            </div>
          </div>

          <!--  邮寄时-->
          <template v-if="sjgjData.sendtype == 1">
            <div class="item">
              <div class="infos">
                <span class="lable">收件人</span>
                <span class="idno">{{sjgjData.person}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">联系方式</span>
                <span class="idno">{{sjgjData.persontel}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">邮寄地址：</span>
                <span class="idno">{{sjgjData.personadd}}</span>
              </div>
            </div>
            <!--                        邮寄完成时-->
            <template v-if="sjgjArchives.state== 3">
              <div class="item">
                <div class="infos">
                  <span class="lable">邮寄单号：</span>
                  <span class="idno">{{sjgjData.number}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">快递名称：</span>
                  <span class="idno">{{sjgjData.express}}</span>
                </div>
              </div>
            </template>
          </template>
          <!--     自取时-->
          <template v-if="sjgjData.sendtype == 0">
            <div class="item">
              <div class="infos">
                <span class="lable">自取日期：</span>
                <span class="idno">{{formatTime(sjgjData.time)}}</span>
              </div>
            </div>
          </template>
          <!--                    他人代办-->
          <template v-if="sjgjData.sendtype == 2">
            <div class="item">
              <div class="infos">
                <span class="lable">代办人：</span>
                <span class="idno">{{sjgjData.people}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">联系方式：</span>
                <span class="idno">{{sjgjData.peopletel}}</span>
              </div>
            </div>
          </template>
          <!--   机要-->
          <template v-if="sjgjData.sendtype == 3">
            <div class="item">
              <div class="infos">
                <span class="lable">机要号：</span>
                <span class="idno">{{sjgjData.confidential}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">机要费：</span>
                <span class="idno">{{sjgjData.orderamount}}</span>
              </div>
            </div>
          </template>
        </template>
      </template>
      <!-- 户口-->
      <template v-if="ywtype == 2">
        <!-- 借阅-->
        <div v-if="type == 1">
          <div class="item">
            <div class="infos">
              <span class="lable">借阅方式：</span>
              <span class="idno">{{getNameByType(sjgjData.sendtype)}}</span>
            </div>
          </div>
          <!-- 邮寄-->
          <template v-if="sjgjData.sendtype == 1">
            <div class="item">
              <div class="infos">
                <span class="lable">当前状态：</span>
                <span class="idno">{{getNameByState(sjgjArchives.state)}}</span>
              </div>
            </div>
            <!--                        待办理-->
            <template v-if="sjgjArchives.state == 1">
              <div class="item">
                <div class="infos">
                  <span class="lable">借阅人：</span>
                  <span class="idno">{{sjgjData.sname}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">身份证号：</span>
                  <span class="idno">{{sjgjData.idcard}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">收件人：</span>
                  <span class="idno">{{sjgjData.postpeople}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">联系方式：</span>
                  <span class="idno">{{sjgjData.postphone}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">邮寄地址：</span>
                  <span class="idno">{{sjgjData.postaddress}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">押金：</span>
                  <span class="idno">{{sjgjData.deposit}}</span>
                </div>
              </div>
            </template>
            <!--                        完成-->
            <template v-if="sjgjArchives.state == 3">
              <div class="item">
                <div class="infos">
                  <span class="lable">借阅人：</span>
                  <span class="idno">{{sjgjData.sname}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">身份证号：</span>
                  <span class="idno">{{sjgjData.idcard}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">收件人：</span>
                  <span class="idno">{{sjgjData.postpeople}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">联系方式：</span>
                  <span class="idno">{{sjgjData.postphone}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">邮寄地址：</span>
                  <span class="idno">{{sjgjData.postaddress}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">邮寄单号：</span>
                  <span class="idno">{{sjgjData.receiptnumber}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">押金：</span>
                  <span class="idno">{{sjgjData.deposit}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">快递名称：</span>
                  <span class="idno">{{sjgjData.expressname}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">预计归还：</span>
                  <span class="idno">{{formatTime(sjgjData.returntime)}}</span>
                </div>
              </div>
            </template>
            <!--                        待邮寄-->
            <template v-if="sjgjArchives.state == 2">
              <div class="item">
                <div class="infos">
                  <span class="lable">借阅人：</span>
                  <span class="idno">{{sjgjData.sname}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">身份证号：</span>
                  <span class="idno">{{sjgjData.idcard}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">收件人：</span>
                  <span class="idno">{{sjgjData.postpeople}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">联系方式：</span>
                  <span class="idno">{{sjgjData.postphone}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">邮寄地址：</span>
                  <span class="idno">{{sjgjData.postaddress}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">押金：</span>
                  <span class="idno">{{sjgjData.deposit}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">预计归还：</span>
                  <span class="idno">{{formatTime(sjgjData.returntime)}}</span>
                </div>
              </div>
            </template>
          </template>
          <!--                    自取-->
          <template v-if="sjgjData.sendtype == 0">
            <div class="item" v-if=" sjgjArchives.state == 3">
              <div class="infos">
                <span class="lable">自取日期：</span>
                <span class="idno">{{formatTime(sjgjData.time)}}</span>
              </div>
            </div>
          </template>
          <!--                    他人代办-->
          <template v-if="sjgjData.sendtype == 2">
            <div class="item">
              <div class="infos">
                <span class="lable">代办人：</span>
                <span class="idno">{{sjgjData.agent}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">联系方式：</span>
                <span class="idno">{{sjgjData.agentphone}}</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">代办日期：</span>
                <span class="idno">{{formatTime(sjgjData.agenttime)}}</span>
              </div>
            </div>
          </template>
        </div>
        <!-- 归还 -->
        <div v-if="type == 2">
          <div class="item">
            <div class="infos">
              <span class="lable">当前状态：</span>
              <span class="idno">{{ReturnByState(sjgjArchives.state)}}</span>
            </div>
          </div>
          <div class="item" v-if="sjgjData.sendtype == 2">
            <div class="infos">
              <span class="lable">归还日期：</span>
              <span class="idno">{{getNameByState(sjgjArchives.returntime)}}</span>
            </div>
          </div>
          <div class="grayline"></div>
          <div class="re-per-con">
            <div class="title">材料归还邮寄信息</div>
            <div class="item">
              <div class="infos">
                <span class="lable">地址：</span>
                <span class="idno">青岛市市南区高雄路18号海洋大厦5层</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">收件人：</span>
                <span class="idno">中海智库人力资源业务部</span>
              </div>
            </div>
            <div class="item">
              <div class="infos">
                <span class="lable">收件电话：</span>
                <span class="idno">0532-85873655</span>
              </div>
            </div>
            <div class="grayline"></div>
            <div class="re-per-con">
              <div class="title">押金退还账号</div>
              <div class="item">
                <div class="infos">
                  <span class="lable">持卡人：</span>
                  <span class="idno">{{sjgjData.name}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">银行名称：</span>
                  <span class="idno">{{sjgjData.bank}}</span>
                </div>
              </div>
              <div class="item">
                <div class="infos">
                  <span class="lable">银行卡号：</span>
                  <span class="idno">{{sjgjData.banknumber}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 户口迁出 -->
        <div v-if="type == 3">
          <div class="item">
            <div class="infos">
              <span class="lable">出库去向：</span>
              <span class="idno">{{sjgjData.hukouowhere}}</span>
            </div>
          </div>
          <div class="item">
            <div class="infos">
              <span class="lable">提取人：</span>
              <span class="idno">{{sjgjData.extractpeople}}</span>
            </div>
          </div>
          <div class="item" v-if="sjgjArchives.state == 3">
            <div class="infos">
              <span class="lable">出库日期：</span>
              <span class="idno">{{formatTime(sjgjData.outtime)}}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import "@/sass/views/mine/recordetail.scss";
import { mapGetters } from "vuex";
import { formatTime } from "@/lib/base.js";

export default {
  name: "recordDetail",
  data() {
    return {
      recordParam: {
        userid: "",
        ywtype: 1,
        page: 1
      },
      title: "",
      sjgjArchives: {},
      recordList: [],
      sjgjData: "",
      id: "",
      type: "", //状态  1借阅2归还3提取
      ywtype: ""
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.ywtype = this.$route.query.ywtype;
    if (this.type == 1) {
      this.title = "借阅";
    } else if (this.type == 2) {
      this.title = "归还";
    } else if (this.type == 3) {
      this.title = "提取";
    }
    this.recordParam.userid = this.userInfo.id;

    this.getRecord();
    this.getMyBusiness();
  },
  methods: {
    /*
     * 获取详情
     */
    getMyBusiness() {
      this.sjgjData = "";
      this.$siaxios.get("/dangan/getRecordInfo/" + this.id).then(res => {
        this.sjgjArchives = res;
        if (this.ywtype == 1) {
          if (this.type == 1) {
            this.sjgjData = this.sjgjArchives.sjgjBorrow;
          } else if (this.type == 2) {
            //归还
            this.sjgjData = this.sjgjArchives.sjgjBack;
          } else if (this.type == 3) {
            //提取
            this.sjgjData = this.sjgjArchives.sjgjExtract;
          }
        }
        if (this.ywtype == 2) {
          if (this.type == 1) {
            this.sjgjData = this.sjgjArchives.sjgjAccountBorrowing;
          } else if (this.type == 2) {
            //归还
            this.sjgjData = this.sjgjArchives.sjgjAccountReturn;
          } else if (this.type == 3) {
            //提取
            this.sjgjData = this.sjgjArchives.sjgjAccountTransfer;
          }
        }
      });
    },
    /**
     * 根据状态获取名称   0确认支付1待办理2待发送/待完成3完成
     */
    getNameByState(state) {
      if (state == 0) return "确认支付";
      else if (state == 1) return "待办理";
      else if (state == 2) return "待邮寄";
      else if (state == 3) return "完成";
    },
    /* 
      户口归还状态值
    */
    ReturnByState(state) {
      if (state == 2) return "待完成";
      else if (state == 3) return "完成";
    },
    /**
     * 获取类型名称  1借阅2归还3提取/迁出
     */
    getNameByType(type) {
      if (type == 0) return "自取";
      else if (type == 1) return "邮寄";
      else if (type == 2) return "他人代办";
      else if (type == 3) return "机要";
    },
    /**
     * 获取借阅记录
     */
    getRecord() {
      this.$siaxios
        .post("/dangan/getRecord", this.recordParam, false)
        .then(res => {
          console.log(2);
          this.recordList = res;
        });
    },
    /**
     * 借阅归还
     */
    borrow() {
      if (this.sjgjArchives.borrowstate == 1) {
        this.$router.forward({
          path: "/mine/backarchives",
          query: { id: this.sjgjArchives.id }
        });
      } else {
        this.$router.forward({
          path: "/mine/creatarchives"
        });
      }
    },
    formatTime: formatTime
  }
};
</script>
<style lang="scss" scoped>
.item {
  height: 30px !important;
}

.item span {
  line-height: 30px !important;
}

.grayline {
  height: 2px;
}
</style>