<template>
    <div class="school-index" >
        <div class="banner-con">
            <div class="banner">
                <span>外来务工人员子女入小学条件</span>
                <p>（2018年烟台芝罘区）</p>
            </div>
        </div>
        <!-- 入学条件 -->
        <div class="title">
            <img src="@/assets/views/mine/house.png" alt/>
            <span class="title-">入学条件</span>
        </div>
        <div class="cont-frist">
            <div class="con-cont" >
                <div class="con-introduce">
                    <span>截止到招生当年8月31日，父母等第一法定监护人在芝罘区购买具有政府合法审批手续并有具有资质开发商开发的商品房</span>
                </div>
            </div>
            <div class="con-cont" style="margin-top:35px">

                <div class="con-introduce">
                    <span>截止到招生当年8月31日，父母等第一法定监护人双方或一方在芝罘区有稳定工作，与用人单位签订用过合同并缴纳社会劳动保险一年以上且保证每年连续缴纳劳动保险</span>
                </div>
            </div>
            <div class="con-cont" style="margin-top:35px">

                <div class="con-introduce">
                    <span>截止到招生当年8月31日，父母等第一法定监护人在芝罘区注资50万元以上成立公司并年缴纳税5万元以上</span>
                </div>
            </div>
        </div>
      <div class="top-back" style="margin-top:13px"></div>
        <!-- 入学证件 -->
        <div class="title">
            <img src="@/assets/views/mine/yewujieshao.png" alt/>
            <span class="title-">入学证件</span>
        </div>
        <div class="cont">
            <div class="con-title">
                <span>入学需提供的证件</span>
            </div>
            <div class="con-cont">
                <div class="introduce-title">
                    <span>1</span>
                </div>
                <div class="con-introduce">
                    <span>入学规定证件</span>
                </div>
            </div>
            <div class="con-cont">
                <div class="introduce-title">
                    <span>2</span>
                </div>
                <div class="con-introduce">
                    <span>适龄少年儿童健康体检表</span>
                </div>
            </div>
            <div class="con-cont">
                <div class="introduce-title">
                    <span>3</span>
                </div>
                <div class="con-introduce">
                    <span>计划免疫证和预防接种完成证明（原件）</span>
                </div>
            </div>
        </div>
        <!-- 外来务工子女入学规定证件 -->
        <div class="title-big">
            <img src="@/assets/views/mine/ruxue.png" alt/>
            <span class="title-">外来务工子女入学规定证件</span>
        </div>
        <div class='small-title'>
            <span>提供以下三类证件之一</span>
        </div>
        <div class="cont-school">
            <div class="con-cont" style='margin-top:10px;'>
                <div class="con-introduce">
                    <span>父母等第一法定监护人在芝罘区购房的要提供父母等第一法定监护人的房产证，含有父母等第一法定监护人和该适龄少年儿童的户口簿</span>
                </div>
            </div>
            <div class="con-cont" style='margin-top:20px;'>

                <div class="con-introduce">
            <span>父母等第一法定监护人在芝罘区（或市里）缴纳劳动保险的要提供父母双方（若只有一方有保险则只提供一方）
              在芝罘区（或市级）社会劳动保障部门办理的历年的社会劳动保险缴费证明、社会劳动保障部门认可的劳动合同、含有适龄儿童和其法定监护人的居住证、租房合同一级含有父母
              等第一法定监护人和适龄少年儿童的户口簿</span>
                </div>
            </div>
            <div class="con-cont" style='margin-top:100px;'>

                <div class="con-introduce">
                    <span>父母等第一法定监护人在芝罘区注资50万元以上成立公司并年缴纳税50万元以上的要提供投资人省份证、投资人及其子女户口溥、营业执照、缴纳证明、含有适龄儿童少年和其法定监护人的居住证</span>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/gallery/school.scss";
    export default {
        name: "index",
        data() {
            return {

            };
        },

    };
</script>
<style lang="scss" scoped>

</style>