<template>
  <div  class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">业务介绍：</div>
      <div class="content">
        孕妇在产前检查，生成时生产的医疗费用，休产假可享受的生育津贴，均可通过申报生育险报销。
      </div>
      <div class="content">档管家为您办理报销业务，让准妈妈们安心休假，不受琐事困扰。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">常见问题</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
      </div>
      <div class="show-content">
        <div class="showtype showtype3" v-if="showType == 1">
          <div class="order-title">常见问题</div>
          <div class="showinfo-3">
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>女职工可享受多少天产假</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>
                    <p>生育保险待遇都有那些;难产的增加 15天,多胞胎生育的,每多生育一胎,增加产假15天;符合《山东省人口与计划生育条例》规定生育子女的,增加产假60天。妊娠未满4个月流产的,产假为15天;妊娠4个月以上流产、引产的 ,产假为42天</p>
                  </span>
                </div>
              </div>
            </div>
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>生育保险待遇都有那些</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>
                    <p>生育保险待遇包括生育医疗费用、生育津贴。生育医疗费用包括生育的医疗费用、计划生育的医疗费用以及法律、规定的其他项目费用。</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div><div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1
    };
  }
};
</script>