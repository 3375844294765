<template>
  <div
    class="pull-con"
    @touchstart="touchStart($event)"
    @touchmove="touchMove($event)"
    @touchend="touchEnd($event)"
  >
  <!-- :style="{transform: mttop}" -->
    <!--上拉加载-->
    <div class="pull-down">
        <img src="./images/jiazai.png" :class="['showimg',isLoading?'loadingsty':'']" ref="showimg" alt/>
      <slot></slot>
      <div class="pull-up">
        <slot name="pull-up">
          <div class="moreData-tip" v-if="pullUpState==1">
            <span class="moreData-tip-text">{{pullUpStateText.moreDataTxt}}</span>
          </div>
          <div class="loadingMoreData-tip" v-if="pullUpState==2">
            <span class="icon-loading"></span>
            <span class="loadingMoreData-tip-text">{{pullUpStateText.loadingMoreDataTxt}}</span>
          </div>
          <div class="noMoreData-tip" v-if="pullUpState==3">
            <span class="connectingLine"></span>
            <span class="noMoreData-tip-text">{{pullUpStateText.noMoreDataTxt}}</span>
            <span class="connectingLine"></span>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
// import img_down from "./images/ssdown.png";
// import img_loading from "./images/loading.png";
export default {
  props: {
    onRefresh: {
      type: Function,
      required: false
    },
    onInfiniteLoad: {
      type: Function,
      require: false
    }
  },
  data() {
    return {
      defaultOffset: 100, //默认高度
      top: 0, //刷新时距离顶部高度
      startY: 0, //下拉开始高度
      isDropDown: false, //是否正在下拉
      isRefreshing: false, //是否正在刷新
      isLoading:false,//调用接口的时候转圈
      ////////////////////////////////////////////////////////以上为下拉  一下为上拉
      pullUpState: 0, // 1:上拉加载更多, 2:加载中……, 3:我是有底线的
      pullUpStateText: {
        moreDataTxt: "上拉加载更多",
        loadingMoreDataTxt: "加载中...",
        noMoreDataTxt: "我是有底线的"
      }
    };
  },
  methods: {
    touchStart(e) {
      this.startY = e.targetTouches[0].pageY;
    },
    touchMove(e) {
      this.scrollIsToTop = document.getElementById("app-main").scrollTop;
      if (e.targetTouches[0].pageY > this.startY) {
        //下拉
        if(this.scrollIsToTop == 0){
          this.pullDownFun(e);
        }
      } else if (e.targetTouches[0].pageY < this.startY) {
        //上拉
        this.pullUpFun();
      }
    },
    touchEnd() {
      if (this.isDropDown && !this.isRefreshing) {
        this.$refs.showimg.style.top = '46px'
        this.isLoading = true
        this.refresh();
        this.isRefreshing = true;
      }
    },
    /**
     * 下拉刷新
     */
    pullDownFun(e) {
      // 下拉
      if(e.targetTouches[0].pageY - this.startY >= 50){
        this.isDropDown = true
        this.$refs.showimg.style.top = '100px'
      }
    },
    /**
     * 上拉加载
     */
    pullUpFun() {
      let innerHeight = document.querySelector(".pull-up").offsetTop;
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop = document.getElementById("app-main").scrollTop;
      // 滚动条到底部的条件
      if (scrollTop  >= innerHeight - 900) {
        //不等于有底线 并且没有滚动条 并且不在刷新中状态
        if (
          this.pullUpState !== 3 &&
          document.querySelector(".pull-down").clientHeight >
            document.documentElement.clientHeight &&
          !this.isRefreshing
        ) {
          this.pullUpState = 1;
          this.infiniteLoad();
        }
      }
    },
    infiniteLoad() {
      if (!this.isRefreshing) {
        this.pullUpState = 2;
        this.isRefreshing = true;
        setTimeout(() => {
          this.onInfiniteLoad(this.infiniteLoadDone);
        }, 800);
      }
    },
    infiniteLoadDone(lengths, currentpage) {
      if (lengths < 10) {
        if (currentpage == 1) this.pullUpState = 0;
        else this.pullUpState = 3;
      } else {
        this.pullUpState = 1;
      }
      this.isRefreshing = false;
    },
    /**
     * 松手刷新
     */
    refresh() {
      setTimeout(() => {
        this.onRefresh(this.refreshDone);
      }, 1200);
    },
    /**
     * 完成刷新
     */
    refreshDone() {
      this.$refs.showimg.style.top = '-50px'
      this.isLoading = false
      this.isRefreshing = false;
      this.isDropDown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.pull-con {
  width: 100%;
  // margin-top: -105px;
//   min-height: calc(100vh + 100px);
  -webkit-overflow-scrolling: touch; /* ios5+ */
  .pull-down {
    // position: relative;
    // .down-tips {
    //   text-align: center;
    //   font-size: 26px;
    //   color: #999;
    //   height: 100px;
    // }
    .showimg {
      width:93px;
      height: 93px;
      position: absolute;
      top: -100px;
      transition: all .3s;
      left: calc(50% - 46px);
      z-index: 10;
    }
    .loadingsty {
      animation: rotating .5s linear infinite;
    }
    @keyframes rotating {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(1turn);
      }
    }
  }
  .pull-up {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
    .moreData-tip,
    .loadingMoreData-tip,
    .noMoreData-tip {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
    }
    .connectingLine {
      display: inline-flex;
      width: 100px;
      height: 2px;
      background: #ddd;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
</style>