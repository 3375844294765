<template>
  <div class="renew-index">
    <div class="banner-con2">
      <div class="banner">
        <span class="xufei">同时办理档案续费、落户续费业务</span>
        <p class="can">
          可享受
          <span style="color:#ffebad;font-size: 28px; ">减免</span>优惠
        </p>
      </div>
    </div>
    <div class="select">
      <div class="record" :class="typebussiness == 1? 'notactive':'active'" @click="isRecord(1)">
        <div class="record-money">
          <span>档案续费</span>
        </div>
        <div class="record-img">
          <div class="record-inline"></div>
          <div class="record-image">
            <img src="@/assets/views/mine/settle.png" alt />
          </div>
        </div>
      </div>
      <div class="settle" :class="typebussiness == 2? 'notactive':'active'" @click="isRecord(2)">
        <div class="settle-money">
          <span>落户续费</span>
        </div>
        <div class="settle-img">
          <div class="settle-inline"></div>
          <div class="settle-image">
            <img src="@/assets/views/mine/record_03.png" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="money-introduce">
      <div class="money-cont">
        <div class="money-title">当前状态</div>
        <div class="money-statecont">
          <div class="money-city">
            <div class="city">存放城市</div>
            <div class="iscity">{{typebussiness == 1?dParam.cityname:hParam.cityname}}</div>
          </div>
        </div>
        <div class="money-statecont">
          <div class="money-city">
            <div class="city">到期日</div>
            <div
              class="iscity"
            >{{typebussiness == 1?formatTime(dParam.expiringtime):formatTime(hParam.duedate)}}</div>
          </div>
        </div>
        <div class="money-statecont" v-if="typebussiness == 2">
          <div class="money-city">
            <div class="city">派出所</div>
            <div class="iscity">{{hParam.policeoffice}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="re-btn">
      <div class="record">
        <span class="text">续费标准：</span>
        <span class="num">{{typebussiness==1?danganPrice:hukouPrice}}元/年</span>
      </div>
      <div class="renew" @click="goMoney(typebussiness)">立即续费</div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/mine/renew.scss";
import { mapGetters } from "vuex";
import { formatTime } from "@/lib/base.js";
import grallery from "@/components/plug-in/grallery";
export default {
  name: "index",
  data() {
    return {
      danganTip: false,
      hukouTip: false,
      hParam: "",
      dParam: "",
      year: "",
      title: "",
      // xufeiPrice: "", //单项续费时的费用
      togetherCufei: "", //同时续费时的费用
      typebussiness: "",
      sjgjArchives: "",
      danganPrice: "",
      hukouPrice: "",
      fristBool: false
    };
  },
  mounted() {
    this.getMyBusiness(); //获取档案业务
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /*
     * 获取档案信息
     */
    getMyBusiness() {
      this.$axios.get("/dangan/getDanganInfo/" + this.userInfo.id).then(res => {
        if (res.data.success) {
          this.typebussiness = 1;
          this.danganTip = true;
          this.sjgjArchives = res.data.data;
          this.dParam = {
            cityname: this.sjgjArchives.cityname,
            remark: this.sjgjArchives.remark,
            cityid: this.sjgjArchives.cityid,
            tel: this.sjgjArchives.tel,
            police: "", //派出所
            settlemode: "",
            expiringtime: this.sjgjArchives.expiringtime,
            userid: this.userInfo.id,
            saletypeid: this.sjgjArchives.ywtype,
            sjgjOrderHukou: {
              year: this.year
            }
          };
          this.getCityCharge(this.sjgjArchives.ywtype);
          this.getYear(this.typebussiness); //获取续费时长
        } else {
          this.sjgjArchives = "";
          this.danganTip = false;
          this.$tips({
            title: "提示",
            describe: "您还没有办理相关档案业务",
            callback: () => {
              this.typebussiness = 2;
              this.getDetail();
            }
          });
        }
      });
    },
    /**
     * 获取户口
     */
    getDetail() {
      this.$axios.get("hukou/getHuKouInfo/" + this.userInfo.id).then(res => {
        if (res.data.success) {
          this.sjgjArchives = res.data.data;
          this.hukouTip = true;
          this.hParam = {
            cityname: this.sjgjArchives.cityname, //存放城市
            time: this.sjgjArchives.duedate, //到期日时间
            police: this.sjgjArchives.policeoffice, //派出所
            cityid: this.sjgjArchives.cityid, //城市id
            tel: this.sjgjArchives.phone, //电话
            remark: this.sjgjArchives.remark,
            settlemode: this.sjgjArchives.settlemode,
            userid: this.userInfo.id,
            saletypeid: this.sjgjArchives.ywtype,
            policeoffice: this.sjgjArchives.policeoffice,
            duedate: this.sjgjArchives.duedate,
            sjgjOrderHukou: {
              year: this.year
            }
          };

          this.getCityCharge(this.sjgjArchives.ywtype); //获取户口缴费标准
        } else {
          this.sjgjArchives = "";
          // this.togetherCufei = ""
          this.hukouTip = false;
          this.$tips({
            title: "提示",
            describe: "您还没有办理相关户口业务",
            callback: () => {
              this.typebussiness = 1;
              this.getMyBusiness();
            }
          });
        }
      });
    },

    //获取续费时长
    getYear(typebussiness) {
      this.$siaxios
        .get("order/getYear/" + this.userInfo.id + "/" + typebussiness)
        .then(res => {
          this.year = res;
        });
    },
    //创建缴费订单
    goMoney(typebussiness) {
      if (typebussiness == 1) {
        // 存在档案业务
        if (this.danganTip) {
          /* 判断是否在同一城市 */
          this.$siaxios
            .get("order/getDandAnHuKou/" + this.userInfo.id)
            .then(res => {
              if (res) {
                this.fristBool = true;
                this.getDetail();
                // grallery.installConfirm({
                //   isDefren: true,
                //   title: "限时优惠",
                //   describe:
                //     "同时续费档案和落户业务，享" + this.togetherCufei + "元/年",
                //   param: this.dParam,
                //   money: this.danganPrice,
                //   hmoney: this.togetherCufei
                // });
              } else {
                grallery.installConfirm({
                  isDefren: false,
                  title: "档案续费",
                  describe: "",
                  param: this.dParam,
                  money: this.danganPrice,
                  hmoney: this.togetherCufei
                });
              }
            });
        } else {
          this.$tips({
            title: "提示",
            describe: "您还没有办理相关档案业务"
          });
        }
      } else {
        if (this.hukouTip) {
          // 判断是否在同一城市
          this.$siaxios
            .get("order/getDandAnHuKou/" + this.userInfo.id)
            .then(res => {
              if (res) {
                grallery.installConfirm({
                  isDefren: true,
                  title: "限时优惠",
                  describe:
                    "同时续费档案和落户业务，享" + this.togetherCufei + "元/年",
                  param: this.hParam,
                  money: this.hukouPrice,
                  hmoney: this.togetherCufei
                });
              } else {
                grallery.installConfirm({
                  isDefren: false,
                  title: "户口续费",
                  describe: "",
                  param: this.hParam,
                  money: this.hukouPrice,
                  hmoney: this.togetherCufei
                });
              }
            });
        } else {
          this.xufeiPrice = "";
          this.togetherCufei = "";
          this.$tips({
            title: "提示",
            describe: "您还没有办理相关户口业务"
          });
        }
      }
    },
    //获取不同城市的续费价格
    getCityCharge(saletypeid) {
      this.$siaxios
        .get(
          "common/getBusinessCharge/" +
            this.sjgjArchives.cityid +
            "/" +
            saletypeid
        )
        .then(res => {
          //档案价格
          if (saletypeid == 41 || saletypeid == 19) {
            this.danganPrice = res[0].xufei;
          } else {
            for (var i = 0; i < res.length; i++) {
              if (this.sjgjArchives.settlemode == res[i].taocan) {
                this.hukouPrice = res[i].xufei; //户口单独续费时
                this.togetherCufei = res[i].hdxufei; //同时续费时
                if (this.fristBool) {
                  grallery.installConfirm({
                    isDefren: true,
                    title: "限时优惠",
                    describe:
                      "同时续费档案和落户业务，享" +
                      this.togetherCufei +
                      "元/年",
                    param: this.dParam,
                    money: this.danganPrice,
                    hmoney: this.togetherCufei
                  });
                }
                this.fristBool = false;
              }
            }
          }
        });
    },
    //选项卡切换
    isRecord(type) {
      this.typebussiness = type;
      if (type == 1) {
        this.getMyBusiness(); //获取档案信息
      } else {
        this.getDetail(); //获取户口信息
      }
    },
    formatTime: formatTime
  }
};
</script>