<template>

  <div class="order-services">
    <div class="title">
      <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
    </div>
    <div class="show-content">
      <div class="showtype" style=" background: #f6faff;" v-if="showType == 3">
        <div class="order-title">常见问题</div>
        <div class="showinfo-3">
          <div class="showinfo-3-item">
            <div class="showinfo-3-quest">
              <div class="show3-num">
                <span>Q</span>
              </div>
              <div class="shou3-cont">
                <span>济南贴息政策范围和条件是什么</span>
              </div>
            </div>
            <div class="item-line"></div>
            <div class="showinfo-3-cont">
              <div class="show3-num">
                <span>A</span>
              </div>
              <div class="shou3-cont">
                <span>
                  服务对象：城镇登记失业人员、就业困难人员、复员转业退役军人、刑满释放人员、高校毕业生、化解过剩产能企业职工和失业人员、返乡创业农民工、网络商户、建档立卡贫困人口、农村自主创业农民
                  享受待遇：对符合条件的个人创业者，贷款额度最高20万元，合伙及组织起来创业的，可依据人数适当扩大贷款规模，每人不超过20万元，贷款最高额度不超过60万元。贷款期限最长2年，全额贴息。对还款积极、带动就业能力强(带动就业两人以上)、创业项目好的借款个人，可继续提供创业担保贷款贴息，但累计次数不得超过3次，每次贴息年限最长为2年.
                </span>
              </div>
            </div>
          </div>
          <div class="showinfo-3-item">
            <div class="showinfo-3-quest">
              <div class="show3-num">
                <span>Q</span>
              </div>
              <div class="shou3-cont">
                <span>青岛贴息政策范围和条件是什么</span>
              </div>
            </div>
            <div class="item-line"></div>
            <div class="showinfo-3-cont">
              <div class="show3-num">
                <span>A</span>
              </div>
              <div class="shou3-cont">
                <span>
                  补贴范围：
                  法定劳动年龄内创业者,在青岛行政区域内创办各类创业实体 (含社会团体、事务所等),持有有效注册登记证明,符合条件的可申请创业担保贷款及贴息。
                  <p>
                    补贴标准：
                    贷款额度15万元（含）以下，给与三年全额贴息（利息：基准利率）贷款额度15万元—45万元，贷款前两年给与全额贴息，第三年按比例给与最高全额贴息（利息：基准利率）。小微企业最高可贷300万元，50%贴息（利息：基准利率）
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div class="showinfo-3-item">
            <div class="showinfo-3-quest">
              <div class="show3-num">
                <span>Q</span>
              </div>
              <div class="shou3-cont">
                <span>创业贷款的利率是如何规定的</span>
              </div>
            </div>
            <div class="item-line"></div>
            <div class="showinfo-3-cont">
              <div class="show3-num">
                <span>A</span>
              </div>
              <div class="shou3-cont">
                <span>贷款利率可在贷款合同签订日贷款基础利率的基础上上浮不超过1个百分点。人力资源社会保障部门、人民银行结合申办银行的贷款利率、服务质量、网点分布等因素采取公开招标的方式确定创业担保贷款经办银行。</span>
              </div>
            </div>
          </div>
          <div class="showinfo-3-item">
            <div class="showinfo-3-quest">
              <div class="show3-num">
                <span>Q</span>
              </div>
              <div class="shou3-cont">
                <span>创业贷款的担保方式有几种</span>
              </div>
            </div>
            <div class="item-line"></div>
            <div class="showinfo-3-cont">
              <div class="show3-num">
                <span>A</span>
              </div>
              <div class="shou3-cont">
                <span>贷款者提供反担保，可选择两种方式，一种为房屋抵押贷款，申请人提供的抵押房屋在本市行政区域内，产权清晰，未处于抵押或拆迁冻结期，并征得房屋产权人以及同住人同意；一种为保证人担保贷款，A.本市机关事业单位在编职工；B.企业社保缴费基数5000元以上的职工（稳定就业1年以上且上个月缴纳社会保险基数5000以上）。C.贷款金额15万元以下的（含15万元），1人担保； 15万元以上的, 2人担保。D.保证人须距离法定退休年龄高于贷款担保责任期1年以上，保证人之间为无限连带责任。</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import "@/sass/views/creatorder/introduce.scss";
export default {
  name: "Introduce",
  data() {
    return {
      showType: 3 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>
