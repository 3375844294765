<template>
    <div class="prove-index">
        <div class="banner-con">
            <div class="banner">
                <div class="title-banner">
                    存档证明
                </div>

            </div>
        </div>
            <div class="cont-use">
                <div class="use-cont">
                    <div class="use-introduce">
                        <img src="@/assets/views/mine/banshi.jpg" alt="">
                        <span>业务介绍</span>
                    </div>
                    <div class="use-cont">
                    <span>
                      需要我方出具证明的请联系我们的专业顾问，没有特殊说明的我方会根据自己的模板内容填写，如有内容或格式要求，请将模板内容直接传给专业顾问，我方会根据您的要求打印盖章后寄出
                    </span>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
    import "@/sass/views/gallery/prove.scss";
    export default {
        name: "archives-use",
        data() {
            return {

            };
        },

    };
</script>

