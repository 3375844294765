<!--
 * @Description: 政策列表模板
 * @Autor: bjp
 -->
<template>
    <div>
        <ul class="content" v-if="list.length > 0">
            <li v-if="id == 3">常见问题</li>
            <li v-else v-for="item in list" @click="$router.forward({path:'/information/detail',query:{id:item.id}})" :key="item.id"
                :class="item.imgurl?'isImg':'noImg'">
                <div class="desc">
                    <div class="title">{{item.title}}</div>
                    <div class="con" v-html="item.introduce"></div>
                    <div class="btnOrTime">
                        <span :class="`btn btn${item.zixuntype}`">{{getInformationType(item.zixuntype)}}</span>
                        <span v-if="item.state" :class="`btnType btnType${item.state}`">{{getType(item)}}</span>
                        <span class="time">{{formatTime(item.publishtime)}}</span>
                    </div>
                </div>
                <img v-if="item.imgurl" :src="item.imgurl" alt />
            </li>
        </ul>
    </div>
</template>
<script>
import { formatTime } from "@/lib/base.js";
export default {
    props: {
        list: {
            type: Array,
            default: () => { }
        },
        label: {
            type: String,
            default: "1"
        },
        id: {
            default: "1"
        }
    },
    data() {
        return {};
    },
    methods: {
        formatTime: formatTime,
        /**
         * 获取咨询类型 1正常2资讯
         */
        getInformationType(zixuntype) {
            if (zixuntype == 1) return '政策'
            else if (zixuntype == 2) return '资讯'
        },
        /**
         * 获取咨询类型 1正常2资讯
         */
        getType(row) {
            if (row.type == 1) {
                if (row.state == 1) return "养老";
                else if (row.state == 2) return "医疗";
                else if (row.state == 3) return "工伤";
                else if (row.state == 4) return "生育";
                else if (row.state == 5) return "失业";
                else if (row.state == 6) return "社保卡";
            } else {
                if (row.state == 1) return "贷款";
                else if (row.state == 2) return "提取";
                else if (row.state == 3) return "其他";
            }
            return "其他";
        },
    }
};
</script>
