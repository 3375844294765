<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>一次性创业补贴补贴额度（1000.1500.2000）	青岛</p>
                <span>补贴范围：</span>
                <p>
                    在青岛行政区域内创办各类创业实体的下类人员大中专毕业生、技工学校，技师学院毕业生、登记失业人员、返乡农民工、符合单六及双六政策的人员。
                </p>

                <span>
                    补贴标准:
                </span>
                <p>
                    2011年10月1日—2015年6月23日创办创业实体补贴5000元2015年6月23日以后创办创业实体的补贴1万元。
                </p>
            </div>
        </div>

    </div>
</template>
<script>
     import "@/sass/views/creatorder/business_introduce.scss";
    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

