<template>
  <div class="informationdetail">
    <div class="in-title">{{info.title}}</div>
    <div class="in-date">{{formatTime(info.publishtime)}}</div>
    <div class="in-content" v-html="info.content"></div>
    <div class="attention" v-if="$route.query.isShow">
      <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU1NTcxMjUwNg==&scene=124#wechat_redirect">关注公众号</a>
    </div>
  </div>
</template>
<script>
import "@/sass/views/information/detail.scss";
import { formatTime } from "@/lib/base";
import wxSdk from "weixin-js-sdk";
import { eventBus } from "@/main";
import shareLogo from "@/assets/share_logo.png";
export default {
  name: "index",
  data() {
    return {
      id: "",
      info: ""
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    formatTime: formatTime,
    getDetail() {
      this.$siaxios
        .get(`information/info/${this.$route.query.id}`)
        .then(res => {
          this.info = res;
          eventBus.$emit("infomationDetails", res);
          this.setShare(res);
        });
    },

    setShare(data) {
      let url = `${
        window.location.href.split("#")[0]
      }/#/information/detail?id=${this.id}&isShow=1`;
      wxSdk.ready(function() {
        wxSdk.showAllNonBaseMenuItem();
        wxSdk.updateAppMessageShareData({
          //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
          title: data.title, // 分享标题
          desc: "档管家--专注人力资源", // 分享描述
          link: url,
          imgUrl: shareLogo, // 分享图标
          type: "link", // 分享类型,music、video或link，不填默认为link
          dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
          success: function() {}
        });
        wxSdk.updateTimelineShareData({
          //需在用户可能点击分享按钮前就先调用
          title: data.title, // 分享标题
          desc: "档管家--专注人力资源", // 分享描述
          link: url,
          imgUrl: shareLogo, // 分享图标
          success: function() {}
        });
      });
    }
  }
};
</script>
