<template>
  <div class="dangan-creatorder">
    <div class="search">
      <div class="banner">
        <div class="banner-search">
          <span>档案查询</span>
        </div>
        <div class="search-step">
          <div :class="!isActive1?'search-num':'active-num'">
            <div class="num">
              <span>1</span>
            </div>
            <div class="text">
              <span>未办理</span>
            </div>
          </div>
          <div :class="!isActive2?'search-line':'active-line'"></div>
          <div :class="!isActive2?'search-num':'active-num'" style="margin-left: -10px">
            <div class="num">
              <span>2</span>
            </div>
            <div class="text">
              <span>办理中</span>
            </div>
          </div>
          <div :class="!isActive3?'search-line':'active-line'"></div>
          <div :class="!isActive3?'search-num':'active-num'" style="margin-left: -10px">
            <div class="num">
              <span>3</span>
            </div>
            <div class="text">
              <span>已完成</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <!-- 未办理  -->
    <div class="introduce" v-if="!isActive1 && isActive2 && isActive3">
      <div class="nohave">您尚未办理业务</div>
      <div class="noselect">您当前未办理此业务 无法查询</div>
      <div class="gopay" @click="$router.push('/creatorder/general?id=19&businessname=存放档案')">去下单</div>
    </div>
    <!-- 办理中 -->
    <div class="introduce" v-if="!isActive1 && !isActive2 && isActive3">
      <div class="title">
        <span class="intro-title">档案办理流程进度</span>
      </div>
      <div class="item" v-for="item in flowDetail" :key="item.id">
        <div class="imgbox">
          <div class="step" :class="{step_active:item.active}"></div>
        </div>
        <div class="title" :class="{active:item.active}">{{item.liucheng}}</div>
        <div class="content" :class="{active:item.active}">{{item.miaoshu}}</div>
      </div>
    </div>
    <div class="content-botm" v-if="!isActive1 && !isActive2 && isActive3">
      <span>网签完成：学校审核入库/人社部审核入库</span>
    </div>
    <div class="introduce" v-if="!isActive1 && !isActive2 && !isActive3">
      <div class="money-introduce">
        <div class="money-cont">
          <div class="money-statecont">
            <div class="money-city">
              <div class="city">存放城市:</div>
              <div class="iscity">{{danganInfo.cityname}}</div>
            </div>
          </div>
          <div class="money-statecont">
            <div class="money-city">
              <div class="city">到期日:</div>
              <div class="iscity">{{formatTime(danganInfo.expiringtime)}}</div>
            </div>
          </div>
          <div class="money-statecont" @click="$router.push('/mine/archives')">
            <button>查看详情</button>
          </div>
        </div>
      </div>
    </div>
    <div class="showmessage"></div>
  </div>
</template>
<script>
import "@/sass/views/mine/document-query.scss";
import { formatTime } from "@/lib/base.js";
import { mapGetters } from "vuex";

export default {
  name: "backarchive",
  data() {
    return {
      amount: 0,
      isActive1: false,
      isActive2: false,
      isActive3: false,
      flowDetail: [],
      danganInfo: ""
    };
  },
  created() {
    this.getStep();
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 获取流程
     */
    getStep() {
      this.$siaxios
        .get(`/liucheng/getLiuchengInfo/${this.userInfo.id}/2`)
        .then(res => {
          if (res.length == 0) {
            this.isActive1 = false;
            this.isActive2 = true;
            this.isActive3 = true;
          } else {
            this.flowDetail = res;
            this.getMyBusiness();
            for (var i = 0; i < this.flowDetail.length; i++) {
              if (this.flowDetail[this.flowDetail.length - 1].state == 2) {
                this.isActive1 = false;
                this.isActive2 = false;
                this.isActive3 = false;
              } else if (this.flowDetail[i].state == 2) {
                this.flowDetail[i].active = true;
                this.isActive1 = false;
                this.isActive2 = false;
                this.isActive3 = true;
              }
            }
          }
        });
    },
    getMyBusiness() {
      this.$siaxios
        .get("/dangan/getDanganInfo/" + this.userInfo.id)
        .then(res => {
          this.danganInfo = res;
        });
    },
    formatTime: formatTime
  }
};
</script>
<style lang="scss" scoped>
.search {
  background: #3f68e1;
  height: 360px;
  overflow: hidden;
  .banner-search {
    font-size: 32px;
    color: white;
    text-align: center;
    margin-top: 50px;
    font-weight: bold;
  }
  .search-step {
    margin-left: 90px;
    margin-top: 80px;

    .search-num {
      width: 90px;
      height: 84px;
      float: left;

      .num {
        width: 40px;
        height: 40px;
        background: white;
        border-radius: 100%;
        color: #3f68e1;
        text-align: center;
        line-height: 40px;
        font-weight: bold;
        margin-left: 15px;
      }

      .text {
        font-size: 22px;
        color: white;
        margin-top: 20px;
      }
    }

    .active-num {
      width: 90px;
      height: 84px;
      float: left;

      .num {
        width: 40px;
        height: 40px;
        background: #9fb4f0;
        border-radius: 100%;
        color: #6f8ee8;
        text-align: center;
        line-height: 40px;
        font-weight: bold;
        margin-left: 15px;
      }

      .text {
        font-size: 22px;
        color: #9fb4f0;
        margin-top: 20px;
      }
    }

    .active-line {
      width: 211px;
      background: #9fb4f0;
      height: 2px;
      float: left;
      margin-left: -35px;
      margin-top: 20px;
    }

    .search-line {
      width: 211px;
      background: white;
      height: 2px;
      float: left;
      margin-left: -35px;
      margin-top: 20px;
    }
  }
}

.introduce {
  .title {
    .intro-title {
      font-size: 32px;
      margin-left:30px;
    }
  }

  .content-botm {
    width: 100%;
    color: #999999;
    padding-top: 40px;
    margin-left: 50px;
  }
  .content {
    .manage {
      overflow: hidden;
      float: left;
      padding: 40px 0px 0px 0px;
      width: 602px;
      font-size: 28px;

      color: #333333;

      span {
        margin-left: 20px;
      }

      &::before {
        position: absolute;
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #3367ff;
        margin-top: 20px;
        margin-left: -20px;
      }
    }

    .manage2 {
      overflow: hidden;
      float: left;
      padding: 40px 0px 0px 35px;
      width: 602px;
      font-size: 28px;

      color: #333333;

      span {
        margin-left: 20px;
      }

      &::before {
        position: absolute;
        content: "";
        background: url("../../assets/views/mine/xieyi.jpg") 100% 100%;
        background-size: cover;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        /*background: #3367ff;*/
        margin-top: 10px;
        margin-left: -30px;
      }
    }

    .manage-line {
      width: 100%;
      overflow: hidden;

      .top {
        width: 1px;
        height: 98px;
        background: #dfe7ff;
        margin-left: 22px;
        position: absolute;
        margin-top: -11px;
      }

      .left {
        width: 620px;
        height: 1px;
        background: #eeeeee;
        margin-left: 55px;
        margin-top: 35px;
      }
    }
  }
}
</style>
