<template>
  <div class="pageadd-index">
       <div :class="$route.query.id==1?'banner-con2':'banner-con'">
      <div class="banner">
        <div class="title-banner">
          <span>单页补办</span>
        </div>
      </div>
    </div>
    <div class="cont-use">
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>因单页不慎丢失或损坏，可申请单页补办手续。</span>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style='padding-top:20px'>
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/da-dc1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>APP提交申请</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>付款成功</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/guihuan2.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>提供资料</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 20px;">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/police (1).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>派出所补办</span>
                  </div>
                </div>
                <div class="trastom">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:30px;">
        <div class="use-introduce">
          <img src="@/assets/views/mine/shuoming.jpg" alt style="width:15px;height:15px" />
          <span>业务说明</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="hot-cont">
          <p class="tips-cont">选择邮寄归还的请将材料邮寄到我方指定地址</p>
        </div>
        <div class="hot-cont" style="margin-top:5px;">
          <p class="tips-cont">收件人：李青青</p>
          <p class="tips-cont" style="margin-top:5px">联系电话：0532-85873655转813或815</p>
          <p class="tips-cont" style="margin-left:62px;margin-top:5px">18661887826</p>
          <p class="tips-cont" style="margin-top:5px">联系地址： 青岛市市南区高雄路18号海洋大厦5层</p>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:20px;">
        <div class="use-introduce">
          <img src="@/assets/views/mine/cailiao.jpg" alt />
          <span>所需材料</span>
        </div>
        <div class="content">
          <div class="needs">
            <div class="needs-cont" style="float:none">
              <div class="neds-num">1</div>
              <div class="neds-text">身份证</div>
            </div>
            <div class="needs-cont" style="float:none">
              <div class="neds-num">2</div>
              <div class="neds-text">补办户口单页申请书 （附模板）</div>
            </div>
            <div class="needs-cont" style="float:none">
              <div class="neds-num">3</div>
              <div class="neds-text">委托书(非本人补办）（附模板）</div>
            </div>
          </div>
          <div class="needs">
            <div class="word">
              <div class="word-icon">
                <img class="title-img" src="@/assets/views/mine/house.png" alt />
              </div>
              <div class="word-text">
                <span>补办户口单页申请书模板.doc</span>
              </div>
              <div class="word-btn" @click="uploadFile(7)">
                <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
                <button style="margin-left:20px;">下载</button>
              </div>
            </div>
            <div class="word">
              <div class="word-icon">
                <img class="title-img" src="@/assets/views/mine/house.png" alt />
              </div>
              <div class="word-text">
                <span>委托书模板.doc</span>
              </div>
              <div class="word-btn" @click="uploadFile(1)">
                <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
                <button>下载</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/page-add.scss";

export default {
  name: "archives-use",
  data() {
    return {
      exportApi: ""
    };
  },
  methods: {
    //委托书模板下载
    uploadFile(type) {
      let url = this.$axios.defaults.baseURL + "common/downloadFile/" + type;
      this.$copyText(`${url}`).then(
        () => {
          this.$tips({
            describe: "已成功复制链接，请通过浏览器下载该文档"
          });
        },
        () => {
          this.$tips({
            describe: "复制失败"
          });
        }
      );
    }
  }
};
</script>

 