<template>
  <div class="message-index">
    <v-refresh ref="getState" :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad">
      <ul v-if="showList.list.length>0">
        <li v-for="item in showList.list" :key="item.id" @click="goUrl(item)">
          <div class="time">{{getTime(item.time)}}</div>
          <div class="info">
            <span class="name">{{item.title}}</span>
            <div v-if="item.state == 0" class="isreade"></div>
            <div class="detail">{{item.content}}</div>
          </div>
        </li>
      </ul>
      <div v-else>
        <no-data></no-data>
      </div>
    </v-refresh>
  </div>
</template>
<script>
import "@/sass/views/mine/message.scss";
import refresh from "@/components/plug-in/pulldown";
import no_data from "../nonepage/norecord";
import { mapGetters } from "vuex";
import { timeago } from "@/lib/base";
export default {
  name: "Message",
  beforeRouteEnter(to, from, next) {
    if (
      (from.name == "Mine" || from.name == "IndexPage") &&
      to.name == "Message"
    ) {
      to.meta.isBack = false;
    } else {
      to.meta.isBack = true;
    }
    next();
  },
  data() {
    return {
      showList: {
        currentpage: 1,
        list: []
      },
      routeBool: true
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.getList();
  },
  activated() {
    if (!this.$route.meta.isBack) {
      this.showList.list = [];
      this.showList.currentpage = 1;
      this.$refs.getState.pullUpState = 0;
      this.getList();
    } 
  },
  components: {
    "v-refresh": refresh,
    "no-data": no_data
  },
  methods: {
 
    //获取消息列表
    async getList() {
      var showdatacopy = [];
      showdatacopy = this.showList.list;
      let tempList = await this.$siaxios.get(
        `/pushmessage/selflist/${this.showList.currentpage}/${this.userInfo.id}`
      );
      if (tempList.list.length > 0) {
        tempList.list = showdatacopy.concat(tempList.list);
        this.showList.list = tempList.list;
      } else {
        this.showList.list = showdatacopy;
      }
      return tempList.list.length;
    },
    getTime(time) {
      return timeago(time);
    },
    reading(item) {
      this.$siaxios.get(`/pushmessage/info/${item.id}`).then(() => {
        item.state = 1;
      });
    },
    /**
     * 跳转外部链接
     */
    goUrl(item) {
      if (!item.linkurl) return;
      if (this.userInfo.device) {
        if (this.userInfo.device.source == "android") {
          // eslint-disable-next-line
          rswy_view.officialWebView(item.linkurl);
          return;
        } else if (this.userInfo.device.source == "ios") {
          window.webkit.messageHandlers.officialWebView.postMessage(
            item.linkurl
          );
          return;
        }
      }
      window.location.href = item.linkurl;
    },
    //下拉刷新
    onRefresh(done) {
      this.showList.list = [];
      this.showList.currentpage = 1;
      this.getList().then(() => {
        done(); // call done
      });
    },
    // 上拉加载
    onInfiniteLoad(done) {
      this.showList.currentpage++;
      this.getList().then(res => {
        done(res);
      });
    }
  }
};
</script>
