<template>
  <div class="substitute-index">
    <template v-if="showdata.length>0">
      <div class="substitute-con" v-for="(item,index) in showdata" :key="index">
        <div class="title">
          {{item.year}}年
          <span class="tips" v-if="index == 0">已停保的月份不会显示在列表中</span>
        </div>
        <div class="item">
          <div class="time h-table">时间</div>
          <div class="city h-table">城市</div>
          <div class="state h-table">状态</div>
        </div>
        <div class="item" v-for="child in item.list" :key="child.id">
          <div class="time">{{child.cycle}}</div>
          <div class="city">{{child.cityname}}</div>
          <div class="state">{{child.states==0?'已缴纳':'待缴纳'}}</div>
        </div>
      </div>
    </template>
    <div v-else>
      <no-data></no-data>
    </div>
    <div class="sub-bottom-btn">
      <!-- <div class="sub-stop-btn disble" v-if="!isdisble">已停保</div> -->
      <!-- <template v-else> -->
      <template v-if="creatOrderType == 2 || creatOrderType == 3">
        <div class="sub-order-btn half-width" @click="goCreatOrder()">续缴</div>
        <div class="sub-stop-btn disble half-width" v-if="!isdisble">已申请停保</div>
        <div class="sub-stop-btn half-width" @click="stopOrder()" v-else>停保</div>
      </template>
      <div class="sub-order-btn" v-else @click="goCreatOrder()">缴纳</div>
      <!-- </template> -->
    </div>
  </div>
</template>

<script>
import "@/sass/views/mine/substitute.scss";
import { mapGetters } from "vuex";
import no_data from "../nonepage/norecord";
export default {
  name: "Substitute",
  data() {
    return {
      type: 1, //1社保2公积金
      cityid: "", //当前缴纳城市id
      creatOrderType: "",
      btnname: "下单",
      isShow: false,
      isdisble: true,
      showdata: []
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  created() {
    this.type = this.$route.query.type;
    this.cityid = this.$route.query.cityid;
    this.isXuJiao();
    this.getPayment();
    this.getIsStop();
  },
  components: {
    "no-data": no_data
  },
  methods: {
    /**
     * 获取缴费记录
     */
    getPayment() {
      this.showdata = [];
      this.$siaxios
        .get(`/mybusiness/getPayment/${this.userInfo.id}/${this.type}`)
        .then(res => {
          let tempdata;
          let cttrMonth = "";
          if (res.length > 0) {
            res.forEach(element => {
              let temptime = element.cycle.split("-");
              if (cttrMonth != temptime[0]) {
                cttrMonth = temptime[0];
                tempdata = {
                  year: temptime[0],
                  list: []
                };
                this.showdata.push(tempdata);
              }
              tempdata.list.push(element);
            });
          } else {
            this.showdata = [];
          }
        });
    },
    /**
     * 获取是否可以停缴
     */
    getIsStop() {
      this.$siaxios
        .get(`mybusiness/getTingJiao/${this.userInfo.id}/${this.type}`)
        .then(res => {
          this.isdisble = res;
        });
    },
    /**
     * 判断是否续缴
     */
    isXuJiao() {
      this.$siaxios
        .get(`/order/isXujiao?userid=${this.userInfo.id}&type=${this.type}`)
        .then(res => {
          //社保
          if (this.type == 1) {
            if (res == null || (res != null && res.state == 4))
              this.creatOrderType = 0;
            else this.creatOrderType = 2;
          } else {
            if (res == null || (res != null && res.state == 4))
              this.creatOrderType = 1;
            else this.creatOrderType = 3;
          }
        });
    },
    /**
     * 去下单  0社保1公积金2社保续费3公积金续费
     */
    goCreatOrder() {
      if (this.type == 1) {
        if (this.creatOrderType == 0)
          this.$router.forward("/creatorder/socialsecurity");
        else this.$router.forward("/creatorder/creatsocialsecurity");
      } else if (this.type == 2) {
        this.$router.forward("/creatorder/accumulationfund");
      }
    },
    stopOrder() {
      this.$router.push({
        path: "/mine/stopPayment",
        query: { id: this.userInfo.id, type: this.type, cityid: this.cityid }
      });
    }
  }
};
</script>