<template>
  <div class="paybackorder-index">
    <div v-if="showData.length">
      <div v-for="item in showData" :key="item.id">
        <div
          class="orderlist-item"
          @click="$router.forward({path:'/mine/recordDetail',query: { id: item.id ,type:item.type,ywtype:2}})"
        >
          <div class="order-top">
            <div class="order-num">户口{{getStype(item.type)}}</div>
            <div class="order-state">{{getState(item.state)}}</div>
          </div>
          <div class="order-info">
            <div class="price">{{item.sname != null?item.sname:item.name}}</div>
            <div class="all-price">{{formatTime(item.time)}}</div>
          </div>
        </div>
        <div class="inline"></div>
      </div>
    </div>
    <div v-else>
      <no-data></no-data>
    </div>
  </div>
</template>
<script>
import "@/sass/views/mine/record.scss";
import { formatTime } from "@/lib/base.js";
import no_data from "../nonepage/norecord";
import { mapGetters } from "vuex";
export default {
  name: "borrowedRecord-hukou",
  data() {
    return {
      param: {
        userid: "", // 用户id
        ywtype: 2, // 类型id
        page: 1 //当前页
      },
      showData: []
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  activated() {
    this.getList();
  },
  mounted() {
    this.getList();
  },
  components: {
    "no-data": no_data
  },
  methods: {
    //获取借阅记录
    async getList() {
      this.param = {
        userid: this.userInfo.id,
        ywtype: this.param.ywtype,
        page: this.param.page
      };
      this.$siaxios;
      let tempList = await this.$siaxios.post(
        "dangan/getRecord",
        this.param,
        false
      );

      this.showData = tempList;
    },
    //获取户口类型
    getStype(type) {
      if (type == 1) {
        return "借阅";
      } else if (type == 2) {
        return "归还";
      } else if (type == 3) {
        return "迁出";
      }
    },
    //获取状态值
    getState(state) {
      if (state == 1) {
        return "待办理";
      } else if (state == 2) {
        return "待完成";
      } else if (state == 3) {
        return "完成";
      }
    },
    formatTime: formatTime
  }
};
</script>
<style lang="scss" scoped>
.paybackorder-index{
  margin-top:0
}

</style>
