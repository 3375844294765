<template>
    <div class="order-services">
        <div class="title">
            <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
        </div>
        <div class="show-content">
            <div
                    class="showtype "
                    style=" background: #f6faff;"

            >
                <div class="order-title">常见问题</div>
                <div class="showinfo-3">
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                                一次性创业补贴补贴范围的什么
                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                                <span>
在本市行政区域内创办各类创业实体的下类人员大中专毕业生、技工学校，技师学院毕业生、登记失业人员、返乡农民工、符合单六及双六政策的人员。                                </span>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'
    export default {
        name: "Introduce",
        data() {
            return {
                showType: 3 //1购买流程 2平台优势 3常见问题 4温馨提示
            };
        }
    };
</script>
