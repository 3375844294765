<!--
 * @Description: 办理流程
 * @Autor: bjp
 -->
<template>
  <div class="flowContents">
    <!-- 档案 -->
    <div class="flowChart1" v-if="fileShow || (!fileShow && !hukouShow)">
      <div class="title">办理档案步骤</div>
      <div class="flows">
        <div class="chart">
          <div class="top">
            <div class="topOne"></div>
            <div class="topTwo"></div>
            <ul class="charts flow1">
              <li
                v-for="item in (stepListFile.slice(0,4))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
          </div>
          <div class="bottom">
            <div class="bottomOne"></div>
            <ul class="charts flow2">
              <li
                v-for="item in (stepListFile.slice(4,6))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
            <div class="bottomTwo"></div>
            <ul class="charts flow3">
              <li
                v-for="item in (stepListFile.slice(6,8))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 户口 -->
    <div class="flowChart2" v-if="hukouShow || (!fileShow && !hukouShow)">
      <div class="title">办理户口步骤</div>
      <div class="flows">
        <div class="chart">
          <div class="top">
            <div class="topOne"></div>
            <div class="topTwo"></div>
            <ul class="charts flow1">
              <li
                v-for="item in (stepListResidence.slice(0,3))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
          </div>
          <div class="bottom">
            <div class="bottomOne"></div>
            <ul class="charts flow2">
              <li
                v-for="item in (stepListResidence.slice(3,5))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
            <div class="bottomTwo"></div>
            <ul class="charts flow3">
              <li
                v-for="item in (stepListResidence.slice(5,7))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 档案户口同时 -->
    <div class="flowChart3" v-if="(!fileShow && !hukouShow) || (fileShow && hukouShow)">
      <div class="title">同时办理档案和户口的流程</div>
      <div class="flows">
        <div class="chart">
          <div class="top">
            <div class="topOne"></div>
            <div class="topTwo"></div>
            <ul class="charts flow1">
              <li
                v-for="item in (stepListFileAndResi.slice(0,3))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
          </div>
          <div class="bottom">
            <div class="bottomOne"></div>
            <ul class="charts flow2">
              <li
                v-for="item in (stepListFileAndResi.slice(3,6))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
            <div class="bottomTwo"></div>
            <ul class="charts flow3">
              <li
                v-for="item in (stepListFileAndResi.slice(6,9))"
                :key="item.id"
                >
                <div class="circle">{{item.id}}</div>
                <span>{{item.title}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/graduate/flowChart.scss"
export default {
  props:{
    fileFlag:{
      type:Boolean,
      default:true
    },
    hukouFlag:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      stepListFile:[
        {id:1,title:'注册账号'},
        {id:2,title:'付款成功'},
        {id:3,title:'填写相关信息'},
        {id:4,title:'专员跟进办理'},
        {id:6,title:'毕业后学校邮寄档案到档案室'},
        {id:5,title:'网签'},
        {id:7,title:'档案办理入库手续'},
        {id:8,title:'完成'},
      ],
      stepListResidence:[
        {id:1,title:'注册账号'},
        {id:2,title:'付款成功'},
        {id:3,title:'填写相关信息'},
        {id:5,title:'专员跟进办理'},
        {id:4,title:'递交相关材料'},
        {id:6,title:'派出所落户'},
        {id:7,title:'完成'},
      ],
      stepListFileAndResi:[
        {id:1,title:'APP注册账号'},
        {id:2,title:'付款成功'},
        {id:3,title:'填写相关信息'},
        {id:6,title:'收集整理材料'},
        {id:5,title:'专员跟进'},
        {id:4,title:'递交相关材料'},
        {id:7,title:'专员提交材料到相关部门审核'},
        {id:8,title:'审核通过'},
        {id:9,title:'完成'},
      ],
      fileShow:true,
      hukouShow:true,
    }
  },
  watch:{
    'fileFlag'(val){
      this.fileShow = val
    },
    'hukouFlag'(val){
      this.hukouShow = val
    }
  },
}
</script>