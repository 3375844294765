<template>
  <div></div>
</template>

<script>
import { getUrlParms } from "@/lib/base";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    }),
  },
  mounted() {
    //通过微信 获取用户信息
    let code = getUrlParms("code");
    this.$siaxios.get("/wechat/hcode/" + code).then(res => {
      this.userInfo.openid = res.openid;
      this.userInfo.headimageurl = res.headimageurl;
      this.userInfo.nickname = res.nickname;
      this.userInfo.sex = res.sex;
      this.$store.commit("setMyUserInfo", this.userInfo);
      this.$router.forward({ path: "/index/index" });
    });
  }
};
</script>