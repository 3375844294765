<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>创新创业一次性安家费： 1000元/次 青岛 </p>
                <span>补贴适用范围：</span>
                <p>
                    1、国内普通高校统招全日制研究生、教育部留学服务中心国（境）外学历学的留学回国研究生。
                </p>
                <p>
                    2、博士研究生年龄40周岁以下，硕士研究生年龄35周岁以下（以申报时实际年龄为准）
                </p>
                <span>
                    条件：青岛行政区域内就业，取得全日制研究生、本科学历且获得博士、硕士、学士学位，具有本市户籍的毕业生。
                </span>

            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

