<template>
  <div class="express-detail">
    <div class="ex-detail">
      <div class="item">
        <div class="lable">类型</div>
        <div class="info">{{gettype(detail.type)}}</div>
      </div>
      <div class="item">
        <div class="lable">内含项</div>
        <div
          class="info"
        >{{detail.allfile}}</div>
      </div>
      <div class="item">
        <div class="lable">快递公司</div>
        <div class="info">{{detail.expressname}}</div>
      </div>
      <div class="item">
        <div class="lable">快递单号</div>
        <div class="info">{{detail.expressnum}}</div>
      </div>
      <div class="item">
        <div class="lable">邮寄时间</div>
        <div class="info">{{formatTime(detail.posttime)}}</div>
      </div>
      <div class="item">
        <div class="lable">状态</div>
        <div class="info" :class="{'blue':detail.state==0}">{{detail.state==0?'待签收':'已签收'}}</div>
      </div>
      <div class="item" v-if="detail.signtime">
        <div class="lable">签收时间</div>
        <div class="info">{{formatTime(detail.signtime)}}</div>
      </div>
    </div>
    <div class="ex-submit" @click="$router.forward({path:'/expressmail/send',query:{id:detail.id}})" v-if="detail.type == 1 && detail.state == 0">修改信息</div>
    <div class="ex-submit" @click="sign()" v-if="detail.type == 2 && detail.state == 0">确认签收</div>
  </div>
</template>
<script>
import "@/sass/views/expressmail/detail.scss";
import { formatTime } from "@/lib/base";
export default {
  name: "Expressdetail",
  data() {
    return {
      detail: {}
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    formatTime: formatTime,
    /**
     * 保存信息
     */
    getDetail() {
      this.$siaxios.get(`filepost/info/${this.$route.query.id}`).then(res => {
        this.detail = res;
      });
    },
    gettype(type) {
      if (type == 1) return "用户邮寄";
      if (type == 2) return "公司邮寄";
    },
    sign() {
      this.$siaxios.get(`filepost/sign/${this.$route.query.id}`).then(() => {
        this.getDetail();
      });
    }
  }
};
</script>

