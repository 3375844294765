problem<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>网签很关键，档管家一键搞定。 </p>
                <span>网签就是高校毕业生在毕业生就业信息网上和各用人单位签署的一份三方协议，表明这个企业已经录用你，毕业以后就可以去入职工作。网签之后你的档案就会在毕业之后派发到你公司所在地人社局，而没有网签档案通常会派发会生源地人社局。</span>

            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

