<template>
    <div class="income">
        <div class="top">
            <button @click="$router.forward('/calculator/incomeTax/incomeDetail')">调整收入</button>
            <label>年度综合税前收入</label>
            <span>¥</span>
            <input type="number" v-model="yearInfo.income" readonly>
        </div>
        <ul class="tax-list">
            <li @click="selectCity">
                <label>所在城市</label>
                {{cityname}}
            </li>
            <li @click="$router.forward('/calculator/incomeTax/socialIns')">
                <label>社保总费用</label>
                ¥{{yearInfo.security}}
            </li>
            <li @click="$router.forward('/calculator/incomeTax/fund')">
                <label>公积金总费用</label>
                ¥{{yearInfo.fund}}
            </li>
            <li @click="$router.forward('/calculator/special')">
                <label>月度专项扣除</label>
                ¥{{yearInfo.special}}
            </li>
            <li @click="$router.forward('/calculator/special')">
                <label>年度专项扣除</label>
                ¥{{yearInfo.specialYear}}
            </li>
            <li>
                <label>年度个人所得税</label>
                ¥{{yearInfo.tax}}
            </li>
        </ul>
        <div class="chart-con">
            <div class="text">
                <label>年度税后收入</label>
                <template>¥{{yearInfo.income-yearInfo.security-yearInfo.fund-yearInfo.tax>0?yearInfo.income-yearInfo.security-yearInfo.fund-yearInfo.tax:'0.00'}}</template>
            </div>
            <div class="chart" id="chart"></div>
            <button @click="$router.forward('/calculator/incomeTax/month')">查看月收入记录</button>
        </div>

    </div>
</template>
<script>
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            yearInfo: {
                income: '',
                security: '0.00',
                fund: '0.00',
                special: '0.00',
                specialYear: '0.00',
                tax: '0.00'
            },
            allCity: Vue.getData('allCity'),
            cityid: Vue.getData('cityid') ? Vue.getData('cityid') : 171,
            cityname: Vue.getData('cityname') ? Vue.getData('cityname') : '青岛市',
            taxList: Vue.getData('taxList'),
            allInfo: Vue.getData('allInfo'),
            household: '',
            secList: Vue.getData('secList'),
            fundBasicNum: '',
            month: this.$route.query.month ? this.$route.query.month : new Date().getMonth() + 1,
            workIndex: 0,
            incomeIndex: 0,
            workList: Vue.getData('workList'),
            nodata: false,
            message: '',
            monthInfo: {},
            secBasicNum: 0,
        }
    },
    mounted() {
        this.checkCity();
        Vue.setData('cityid', this.cityid);
        Vue.setData('cityname', this.cityname);

    },
    methods: {
        getYearInfo() {
            this.yearInfo = {
                income: this.countSum('income'),
                security: this.countSum('security'),
                fund: this.countSum('fund'),
                special: Vue.getData('special') ? Vue.getData('special').monthTotal : '0.00',
                specialYear: Vue.getData('special') ? Vue.getData('special').yearTotal : '0.00',
                tax: this.countSum('tax'),
            }

            this.drawPie();
        },
        // 计算总和
        countSum(key) {
            let sum = 0;
            this.workList.forEach(ele => {
                var arr = ele.incomeList.map(item => { return Number(item[key]) })
                sum += eval(arr.join("+"))
            });
            return sum.toFixed(2);
        },
        // 计算当前工资的个税
        countTax(index, num) {
            let money = this.workList[index].incomeList[num].income - this.workList[index].incomeList[num].security - this.workList[index].incomeList[num].fund - this.workList[index].incomeList[num].special;
            let lastMoney = (money - this.taxList[0].taxthreshold).toFixed(2);
            let tax = 0;
            this.taxList.forEach(ele => {
                if (lastMoney <= 0) {
                    tax = 0;
                } else if (lastMoney > ele.lowtax && lastMoney < ele.hightax) {
                    tax = ((lastMoney * ele.taxrate).toFixed(2) * 100 / 100 - ele.quickdeduction).toFixed(2);
                }
            });
            return tax;
        },
        // 计算社保
        countSecurity() {
            if (this.workList[this.workIndex].incomeList[this.incomeIndex].secType == 1) {
                if (this.monthInfo.income < this.allInfo.lowshebao) {
                    this.secBasicNum = this.allInfo.lowshebao;
                } else if (this.monthInfo.income > this.allInfo.highshebao) {
                    this.secBasicNum = this.allInfo.highshebao
                } else {
                    this.secBasicNum = this.monthInfo.income;
                }
            } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].secType == 2) {
                this.secBasicNum = this.allInfo.lowshebao
            } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].secType == 3) {
                this.secBasicNum = 0
            } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].secType == 4) {
                this.secBasicNum = this.workList[this.workIndex].incomeList[this.incomeIndex].secBasicNum;
            }
            let sum = 0;
            this.secList.forEach(ele => {
                sum += ele.people * this.secBasicNum;
            });
            return sum.toFixed(2);
        },
        //计算公积金
        countFund() {
            if (this.workList[this.workIndex].incomeList[this.incomeIndex].fundType == 1) {
                if (this.monthInfo.income < this.allInfo.lowgongjijin) {
                    this.fundBasicNum = this.allInfo.lowgongjijin;
                } else if (this.monthInfo.income > this.allInfo.highgongjijin) {
                    this.fundBasicNum = this.allInfo.highgongjijin
                } else {
                    this.fundBasicNum = this.monthInfo.income;
                }
            } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].fundType == 2) {
                this.fundBasicNum = this.allInfo.lowgongjijin;
            } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].fundType == 3) {
                this.fundBasicNum = 0
            } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].fundType == 4) {
                this.fundBasicNum = this.workList[this.workIndex].incomeList[this.incomeIndex].fundBasicNum;
            }
            return (this.fundBasicNum * this.workList[this.workIndex].incomeList[this.incomeIndex].fundScale).toFixed(2);
        },
        // 验证当前城市是否支持计算
        checkCity() {
            if (this.allCity.filter(item => { return item.id == this.cityid }).length == 0) {
                this.message = "该城市暂不支持查询，请切换其他城市"
                this.nodata = true;
            } else {
                this.getYearInfo();
            }
        },
        //选择城市
        selectCity() {
            this.$citycalculatorselect({
                callback: (id, name) => {
                    this.cityid = id;
                    this.cityname = name;
                    Vue.setData('cityid', this.cityid);
                    Vue.setData('cityname', this.cityname);
                    this.getWorkerInfo()
                }
            });
        },
        getWorkerInfo() {
            this.$siaxios.get("/order/getCityBase/" + this.cityid).then(res => {
                this.allInfo = res;
                Vue.setData('allInfo', res);
                this.household = res.sjgjHouseHoldRegisters[0].id;
                this.getScaleDetail();
            })
        },
        /**
         * 根据户籍性质获取社保比例详情
         */
        getScaleDetail() {
            this.$siaxios
                .get(`/order/getBaoXian/${this.household}`)
                .then(res => {
                    this.secList = res;
                    Vue.setData('secList', res);
                    this.workList.forEach((ele, index) => {
                        ele.incomeList.forEach((item, num) => {
                            item.security = this.countSecurity(index, num);
                            item.fund = this.countFund(index, num);
                            item.tax = this.countTax(index, num);
                        });
                    });
                    Vue.setData('workList', this.workList);
                    this.getYearInfo();
                });
        },
        drawPie() {
            let leftMoney = this.yearInfo.income - this.yearInfo.security - this.yearInfo.fund - this.yearInfo.tax;
            let seriesData = []
            if (leftMoney > 0) {
                seriesData = [
                    { value: (leftMoney / this.yearInfo.income * 100).toFixed(2), name: '税后收入' },
                    { value: (this.yearInfo.fund / this.yearInfo.income * 100).toFixed(2), name: '公积金缴纳' },
                    { value: (this.yearInfo.security / this.yearInfo.income * 100).toFixed(2), name: '社保缴纳' },
                    { value: (this.yearInfo.tax / this.yearInfo.income * 100).toFixed(2), name: '个人所得税' },
                ]
            }else{
                seriesData=[
                    {value: 100, name: '暂无数据'}
                 ]
            }
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("chart"));
            // 绘制图表
            myChart.setOption({
                color: ["#2caa7f", '#4884ff', '#ff7a7a', '#ffb849'],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                grid: {
                    //图表的位置
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    containLabel: true
                },
                legend: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    icon: 'circle',
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['税后收入', '公积金缴纳', '社保缴纳', '个人所得税']
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '66%'],
                        center: ['18%', '35%'],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        itemStyle: {
                            borderWidth: leftMoney>0?3:0,
                            borderColor: '#fdfeff'
                        },
                        label: {
                            show: leftMoney>0?false:true,
                            position: 'center',
                        },
                        emphasis: {
                            show:leftMoney>0?true:false,
                            label: {
                                show: true,
                                fontSize: '16',
                                formatter: '{c}%'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: seriesData
                    }
                ]
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.income {
    min-height: 100vh;
    background: #fdfeff;
    .top {
        height: 250px;
        background: #2caa7f;
        padding: 70px 40px 0 40px;
        color: #fff;
        position: relative;
        button {
            float: right;
            width: 150px;
            height: 46px;
            border-radius: 23px;
            border: #fff 1px solid;
            color: #fff;
            font-size: 24px;
            background: none;
            outline: none;
        }
        label {
            font-size: 26px;
            margin-bottom: 30px;
            display: block;
        }
        span {
            font-size: 46px;
            line-height: 46px;
            width: 30px;
        }
        input {
            border: none;
            border-bottom: #fff 1px solid;
            background: none;
            padding: 6px 0 24px 34px;
            font-size: 46px;
            line-height: 46px;
            color: #fff;
            position: relative;
            margin-left: -30px;
            width: calc(100% - 40px);
        }
    }
    .tax-list {
        background: #fff;
        margin: -70px 30px 55px 30px;
        border-radius: 10px;
        position: relative;
        z-index: 2;
        padding: 0 40px;
        box-shadow: 0 0px 20px 1px #eaf2ff inset;
        li {
            height: 100px;
            line-height: 100px;
            background: url("../../../assets/right.png") right center no-repeat;
            background-size: 10px auto;
            text-align: right;
            border-bottom: #eeeeee 1px solid;
            padding-right: 25px;
            font-size: 28px;
            color: #333;
            &:last-child {
                border-bottom: none;
                background: none;
            }
            label {
                color: #666;
                font-size: 26px;
                float: left;
            }
        }
    }
    .chart-con {
        padding: 0 40px 40px 40px;
        position: relative;
        .chart {
            height: 240px;
            margin-bottom: 46px;
        }
        .text {
            position: absolute;
            left: 42%;
            top: 0;
            top: 30px;
            font-size: 46px;
            color: #2caa7f;
            label {
                color: #333;
                font-size: 26px;
                display: block;
                margin-bottom: 20px;
            }
            span {
                font-size: 24px;
                color: #999;
            }
        }
        button {
            height: 80px;
            background: #2caa7f
                url("../../../assets/views/calculator/arrow.png") 582px center
                no-repeat;
            background-size: 40px 30px;
            border-radius: 40px;
            color: #fff;
            font-size: 30px;
            width: 100%;
            border: none;
            padding: 0 45px;
            text-align: left;
            outline: none;
        }
    }
}
</style>