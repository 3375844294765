<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>高校毕业生小微企业就业补贴 400元（青岛） </p>
                <span>补贴适用范围：</span>
                <p>
                    2018年及以后毕业的派遣期内普通高校统招全日制本科及以上学历毕业生
                    条件：毕业生在我市小微企业就业，企业按规定办理就业登记、缴纳城镇职工社会保险的。
                </p>
            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";
    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

