import Vue from 'vue';

/**
 * 对Vue对象的扩展
 * ==============================================
 */

/**
 * setter getter
 */
let _vue_ = {
    STORE_MAIN_KEY: 'calculator',
    set store(data) {
        this._data = data;
        localStorage[this.STORE_MAIN_KEY] = JSON.stringify(data);
    },
    get store() {
        if (!this._data) {
            var storeStr = localStorage[this.STORE_MAIN_KEY];
            if (storeStr === undefined) {
                localStorage[this.STORE_MAIN_KEY] = '{}';
                this._data = {};
            } else {
                this._data = JSON.parse(storeStr);
            }
        }
        return this._data;
    }
};


/**
 * 通过Vue对象设置数据，有两种重载
 *  给指定对象赋值 Vue.setData({},'key','val')
 *  存储到Vue全局 Vue.setData('key','val')
 * 注：第二种视情况可考虑修改为存储到localStorage
 */
Vue.setData = function() {
    var obj, key, val, lastobj, initData;
    if (arguments.length === 3) {
        obj = arguments[0];
        key = arguments[1];
        val = arguments[2];
        lastobj = obj;
    } else {
        key = arguments[0];
        val = arguments[1];
        initData = lastobj = _vue_.store;
    }
    var paths = key.split('.');
    for (var i = 0; i < paths.length - 1; i++) {
        if (Vue.isNone(lastobj[paths[i]])) {
            lastobj[paths[i]] = {};
        }
        lastobj = lastobj[paths[i]];
    }
    lastobj[paths[paths.length - 1]] = val;
    if (arguments.length === 2) _vue_.store = initData;
};

/**
 * 通过Vue获取数据，有多种重载
 * Vue.getData([obj],key,[defaultVal])
 *  获取指定对象的属性Vue.getData({},'key')
 *  获取Vue全局对象的属性Vue.getData('key')
 *  带有默认值 Vue.getData('key',[])
 * 注：obj不能是字符串，不过可以使用new String(str)
 */
Vue.getData = function() {
    var obj, key, lastobj, defaultVal;
    var args = arguments;

    if (typeof args[0] !== 'string' && typeof args[1] === 'string') {
        obj = args[0];
        lastobj = obj;
        Array.prototype.shift.call(args);
    } else {
        lastobj = _vue_.store;
    }
    key = args[0];
    defaultVal = args[1];

    var paths = key.split('.');
    for (var i = 0; i < paths.length; i++) {
        if (Vue.isNone(lastobj)) return defaultVal;
        lastobj = lastobj[paths[i]];
    }
    return lastobj === undefined ? defaultVal : lastobj;
};

/**
 * 判断对象是否为空（null和undefined）
 */
Vue.isNone = function(obj) {
    return obj === null || obj === undefined;
};

Vue.isNull = function(obj) {
    return obj === null || obj === undefined || obj === '';
};
/**
 * 清空 localStorage 数据
 */
Vue.cleanUp = function() {
    localStorage.removeItem(_vue_.STORE_MAIN_KEY)
};