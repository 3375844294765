<template>
    <div class="children calculator">
        <div class="top-money">
            <button @click="clearData">清除数据</button>
            <label>本人年度扣除金额</label>
            <span>¥</span>
            <input type="number" v-model="old.total" readonly>
        </div>
        <div class="form-con border-top">
            <div class="form-group">
                <label>类型选择</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="old.type" value="1"></v-radio><span>独生子女</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="old.type" value="2"></v-radio><span>非独生子女</span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-show="old.type==2">
                <div class="right-input">
                    <input type="number" placeholder="请输入比例" v-model="old.scale" @blur="check">%
                </div>
                <label>本人分摊比例</label>
            </div>
            <div class="form-group">
                <label>扣除方式</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="old.deductType" value="1"></v-radio><span>每月</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="old.deductType" value="2"></v-radio><span>每年</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="right-select" ref="month">
                    {{old.monthNum}}月
                </div>
                <label>开始扣除月份</label>
            </div>
        </div>
        <div class="description">
            <div class="title"><span>赡养老人抵扣说明</span></div>
            <dl class="description-text">
                <dt>扣除范围</dt>
                <dd>
                    <ul class="list">
                        <li>独生子女，每月抵扣2000元/每年抵扣24000</li>
                        <li>非独生子女，根据分摊比例分配，分摊金额不超过每年12000元</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>适用范围</dt>
                <dd>
                    60岁（含）以上父母以及祖辈
                </dd>
            </dl>
            <dl class="description-text">
                <dt>证明材料</dt>
                <dd>
                    个人申报+政府相关数据，税务部门核实
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>
import MobileSelect from "@/components/plug-in/mobile-select";
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            old: {
                total: 0,
                type: '',
                scale: '',
                deductType: '',
                monthNum: 1
            },
            monthList: []
        }
    },
    watch: {
        "old": {
            handler(newVal) {
                let sum = 0;
                if (newVal.type == 1) {
                    sum = (12000 / 12 * (13 - newVal.monthNum)).toFixed(2)*100/100
                } else if (newVal.type == 2 && newVal.scale) {
                    sum = ((12000 * newVal.scale / 100) / 12 * (13 - newVal.monthNum)).toFixed(2)*100/100
                }
                this.old.total = sum;
                Vue.setData('special.old', this.old);
            },
            deep: true
        }
    },
    created() {
        for (var i = 1; i < 13; i++) {
            this.monthList.push({ id: i, value: i + '月' })
        }
    },
    mounted() {
        if (!!Vue.getData('special') && !!Vue.getData('special.old')) {
            this.old = Vue.getData('special.old');
        }
        this.selectMonth()
    },
    methods: {
        check(){
            if(this.old.scale<0 || this.old.scale>50){
                this.$alert('请输入0-50之间的正数')
                if(this.old.scale<0){
                    this.old.scale=0
                }else{
                    this.old.scale=50
                }
            }
        },
        clearData() {
            this.old = {
                total: 0,
                deducter: '',
                deductType: '',
                monthNum: 1
            }
        },
        selectMonth() {
            new MobileSelect({
                trigger: this.$refs.month,
                title: "开始扣除月份",
                wheels: [{ data: this.monthList }],
                callback: (indexArr, data) => {
                    this.old.monthNum = data[0].id;
                }
            });

        }
    }

}
</script>
<style lang="scss" scoped>
.calculator {
    background: #fff;
    min-height: 100vh;
}
.top-money {
    background-image: linear-gradient(to right, #ff6364, #ff9190);
}
</style>