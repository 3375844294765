<template>
    <div class="house-index" >
        <div class="banner-con">
            <div class="banner">
                <span>——&nbsp;&nbsp;济南落户政策详细解读&nbsp;&nbsp;——</span>
            </div>
        </div>
        <div class="title">
            <img src="@/assets/views/mine/jinan1.png" alt/>
            <span class="title-">济南人才落户</span>
        </div>
        <div style="margin-top:15px">
            <div class="con-title-left"></div>
            <div class="con-title-right"></div>
            <div class="cont" style="background: #eff5ff;">
                <div class="con-title-center">
                    <span>嘉奖类人才</span>
                </div>
                <div class="cont-content">
                    <span>受到中央部委、省委省政府、表彰或在世界技能大赛和国家级一、二类职业技能竞赛中获奖人员或近3年荣获本省一类职业技能竞赛三等奖以上或本市一类职业技能竞赛二等奖以上的人员本人、配偶及未成年子女</span>
                </div>

            </div>
        </div>
        <div class="top-con" >
            <div class="con-title-left" style="border-color: transparent #33a6ff #33a6ff transparent;"></div>
            <div class="con-title-right" style="border-color: transparent transparent #33a6ff #33a6ff ;"></div>
            <div class="cont" style="background: #e9f5ff;height: 116px">
                <div class="con-title-center" style="background: #33a6ff;border-color: #33a6ff">
                    <span>50岁以下</span>
                </div>
                <div class="cont-content">
                    <span>具有中级以上专业技术职务资格，或工人技师以上职业资格，签订劳动合同且按规定参加本市城镇职业养老保险的人员本人、配偶及未成年子女</span>
                </div>

            </div>
        </div>
        <div class="top-con">
            <div class="con-title-left" style="border-color: transparent #ff8933 #ff8933 transparent;"></div>
            <div class="con-title-right" style="border-color: transparent transparent #ff8933 #ff8933 ;"></div>
            <div class="cont" style="background: #fff2e9;height:250px">
                <div class="con-title-center" style="background: #ff8933;border-color: #ff8933">
                    <span>45周岁以下</span>
                </div>
                <div class="cont-content" style="margin-top:5px">
                    <div class="title-cont" ></div>
                    <span>具有初级专业技术职务或高级职业资格，签订劳动合同且按规定参加本市城镇职工养老保险满2年，且持有居住证的人员本人、配偶及未成年子女</span>
                </div>
                <div class="cont-content" style="margin-top:15px">
                    <div class="title-cont" ></div>
                    <span >具有国家承认的研究生学历或本科学历(含教育部认定的留学归国取得同等学历人员)，签订劳动合同且按规定参加本市城镇职工养老保险的人员本人、配偶及未成年子女</span>
                </div>

            </div>
        </div>
        <div class="top-con">
            <div class="con-title-left" style="border-color: transparent #00c289 #00c289 transparent;"></div>
            <div class="con-title-right" style="border-color: transparent transparent #00c289 #00c289 ;"></div>
            <div class="cont" style="background: #ecfff9;height:130px">
                <div class="con-title-center" style="background: #00c289;border-color: #00c289">
                    <span>45周岁以下</span>
                </div>
                <div class="cont-content">
                    <span>具有全日制普通大专、中专学历(含职业院校及教育部认定的留学归国取得同等学历人员)，按规定参加本市城镇职工养老保险分别满1年、2年，并签订劳动合同且取得居住证的人员本人、配偶及未成年子女</span>
                </div>

            </div>
        </div>
        <div class="top-back" style="margin-top:20px"></div>
        <div class="title">
            <img src="@/assets/views/mine/jinan2.png" alt/>
            <span class="title-">居住就业落户</span>
        </div>
        <div class="cont" style="height:340px;background: white;padding-top:0;margin-left:3px ">
            <div class="con-cont" >
                <div class="con-introduce">
                    <span>按规定 <span style="color:#00c289">参加本市城镇职工养老保险满1年，拥有合法住宅用途房产</span>(含居住性公寓)并 <span style="color:#00c289">取得房产证或不动产证</span>的人员本人、配偶及其未成年子女，可在本区域落户 </span>
                </div>
            </div>
            <div class="con-cont" style="margin-top:35px">
                <div class="con-introduce">
                    <span> <span style="color:#00c289">每套住宅用途房产</span>（含居住性质公寓），自落户之日起 <span style="color:#00c289">两年内</span>只能享受一次居住就业落户政策 </span>
                </div>
            </div>
            <div class="con-cont" style="margin-top:10px">
                <div class="con-introduce">
                    <span> <span style="color:#00c289">夫妻一方参加本市城镇职工养老保险，另一方拥有房产，申请迁入本区域，</span>
                        应当办理整户迁移。 <span style="color:#00c289">房屋产权属共同所有，所有人均系外地常住户口</span> 居民的，允许
                        <span style="color:#00c289">其中1人</span>按相关居住就业落户条件办理在济落户，其他共有人需出具同意其落户证明；所有人中有济南市区常住户口居民的，其他所有人不得通过本套房屋办理居住就业落户
                    </span>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import "@/sass/views/gallery/buhouse.scss";
    export default {
        name: "index",
        data() {
            return {};
        },

    };
</script>
<style lang="scss" scoped>
    .banner-con{
        background: url(../../assets/views/mine/jinan-luohu.jpg) 100% 100%;
        background-size: cover;
    }
  
    .con-title-left {
        width: 0;
        height: 0;
        border: 13px solid;
        border-color: transparent #3367ff #3367ff transparent;
        margin-left: 267px;
        float: left;
    }

    .cont {
        position: relative;
        padding-top: 72px;
        margin-top: 0;
        width: 690px;
        height: 270px;
        border: 1px solid white;
        border-radius: 20px;
        .cont-content {
            width: 627px;
            margin-left: 33px;
            font-size: 26px;
        }
        span {
            line-height: 50px;

        }
    }

    .con-title-center {
        width: 181px;
        height: 53px;
        background: #3367ff;
        border: 1px solid #3367ff;
        border-radius: 10px;
        position: absolute;
        top: -28px;
        left: 257px;
        text-align: center;

        span {
            color: white;
            line-height: 53px;
            font-size: 26px;
        }
    }

    .con-title-right {
        width: 0;
        height: 0;
        border: 13px solid;
        border-color: transparent transparent #3367ff #3367ff;
        margin-left: 466px;
        /*float: left;*/
    }
    .top-con{
        margin-top:80px
    }
    .title-cont{
        width:38px;
        height:6px;
        background: #ff8933;
        margin-bottom: 10px;
    }

</style>