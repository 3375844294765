<template>
    <div class="order-services">
        <div class="title">
            <div class="item" @click="showType = 1" :class="{'active': showType == 1}">购买流程</div>

            <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>

        </div>

        <div class="show-content">
            <div
                    class="showtype" style="background: #f6faff;"
                    v-if="showType == 1"
            >
                <div class="order-title">购买流程</div>
                <div class="showinfo-1">
                    <div class="showinfo-1-">
                        <div class="showinfo-1-item" style="margin-left:20px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-1.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>下载APP</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-2.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>注册成功</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-3.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>完成支付</span>
                            </div>
                        </div>
                    </div>
                    <div class="showinfo-1-">
                        <div class="show-img">
                            <img src="@/assets/views/creatorder/arrow2.png" alt="">
                        </div>
                    </div>
                    <div class="showinfo-1-" style="margin-top: 50px">
                        <div class="showinfo-1-item" style="margin-left:20px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-6.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>完成</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="" style="transform: rotate(180deg);">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-5.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>专员办理</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="" style="transform: rotate(180deg);">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-4.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>专员跟进</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                    class="showtype "
                    style=" background: #f6faff;"
                    v-if="showType == 3"
            >
                <div class="order-title">常见问题</div>
                <div class="showinfo-3">
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                                毕业生档案可以自取吗
                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                                <span>
不可以。
毕业生档案只能公对公转移。学生毕业时，提交派遣申请，就业办老师会根据学生提交的派遣申请信息，将学生档案用EMS高校档案专用邮寄通道邮寄给派遣地。
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                                是不是我只要提交了派遣申请，领取了《报到证》，就没事儿了，反正学校会把我的档案邮寄
                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                                <span>
并不是。
领取《报到证》之后，需根据《报到证》上档案邮寄地的信息，在档案已邮寄之后至档案邮寄地查看本人档案是否已到，并上交《报到证》。

                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                                档案一般派遣到什么地方
                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                                <span>
档案派遣根据学生提交的申请，一般有两个派遣地：
  派遣至原生源地的毕业生就业部门
派遣至就业单位，根据学生提交的《就业协议书》上关于“档案转递单位”和“档案转递单位地址”邮寄

                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
我如何查看我的档案派遣到哪里去了呢                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                                <span>
以下两个方式可以查看自己的档案派遣地：
   派遣手续完成，并档案邮寄后，就业办公室会发邮件至学生的个人邮箱、西浦邮箱，告知档案派遣地信息，并同时告知邮寄档案的EMS单号，以便学生查看档案邮寄进度；
  领取《报到证》后，《报到证》上有档案派遣地的信息。

                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
我如何确认我的档案已经派遣到档案派遣地了呢                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                                <span>
有以下两个方式：
    本人至档案派遣地，查看档案是否已到，并上交《报到证》；
       登录档案派遣地的官网，查看档案是否已到
。

                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'

    export default {
        name: "Introduce",
        data() {
            return {
                showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
            };
        }
    };
</script>
