<template>
  <div  class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">重要说明 :（必读）</div>
      <div class="content">青岛居住证是吸引人才的重要手段，拥有青岛居住证后可享受与青岛户口相同的买车，子女上学，办理出入境等权益。</div>
      <div class="content">档管家为您提供在线办理居住证服务，让您无需奔波即可办理，第一时间享受青岛福利。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">公积金封存注意事项</div>
      <div class="content">若您停止购买后，本平台通常会于次月截止时间后一周操作封存公积金（部分地区可能会有延迟），需要提前封存的用户，请提前联系顾问</div>
      <div class="content">如果您当月申请封存后，又想重新购买当月的公积金，请务必先联系顾问撤销封存，避免漏缴</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">办理流程</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
        <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
        <div class="item" @click="showType = 4" :class="{'active': showType == 4}">温馨提示</div>
      </div>
      <div class="show-content">
        <div
          class="showtype"
          style="background: #f6faff;"
          v-if="showType == 1"
        >
          <div class="order-title">办理流程</div>
          <div class="showinfo-1">
            <div class="showinfo-1-">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-1.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>办理人登记填写信息资料</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-3.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>付款成功</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-4.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>专员跟进</span>
                </div>
              </div>
            </div>
            <div class="showinfo-1-">
              <div class="show-img">
                <img src="@/assets/views/creatorder/arrow2.png" alt />
              </div>
            </div>
            <div class="showinfo-1-" style="margin-top: 40px">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-6.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>完成</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-5.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>专人办理</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-2.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>收集材料</span>
                </div>
              </div>
            </div>
          </div>
        </div><div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="showtype showtype3"
          v-if="showType == 3"
        >
          <div class="order-title">常见问题</div>
          <div class="showinfo-3">
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>办理流动人口居住证都需要什么条件</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>公民离开常住户口所在地，到其他城市居住半年以上，符合有合法稳定就业，合法稳定住所，连续就读条件之一的，可以申领居住证</span>
                </div>
              </div>
            </div>
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>办理居住证周期有多长</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>自受理之日起15个工作日</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="showtype"
          v-if="showType == 4"
        >
          <div class="order-title">温馨提示</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item" style="height:70px">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>避免因错过截止时间忘记下单，造成社保断交的，建议您一次性购买多个月份，若有新的工作单位，未缴费的月份可申请退款，若申请退费，请于该月份截止时间前联系顾问</span>
              </div>
            </div>
            <div class="showinfo-2-item" style="height:50px">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>本套餐适用于18至法定退休年龄之间的用户购买</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>