import { render, staticRenderFns } from "./incomeDetail.vue?vue&type=template&id=0fc13d08&scoped=true&"
import script from "./incomeDetail.vue?vue&type=script&lang=js&"
export * from "./incomeDetail.vue?vue&type=script&lang=js&"
import style0 from "./incomeDetail.vue?vue&type=style&index=0&id=0fc13d08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc13d08",
  null
  
)

export default component.exports