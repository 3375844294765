<template>
    <div class="income-detail">
        <dl class="work" v-for="(item,key) in workList" :key="key">
            <dd v-for="(value,index) in item.incomeList" v-show="value.type==1" :key="index">
                <div class="main">
                    <div class="month">
                        <label>{{value.month}}月</label>
                        <button @click="$router.push({path:'/calculator/security/workers',query:{month:value.month}})">修改</button>
                    </div>
                    <div class="income">
                        <div class="cur">
                            <label>当月个人缴纳</label>¥{{Number(value.security)}}
                        </div>
                        <div class="sum">
                            <label>累计个人缴纳</label>¥{{countSum(key,index)}}
                        </div>
                    </div>
                </div>
            </dd>
        </dl>
    </div>
</template>
<script>
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            workList: Vue.getData('workList')
        }
    },
    methods:{
         countSum(key, index) {
            let sum = 0;
            for (let i = 0; i <= index; i++) {
                sum += Number(this.workList[key].incomeList[i].security);
            }
            return sum;
        },
    }
}
</script>
<style lang="scss" scoped>
.income-detail {
    .work {
        padding: 30px 30px 10px 30px;
        dd {
            margin-bottom: 30px;
            position: relative;
            height: 180px;
            .main {
                background: #4a81e4;
                background-size: 149px auto;
                border-radius: 12px;
                padding: 0 40px 0 30px;
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 70px);
                z-index: 2;
                .month {
                    width: 160px;
                    padding: 44px 0 0 0;
                    text-align: center;
                    margin-right: 20px;
                    display: inline-block;
                    label {
                        display: block;
                        font-size: 40px;
                        line-height: 40px;
                        margin-bottom: 18px;
                    }
                    button {
                        width: 100px;
                        height: 40px;
                        border: #fff 1px solid;
                        border-radius: 10px;
                        background: none;
                        outline: none;
                        font-size: 22px;
                        color: #fff;
                    }
                }
                .income {
                    width: calc(100% - 180px);
                    display: inline-block;
                    vertical-align: top;
                    > div {
                        line-height: 90px;
                        text-align: right;
                        font-size: 30px;
                        &.cur {
                            border-bottom: 1px solid rgba(#fff, 0.3);
                        }
                        label {
                            font-size: 28px;
                            opacity: 0.8;
                            float: left;
                        }
                    }
                }
            }
        }
    }
}
</style>