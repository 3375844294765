<template>
    <div class="order-services">
        <div class="title">
            <div class="item" @click="showType = 4" :class="{'active': showType == 4}">温馨提示</div>
        </div>
        <div class="show-content">
            <div
                    class="showtype"
                    v-if="showType == 4"
            >
                <div class="order-title">温馨提示</div>
                <div class="showinfo-2">
                    <div class="showinfo-2-item" style="height:70px">
                        <div class="num">
                            <span>1</span>
                        </div>
                        <div class="item-cont">
                            <span>申报人员上学期间为就业状态或有城镇职工社保缴纳记录的,不能申报高校毕业生住房补贴。</span>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'
    export default {
        name: "Introduce",
        data() {
            return {
                showType: 4 //1购买流程 2平台优势 3常见问题 4温馨提示
            };
        }
    };
</script>
