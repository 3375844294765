<template>
  <div class="QD-residence-index">
    <div class="banner-con">
      <div class="banner">
        <span>— 青岛积分落户详细解读 —</span>
      </div>
    </div>
    <!-- 入学条件 -->
    <div class="title">
      <img src="@/assets/views/mine/house.png" alt />
      <span class="title-">基本条件</span>
    </div>
    <div class="cont-frist">
      <div class="con-cont" style="font-weight: bold">申请入户青岛市，须同时符合以下条件</div>
      <div class="con-cont">
        <div class="con-introduce">
          <span>在本市有合法固定住所</span>
        </div>
      </div>
      <div class="con-cont">
        <div class="con-introduce">
          <span>持本市有效的山东省居住证满1年</span>
        </div>
      </div>
      <div class="con-cont">
        <div class="con-introduce">
          <span>在本市就业或创业并正常连续缴纳社会保险满1年（补缴社 保不予认可）</span>
        </div>
      </div>
      <div class="con-cont">
        <div class="con-introduce">
          <span>无犯罪记录</span>
        </div>
      </div>
    </div>
    <div class="top-back"></div>
    <!--        入学材料-->
    <div class="title">
      <img src="@/assets/views/mine/cookie.jpg" alt />
      <span class="title-">积分条件</span>
    </div>
    <div class="cont">
      <div class="con-title" style="margin-bottom: 0px;">
        <span>100分</span>
      </div>
      <div class="con-cont">
        <div class="cont-cont-">
          根据青岛经济社会发展情况建立积分指标体系，对申请人 申请落户的条件进行量化评分，当申请人累计积分达到规 定分值
          <span style="color: #3367ff">100</span>分时，经公示无异议的，获得入户资格
        </div>
      </div>
    </div>
    <!-- 所需资料 -->
    <div class="top-back"></div>
    <div class="title">
      <img src="@/assets/views/mine/jifen.jpg" alt />
      <span class="title-">所需资料</span>
    </div>
    <div class="cont-frist source-img">
      <div class="con-cont">
        <div class="con-introduce">
          <span>居住证原件及复印件</span>
        </div>
      </div>
      <div class="con-cont">
        <div class="con-introduce">
          <span>不动产权属证书原件及复印件</span>
        </div>
      </div>
      <div class="con-cont">
        <div class="con-introduce">
          <span>积分指标证明材料原件及复印件</span>
        </div>
      </div>
      <div class="con-cont" style="margin-top:30px;padding-bottom: 20px">
        <div class="warn">
          <img src="@/assets/views/mine/warn.jpg" alt />
        </div>
        <div class="warin-cont">并对提供申请材料的真实性做出的书面承诺</div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/QD-residence.scss";

export default {
  name: "index",
  data() {
    return {};
  }
};
</script>
