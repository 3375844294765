<template>
  <div class="zxtz">
    <div v-for="(item,index) in xqlist" :key="index" :class="item.imgurl  ? 'bottom2' : 'bottom1'" @click="zxJump(item.id)">
      <div class="bottom2title1">{{item.title}}</div>
      <div class="bottom2title2">{{item.introduce}}</div>
      <div class="bottom2title3"></div>
      <img v-if="item.imgurl" :src="item.imgurl" alt />
      <div class="bottom2title4">{{formatTime(item.publishtime)}}</div>
    </div>
  </div>
</template>

<script>
import { formatTime } from "@/lib/base.js";
export default {
  data(){
    return {
      xqlist:[],
    }
  },
  mounted(){
    this.xq()
  },
  activated(){
    this.xq()
  },
  props:{
    type:{},
  },
  methods:{
    formatTime: formatTime,
    zxJump(id){
      this.$router.forward({
        path:'/information/detail',
        query: { id:id}  
      })
    },
    xq(){
      this.$siaxios.get(`information/list/1?zixuntype=2&type=${this.type}`).then(res => {
        this.xqlist = res.list
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .bottom1{
    width:681px;
    height:254px;
    padding: 0 42px 0 27px;
    border-top:1px solid #eeeeee;
    background:#ffffff;
    position: relative;;
    .bottom2title1{
      font-size:28px;
      color:#333333;
      font-weight: 600;
      margin-top:41px;
    }
    .bottom2title2{
      width:681px;
      height:70px;
      font-size:25px;
      color:#555555;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-top:26px;
    }
    .bottom2title4{
      font-size:23px;
      color:#999999;
      margin-top:12px;
    }
  }
  .bottom2{
    width:681px;
    height:254px;
    padding: 0 42px 0 27px;
    border-top:1px solid #eeeeee;
    background:#ffffff;
    position: relative;
    .bottom2title1{
      font-size:28px;
      color:#333333;
      font-weight: 600;
      margin-top:41px;
      width:425px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis
    }
    .bottom2title2{
      width:392px;
      height:70px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-top:26px;
      color: #555;
    }
    .bottom2title3{
      width:106px;
      height:37px;
      background:#ffffff;
      position:absolute;
      top:140px;
      left:314px;
    }
    img{
      width:240px;
      height:180px;
      position:absolute;
      top:34px;
      right:34px;
    }
    .bottom2title4{
      font-size:23px;
      color:#999999;
      margin-top:12px;
    }
  }
</style>