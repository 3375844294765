<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>技术技能提升补贴，（青岛，济南）（200元） </p>
                <span>补贴适用范围：</span>
                <p>
                    职工取得职业资格证书、职业技能等级证书、专业技术人员职业资格证书等，按照等级进行补贴1000元—2000元。专业技术人员职业资格证书按等级补贴1500元—2000元。
                    档管家，从技能培训到技能补贴一站式服务，让您的职业资格证更值钱。
                </p>

            </div>
        </div>

    </div>
</template>
<script>
      import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

