<template>
  <div class="provefind-index">
    <div class="banner-con">
      <div class="banner">
        <div class="title-banner">档案找回</div>
      </div>
    </div>
    <div class="cont-use">
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>不知道档案在哪？在哪查？找谁查？没时间？电话打不通？找不着人？ 档管家可为您提供查询服务。让您省心省力，解决您的后顾之忧</span>
        </div>
      </div>
    </div>
    <div class="gary-line"></div>
    <div class="cont-use">
      <div class="use-cont" >
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img" style="margin-left:10px;">
                    <img src="@/assets/views/mine/zixun-.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>咨询专业顾问</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow" style="margin-left:-5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:28px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>付款成功</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow" style="margin-left:8px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:22px;">
                  <div class="showinfo-1-img" style="margin-left:10px;">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 20px;">
                <div class="showinfo-1-item" style="margin-left:20px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:12px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:22px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/kehu.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>客户本人核实</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:-8px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:22px;">
                  <div class="showinfo-1-img" style="margin-left:5px;">
                    <img src="@/assets/views/mine/idcard.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>提供查询信息</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/prove-find.scss";

export default {
  name: "archives-use",
  data() {
    return {};
  }
};
</script>


