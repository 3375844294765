<template>
    <div class="flexible calculator">
        <div class="top">
            <div class="city" @click="selectCity">
                <label>城市</label>
                {{cityname}}
            </div>
        </div>
        <div v-if="!nodata">

            <div class="type" v-for="(item,index) in allInfo" :key="index">
                <div class="title">
                    <label for="">是否缴纳{{item.name}}</label>
                    <div class="radio-con">
                        <v-radio v-model="other.formList[index].checked" v-bind:value="1"></v-radio><span>缴纳</span>
                        <v-radio v-model="other.formList[index].checked" v-bind:value="2"></v-radio><span>不缴纳</span>
                    </div>
                </div>
                <div class="con" v-show="other.formList[index].checked==1">
                    <div class="format" v-for="(basic,key) in item.sjgjCalculatorBaseList" :key="basic.id" v-show="item.basetype==2 && key==0">
                        <label>{{item.name}}基数按照{{Number(item.sjgjCalculatorBaseList[0].highmoney)}}-{{Number(item.sjgjCalculatorBaseList[0].lowmoney)}}之间自由选择：</label>
                        <span>元</span>
                        <input type="number" placeholder="请输入" v-model="other.formList[index].baseNum" @blur="testBaseNum(item,index)">
                    </div>
                    <div class="format" v-show="item.basetype==1">
                        <label>{{item.name}}基数按照以下标准自由选择：</label>
                        <div class="radio-con">
                            <template v-for="value in item.sjgjCalculatorBaseList">
                                <v-radio :key="value.id" v-model="other.formList[index].baseNum" v-bind:value="value.money"></v-radio>
                                <span :key="value.id">{{value.money}}</span>
                            </template>
                        </div>
                    </div>
                    <div class="format" v-show="item.proportiontype==2">
                        <label>{{item.name}}缴费标准：</label>
                        <div class="radio-con">
                            <template v-for="value in item.sjgjCalculatorProportionList">
                                <v-radio :key="value.id" v-model="other.formList[index].proportion" v-bind:value="value.proportionnum"></v-radio>
                                <span :key="value.id">{{value.proportionname}}</span>
                            </template>
                        </div>
                    </div>
                    <div class="format" v-show="item.proportiontype==3">
                        <label>{{item.name}}缴费标准：{{other.formList[index].proportion}}</label>
                        <div class="radio-con">
                            <template v-for="value in item.sjgjCalculatorProportionList">
                                <v-radio :key="value.id" v-model="other.formList[index].proportion" v-bind:value="value.dangweimoney"></v-radio>
                                <span :key="value.id">{{value.dangweiname}}</span>
                            </template>
                        </div>
                    </div>
                    <div class="percent" v-if="item.proportiontype==1">
                        缴费比例：{{item.sjgjCalculatorProportionList[0].appointrate}}%
                    </div>
                    <div class="percent" v-show="item.extrachargestype==1" v-for="(value,index) in item.sjgjCalculatorExtrachargesList" :key="index">
                        {{value.xiangmuname}}：{{value.xiangmumoney}}元
                    </div>
                    <div class="sum">
                        <label>{{item.name}}费用合计：</label>
                        <big>{{other.formList[index].sum}}</big>
                        {{item.companytype==1?'元/月':'元/年'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="chart-con" v-if="!nodata">
            <div class="text" v-show="sumShow">
                <label>共计缴纳金额{{isYear?'（元/年）':'（元/月）'}}</label>
                ¥{{other.sum}}
            </div>
            <div class="chart" id="chart"></div>
        </div>
        <no-data :message="message" v-show="nodata"></no-data>
    </div>
</template>
<script>
// import MobileSelect from "@/components/plug-in/mobile-select";
import noData from "../../nonepage/nocalculator";
import Vue from 'vue'
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
export default {
    components: { noData },
    data() {
        return {
            other: {
                sum: 0,
                formList: []
            },
            allCity: [],
            allInfo: [],
            type: this.$route.query.type,
            sumShow: false,
            isYear: false,
            message: '',
            chartShow: 0,
            legendData: [],
            seriesData: [],
            nodata: false,
            income: Vue.getData('income') ? Vue.getData('income') : 5000,
            cityid: Vue.getData('cityid') ? Vue.getData('cityid') : 171,
            cityname: Vue.getData('cityname') ? Vue.getData('cityname') : '青岛市',
        }
    },
    watch: {
        "other.formList": {
            handler() {
                this.allInfo.forEach(ele => {
                    if (ele.companytype == 2) {
                        this.isYear = true;
                        return
                    }
                })
                var sum = 0;
                this.legendData = [];
                this.seriesData = [];
                this.other.formList.forEach((element, index) => {
                    if (element.checked == 1) {
                        if (this.allInfo[index].proportiontype == 1) {
                            element.proportion = element.baseNum * this.allInfo[index].sjgjCalculatorProportionList[0].appointrate / 100;
                        }
                        element.sum = (Number(element.proportion) + Number(element.extracharges)).toFixed(2);
                        if (this.isYear) {
                            sum += this.allInfo[index].companytype == 2 ? Number(element.sum) : element.sum * 12;
                        } else {
                            console.log(sum, element.sum)
                            sum = (Number(sum) + Number(element.sum)).toFixed(2);
                        }
                        this.legendData.push(this.allInfo[index].name);
                        this.seriesData.push({ value: (element.sum / this.other.sum * 100).toFixed(2), name: this.allInfo[index].name });
                        this.drawLine();
                    }
                });
                if (sum != 0) {
                    this.sumShow = true
                }
                this.other.sum = sum;
            },
            deep: true
        },
        "other": {
            handler(newVal) {
                Vue.setData('other', newVal)
            },
            deep: true
        }
    },
    mounted() {
        Vue.setData('cityid', this.cityid);
        Vue.setData('cityname', this.cityname);
        if (Vue.getData('other')) {
            this.fundInfo = Vue.getData('other');
        }
        if (Vue.getData('allCity') && Vue.getData('allCity').length > 0) {
            this.allCity = Vue.getData('allCity');
            this.checkCity();
        } else {
            this.getCityList();
        }
    },
    methods: {
        // 范围比较
        testBaseNum(item, index) {
            console.log(item.sjgjCalculatorBaseList[0].highmoney)
            if (this.other.formList[index].baseNum < Number(item.sjgjCalculatorBaseList[0].highmoney) || this.other.formList[index].baseNum > Number(item.sjgjCalculatorBaseList[0].lowmoney)) {
                this.$alert('基数超出范围')
            }
        },
        // 获取所有城市
        getCityList() {
            this.$siaxios.get("citybasic/calculatorcities").then(res => {
                if (res) {
                    for (let a = 0; a < res.length; a++) {
                        if (res[a].sjgjRegions.length != 0) {
                            this.allCity = this.allCity.concat(res[a].sjgjRegions)
                        }
                    }
                    Vue.setData('allCity', this.allCity);
                    this.checkCity();
                }
            });
        },
        // 验证是否有该城市
        checkCity() {
            if (this.allCity.filter(item => { return item.id == this.cityid }).length == 0) {
                this.message = "该城市暂不支持查询，请切换其他城市"
                this.nodata = true;
            } else {
                this.citybasicid = this.allCity.filter(item => { return item.id == this.cityid })[0].citybasicid;
                this.getInfo();
            }
        },
        // 获取城市下社保信息
        getInfo() {
            this.$siaxios
                .get(`/citybasic/calculatorlist/${this.citybasicid}/${this.type}`)
                .then(res => {
                    if (res.length == 0) {
                        this.message = "该城市下暂无" + (this.type == 1 ? '灵活就业社保计算' : '居民社保计算') + '查询';
                        this.nodata = true;
                    } else {
                        this.allInfo = res;
                        res.forEach(element => {
                            var exsum = 0;
                            if (element.extrachargestype == 1) {
                                for (var i = 0; i < element.sjgjCalculatorExtrachargesList.length; i++) {
                                    exsum = exsum + Number(element.sjgjCalculatorExtrachargesList[i].xiangmumoney)
                                }
                            }
                            this.other.formList.push({
                                checked: 0,
                                baseNum: '',
                                extracharges: exsum,
                                proportion: '',
                                sum: 0
                            })
                        });
                    }
                });
        },
        // 切换城市
        selectCity() {
            this.$citycalculatorselect({
                callback: (id, name, citybasicid) => {
                    this.cityid = id;
                    this.cityname = name;
                    this.citybasicid = citybasicid;
                    this.nodata = false;
                    this.getInfo();
                }
            });
        },
        drawLine() {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("chart"));
            // 绘制图表
            myChart.setOption({
                color: ["#4884ff", '#44dd7a', '#ff7a7a', '#ffb849'],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                grid: {
                    //图表的位置
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    containLabel: true
                },
                legend: {
                    orient: 'vertical',
                    top: '60%',
                    left: '40%',
                    right: 0,
                    bottom: 0,
                    data: this.legendData
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['60%', '90%'],
                        center: ['20%', '50%'],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        itemStyle: {
                            borderWidth: 3,
                            borderColor: '#fdfeff'
                        },
                        label: {
                            show: false,
                            position: 'center',
                            fontSize: '16',
                            formatter: '{c}%'
                        },
                        emphasis: {
                            label: {
                                show: true,
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.seriesData
                    }
                ]
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.flexible {
    background: #fff url("../../../assets/views/calculator/bg.png") top
        no-repeat;
    background-size: 100%;
    min-height: 100vh;
    .top {
        padding: 30px;
        .city {
            height: 100px;
            background: #fff url("../../../assets/right.png") 648px center
                no-repeat;
            background-size: 10px auto;
            border-radius: 16px;
            padding: 0 55px 0 40px;
            font-size: 28px;
            color: #333;
            line-height: 100px;
            text-align: right;
            label {
                color: #666;
                font-size: 26px;
                float: left;
            }
        }
    }
    .type {
        background: #fff;
        border-radius: 16px;
        margin: 0 30px 30px 30px;
        box-shadow: 0 0 20px 1px #eee inset;
        padding: 0 30px;
        .title {
            font-size: 28px;
            color: #333;
            line-height: 100px;
            text-align: right;
            label {
                float: left;
                color: #666;
                font-size: 26px;
            }
        }
        .con {
            border-top: 1px solid #ccc;
            > div {
                padding: 16px 0;
                line-height: 68px;
                border-bottom: #eee 1px solid;
                font-size: 28px;
                color: #333;
                &.sum {
                    border-bottom: none;
                    text-align: right;
                    font-size: 26px;
                    color: #ff7a7a;
                    big {
                        font-size: 32px;
                    }
                    label {
                        float: left;
                        color: #666;
                    }
                }
                &.percent {
                    color: #666;
                }
                &.format {
                    label {
                        display: block;
                        color: #666;
                    }
                    input {
                        width: 60%;
                        border: none;
                    }
                    > span {
                        float: right;
                    }
                }
            }
        }
    }
    .radio-con {
        span {
            margin: 0 52px 0 12px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .chart-con {
        margin-top: 70px;
        padding: 0 40px;
        position: relative;
        .chart {
            height: 350px;
            padding-bottom: 56px;
        }
        .text {
            position: absolute;
            left: 42%;
            top: 0;
            font-size: 46px;
            color: #ff7a7a;
            label {
                color: #333;
                font-size: 26px;
                display: block;
                margin-bottom: 20px;
            }
        }
    }
}
</style>
