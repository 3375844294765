<template>
  <div class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">重要说明 :（必读）</div>
      <div
        class="content"
      >档案室对收集来的档案分门别类组成有序体系的一项业务，是档案管理中的一项基础工作， 按照一定原则对档案实体进行系统分类、组合、排列、编号和基本编目，使之有序化 。档管家提供专人一对一的档案整理服务，让您的档案更清晰专业有条理。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">办理流程</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
      </div>

      <div class="show-content">
        <div class="showtype" style="background: #f6faff;" v-if="showType == 1">
          <div class="order-title">办理流程</div>
          <div class="showinfo-1">
            <div class="showinfo-1-">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-1.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>申请登记</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-3.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>付款成功</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-4.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>专员跟进</span>
                </div>
              </div>
            </div>
            <div class="showinfo-1-">
              <div class="show-img">
                <img src="@/assets/views/creatorder/arrow2.png" alt />
              </div>
            </div>
            <div class="showinfo-1-" style="margin-top: 40px">
              <div class="showinfo-1-item" style="margin-left:20px">
              </div>
              <div class="trastom">
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-6.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>完成</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-2.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>专人办理</span>
                </div>
              </div>
            </div>
          </div>
        </div><div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>