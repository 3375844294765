<template>
    <div class="order-services">
        <div class="title">
            <div class="item" @click="showType = 1" :class="{'active': showType == 1}">购买流程</div>
        </div>
        <div class="show-content">
            <div
                    class="showtype" style="background: #f6faff;"
                    v-if="showType == 1"
            >
                <div class="order-title">购买流程</div>
                <div class="showinfo-1">
                    <div class="showinfo-1-">
                        <div class="showinfo-1-item" style="margin-left:20px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-1.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>下载APP</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-2.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>注册成功</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-3.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>完成支付</span>
                            </div>
                        </div>
                    </div>
                    <div class="showinfo-1-">
                        <div class="show-img">
                            <img src="@/assets/views/creatorder/arrow2.png" alt="">
                        </div>
                    </div>
                    <div class="showinfo-1-" style="margin-top: 50px">
                        <div class="showinfo-1-item" style="margin-left:20px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-6.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>完成</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="" style="transform: rotate(180deg);">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-5.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>专员办理</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="" style="transform: rotate(180deg);">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-4.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>专员跟进收集材料</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'
    export default {
        name: "Introduce",
        data() {
            return {
                showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
            };
        }
    };
</script>
