<template>
    <div class="aboutus-index">
        <div class="logo">
            <img class="logo-img" src="@/assets/logo-dgj.png" alt />
        </div>
        <div class="title">档管家</div>
        <div class="tips">当前版本号：1.0.0</div>
        <div class="content">档管家以让职场人享受更多的福利保障为核心，满足个性化需求，提供专业的人力资源解决方案，促进企业和个人在人力资源方面的有效运用与优化配置，助力个人发展和企业战略的高效执行</div>
        <div class="areements">
            <!-- <agreement></agreement>
            <privacyAgreement></privacyAgreement> -->
        </div>
        <div class="conpany-info">
            <div class="link"><span @click="$router.forward('/login/agreement')">《用户服务协议》</span>|<span @click="$router.forward('/login/privacyagreement')">《隐私协议》</span></div>
            <div class="line">Copyright©2021-2021</div>
            <div class="line">青岛中海智库人力资源服务有限公司版权所有</div>
        </div>
    </div>
</template>

<script>
import "@/sass/views/mine/aboutus.scss";
import { mapGetters } from "vuex";
export default {
    name: "Aboutus",
    data() {
        return {
            name: ""
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    }
};
</script>