<template>
  <div class="confirmserviceinfo-wrapper">
    <div class="order-detail-title">缴费信息</div>
    <div class="service-info">
      <div class="info-line">
        <div>缴费开始月份</div>
        <div class="select-style" ref="startTime">{{orderInfo.paybackstarttime}}</div>
        <img class="right" src="@/assets/right.png" alt />
      </div>
      <div class="info-line">
        <div>缴纳结束月份</div>
        <div class="select-style" ref="overTime">{{orderInfo.paybackovertime}}</div>
        <img class="right" src="@/assets/right.png" alt />
      </div>
      <template v-if="this.orderInfo.saletypeid == 2">
        <div class="info-line">
          <div>户籍性质</div>
          <div class="select-style" ref="hukoucharacter">请选择</div>
          <img class="right" src="@/assets/right.png" alt />
        </div>
        <div class="info-line">
          <div>社保基数</div>
          <div class="inputcon">
            <input
              class="iptmoney"
              type="number"
              :placeholder="(this.baseInfos.lowshebao||0)+'.00至'+(this.baseInfos.highshebao||0)+'.00'"
              v-model="orderInfo.paybase"
              @click="sblow = false"
              @blur="isHighshebaoOrGj()"
            />元
          </div>
          <div class="zuidi" @click="setLower()">
            <img v-if="orderInfo.paybase == baseInfos.lowshebao" src="@/assets/checked.png" alt />
            <img v-else src="@/assets/nochecked.png" alt />
            <div>最低</div>
          </div>
        </div>
      </template>
      <template v-if="this.orderInfo.saletypeid == 14">
        <div class="info-line">
          <div>公积金基数</div>
          <div class="inputcon">
            <input
              class="iptmoney"
              type="number"
              :placeholder="(this.baseInfos.lowgongjijin||0)+'.00至'+(this.baseInfos.highgongjijin||0)+'.00'"
              v-model="orderInfo.base"
              @click="sblow = false"
              @blur="isHighGongjijinOrGj()"
            />元
          </div>
          <div class="zuidi" @click="setLowerGongjijin()">
            <img v-if="orderInfo.base == baseInfos.lowgongjijin" src="@/assets/checked.png" alt />
            <img v-else src="@/assets/nochecked.png" alt />
            <div>最低</div>
          </div>
        </div>
        <div class="info-line">
          <div>公积金比例(%)</div>
          <div class="select-style">
            <input v-model="orderInfo.proportion" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" type="text" />
          </div>
        </div>
      </template>
    </div>
    <div class="order-detail-title">其他信息</div>
    <div class="service-info">
      <div class="info-line">
        <div>联系电话</div>
        <div class="select-style">
          <input v-model="orderInfo.tel" type="text" />
        </div>
      </div>
      <div class="reason-line pb85">
        <div>备注</div>
        <textarea v-model="orderInfo.remark" name id rows="5" placeholder="请输入缴纳社保原因"></textarea>
      </div>
    </div>
    <div class="next" @click="goOrderDetail()">提交申请</div>
  </div>
</template>
<script>
import "@/sass/views/creatorder/creatorder.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import { mapGetters } from "vuex";
export default {
  name: "CreatPaybackorder",
  data() {
    return {
      baseInfos: "",
      datatimeList: [
        //年
        { data: [] },
        //月
        {
          data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12"
          ]
        }
      ],
      startTime:'',
      endTime:''
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      orderInfo: "getOrder"
    }),
    choiceTime(){
      return {
        startTime:this.startTime,
        endTime:this.endTime
      }
    }
  },
  created() {
    this.orderInfo.tel = this.userInfo.tel;
    this.orderInfo.userid = this.userInfo.id;
    this.$nextTick(() => {
      this.iniSelect();
    });
  },
  watch:{
    choiceTime:{
      deep:true,
      handler(val){
        if(val.startTime && val.endTime){
          this.getBaseInfo(val.startTime , val.endTime);
        }
      }
    }
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    iniSelect() {
      //初始化年
      let currentYear = new Date().getFullYear();
      for (let index = currentYear; index > currentYear - 150; index--) {
        this.datatimeList[0].data.push(index);
      }
      new MobileSelect({
        trigger: this.$refs.overTime,
        title: "缴费结束时间",
        triggerDisplayData: false,
        wheels: this.datatimeList,
        callback: (indexArr, data) => {
          let tempStr = data[0] + "-" + data[1]
          this.orderInfo.paybackovertime = tempStr;
          this.orderInfo.endtime = tempStr;
          this.endTime = tempStr
          this.$forceUpdate();
        }
      });
      new MobileSelect({
        trigger: this.$refs.startTime,
        title: "缴费开始时间",
        triggerDisplayData: false,
        wheels: this.datatimeList,
        callback: (indexArr, data) => {
          let tempStr = data[0] + "-" + data[1]
          this.orderInfo.paybackstarttime = tempStr;
          this.orderInfo.starttime = tempStr;
          this.startTime = tempStr
          this.$forceUpdate();
        }
      });
    },
    setLower() {
      this.orderInfo.paybase = this.baseInfos.lowshebao
      this.$forceUpdate();
    },
    setLowerGongjijin() {
      this.orderInfo.base = this.baseInfos.lowgongjijin
      this.$forceUpdate();
    },
    isHighshebaoOrGj() {
      if (
        this.orderInfo.paybase != 0 &&
        (this.orderInfo.paybase > this.baseInfos.highshebao ||
          this.orderInfo.paybase < this.baseInfos.lowshebao)
      ) {
        this.$tips({
          title: "提示",
          describe:
            this.baseInfos.lowshebao +
            ".00元至" +
            this.baseInfos.highshebao +
            ".00元"
        });
        this.orderInfo.paybase = "";
      }
    },
    isHighGongjijinOrGj() {
      if (
        this.orderInfo.base != 0 &&
        (this.orderInfo.base > this.baseInfos.highgongjijin ||
          this.orderInfo.base < this.baseInfos.lowgongjijin)
      ) {
        this.$tips({
          title: "提示",
          describe:
            this.baseInfos.lowgongjijin +
            ".00元至" +
            this.baseInfos.highgongjijin +
            ".00元"
        });
        this.orderInfo.base = "";
      }
    },
    /**
     * 初始户籍性质
     */
    iniRegistrySelect() {
      this.baseInfos.sjgjHouseHoldRegisters.forEach(element => {
        element.value = element.name;
      });
      if (this.natureSelect) {
        this.natureSelect.updateWheel(0, this.baseInfos.sjgjHouseHoldRegisters);
      } else {
        this.natureSelect = new MobileSelect({
          trigger: this.$refs.hukoucharacter,
          title: "户籍性质",
          wheels: [{ data: this.baseInfos.sjgjHouseHoldRegisters }],
          callback: (indexArr, data) => {
            this.orderInfo.hukouid = data[0].id;
          }
        });
      }
    },
    //获取城市配置
    getBaseInfo(start,end) {
      this.$siaxios
        .get(`/order/getCityBase/${this.orderInfo.cityid}?startmonth=${start}&endmonth=${end}`)
        .then(res => {
          this.baseInfos = res;
          this.iniRegistrySelect();
        });
    },
    /**
     * 开始下单
     */
    goOrderDetail() {
      if (!this.orderInfo.paybackstarttime) {
        this.$tips({ title: "提示", describe: "请选择开始缴纳月份" });
        return;
      }
      if (!this.orderInfo.paybackovertime) {
        this.$tips({ title: "提示", describe: "请选择结束缴纳月份" });
        return;
      }
      if(Date.parse(this.orderInfo.paybackstarttime)>Date.parse(this.orderInfo.paybackovertime)) {
        this.$tips({ title: "提示", describe: "请选择开始月不能大于结束缴纳月份" });
        return;
      }
      if(Date.parse(this.orderInfo.paybackovertime)>this.orderInfo.latestpayment) {
        this.$tips({ title: "提示", describe: "补缴不能超过当前月份" });
        return;
      }
      if (this.orderInfo.saletypeid == 2) {
        if (!this.orderInfo.hukouid) {
          this.$tips({ title: "提示", describe: "请选择户籍性质" });
          return;
        }
        if (!this.orderInfo.paybase) {
          this.$tips({ title: "提示", describe: "请填写社保基数" });
          return;
        }
        this.$siaxios
          .post(`/order/saveSheBaoBuJiao`, this.orderInfo, false)
          .then(() => {
            this.$router.forward("/creatorder/success");
          });
      }
      if (this.orderInfo.saletypeid == 14) {
        if (!this.orderInfo.base) {
          this.$tips({ title: "提示", describe: "请填写公积金基数" });
          return;
        }
        if (!this.orderInfo.proportion) {
          this.$tips({ title: "提示", describe: "公积金比例不能为空" });
          return;
        }
        if (this.orderInfo.proportion>12) {
          this.$tips({ title: "提示", describe: "公积金比例不能超过12" });
          return;
        }
        this.$siaxios
          .post(`/order/saveGongJiJinBuJiao`, this.orderInfo, false)
          .then(() => {
            this.$router.forward("/creatorder/success");
          });
      }
    }
  }
};
</script>