<template>
  <div class="gfprove">
    <div class="banner-con">
      <div class="banner">
        <span>购房证明</span>
      </div>
    </div>

    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <span>限购证明也是购房资格证明，有购房意向的客户首先应该办理的一个证明文件。只有开具了限购证明，才能证明你的购房资格。也是合同网签的必备条件</span>
      </div>
      <div class="introduce showmessage" style="padding-left: 0">
        <div class="title">
          <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
          <span class="intro-title">业务流程</span>
        </div>

        <div class="process">
          <div class="process-con">
            <div class="proess-img">
              <img src="@/assets/views/mine/da-dc1.jpg" alt />
            </div>
            <div class="procss-text">
              <span>APP注册账号</span>
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/views/mine/da-arrow.jpg" alt />
          </div>
          <div class="process-con">
            <div class="proess-img">
              <img src="@/assets/views/mine/fukuan.jpg" alt />
            </div>
            <div class="procss-text">
              <span>付款成功</span>
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/views/mine/da-arrow.jpg" alt />
          </div>
          <div class="process-con">
            <div class="proess-img">
              <img src="@/assets/views/mine/guihuan1.jpg" alt />
            </div>
            <div class="procss-text">
              <span>填写相关信息</span>
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/views/mine/da-arrow.jpg" alt />
          </div>
          <div class="process-con">
            <div class="proess-img">
              <img src="@/assets/views/mine/jinan3.png" alt />
            </div>
            <div class="procss-text">
              <span>递交相关材料</span>
            </div>
          </div>
          
        </div>

        <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>

        <div class="process" style="margin-top: 20px">
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/chuangye.png" alt />
          </div>
          <div class="procss-text">
            <span>房产交易中心办理</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
    
    <div class="order-gray-line"></div>
    
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/gallery/gfzm/cl.png" alt />
        <span class="intro-title">所需材料</span>
      </div>
      <div class="smalltitle">已婚：</div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">夫妻双方身份证</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">结婚证</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text" style="width:250px">夫妻双方及未成年子女户口簿</div>
          </div>
        </div>
      </div>
      <div class="heng"></div>
      <div class="smalltitle">单身：</div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">身份证</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">户口簿</div>
          </div>
        </div>
      </div>
      <div class="heng"></div>
      <div class="smalltitle">丧偶：</div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">身份证</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text" style="width:250px">户口簿及未成年子女户口簿</div>
          </div>
        </div>
      </div>
      <div class="heng"></div>
      <div class="smalltitle">现役军人：</div>
      <div class="heng"></div>
      <div>除上述需提供资料以外</div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">军官证或士兵证</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">身份证(原证)</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text" style="width:250px">部队证明(原件)</div>
          </div>
        </div>
      </div>
    </div>

    <div class="showService" @click="showConsult">咨询顾问</div>
  </div>
</template>

<script>
import "@/sass/views/mine/creatorder.scss";
import "@/sass/views/gallery/hkguanli-gfprove.scss";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods:{
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return
      }
      this.$consult({ userid: this.userInfo.id })
    },
  }
}
</script>

<style>

</style>