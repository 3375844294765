<template>
    <div class="children calculator">
        <div class="top-money">
            <button @click="clearData">清除数据</button>
            <label>本人年度扣除金额</label>
            <span>¥</span>
            <input type="number" v-model="rent.total" readonly>
        </div>
        <div class="form-con">
            <div class="form-group">
                <label>婚姻状况</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="rent.type" value="1"></v-radio><span>已婚</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="rent.type" value="2"></v-radio><span>未婚</span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-show="rent.type==1">
                <label>夫妻租房城市</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="rent.isSame" value="1"></v-radio><span>同城</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="rent.isSame" value="2"></v-radio><span>不同城</span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-show="rent.isSame==1&&rent.type==1">
                <label>扣除人</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="rent.deducter" value="1"></v-radio><span>本人</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="rent.deducter" value="2"></v-radio><span>配偶</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>扣除方式</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="rent.deductType" value="1"></v-radio><span>每月</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="rent.deductType" value="2"></v-radio><span>每年</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>租房地区</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="rent.city" value="1"></v-radio><span>大城市</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="rent.city" value="2"></v-radio><span>中城市</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="rent.city" value="3"></v-radio><span>小城市</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="right-select" ref="month">
                    {{rent.monthNum}}月
                </div>
                <label>开始扣除月份</label>
            </div>
        </div>
        <div class="description">
            <div class="title"><span>住房租金抵扣说明</span></div>
            <div class="show-table">
                <table>
                    <thead>
                        <tr>
                            <th colspan="2">类型</th>
                            <th>大城市</th>
                            <th>中城市</th>
                            <th>小城市</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowspan="2">扣除金额</td>
                            <td>每月</td>
                            <td>1500</td>
                            <td>1100</td>
                            <td>800</td>
                        </tr>
                        <tr>
                            <td>每年</td>
                            <td>18000</td>
                            <td>13200</td>
                            <td>9600</td>
                        </tr>
                        <tr>
                            <td rowspan="2" style="border-bottom:none">扣除人</td>
                            <td>同城</td>
                            <td colspan="3">其中一方扣除</td>
                        </tr>
                        <tr>
                            <td>不同城</td>
                            <td colspan="3">分别扣除</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <dl class="description-text">
                <dt>适用说明</dt>
                <dd>
                    每年汇算清缴
                </dd>
            </dl>
            <dl class="description-text">
                <dt>适用范围</dt>
                <dd>
                    <ul class="list">
                        <li>大城市：直辖市、省会城市、计划单列市以及国务院确定的其他城市</li>
                        <li>中城市：市辖区户籍人口超过100万的</li>
                        <li>小城市：市辖区户籍人口不超过100万的</li>
                        <li>纳税人及配偶在主要工作城市没有自有住房而发生的租金支出可以按照标准扣除</li>
                        <li>夫妻双方主要工作城市相同，只能由一方扣除住房租金，而且只能由签订租赁租房合同的承租人扣除</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>证明材料</dt>
                <dd>
                    个人申报+政府相关数据，税务部门核实
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>
import MobileSelect from "@/components/plug-in/mobile-select";
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            rent: {
                total: 0,
                deducter: '',
                deductType: '',
                type: '',
                city: '',
                isSame: '',
                monthNum: 1
            },
            monthList: []
        }
    },
    watch: {
        "rent": {
            handler(newVal) {
                var sum = 0;
                if (newVal.type == 2 || newVal.isSame == 2 || (newVal.isSame == 1 && newVal.deducter == 1)) {
                    switch (Number(newVal.city)) {
                        case 1: {
                            sum = (18000 / 12 * (13 - newVal.monthNum)).toFixed(2) * 100 / 100;
                            break
                        }
                        case 2: {
                            sum = (13200 / 12 * (13 - newVal.monthNum)).toFixed(2) * 100 / 100;
                            break
                        }
                        case 3: {
                            sum = (9600 / 12 * (13 - newVal.monthNum)).toFixed(2) * 100 / 100;
                            break
                        }
                    }
                } else {
                    sum = 0
                }
                this.rent.total = sum;
                Vue.setData('special.rent', this.rent);
            },
            deep: true
        }
    },
    created() {
        for (var i = 1; i < 13; i++) {
            this.monthList.push({ id: i, value: i + '月' })
        }
    },
    mounted() {
        if (!!Vue.getData('special') && !!Vue.getData('special.rent')) {
            this.rent = Vue.getData('special.rent');
        }
        this.selectMonth();
    },
    methods: {
        clearData() {
            this.rent = {
                total: 0,
                deducter: '',
                deductType: '',
                type: '',
                city: '',
                isSame: '',
                monthNum: 1
            }
        },
        selectMonth() {
            new MobileSelect({
                trigger: this.$refs.month,
                title: "开始扣除月份",
                wheels: [{ data: this.monthList }],
                callback: (indexArr, data) => {
                    this.rent.monthNum = data[0].id;
                }
            });

        }
    }

}
</script>
<style lang="scss" scoped>
.calculator {
    background: #fff;
    min-height: 100vh;
}
.top-money {
    background-image: linear-gradient(to right, #ff9440, #ffb172);
}
.calculator .show-table table th {
    background: #ffaf6f;
}
</style>