<template>
    <div class="socialsecurity-index" v-wechat-title="param.businessname">
        <div class="general-top">
            <div class="banner-con">
                <div class="swiper-container" id="swiper1">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="item in showInfo.businessinfo.commodityimgs" :key="item.id">
                            <img :src="item.commodityimgurl" alt />
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="title">{{showInfo.businessconfig.businessname}}</div>
            <div class="general-tips">{{showInfo.businessconfig.description}}</div>
            <div class="general-money" v-if="this.param.saletypeid !=2 && this.param.saletypeid !=14">
                ￥
                <span class="general-price">{{param.price}}</span> 起
            </div>
            <div class="order-content">
                <div class="info-line-city" @click="selectCity">
                    <div>缴纳城市</div>
                    <div class="right">
                        <div class="selectCity">{{param.cityname}}</div>
                        <img class="right" src="@/assets/right.png" alt />
                    </div>
                </div>
                <div class="info-line" v-show="businessCharge.length>1">
                    <div>{{(param.saletypeid == 22||param.saletypeid == 42)?'落户方式':'套餐'}}</div>
                    <div class="select-style" ref="hukoucharacter">请选择</div>
                    <img class="right" src="@/assets/right.png" alt />
                </div>
                <div class="info-line" v-if="param.saletypeid==35">
                    <div>开发补贴人数</div>
                    <input v-model="param.num" class="textsty" onkeyup="this.value=this.value.replace(/\D/g,'')"
                        onafterpaste="this.value=this.value.replace(/\D/g,'')" placeholder="请填写开发补贴人数" type="number" pattern="[0-9]*" />
                </div>
            </div>
        </div>
        <div class="order-gray-line"></div>
        <saletype2 v-if="param.saletypeid == 2" />
        <saletype3 v-if="param.saletypeid == 3" />
        <saletype4 v-if="param.saletypeid == 4" />
        <saletype5 v-if="param.saletypeid == 5" />
        <saletype6 v-if="param.saletypeid == 6" />
        <saletype7 v-if="param.saletypeid == 7" />
        <saletype8 v-if="param.saletypeid == 8" />
        <saletype9 v-if="param.saletypeid == 9" />
        <saletype10 v-if="param.saletypeid == 10" />
        <saletype11 v-if="param.saletypeid == 11" />
        <saletype12 v-if="param.saletypeid == 12" />
        <saletype14 v-if="param.saletypeid == 14" />
        <saletype15 v-if="param.saletypeid == 15" />
        <saletype16 v-if="param.saletypeid == 16" />
        <saletype17 v-if="param.saletypeid == 17" />
        <saletype18 v-if="param.saletypeid == 18" />
        <saletype19 v-if="param.saletypeid == 19" />
        <saletype20 v-if="param.saletypeid == 20" />
        <saletype21 v-if="param.saletypeid == 21" />
        <saletype22 v-if="param.saletypeid == 22" />
        <saletype23 v-if="param.saletypeid == 23" />
        <saletype24 v-if="param.saletypeid == 24" />
        <saletype25 v-if="param.saletypeid == 25" />
        <!--    限购证明-->
        <template v-if="param.saletypeid == 26">
            <Purchase />
            <div class="order-gray-line"></div>
            <Problem />
        </template>
        <!--   就业困难人员认定服务 -->
        <template v-if="param.saletypeid == 27">
            <hardJod />
            <div class="order-gray-line"></div>
            <Introduce />
        </template>
        <!--    灵活就业社会保险-->
        <template v-if="param.saletypeid == 28">
            <Flex />
            <div class="order-gray-line"></div>
            <FlexProblem />
        </template>
        <!--        高校毕业生住房补贴-->
        <template v-if="param.saletypeid == 29">
            <CollegesAllowance />
            <div class="order-gray-line"></div>
            <CollegesAllowanceProblem />
        </template>
        <!--        高校毕业生小微企业就业补贴-->
        <template v-if="param.saletypeid == 30">
            <Allowance />
            <div class="order-gray-line"></div>
            <AllowanceProblem />
        </template>
        <!--       创新创业一次性安家费 -->
        <template v-if="param.saletypeid == 31">
            <Settle />
            <div class="order-gray-line"></div>
            <SettleProblem />
        </template>
        <!--       技术技能提升补贴 -->
        <template v-if="param.saletypeid == 32">
            <isCreated />
            <div class="order-gray-line"></div>
            <isCreatedProblem />
        </template>
        <!--        一次性创业补贴-->
        <template v-if="param.saletypeid == 33">
            <oneJob />
            <div class="order-gray-line"></div>
            <!--            <oneJobProblem />-->
        </template>
        <!--        一次性小微企业创业补贴-->
        <template v-if="param.saletypeid == 34">
            <oneCompany />
            <div class="order-gray-line"></div>
            <oneCompanyProblem />
        </template>
        <!--        一次性创业岗位开发补贴-->
        <template v-if="param.saletypeid == 35">
            <oneDelelop />
            <div class="order-gray-line"></div>
            <oneDelelopProblem />
        </template>
        <!--        用人单位先就业社保补贴和岗位补贴-->
        <template v-if="param.saletypeid == 36">
            <Peolple />
            <div class="order-gray-line"></div>
            <!--            <PeolpleProblem />-->
        </template>
        <!--        创业担保贴息贷款-->
        <template v-if="param.saletypeid == 37">
            <Chuangye />
            <div class="order-gray-line"></div>
            <ChuangyeProblem />
        </template>
        <!--        国（境）外学历认证-->
        <template v-if="param.saletypeid == 38">
            <Foreign />
            <div class="order-gray-line"></div>
            <ForeignProblem />
        </template>
        <!--        网签手续办理-->
        <template v-if="param.saletypeid == 39">
            <Intent />
            <div class="order-gray-line"></div>
            <IntentProblem />
        </template>
        <!--        改派手续办理-->
        <template v-if="param.saletypeid == 40">
            <GaiPai />
            <div class="order-gray-line"></div>
            <GaiPaiProblem />
        </template>
        <!--      高校毕业生档案  -->
        <template v-if="param.saletypeid == 41">
            <Saletype41 />
            <div class="order-gray-line"></div>
            <Saletype41_ />
        </template>
        <!--        高校毕业生户口-->
        <template v-if="param.saletypeid == 42">
            <Saletype42 />
            <div class="order-gray-line"></div>
            <Saletype42_ />
        </template>
        <!--        在校大学生就业能力提升补贴-->
        <template v-if="param.saletypeid == 43">
            <Saletype44 />
            <div class="order-gray-line"></div>
            <Saletype44_ />
        </template>
        <!--        高校毕业生住房补贴-->
        <template v-if="param.saletypeid == 44">
            <Saletype43 />
            <div class="order-gray-line"></div>
            <Saletype43_ />
        </template>
        <!--        高校毕业生小微企业就业补贴-->
        <template v-if="param.saletypeid == 45">
            <Saletype45 />
            <div class="order-gray-line"></div>
            <Saletype45_ />
        </template>
        <!--        劳动咨询-->
        <template v-if="param.saletypeid == 46">
            <Saletype46 />
            <div class="order-gray-line"></div>
            <Saletype46_ />
        </template>
        <div class="bottom-menu">
            <div class="consult" @click="showConsult()">
                <img src="@/assets/zaixian.png" class="consult-icon" alt />咨询
            </div>
            <div class="order-btn" @click="nextStemp()">下一步</div>
        </div>
    </div>
</template>
<script>
import "@/sass/views/creatorder/socialsecurity.scss";
import saletype2 from "./business-introduction/saletypeid-2/introduce";
import saletype3 from "./business-introduction/saletypeid-3/introduce";
import saletype4 from "./business-introduction/saletypeid-4/introduce";
import saletype5 from "./business-introduction/saletypeid-5/introduce";
import saletype6 from "./business-introduction/saletypeid-6/introduce";
import saletype7 from "./business-introduction/saletypeid-7/introduce";
import saletype8 from "./business-introduction/saletypeid-8/introduce";
import saletype9 from "./business-introduction/saletypeid-9/introduce";
import saletype10 from "./business-introduction/saletypeid-10/introduce";
import saletype11 from "./business-introduction/saletypeid-11/introduce";
import saletype12 from "./business-introduction/saletypeid-12/introduce";
import saletype14 from "./business-introduction/saletypeid-14/introduce";
import saletype15 from "./business-introduction/saletypeid-15/introduce";
import saletype16 from "./business-introduction/saletypeid-16/introduce";
import saletype17 from "./business-introduction/saletypeid-17/introduce";
import saletype18 from "./business-introduction/saletypeid-18/introduce";
import saletype19 from "./business-introduction/saletypeid-19/introduce";
import saletype20 from "./business-introduction/saletypeid-20/introduce";
import saletype21 from "./business-introduction/saletypeid-21/introduce";
import saletype22 from "./business-introduction/saletypeid-22/introduce";
import saletype23 from "./business-introduction/saletypeid-23/introduce";
import saletype24 from "./business-introduction/saletypeid-24/introduce";
import saletype25 from "./business-introduction/saletypeid-25/introduce";
import Purchase from "./business-introduction/saletypeid-26/introduce"; //限购资格证明
import Problem from "./business-introduction/saletypeid-26/problem"; //限购资格证明
import hardJod from "./business-introduction/saletypeid-27/problem"; //就业困难人员认定服务
import Introduce from "./business-introduction/saletypeid-27/introduce"; //就业困难人员认定服务流程
import Flex from "./business-introduction/saletypeid-28/introduce"; //灵活就业介绍
import FlexProblem from "./business-introduction/saletypeid-28/problem"; //灵活就业流程
import CollegesAllowance from "./business-introduction/saletypeid-29/introduce"; //高校毕业生住房补贴介绍
import CollegesAllowanceProblem from "./business-introduction/saletypeid-29/problem"; //高校毕业生住房补贴流程
import Allowance from "./business-introduction/saletypeid-30/introduce"; //高校毕业生小微企业就业补贴
import AllowanceProblem from "./business-introduction/saletypeid-30/problem"; //高校毕业生小微企业就业补贴流程
import Settle from "./business-introduction/saletypeid-31/introduce"; //创新创业一次性安家费
import SettleProblem from "./business-introduction/saletypeid-31/problem"; //创新创业一次性安家费流程
import isCreated from "./business-introduction/saletypeid-32/introduce"; //技术技能提升补贴
import isCreatedProblem from "./business-introduction/saletypeid-32/problem"; // 技术技能提升补贴流程
import oneJob from "./business-introduction/saletypeid-33/introduce"; //一次性创业补贴
import oneCompany from "./business-introduction/saletypeid-34/introduce"; //一次性小微企业创业补贴
import oneCompanyProblem from "./business-introduction/saletypeid-34/problem"; //一次性小微企业创业补贴流程
import oneDelelop from "./business-introduction/saletypeid-35/introduce"; //一次性创业岗位开发补贴
import oneDelelopProblem from "./business-introduction/saletypeid-35/problem"; //一次性创业岗位开发补贴流程
import Peolple from "./business-introduction/saletypeid-36/introduce"; //用人单位吸纳就业
import Chuangye from "./business-introduction/saletypeid-37/introduce"; //创业担保贴息贷款
import ChuangyeProblem from "./business-introduction/saletypeid-37/problem"; //创业担保贴息贷款流程
import Foreign from "./business-introduction/saletypeid-38/introduce"; //国（境）外学历认证
import ForeignProblem from "./business-introduction/saletypeid-38/problem"; //国（境）外学历认证流程
import Intent from "./business-introduction/saletypeid-39/introduce"; //网签手续办理
import IntentProblem from "./business-introduction/saletypeid-39/problem"; //网签手续办理流程
import GaiPai from "./business-introduction/saletypeid-40/introduce"; //改派手续办理
import GaiPaiProblem from "./business-introduction/saletypeid-40/problem"; //改派手续办理流程
import Saletype41 from "./business-introduction/saletypeid-41/introduce"; //高校毕业生档案
import Saletype41_ from "./business-introduction/saletypeid-41/problem"; //高校毕业生档案流程
import Saletype42 from "./business-introduction/saletypeid-42/introduce"; //高校毕业生档案
import Saletype42_ from "./business-introduction/saletypeid-42/problem"; //高校毕业生档案流程
import Saletype43 from "./business-introduction/saletypeid-43/introduce"; //高校毕业生户口
import Saletype43_ from "./business-introduction/saletypeid-43/problem"; //高校毕业生档案流程
import Saletype44 from "./business-introduction/saletypeid-44/introduce"; //在校大学生就业能力提升
import Saletype44_ from "./business-introduction/saletypeid-44/problem"; //高校毕业生档案流程
import Saletype45 from "./business-introduction/saletypeid-45/introduce"; //高校毕业生住房补贴
import Saletype45_ from "./business-introduction/saletypeid-45/problem"; //高校毕业生档案流程
import Saletype46 from "./business-introduction/saletypeid-46/introduce"; //高校毕业生小微企业就业补贴
import Saletype46_ from "./business-introduction/saletypeid-46/problem"; //高校毕业生档案流程
import MobileSelect from "@/components/plug-in/mobile-select";
import Swiper from "swiper";
import { mapGetters } from "vuex";

export default {
    name: "General",
    data() {
        return {
            param: {
                businessname:this.$route.query.businessname?this.$route.query.businessname:'',
                saletypeid: this.$route.query.id,
                cityid: "",
                cityname: "",
                settlemodeid: "",
                price: "0.00" //订单预期金额
            },
            businessCharge: [], //套餐
            cttrBusinessChargeIndex: "",
            isNeedLuohu: false,
            showInfo: {
                businessconfig: {},
                businessinfo: {
                    commodityimgs: []
                }
            }
        };
    },
    created() {
        this.getDescribe();
    },
    mounted() {
        if (this.userInfo.cityid) {
            this.param.cityid = this.userInfo.cityid;
            this.param.cityname = this.userInfo.cityname;
            this.iniSelect();
        }
        this.isGoDown();
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    components: {
        saletype2,
        saletype3,
        saletype4,
        saletype5,
        saletype6,
        saletype7,
        saletype8,
        saletype9,
        saletype10,
        saletype11,
        saletype12,
        saletype14,
        saletype15,
        saletype16,
        saletype17,
        saletype18,
        saletype19,
        saletype20,
        saletype21,
        saletype22,
        saletype23,
        saletype24,
        saletype25,
        Purchase, //限购资格证明
        Problem, //限购资格证明
        hardJod, //就业困难人员认定服务
        Introduce, //就业困难人员认定服务流程
        Flex, //灵活就业介绍
        FlexProblem, //灵活就业流程
        CollegesAllowance, //高校毕业生住房补贴介绍
        CollegesAllowanceProblem, //高校毕业生住房补贴流程
        Allowance, //高校毕业生小微企业就业补贴
        AllowanceProblem, //高校毕业生小微企业就业补贴流程
        Settle, //创新创业一次性安家费
        SettleProblem, //创新创业一次性安家费流程
        isCreated, //技术技能提升补贴
        isCreatedProblem, // 技术技能提升补贴流程
        oneJob, //一次性创业补贴
        oneCompany, //一次性小微企业创业补贴
        oneCompanyProblem, //一次性小微企业创业补贴流程
        oneDelelop, //一次性创业岗位开发补贴
        oneDelelopProblem, //一次性创业岗位开发补贴流程
        Peolple, //用人单位吸纳就业
        Chuangye, //创业担保贴息贷款
        ChuangyeProblem, //创业担保贴息贷款流程
        Foreign, //国（境）外学历认证
        ForeignProblem, //国（境）外学历认证流程
        Intent, //网签手续办理
        IntentProblem, //网签手续办理流程
        GaiPai, //改派手续办理
        GaiPaiProblem, //改派手续办理流程
        Saletype41, //高校毕业生档案
        Saletype41_, //高校毕业生档案流程
        Saletype42, //高校毕业生档案
        Saletype42_, //高校毕业生档案流程
        Saletype43, //高校毕业生档案
        Saletype43_, //高校毕业生档案流程
        Saletype44, //高校毕业生档案
        Saletype44_, //高校毕业生档案流程
        Saletype45, //高校毕业生档案
        Saletype45_, //高校毕业生档案流程
        Saletype46, //高校毕业生档案
        Saletype46_ //高校毕业生档案流程
    },
    methods: {
        /**
         * 新生儿落户需要户主先落户
         */
        isGoDown() {
            //没有认证则不需要调用
            if (this.userInfo.renzhengstate != 1) return;
            if (this.param.saletypeid == 23) {
                this.$siaxios.get(`/order/getState/4/${this.userInfo.id}`).then(res => {
                    if (!res) {
                        this.isNeedLuohu = true;
                        this.$tips({
                            title: "提示",
                            describe: "只有办理了落户业务的人员，才能办理新生儿落户"
                        });
                    }
                });
            }
        },
        /**
         * 初始banner
         */
        iniSwiper() {
            this.$nextTick(() => {
                new Swiper("#swiper1", {
                    slidesPerView: 1, //每屏显示的个数
                    spaceBetween: 30, //间隔距离
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    autoplay: true
                });
            });
        },
        /**
         * 初始化下拉框
         */
        async iniSelect() {
            if (this.param.cityid) {
                this.businessCharge = await this.$siaxios.get(
                    `common/getBusinessCharge/${this.param.cityid}/${this.param.saletypeid}`
                );

                if (
                    this.cttrBusinessChargeIndex != "" &&
                    this.businessCharge.length > 0
                ) {
                    let busche = this.businessCharge[this.cttrBusinessChargeIndex];
                    //套餐
                    this.param.settlemodeid = busche.id;
                    this.param.taocan = busche.value;
                    //补贴
                    this.param.butie = busche.butie;
                    //2一次性取收费shoufei  3定价加尾款取首付shoufu
                    if (busche.chargetype == 2) this.param.price = busche.shoufei;
                    else this.param.price = busche.shoufu;
                }

                if (this.businessCharge.length == 1) {
                    //如果返回只有一条则不是套餐
                    if (this.businessCharge[0].chargetype == 2)
                        this.param.price = this.businessCharge[0].shoufei;
                    else this.param.price = this.businessCharge[0].shoufu;
                } else if (this.businessCharge.length > 1) {
                    this.businessCharge.forEach(element => {
                        element.value = element.taocan;
                    });
                    if (this.natureSelect) {
                        this.natureSelect.updateWheel(0, this.businessCharge);
                    } else if (this.businessCharge.length > 0) {
                        this.natureSelect = new MobileSelect({
                            trigger: this.$refs.hukoucharacter,
                            title:
                                this.param.saletypeid == 22 || this.param.saletypeid == 42
                                    ? "落户方式"
                                    : "套餐",
                            wheels: [{ data: this.businessCharge }],
                            position: [0],
                            callback: (indexArr, data) => {
                                this.cttrBusinessChargeIndex = indexArr;
                                //套餐
                                this.param.settlemodeid = data[0].id;
                                this.param.taocan = data[0].value;
                                //补贴
                                this.param.butie = data[0].butie;
                                //2一次性取收费shoufei  3定价加尾款取首付shoufu
                                if (data[0].chargetype == 2) this.param.price = data[0].shoufei;
                                else this.param.price = data[0].shoufu;
                            }
                        });
                    }
                }
            }
        },
        //选择城市
        selectCity() {
            this.$cityopenselect({
                ywid: this.param.saletypeid,
                callback: (id, name) => {
                    this.param.cityid = id;
                    this.param.cityname = name;
                    this.iniSelect();

                    let tempUserInfo = JSON.parse(JSON.stringify(this.userInfo));
                    tempUserInfo.cityid = id;
                    tempUserInfo.cityname = name;
                    this.$store.commit("setMyUserInfo", tempUserInfo);
                }
            });
        },
        /**
         * 获取业务介绍
         */
        getDescribe() {
            this.$siaxios
                .get(`homepage/detail/${this.param.saletypeid}`)
                .then(res => {
                    this.showInfo = res;
                    this.param.businessname = res.businessconfig.businessname;
                    this.param.listimgurl = res.businessinfo.listimgurl;
                    this.iniSwiper();
                });
        },
        /**
         * 展示顾问
         */
        showConsult() {
            if (this.userInfo.id == "") {
                this.$router.forward("/login/login");
                return
            }
            this.$consult({ userid: this.userInfo.id })
        },
        /**
         * 下一步去创建订单
         */
        nextStemp() {
            if (this.userInfo.id == "") {
                this.$router.forward("/login/login");
                return
            }
            if (this.userInfo.renzhengstate != 1) {
                this.$confirm({
                    describe: "办理业务请先认证，是否跳转认证页面",
                    callback: boolean => {
                        if (boolean) this.$router.forward("/mine/authentication");
                    }
                });
                return;
            }
            if (this.isNeedLuohu) {
                this.$tips({
                    title: "提示",
                    describe: "只有办理了落户业务的人员，才能办理新生儿落户"
                });
                return;
            }
            if (this.param.cityid == "") {
                this.$tips({ title: "提示", describe: "请选择缴纳城市" });
                return;
            }
            if (this.businessCharge.length > 1 && this.param.settlemodeid == "") {
                let str =
                    this.param.saletypeid == 22 || this.param.saletypeid == 42
                        ? "落户方式"
                        : "套餐";
                this.$tips({ title: "提示", describe: `请选择${str}` });
                return;
            }
            if (this.param.saletypeid == 35) {
                if (this.param.num == undefined) {
                    this.$tips({
                        title: "提示",
                        describe: "开发人数不能为空"
                    });
                    return;
                } else if (this.param.num == 0) {
                    this.$tips({
                        title: "提示",
                        describe: "开发人数不能为0"
                    });
                    return;
                }
            }
            this.$store.commit("setOrderInfo", this.param);
            if (this.param.saletypeid == 2 || this.param.saletypeid == 14) {
                this.$router.forward({
                    path: "/creatorder/payback",
                    query: { saletypeid: this.param.saletypeid }
                });
            } else {
                this.$router.forward("/creatorder/creatgeneral");
            }
        }
    }
};
</script>
