<template>
  <div class="set-index">
    <div class="items">
      <!-- <div class="item">
        <div class="lable">更改头像</div>
        <div class="content">
          <img src="@/assets/right.png" alt class="icon-img" />
        </div>
      </div>-->
      <div class="item" @click="$router.forward('/mine/feedback')">
        <div class="lable">意见反馈</div>
        <div class="content">
          <img src="@/assets/right.png" alt class="icon-img" />
        </div>
      </div>
      <div class="item" @click="$router.forward('/mine/aboutus')">
        <div class="lable">关于档管家</div>
        <div class="content">
          <img src="@/assets/right.png" alt class="icon-img" />
        </div>
      </div>
      <div class="item" @click="loginOut()">
        <div class="lable">退出登录</div>
        <div class="content">
          <img src="@/assets/right.png" alt class="icon-img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/mine/set.scss";
import { mapGetters } from "vuex";
export default {
  name: "mine-set",
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 退出登录
     */
    loginOut() {
      this.$confirm({
        title: "提示",
        describe: "是否确认退出登录",
        callback: boolean => {
          if (boolean) {
            let tempdata;
            if (this.userInfo.device) {
              tempdata = {
                id: ""
              };
            } else {
              tempdata = {
                id: "",
                openid: this.userInfo.openid,
                headimageurl: this.userInfo.headimageurl,
                nickname: this.userInfo.nickname,
                sex: this.userInfo.sex,
                cityid: this.userInfo.cityid,
                cityname: this.userInfo.cityname,
                device: this.userInfo.device
              };
            }
            localStorage.removeItem('calculator');
            this.$store.commit("setMyUserInfo", tempdata);
            this.$router.forward("/login/login");
          }
        }
      });
    }
  }
};
</script>
