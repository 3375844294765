<template>
  <div  class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">业务介绍：</div>
      <div class="content">
        根据我国现行政策，养老保险关系可在全国互转。当参保人遇到工作地点变动、居住地跨省跨城市搬迁、户籍地发生变动时，可通过养老保险关系转移接续进行合并。
        可以转移部分包括：养老保险、医疗保险。养老保险转移包括个人账户、缴费年限以及根据各个城市规定的社会统筹部分；医疗保险转移包括医疗缴费年限，用于计算医疗保险累计的年限（仅部分城市支持）。
      </div>
      <div class="content">档管家可以为您提供专业的跨省跨城市的转移接续服务，将您的保险归集到同一城市，为您将来的退休手续提供更优质的保障。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">常见问题</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
      </div>
      <div class="show-content">
        <div class="showtype showtype3" v-if="showType == 1">
          <div class="order-title">常见问题</div>
          <div class="showinfo-3">
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>请问办理保险转出需要什么条件？</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>
                    <p>您在办理社保转出前，需确保接收保险的区域是正常参保状态。</p>
                  </span>
                </div>
              </div>
            </div>
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>办理社保转移需要多长时间呢？</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>
                    <p>从收集完你的个人相关转移资料后，顾问专员到社保经办机构转移社保关系之后，社保经办机构会在15个工作日内办结有关手续，不同城市的办理转移会相差较大，需要耐心等候</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1
    };
  }
};
</script>