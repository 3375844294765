<template>
  <div class="workers calculator">
    <div class="top">
      <div class="title">
        <div class="location" @click="selectCity">{{cityname}}</div>
        <label>社保缴纳基数</label>
      </div>
      <div class="money">
        <span @click="submit">开始计算</span>
        <em>¥</em>
        <input type="tel" pattern="[0-9]*" v-model="workerInfo.secBasicNum">
      </div>
      <div class="btn">
        <button :class="{on:workerInfo.secType==2}" @click="workerInfo.secBasicNum=allInfo.lowshebao;workerInfo.secType=2;saveData()">最低</button>
        <button :class="{on:workerInfo.secType==1}" @click="workerInfo.secBasicNum=income>allInfo.highshebao?allInfo.highshebao:income;workerInfo.secType=1;saveData()" v-show="income">全额</button>
        <button :class="{on:workerInfo.secType==3}" @click="workerInfo.secBasicNum=0;workerInfo.secType=3;saveData();$router.go(-1)">不缴纳</button>
      </div>
    </div>
    <div class="nature" ref="hukoucharacter">
      <label>户口性质</label>
      {{cityid==''?'请先选择城市':hukouCharacterName}}
    </div>
    <div class="show" v-show="!nodata">
      <div class="chart" id="chart"></div>
      <div class="show-table">
        <table>
          <thead>
            <tr>
              <th>缴纳项目</th>
              <th>单位缴纳</th>
              <th>个人缴纳</th>
              <th>合计</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableData" :key="item.id">
              <td>{{item.name}}</td>
              <td>{{item.company}}</td>
              <td>{{item.people}}</td>
              <td>{{item.sum}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <no-data :message="message" v-show="nodata"></no-data>
  </div>
</template>
<script>
import MobileSelect from "@/components/plug-in/mobile-select";
import noData from "../../nonepage/nocalculator";
import Vue from 'vue'
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
export default {
  components: { noData },
  data() {
    return {
      workerInfo: {},
      allCity: Vue.getData('allCity') && Vue.getData('allCity').length > 0 ? Vue.getData('allCity') : [],
      cityid: Vue.getData('cityid') ? Vue.getData('cityid') : 171,
      cityname: Vue.getData('cityname') ? Vue.getData('cityname') : '青岛市',
      allInfo: Vue.getData('allInfo') ? Vue.getData('allInfo') : {},
      hukouCharacterName: '', //户籍性质下拉显示名称
      hukouCharacterId: '',
      natureSelect: null, //户籍性质下拉
      placeholderText: '',
      secList: [],
      nodata: false,
      message: '',
      month: this.$route.query.month,
      workIndex: 0,
      incomeIndex: 0,
      income: '',
      workList: Vue.getData('workList') ? Vue.getData('workList') : [],
      tableData: [],
    }
  },
  mounted() {
    Vue.setData('cityid', this.cityid);
    Vue.setData('cityname', this.cityname);
    if (this.allCity.length > 0) {
      this.checkCity();
    } else {
      this.getCityList();
    }
    if (this.workList.length > 0) {
      this.workList.forEach((ele, index) => {
        if (typeof this.month === 'undefined') {
          let arr = ele.incomeList.filter(item => { return item.secChange == 1 && item.type == 1 });
          this.income = arr[0].income;
          this.workerInfo = {
            secBasicNum: arr[0].secBasicNum,
            secType: arr[0].secType, //1全额，2最低，3不缴纳，4固定额
            secTotal: arr[0].secTotal
          };
        } else {
          ele.incomeList.some((item, num) => {
            if (item.month == this.month && item.type == 1) {
              this.workIndex = index;
              this.incomeIndex = num;
              this.income = item.income;
              this.workerInfo = {
                secBasicNum: item.secBasicNum,
                secType: item.secType, //1全额，2最低，3不缴纳，4固定额
                secTotal: item.secTotal
              };
            }
          });
        }
      });
    } else {
      this.workerInfo = {
        secBasicNum: 5000,
        secType: 4, //1全额，2最低，3不缴纳，4固定额
        secTotal: 0
      }
    }
    if (Object.keys(this.allInfo).length == 0) {
      this.getWorkerInfo();
    } else {
      this.hukouCharacterName = this.allInfo.sjgjHouseHoldRegisters[0].name;
      this.secList = Vue.getData('secList');
      this.iniSelect();
      this.changeTable();
      this.drawLine();
    }
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    // 开始计算
    submit() {
      this.workerInfo.secType = 4;
      if (this.workerInfo.secBasicNum < this.allInfo.lowshebao) {
        this.$alert('缴纳基数不能小于最低缴纳基数');
        this.workerInfo.secBasicNum = this.allInfo.lowshebao;
      } else if (this.workerInfo.secBasicNum > this.allInfo.highshebao) {
        this.$alert('缴纳基数不能大于最高缴纳基数');
        this.workerInfo.secBasicNum = this.allInfo.highshebao;
      } else if (this.income != '' && this.workerInfo.secBasicNum > this.income) {
        this.$alert('缴纳基数不能大于月度税前收入');
        this.workerInfo.secBasicNum = this.income;
      }
      this.saveData();
    },
    // 存数据
    saveData() {
      let sum = 0;
      this.secList.forEach(ele => {
        sum += ele.people * this.workerInfo.secBasicNum;
      });
      this.workerInfo.secTotal = sum.toFixed(2);
      this.drawLine();
      this.changeTable();
      if (this.workList.length > 0) {
        if (typeof this.month === 'undefined') {
          this.workList.forEach(ele => {
            ele.incomeList.forEach(item => {
              if (item.type == 1 && item.secChange == 1) {
                item.secBasicNum = this.workerInfo.secBasicNum;
                item.secType = this.workerInfo.secType;
                item.security = this.workerInfo.secTotal;
              }
            });
          });
        } else {
          this.workList[this.workIndex].incomeList[this.incomeIndex].secBasicNum = this.workerInfo.secBasicNum;
          this.workList[this.workIndex].incomeList[this.incomeIndex].secType = this.workerInfo.secType;
          this.workList[this.workIndex].incomeList[this.incomeIndex].security = this.workerInfo.secTotal;
          this.workList[this.workIndex].incomeList[this.incomeIndex].secChange = 2;
        }
        Vue.setData('workList', this.workList)
      }
    },
    changeTable() {
      this.tableData = [];
      this.secList.forEach(ele => {
        this.tableData.push({
          name: ele.name,
          company: (ele.company * this.workerInfo.secBasicNum).toFixed(2),
          people: (ele.people * this.workerInfo.secBasicNum).toFixed(2),
          sum: ((ele.company + ele.people) * this.workerInfo.secBasicNum).toFixed(2)
        })
      })
      var arr = [{
        name: '合计',
        company: eval(this.tableData.map(item => { return Number(item.company) }).join('+')).toFixed(2),
        people: eval(this.tableData.map(item => { return Number(item.people) }).join('+')).toFixed(2),
        sum: eval(this.tableData.map(item => { return Number(item.sum) }).join('+')).toFixed(2)
      }]
      this.tableData = this.tableData.concat(arr)
    },
    getCityList() {
      this.$siaxios.get("citybasic/calculatorcities").then(res => {
        if (res) {
          for (let a = 0; a < res.length; a++) {
            if (res[a].sjgjRegions.length != 0) {
              this.allCity = this.allCity.concat(res[a].sjgjRegions)
            }
          }
          Vue.setData('allCity', this.allCity);
          this.checkCity();
        }
      });
    },
    checkCity() {
      if (this.allCity.filter(item => { return item.id == this.cityid }).length == 0) {
        this.message = "该城市暂不支持查询，请切换其他城市"
        this.nodata = true;
      }
    },
    /**
     * 初始下拉框
     */
    iniSelect() {
      this.allInfo.sjgjHouseHoldRegisters.forEach(element => {
        element.value = element.name;
      });
      if (this.natureSelect) {
        this.natureSelect.updateWheel(0, this.allInfo.sjgjHouseHoldRegisters);
        this.hukouCharacterName = this.allInfo.sjgjHouseHoldRegisters[0].name;
        this.hukouCharacterId = this.allInfo.sjgjHouseHoldRegisters[0].id;
        this.getScaleDetail();
      } else {
        this.hukouCharacterName = this.allInfo.sjgjHouseHoldRegisters[0].name;
        this.hukouCharacterId = this.allInfo.sjgjHouseHoldRegisters[0].id;
        this.getScaleDetail();
        this.natureSelect = new MobileSelect({
          trigger: this.$refs.hukoucharacter,
          title: "户籍性质",
          triggerDisplayData: false,
          wheels: [{ data: this.allInfo.sjgjHouseHoldRegisters }],
          callback: (indexArr, data) => {
            this.hukouCharacterName = data[0].name;
            this.hukouCharacterId = data[0].id;
            this.getScaleDetail();
          }
        });
      }
    },
    /**
     * 根据户籍性质获取社保比例详情
     */
    getScaleDetail() {
      this.$siaxios
        .get(`/order/getBaoXian/${this.hukouCharacterId}`)
        .then(res => {
          this.secList = res;
          Vue.setData('secList', res);
          this.drawLine();
          this.changeTable();
        });
    },
    selectCity() {
      this.$citycalculatorselect({
        callback: (id, name) => {
          this.cityid = id;
          this.cityname = name;
          Vue.setData('cityid', this.cityid);
          Vue.setData('cityname', this.cityname);
          this.getWorkerInfo();
        }
      });
    },
    getWorkerInfo() {
      this.$siaxios.get("/order/getCityBase/" + this.cityid).then(res => {
        this.allInfo = res;
        Vue.setData('allInfo', res);
        if (this.workerInfo.secType == 1) {
          if (this.income < res.lowshebao) {
            this.workerInfo.secBasicNum = res.lowshebao;
          } else if (this.income > res.highshebao) {
            this.workerInfo.secBasicNum = res.highshebao
          } else {
            this.workerInfo.secBasicNum = this.income
          }
        } else if (this.workerInfo.secType == 2) {
          this.workerInfo.secBasicNum = res.lowshebao;
        } else if (this.workerInfo.secType == 3) {
          this.workerInfo.secBasicNum = 0;
        } else if (this.workerInfo.secType == 4) {
          if (this.workerInfo.secBasicNum < res.lowshebao) {
            this.workerInfo.secBasicNum = res.lowshebao;
          } else if (this.workerInfo.secBasicNum > res.highshebao) {
            this.workerInfo.secBasicNum = res.highshebao
          }
        }
        if (!this.income) {
          this.placeholderText = res.lowshebao + ' ~ ' + res.highshebao;
        } else {
          this.placeholderText = res.lowshebao + ' ~ ' + (this.income > res.highshebao ? res.highshebao : this.income);
        }
        this.hukouCharacterName = res.sjgjHouseHoldRegisters[0].name;
        this.iniSelect();

      })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("chart"));
      let legendData = this.secList.map(item => { return item.name });
      let sum = eval(this.secList.map(item => { return item.people }).join('+'))
      let seriesData = this.secList.map(item => {
        return { value: (item.people / sum * 100).toFixed(2), name: item.name }
      })
      // 绘制图表
      myChart.setOption({
        title: {
          text: '缴纳项目',
          top: 20,
          left: '50%',
          textStyle: {
            fontSize: 16
          }
        },
        color: ["#4884ff", '#44dd7a', '#d973ff', '#ff7a7a', '#ffb849'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          //图表的位置
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          containLabel: true
        },
        legend: {
          orient: 'vertical',
          top: '40%',
          left: '50%',
          right: 0,
          bottom: 0,
          data: legendData
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '90%'],
            center: ['20%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            itemStyle: {
              borderWidth: 3,
              borderColor: '#fdfeff'
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                formatter: '{c}%'
              }
            },
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.workers {
  .top {
    box-sizing: border-box;
    padding: 50px 30px 30px 30px;
    background: #4884ff;
    color: #fff;
    .title {
      font-size: 28px;
      line-height: 48px;
      margin-bottom: 30px;
      .location {
        float: right;
        padding: 0 30px;
        background: url("../../../assets/arrow_down_white.png") right center
          no-repeat;
        background-size: 18px 10px;
      }
      small {
        opacity: 0.6;
      }
    }
    .money {
      position: relative;
      height: 86px;
      border-bottom: #fff 1px solid;
      margin-bottom: 30px;
      input {
        width: 60%;
        font-size: 46px;
        line-height: 46px;
        background: none;
        border: none;
        color: #fff;
      }
      span {
        float: right;
        width: 160px;
        height: 60px;
        text-align: center;
        background: #fff;
        color: #4884ff;
        font-size: 26px;
        line-height: 60px;
        border-radius: 30px;
      }
      em {
        font-size: 46px;
        font-style: normal;
      }
    }
    .errorMes {
      margin-top: -20px;
      color: #ff5151;
      font-size: 24px;
      margin-bottom: 20px;
      min-height: 1px;
    }
    .btn {
      text-align: right;
      button {
        outline: none;
        height: 48px;
        border-radius: 24px;
        border: #fff 1px solid;
        padding: 0 40px;
        background: none;
        margin-left: 40px;
        color: #fff;
        font-size: 26px;
        &.on {
          background: #fff;
          color: #4884ff;
        }
      }
    }
  }
  .nature {
    background: #fff url("../../../assets/right.png") 710px center no-repeat;
    background-size: 10px auto;
    margin-bottom: 20px;
    line-height: 100px;
    font-size: 28px;
    color: #333;
    text-align: right;
    padding: 0 54px 0 30px;
    label {
      color: #666;
      float: left;
      font-size: 26px;
    }
  }
  .show {
    background: #fff;
    padding: 30px;
    .chart {
      height: 310px;
      margin-bottom: 30px;
    }
  }
}
</style>