<template>
  <div class="dangantq-creatorder">
    <div class="banner-con">
      <div class="banner">
        <span>档案提取</span>
      </div>
    </div>
  
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <span>常驻地发生变化或新单位可以接收档案等原因需要转移档案的，可办理提取手续，办理提取手续必须要有正规保管资质的人才机构或单位接收。</span>
      </div>
    </div>
      <div class="order-gray-line"></div>
    <div class="introduce " >
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
        <span class="intro-title">业务流程</span>
      </div>
      <div class="process">
        <div class="process-con" style="margin-left:5px">
          <div class="proess-img">
            <img src="@/assets/views/mine/da-dc1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>提交提档申请</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con" style="margin-left:10px">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan2.jpg" alt />
          </div>
          <div class="procss-text">
            <span>邮寄提档材料</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con" style="margin-left:15px;padding-right:0">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进办理</span>
          </div>
        </div>
      </div>
      <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>
      </div>
      <div class="process" style="margin-top: 20px;margin-left:5px;">
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu2.jpg" alt />
          </div>
          <div class="procss-text">
            <span>档案邮寄到指定地点</span>
          </div>
        </div>
        <div class="arrow" style="float: right;margin-right:10px;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan3.jpg" alt />
          </div>
          <div class="procss-text">
            <span>查收</span>
          </div>
        </div>
        <div class="arrow" style="float: right;margin-right:10px;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 5px;padding-left:0">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce ">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/shuoming.jpg" alt />
        <span class="intro-title">业务说明</span>
      </div>
      <div class="content">
        <div class="isthone">
          <div class="thone">
            <span>1、</span>
          </div>
          <div class="thone-text">
            <span>提当前如有欠费请及时缴纳补齐欠费。</span>
          </div>
        </div>
        <div class="isthone">
          <div class="thone">
            <span>2、</span>
          </div>
          <div class="thone-text">
            <span>提当前请一定要确认好档案接收地址及有机形式（机要，非机要）以免造成退档。</span>
          </div>
        </div>
        <div class="isthone">
          <div class="thone">
            <span>3、</span>
          </div>
          <div class="thone-text">
            <span>请选择邮寄调档材料到我方的请邮寄到指定地址。</span>
            <p>收件人：李青青</p>
            <div class="isthone">
              <div class="thone" style="width:70px;">
                <span>联系电话：</span>
              </div>
              <div class="thone" style="width:200px;">
                <span>0532-85873655转813或815</span>
                <p>18661887826</p>
              </div>
            </div>
            <p>联系地址：青岛市市南区高雄路18号海洋大厦5层</p>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/cailiao.jpg" alt />
        <span class="intro-title">所需材料</span>
      </div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">调档函原件</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">身份证复印件</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text" style="width:250px">委托他人另需委托证书</div>
          </div>
        </div>
        <div class="needs">
          <div class="word">
            <div class="word-icon">
              <img class="title-img" src="@/assets/views/mine/house.png" alt />
            </div>
            <div class="word-text">
              <span>委托书模板.doc</span>
            </div>
            <div class="word-btn" @click="uploadFile()">
              <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
              <button>下载</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="showmessage"></div>
  </div>
</template>
<script>
import "@/sass/views/gallery/dangantq.scss";

export default {
  name: "dangantq",
  data() {
    return {
      exportApi: ""
    };
  },

  methods: {
    //委托书模板下载
    uploadFile() {
      let url = this.$axios.defaults.baseURL + "common/downloadFile/1";
      this.$copyText(`${url}`).then(
        () => {
          this.$tips({
            describe: "已成功复制链接，请通过浏览器下载该文档"
          });
        },
        () => {
          this.$tips({
            describe: "复制失败"
          });
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.banner-con {
  background: url("../../assets/views/mine/dangan-banner.jpg") 100% 100%;
  background-size: cover;
}
.process-con {
  width: 160px;
}
.process2 {
  margin-right: 50px;
  margin-top: 25px;
}
.introduce {
  .title {
    .title-img {
      width: 4%;
    }

    .intro-title {
      font-size: 28px;
    }
  }
}
</style>
