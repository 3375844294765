<template>
  <div class="dangan-creatorder">
    <div class="banner-con">
      <div class="banner">
        <span>档案提取</span>
      </div>
    </div>
    <div class="info-line" ref="hukoucharacter">
      <div>提取方式</div>
      <div class="select-style">请选择</div>
      <img class="right" src="@/assets/right.png" alt />
    </div>
    <template v-if="type==2">
      <div class="info-line">
        <div>收件人</div>
        <input class="inputc" v-model="paramInfo.person" placeholder="请填写收件人" type="text" />
      </div>
      <div class="info-line">
        <div>电话号码</div>
        <input class="inputc" v-model="paramInfo.persontel" placeholder="请填写电话号码" type="text" />
      </div>
      <div class="info-line">
        <div>邮寄地址</div>
        <input class="inputc" v-model="paramInfo.personadd" placeholder="请填写邮寄地址" type="text" />
      </div>
    </template>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <span>档案借阅材料自借阅之日起一个月内请及时归还归还后我方将押金予以退还</span>
      </div>
    </div>
    <div class="introduce showmessage" style="padding-top: 0">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
        <span class="intro-title">业务流程</span>
      </div>
      <div class="process">
        <div class="process-con" style="margin-left:5px">
          <div class="proess-img">
            <img src="@/assets/views/mine/da-dc1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>提交提档申请</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con" style="margin-left:5px">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan2.jpg" alt />
          </div>
          <div class="procss-text">
            <span>邮寄提档材料</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con" style="margin-left:5px">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进办理</span>
          </div>
        </div>
      </div>
      <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>
      </div>
      <div class="process" style="margin-top: 20px">
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu2.jpg" alt />
          </div>
          <div class="procss-text">
            <span>档案邮寄到指定地点</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan3.jpg" alt />
          </div>
          <div class="procss-text">
            <span>查收</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 5px">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/shuoming.jpg" alt />
        <span class="intro-title">业务说明</span>
      </div>
      <div class="content">
        <div class="isthone">
          <div class="thone">
            <span>1、</span>
          </div>
          <div class="thone-text">
            <span>提当前如有欠费请及时缴纳补齐欠费。</span>
          </div>
        </div>
        <div class="isthone">
          <div class="thone">
            <span>2、</span>
          </div>
          <div class="thone-text">
            <span>提当前请一定要确认好档案接收地址及有机形式（机要，非机要）以免造成退档。</span>
          </div>
        </div>
        <div class="isthone">
          <div class="thone">
            <span>3、</span>
          </div>
          <div class="thone-text">
            <span>请选择邮寄调档材料到我方的请邮寄到指定地址。</span>
            <p>收件人：李青青</p>
            <div class="isthone">
              <div class="thone" style="width:70px;">
                <span>联系电话：</span>
              </div>
              <div class="thone" style="width:200px;">
                <span>0532-85873655转813或815</span>
                <p>18661887826</p>
              </div>
            </div>
            <p>联系地址：青岛市市南区高雄路18号海洋大厦5层</p>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/cailiao.jpg" alt />
        <span class="intro-title">所需材料</span>
      </div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">调档函原件</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">身份证复印件</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text" style="width:250px">委托他人另需委托证书</div>
          </div>
        </div>
        <div class="needs">
          <div class="word">
            <div class="word-icon">
              <img class="title-img" src="@/assets/views/mine/house.png" alt />
            </div>
            <div class="word-text">
              <span>委托书模板.doc</span>
            </div>
            <div class="word-btn" @click="uploadFile()">
              <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
              <button>下载</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="showmessage"></div>
    <div class="order-btn" @click="nextStemp()">
      {{this.type==1?'支付机要费':'提交'}}
      <span v-if="type == 1">￥{{amount}}</span>
    </div>
  </div>
</template>
<script>
import "@/sass/views/mine/dangan.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import { mapGetters } from "vuex";

export default {
  name: "extractArchives",
  data() {
    return {
      amount: 0,
      type: "", //1机要 2邮寄
      paramInfo: {
        id: "",
        saletypeid: 19,
        person: "",
        persontel: "",
        personadd: "",
        userid: "",
        sjgjOrderDangan: {
          cityid: this.$route.query.cityid
        }
      },
      exportApi: ""
    };
  },
  mounted() {
    this.getPrice();
    this.iniSelect();
    this.paramInfo.userid = this.userInfo.id;
    this.paramInfo.id = this.$route.query.id;
    // this.paramInfo.userid = this.$route.query.userid
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 初始化列表
     */
    iniSelect() {
      new MobileSelect({
        trigger: this.$refs.hukoucharacter,
        title: "邮寄方式",
        wheels: [
          {
            data: [
              { id: 1, value: "机要" },
              { id: 2, value: "邮寄" }
            ]
          }
        ],
        callback: (indexArr, data) => {
         
          this.type = data[0].id;
          
        }
      });
    },
    getPrice() {
      this.$siaxios.get("order/getDangAnPrice/2").then(res => {
        this.amount = res.amount;
      });
    },
    /**
     * 下一步去创建订单
     */
    nextStemp() {
      if (this.type == "") {
        this.$tips({ title: "提示", describe: "请选择提取方式" });
        return;
      }
      let url;
      if (this.type == 1) {
        url = "order/saveDangAnTiQu";
      } else {
        if (this.paramInfo.person == "") {
          this.$tips({ title: "提示", describe: "请填写收件人" });
          return;
        }
        var reg2 = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
        if (this.paramInfo.persontel == "") {
          this.$tips({ title: "提示", describe: "请填写电话号码" });
          return;
        } else if (!reg2.test(this.paramInfo.persontel)) {
          this.$tips({ describe: "手机号码格式不正确" });
          this.paramInfo.persontel = "";
          return;
        }
        if (this.paramInfo.personadd == "") {
          this.$tips({ title: "提示", describe: "请填写邮寄地址" });
          return;
        }
        url = "dangan/saveDangAnTiQu";
      }
      this.$siaxios.post(url, this.paramInfo, false).then(res => {
        this.$tips({
          title: "提示",
          describe: "提取成功，去支付",
          callback: () => {
            this.$router.push({
              path: "/creatorder/orderdetail",
              query: { id: res.id }
            });
          }
        });
      });
    },
    //委托书模板下载
    uploadFile() {
      let url = this.$axios.defaults.baseURL + "common/downloadFile/1";
      this.$copyText(`${url}`).then(
        () => {
          this.$tips({
            describe: "已成功复制链接，请通过浏览器下载该文档"
          });
        },
        () => {
          this.$tips({
            describe: "复制失败"
          });
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.banner-con {
  background: url("../../assets/views/mine/dangan-banner.jpg") 100% 100%;
  background-size: cover;
}
.process-con {
  width: 160px;
}
.process2 {
  margin-right: 50px;
  margin-top: 25px;
}
.introduce {
  .title {
    .title-img {
      width: 4%;
    }

    .intro-title {
      font-size: 28px;
    }
  }
}
</style>
