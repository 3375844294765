<template>
    <div class="house-index" >
            <div class="banner-con">
                <div class="banner">
                    <span>—&nbsp;&nbsp;烟台买房政策详细解读&nbsp;&nbsp;—</span>
                </div>
            </div>
            <div class="title">
                <img src="@/assets/views/mine/house.png" alt/>
                <span class="title-">买房条件</span>
            </div>
            <div class="cont" style="height:330px">
                <div class="con-title">
                    <span>外地人在烟台买房条件</span>
                </div>
                <div class="con-cont" >
                    <div class="con-introduce">
                        <span>外地人想要在烟台买房的话，购买家庭需要提供在烟台一年以上纳税证明或者社保缴纳政策</span>
                    </div>
                </div>
                <div class="con-cont" style="margin-top:10px">
                    <div class="con-introduce">
                        <span>具有合法有效的身份证明（居民身份证、户口本或其他有效身份证明）及婚姻状况证明</span>
                    </div>
                </div>
                <div class="con-cont" style="margin-top:10px">
                    <div class="con-introduce">
                        <span>购房家庭具有稳定的收入来源和按时足额偿还贷款本息的能力</span>
                    </div>
                </div>
                <div class="con-cont" style="margin-top:7px">
                    <div class="con-introduce">
                        <span>具有良好的信用记录和还款意愿</span>
                    </div>
                </div>
                <div class="con-cont" style="margin-top:-15px">
                    <div class="con-introduce">
                        <span>购房家庭具有支付所购房屋首期房款能力</span>
                    </div>
                </div>
            </div>

    </div>
</template>
<script>
    import "@/sass/views/gallery/buhouse.scss";
    export default {
        name: "index",
        data() {
            return {

            };
        },



    };
</script>
<style lang="scss" scoped>

</style>