<!--
  户口迁出
-->
<template>
  <div class="mine-creatorder">
 
    <div class="showmessage">
      <div class="order-title">简介</div>
      <div
        class="content"
      >常住地发生变化，想将户口迁移至现住地，因本人不在当地或业务繁忙等原因，本人不能亲自去派出所办理户口迁出业务，档管家可全程为您代办，让您省心省力，安全有保障。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">说明</div>
      <div class="content">
        <p>1. 户口迁出条件：公民离开户口所在地到其他地方经常居住，符合居住地人民政府规定落户的，应该办理户口迁移手续。</p>
        <p>2. 办理户口迁出所需材料：1.准迁证（仅限跨省迁移） 2. 居民户口簿或集体户口登记卡。</p>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-btn" @click="nextStemp()">迁出</div>
  </div>
</template>
<script>
import "@/sass/views/mine/creatorder.scss";
import { mapGetters } from "vuex";
export default {
  name: "residenceBack",
  data() {
    return {
      amount: 0,
      id: this.$route.query.id
    };
  },
  // created() {
  //   this.getPrice();
  // },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 户口迁出
     */
    nextStemp() {
      this.$siaxios.get("hukou/HuKouQianChu?" + "id=" + this.id).then(() => {
        this.$tips({
          title: "提示",
          describe: "迁出成功",
          callback:()=>{
            this.$router.go(-1);
          }
        });
      });
    }
  }
};
</script>
