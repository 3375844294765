<template>
  <div class="cutgrom-index">
      <div :class="$route.query.id==1?'banner-con2':'banner-con'">
      <div class="banner">
        <div class="title-banner">
          <span>单页借取</span>
        </div>
      </div>
    </div>
    <div class="cont-use">
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>因买房子，办身份证，结婚，办护照等都需要户口本，户口在我房集体户的可办理借用手续</span>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:20px;">
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/da-dc1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>APP提交申请</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>支付押金</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img style="width:18px;height:21px;" src="@/assets/views/mine/zuobiao.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>填写邮寄地址信息</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 20px;">
                <div class="showinfo-1-item" style="margin-left:100px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:5px">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/police (3).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>查收</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:6px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:8px">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/tiqu2.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>邮寄到指定地址</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:30px;padding-bottom:20px">
        <div class="use-introduce">
          <img src="@/assets/views/mine/shuoming.jpg" alt style="width:15px;height:15px" />
           <span>{{$route.query.id==1?'业务说明':'温馨提示'}}</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="hot-cont">
          <div class="hot-num">1.</div>
          <div class="hot-cont">资料的邮寄地址 联系人 联系方式一定要确认好再填写，以免因地址错误给您的后续工作造成不必要的麻烦</div>
        </div>
        <div class="hot-cont" style="margin-top:10px;">
          <div class="hot-num">2.</div>
          <div class="hot-cont">单页用完之后请在15个工作日内及时时归还，如有特殊情况可先联系我们的专业顾问</div>
        </div>
        <div class="hot-cont" style="margin-top:10px;">
          <div class="hot-num">3.</div>
          <div class="hot-cont">
            <p>单页归还后我方将押金予以退还，选择邮寄归还的请将材料邮寄到我方指定地址</p>
            <p class="tips-cont">收件人：李青青</p>
            <p class="tips-cont">联系电话：0532-85873655转813或815</p>
            <p class="tips-cont" style="margin-left:62px;">18661887826</p>
            <p class="tips-cont">联系地址： 青岛市市南区高雄路18号海洋大厦5层</p>
          </div>
        </div>
      </div>

      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:20px;">
        <div class="use-introduce">
          <img src="@/assets/views/mine/cailiao.jpg" alt />
          <span>所需材料</span>
        </div>
        <div class="content">
          <div class="needs">
            <div class="needs-cont">
              <div class="neds-num">1</div>
              <div class="neds-text">身份证</div>
            </div>
            <div class="needs-cont" style="margin-left: 90px">
              <div class="neds-num">2</div>
              <div class="neds-text">押金</div>
            </div>
          </div>
          <div class="needs" >
            <div class="needs-cont">
              <div class="neds-num">3</div>
              <div class="neds-text">委托书（他人借取）附模板</div>
            </div>
          </div>
          <div class="needs" >
            <div class="word">
              <div class="word-icon">
                <img class="title-img" src="@/assets/views/mine/house.png" alt />
              </div>
              <div class="word-text">
                <span>委托书模板.doc</span>
              </div>
              <div class="word-btn" @click="uploadFile(1)">
                <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
                <button>下载</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="!$route.query.id">
        <div class="gary-line"></div>
        <div class="cont-use">
          <div class="use-cont" style="padding-bottom:20px;">
            <div class="use-introduce">
              <img src="@/assets/views/mine/dangan-quest_03.jpg" alt style="width:15px;height:15px" />
              <span>常见问题</span>
            </div>
          </div>
          <div class="use-cont" style="padding-bottom:20px;">
            <div class="active-title">1. 借阅超过期限怎么办</div>
            <div class="active-cont">借阅期限自借阅之日起15个工作日内要及时归还，如因特殊原因需要延期的，可联系我们的顾问适当做延期处理。</div>
          </div>
         
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import "@/sass/views/mine/cut-from.scss";

export default {
  name: "archives-use",
  data() {
    return {
      exportApi: ""
    };
  },
  methods: {
    //委托书模板下载
    uploadFile(type) {
      let url = this.$axios.defaults.baseURL + "common/downloadFile/" + type;
      this.$copyText(`${url}`).then(
        () => {
          this.$tips({
            describe: "已成功复制链接，请通过浏览器下载该文档"
          });
        },
        () => {
          this.$tips({
            describe: "复制失败"
          });
        }
      );
    }
  }
};
</script>

 