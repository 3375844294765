<template>
    <div class="children calculator">
        <div class="top-money">
            <button @click="clearData">清除数据</button>
            <label>本人年度扣除金额</label>
            <span>¥</span>
            <input type="number" v-model="total" readonly>
        </div>
        <div class="group-con white-bg">
            <div class="group">
                <label>子女数量</label>
                <div class="change-num">
                    <span class="reduce" @click="reduce"></span>
                    <input type="number" v-model="children.length" class="number" readonly>
                    <span class="add" @click="add"></span>
                </div>
            </div>
        </div>
        <div class="form-con border-top">
            <div class="tab-con">
                <div class="tab">
                    <span :class="{on:tabIndex==index}" v-for="(item,index) in children.length" :key="index" @click="changeTab(index)">子女{{index+1}}</span>
                </div>
            </div>
            <div v-for="(item,index) in children" v-show="index==tabIndex" :key="index">
                <div class="form-group">
                    <label>教育类型</label>
                    <div class="group-right">
                        <div class="radio-con">
                            <v-radio v-model="item.eduType" value="1"></v-radio><span>学前教育</span>
                        </div>
                        <div class="radio-con">
                            <v-radio v-model="item.eduType" value="2"></v-radio><span>学历教育</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>扣除方式</label>
                    <div class="group-right">
                        <div class="radio-con">
                            <v-radio v-model="item.deductType" value="1"></v-radio><span>每月</span>
                        </div>
                        <div class="radio-con">
                            <v-radio v-model="item.deductType" value="2"></v-radio><span>每年</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>扣除人</label>
                    <div class="group-right">
                        <div class="radio-con">
                            <v-radio v-model="item.deducter" value="1"></v-radio><span>本人100%</span>
                        </div>
                        <div class="radio-con">
                            <v-radio v-model="item.deducter" value="2"></v-radio><span>配偶100%</span>
                        </div>
                        <div class="radio-con">
                            <v-radio v-model="item.deducter" value="3"></v-radio><span>夫妻各50%</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="right-select" ref="month">
                        {{item.monthNum}}月
                    </div>
                    <label>开始扣除月份</label>
                </div>
            </div>
        </div>
        <div class="description">
            <div class="title"><span>子女教育抵扣说明</span></div>
            <dl class="description-text">
                <dt>扣减金额</dt>
                <dd>
                    学前教育、学历教育，每月1000元/每年12000元
                </dd>
            </dl>
            <dl class="description-text">
                <dt>学前教育</dt>
                <dd>
                    年满3岁至小学入学前
                </dd>
            </dl>
            <dl class="description-text">
                <dt>学历教育</dt>
                <dd>
                    <ul class="list">
                        <li>义务教育（小学和初中教育）</li>
                        <li>高中阶段教育（普通高中、中等职业教育）</li>
                        <li>高等教育（大学专科、大学本科、硕士研究生、博士研 究生教育）</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>证明材料</dt>
                <dd>
                    个人申报+政府相关数据，税务部门核实
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>
import "@/sass/views/calculator/main.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            children: [{
                eduType: '',
                deductType: '',
                deducter: '',
                monthNum: 1,
                total: 0
            }],
            total: '',
            tabIndex: 0,
            monthList: [],
            monthSelect: [null]
        }
    },
    created() {
        for (var i = 1; i < 13; i++) {
            this.monthList.push({ id: i, value: i + '月' })
        }
    },
    watch: {
        "children": {
            handler() {
                let sum = 0;
                this.children.forEach(ele => {
                    if (ele.eduType != '') {
                        if (ele.deducter == 1) {
                            ele.total = (12000/12*(13-ele.monthNum)).toFixed(2)*100/100;
                            sum += ele.total;
                        } else if (ele.deducter == 3) {
                            ele.total = (6000/12*(13-ele.monthNum)).toFixed(2)*100/100;
                            sum += ele.total;
                        }
                    }
                });
                this.total = sum;
                Vue.setData('special.children', this.children);
            },
            deep: true
        }
    },
    mounted() {
        if (!!Vue.getData('special') && !!Vue.getData('special.children')) {
            this.children = Vue.getData('special.children');
        }
        this.selectMonth()
    },
    methods: {
        clearData() {
            this.children = [{
                eduType: '',
                deductType: '',
                deducter: '',
                monthNum: 1,
                total: 0
            }]
        },
        changeTab(index) {
            this.tabIndex = index;
            this.selectMonth();
        },
        reduce() {
            if(this.children.length > 1){
                this.children.splice(this.children.length-1,1)
            }
            if (this.tabIndex > this.children.length - 1) {
                this.tabIndex = this.children.length - 1;
            }
        },
        add() {
            if (this.children.length < 5) {
                this.children.push({
                    eduType: '',
                    deductType: '',
                    deducter: '',
                    monthNum: 1
                });                
                this.monthSelect.push(null)
            }
        },
        selectMonth() {
            if (!this.monthSelect[this.tabIndex]) {
                this.monthSelect[this.tabIndex] = new MobileSelect({
                    trigger: this.$refs.month[this.tabIndex],
                    title: "开始扣除月份",
                    wheels: [{ data: this.monthList }],
                    callback: (indexArr, data) => {
                        this.children[this.tabIndex].monthNum = data[0].id;
                    }
                });
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.calculator {
    background: #fff;
    min-height: 100vh;
}
.top-money {
    background-image: linear-gradient(to right, #3e59ff, #5287ff);
}
.tab-con {
    width: 100%;
    padding: 0 30px;
    overflow-x: auto;
    .tab {
        padding: 0;
        white-space: nowrap;
    }
}
.calculator .form-con .tab span {
    margin-right: 60px;
}
</style>