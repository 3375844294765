<template>
    <div class="notes">
        <div class="top">
            <label>个税欠费</label>
            ¥{{tax}}
        </div>
        <div class="data">
            <div class="title">年度税后收入数据图</div>
            <div class="chart" id="chart"></div>
        </div>
        <div class="data">
            <div class="title">月度税后收入表</div>
            <table class="notes-table">
                <thead>
                    <tr>
                        <th>月份</th>
                        <th>月度税后收入</th>
                        <th>累计税后收入</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in notes" :key="index">
                        <td>{{item.month}}月</td>
                        <td>{{item.leftIncome.toFixed(2)}}</td>
                        <td>{{count(index)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            workList: Vue.getData('workList'),
            notes: [],
            tax:'',
            month:new Date().getMonth() + 1
        }
    },
    mounted() {
        this.workList.forEach(ele => {
            ele.incomeList.forEach(item => {
                if (this.notes.filter(value => { return value.month == item.month }).length > 0) {
                    this.notes.map(value => {
                        if (value.month == item.month) {
                            value.tax+=Number(item.tax);
                            value.leftIncome += item.income - item.fund - item.security - item.tax > 0 ? item.income - item.fund - item.security - item.tax : 0
                        }
                    })
                } else {
                    this.notes.push({
                        month: item.month,
                        tax:item.tax,
                        leftIncome: item.income - item.fund - item.security - item.tax > 0 ? item.income - item.fund - item.security - item.tax : 0
                    })
                }
            });
        });
        console.log(this.notes.map(item=>{return Number(item.tax)}))
        this.tax=(eval(this.notes.map(item=>{return Number(item.tax)}).join('+'))-eval(this.notes.map(item=>{return item.month<=this.month?Number(item.tax):0}).join('+'))).toFixed(2)
        this.drawLine()
        
    },
    methods: {
        count(index) {
            let sum = 0;
            this.notes.forEach((ele, num) => {
                if (num <=index) {
                    sum += ele.leftIncome;
                }
            });
            return sum.toFixed(2)
        },
        drawLine() {
            let myChart = this.$echarts.init(document.getElementById("chart"));
            // 绘制图表
            myChart.setOption({
                color: ['#3367ff'],
                grid: {
                    //图表的位置
                    top: "50",
                    left: "40",
                    right: "0",
                    bottom: "30"
                },
                xAxis: {
                    type: 'category',
                    data: this.notes.map(item=>{return item.month}),
                    axisLine: {
                        lineStyle: {
                            color: '#999'
                        }
                    },
                    axisTick: false,
                },
                yAxis: {
                    name: '收入(元)',
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#999'
                        }
                    },
                    axisTick: false,
                    splitLine: {
                        lineStyle: {
                            color: '#eee'
                        }
                    }
                },
                series: [{
                    data:  this.notes.map(item=>{return item.leftIncome}),
                    type: 'line',
                    smooth: true,
                    symbolSize: 10
                }]
            });
            setTimeout(function () {
                window.onresize = function () {
                    myChart.resize();
                }
            }, 300)
        }
    }
}
</script>
<style lang="scss" scoped>
.notes {
    .top {
        background: #fff;
        margin-bottom: 20px;
        padding: 30px 60px;
        font-size: 44px;
        color: #3367ff;
        line-height: 44px;
        label {
            display: block;
            font-size: 26px;
            color: #333;
            padding-left: 40px;
            background: url("../../../assets/views/calculator/list.png") left
                center no-repeat;
            background-size: 28px 22px;
            margin-bottom: 22px;
        }
    }
    .data {
        background: #fff;
        margin-bottom: 20px;
        padding: 0 30px 30px 30px;
        &:last-of-type{
            margin-bottom: 0;
        }
        .title {
            font-size: 28px;
            padding-left: 20px;
            line-height: 100px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 37px;
                left: 0;
                width: 4px;
                height: 24px;
                background: #3367ff;
            }
        }
        .chart {
            height: 550px;
            border-top: #eeeeee 1px solid;
        }
        .notes-table {
            border-right: #4e72da 1px solid;
            border-top: #4e72da 1px solid;
            border-collapse: collapse;
            border-spacing: 0;
            font-size: inherit;
            width: 100%;
            box-sizing: border-box;
            th {
                background: #4e72da;
                box-sizing: border-box;
                height: 90px;
                color: #fff;
                font-size: 26px;
                text-align: center;
            }
            td {
                height: 88px;
                border-left: #4e72da 1px solid;
                border-bottom: #4e72da 1px solid;
                text-align: center;
                box-sizing: border-box;
                font-size: 26px;
            }
        }
    }
}
</style>