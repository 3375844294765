<template>
  <div class="danganreturn-creatorder">
    <div :class="$route.query.id==1?'banner-con2':'banner-con'">
      <div class="banner">
        <span>档案归还</span>
      </div>
    </div>

    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <span>档案借阅材料自借阅之日起一个月内请及时归还归还后我方将押金予以退还</span>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
        <span class="intro-title">业务流程</span>
      </div>
      <div class="process">
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/da-dc1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>APP申请归还</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>填写归还账号等信息</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan2.jpg" alt />
          </div>
          <div class="procss-text">
            <span>借阅档案邮寄/送达</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan3.jpg" alt />
          </div>
          <div class="procss-text">
            <span>我方收取</span>
          </div>
        </div>
      </div>
      <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>
      </div>
      <div class="process" style="margin-top: 20px">
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan4.jpg" alt />
          </div>
          <div class="procss-text">
            <span>退还押金</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/shuoming.jpg" alt />
        <span class="intro-title">温馨提示</span>
      </div>
      <div class="content">
        <div class="isthone">
          <div class="thone-text">
            <span>请选择邮寄调档材料到我方的请邮寄到指定地址。</span>
            <p>收件人：李青青</p>
            <div class="isthone">
              <div class="thone" style="width:70px;">
                <span>联系电话：</span>
              </div>
              <div class="thone" style="width:200px;">
                <span>0532-85873655转813或815</span>
                <p>18661887826</p>
              </div>
            </div>
            <p>联系地址：青岛市市南区高雄路18号海洋大厦5层</p>
          </div>
        </div>
      </div>
    </div>
    <template v-if='!$route.query.id'>
      <div class="order-gray-line"></div>
      <div class="cont-use" style="padding-top:20px">
        <div class="use-cont">
          <div class="use-introduce">
            <img src="@/assets/views/mine/dangan-quest_03.jpg" alt style="width:15px;height:15px" />
            <span>常见问题</span>
          </div>
        </div>
        <div class="use-cont">
          <div class="active-title">1.档案借阅有期限吗？</div>
          <div
            class="active-cont"
          >借阅期限自借阅之日起一个月内要及时归还，如因特殊原因需要延期的，可联系我们的顾问适当做延期处理。</div>
        </div>
       
       
      </div>
    </template>
  </div>
</template>
<script>
import "@/sass/views/gallery/danganreturn.scss";
import { mapGetters } from "vuex";
export default {
  name: "backarchive",
  data() {
    return {
      amount: 0,
      paramInfo: {
        id: this.$route.query.id,
        name: "",
        bank: "",
        bankcard: ""
      }
    };
  },
  created() {
    // this.getPrice();
  },

  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 下一步去创建订单
     */
    nextStemp() {
      if (this.paramInfo.name == "") {
        this.$tips({ title: "提示", describe: "请填写姓名" });
        return;
      }
      if (this.paramInfo.bank == "") {
        this.$tips({ title: "提示", describe: "请填写银行" });
        return;
      }
      var reg = /^([1-9]{1})(\d{15}|\d{18})$/;
      if (this.paramInfo.bankcard == "") {
        this.$tips({ title: "提示", describe: "请填写银行卡号" });
        return;
      } else if (!reg.test(this.paramInfo.bankcard)) {
        this.$tips({ title: "提示", describe: "请填写正确的银行卡号" });
        this.paramInfo.bankcard = "";
        return;
      }
      this.$siaxios
        .post(`dangan/saveDangGuiHuan`, this.paramInfo, false)
        .then(() => {
          this.$tips({
            title: "提示",
            describe: "归还成功",
            callback: () => {
              this.$router.go(-1);
            }
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
