<template>
  <div class="business-index">
    <div class="item" @click="informDetail(1)">
      <div
        class="business-state"
        v-if="mybussiness.sjgjSheBao.state != '' "
      >{{getState(mybussiness.sjgjSheBao.state)}}</div>
      <div class="logo">
        <img src="@/assets/views/mine/business1.png" alt class="logo-img" />
      </div>
      <div class="title">代缴社保</div>
      <template v-if="mybussiness.sjgjSheBao.state!=4">
        <div
          class="validity"
          v-if="mybussiness.sjgjSheBao  != '' && mybussiness.sjgjSheBao.state != ''  && mybussiness.sjgjSheBao.state != 4"
        >有效期至{{mybussiness.sjgjSheBao.overmonth}}</div>
        <div
          class="validity"
          v-if="mybussiness.sjgjSheBao  == '' || mybussiness.sjgjSheBao.state == ''"
        >暂无数据</div>
      </template>
    </div>
    <div class="item" @click="informDetail(2)">
      <div
        class="business-state"
        v-if="mybussiness.sjgjGongJiJin.states != '' "
      >{{getState(mybussiness.sjgjGongJiJin.states)}}</div>
      <div class="logo">
        <img src="@/assets/views/mine/business2.png" alt class="logo-img" />
      </div>
      <div class="title">代缴公积金</div>

      <template v-if="mybussiness.sjgjGongJiJin.states!=4">
        <div
          class="validity"
          v-if="mybussiness.sjgjGongJiJin.state != '' && mybussiness.sjgjGongJiJin.state != 4 && mybussiness.sjgjGongJiJin != ''"
        >有效期至{{mybussiness.sjgjGongJiJin.end}}</div>
        <div
          class="validity"
          v-if="mybussiness.sjgjGongJiJin.state == '' || mybussiness.sjgjGongJiJin == ''"
        >暂无数据</div>
      </template>
    </div>
    <div class="item" @click="informDetail(3)">
      <div
        class="business-state"
        v-if="mybussiness.sjgjArchives.state != '' && mybussiness.sjgjArchives.state != 1"
      >{{getState_(mybussiness.sjgjArchives.state)}}</div>
      <div class="logo">
        <img src="@/assets/views/mine/business3.png" alt class="logo-img" />
      </div>
      <div class="title">档案代存</div>
      <div
        class="validity"
        v-if="mybussiness.sjgjArchives != '' && mybussiness.sjgjArchives.state != '' && mybussiness.sjgjArchives.state != 5  && mybussiness.sjgjArchives.state != 1"
      >有效期至{{formatTime(mybussiness.sjgjArchives.expiringtime)}}</div>
      <div
        class="validity"
        v-if="mybussiness.sjgjArchives.state == '' || mybussiness.sjgjArchives.state == 1 || mybussiness.sjgjArchives == ''"
      >暂无数据</div>
    </div>
    <div class="item" @click="informDetail(4)">
      <div
        class="business-state"
        v-if="mybussiness.sjgjRegisteredResidence.state != '' && mybussiness.sjgjRegisteredResidence.state != 6"
      >{{getState_re(mybussiness.sjgjRegisteredResidence.state)}}</div>
      <div class="logo">
        <img src="@/assets/views/mine/business4.png" alt class="logo-img" />
      </div>
      <div class="title">落户</div>
      <div
        class="validity"
        v-if=" mybussiness.sjgjRegisteredResidence != '' && mybussiness.sjgjRegisteredResidence.state != 6 && mybussiness.sjgjRegisteredResidence.state != '' && mybussiness.sjgjRegisteredResidence.state != 5"
      >有效期至{{formatTime(mybussiness.sjgjRegisteredResidence.duedate)}}</div>
      <div
        class="validity"
        v-if="mybussiness.sjgjRegisteredResidence == '' || mybussiness.sjgjRegisteredResidence.state == '' || mybussiness.sjgjRegisteredResidence.state == 6"
      >暂无数据</div>
    </div>
    <div class="item" @click="informDetail(5)">
      <div class="logo">
        <img src="@/assets/views/mine/jiuye.png" alt class="logo-img" />
      </div>
      <div class="title">就业</div>
      <div
        class="validity"
        v-if="mybussiness.shebaogongjijinother.sjgjjiuye.length > 0 "
      >{{mybussiness.shebaogongjijinother.sjgjjiuye.length}}项未完成业务</div>
    </div>
    <div class="item" @click="informDetail(6)">
      <div class="logo">
        <img src="@/assets/views/mine/chuangye.png" alt class="logo-img" />
      </div>
      <div class="title">创业</div>
      <div
        class="validity"
        v-if="mybussiness.shebaogongjijinother.sjgjchuangye.length > 0 "
      >{{mybussiness.shebaogongjijinother.sjgjchuangye.length}}项未完成业务</div>
    </div>
    <div class="item" @click="informDetail(7)">
      <div class="logo">
        <img src="@/assets/views/mine/business6.png" alt class="logo-img" />
      </div>
      <div class="title">其他业务</div>
      <div
        class="validity"
        v-if="mybussiness.shebaogongjijinother.deal"
      >{{mybussiness.shebaogongjijinother.deal}}项未完成业务</div>
    </div>
    <div class="item" @click="informDetail(8)">
      <div class="logo">
        <img src="@/assets/views/mine/laodongguanxi.png" alt class="logo-img" />
      </div>
      <div class="title">劳动关系咨询</div>
      <div class="validity" v-if="userInfo.zxnum && userInfo.zxnum > 0 ">剩余{{userInfo.zxnum}}次免费咨询机会</div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/mine/business.scss";
import { formatTime } from "@/lib/base.js";
import { mapGetters } from "vuex";

export default {
  name: "Businiess",
  data() {
    return {
      mybussiness: {
        sjgjGongJiJin: "",
        sjgjSheBao: "",
        shebaogongjijinother: {
          sjgjjiuye: [],
          sjgjchuangye: []
        }, //其他业务
        sjgjArchives: "",
        sjgjRegisteredResidence: "",
        jiuye: "", //就业
        chuangye: "", //创业
        sjgjConsults: "" //劳动关系咨询
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.getMyBusiness();
  },
  methods: {
    /**
     * 获取我的业务
     */
    getMyBusiness() {
      this.$siaxios
        .get("/mybusiness/getBusiness/" + this.userInfo.id)
        .then(res => {
          //代缴公积金
          if (res.sjgjGongJiJin)
            this.mybussiness.sjgjGongJiJin = res.sjgjGongJiJin;
          //代缴社保
          if (res.sjgjSheBao) this.mybussiness.sjgjSheBao = res.sjgjSheBao;
          //其他业务
          if (res.shebaogongjijinother)
            this.mybussiness.shebaogongjijinother = res.shebaogongjijinother;
          //就业
          if (res.jiuye) {
            this.mybussiness.jiuye = res.jiuye;
          }
          //创业
          if (res.chuangye) {
            this.mybussiness.chuangye = res.chuangye;
          }
          //劳动关系咨询
          if (res.sjgjConsults) {
            this.mybussiness.sjgjConsults = res.sjgjConsults;
          }
          //档案代存
          if (res.sjgjArchives)
            this.mybussiness.sjgjArchives = res.sjgjArchives;
          if (res.sjgjRegisteredResidence)
            this.mybussiness.sjgjRegisteredResidence =
              res.sjgjRegisteredResidence;
        });
    },
    //进入详情
    informDetail(type) {
      //代缴社保
      if (type == 1) {
        this.$router.forward({
          path: "/mine/substitute",
          query: { type: 1, cityid: this.mybussiness.sjgjSheBao.cityid }
        });
      }
      //    代缴公积金
      if (type == 2) {
        this.$router.forward({
          path: "/mine/substitute",
          query: { type: 2, cityid: this.mybussiness.sjgjSheBao.cityid }
        });
      }
      //    档案代存
      if (type == 3) {
        if (
          this.mybussiness.sjgjArchives == "" ||
          this.mybussiness.sjgjArchives.state == 1 ||
          this.mybussiness.sjgjArchives.state == ""
        ) {
          this.$router.forward({
            path: "/none/noorder",
            query: { text: "记录" }
          });
        } else {
          this.$router.forward({ path: "/mine/archives", query: { type: 3 } });
        }
      }
      //落户
      if (type == 4) {
        if (
          this.mybussiness.sjgjRegisteredResidence == "" ||
          this.mybussiness.sjgjRegisteredResidence.state == 6 ||
          this.mybussiness.sjgjRegisteredResidence.state == ""
        ) {
          this.$router.forward({
            path: "/none/noorder",
            query: { text: "记录" }
          });
        } else {
          this.$router.forward({ path: "/mine/residence", query: { type: 4 } });
        }
      }
      //就业
      if (type == 5) {
        this.$router.forward({ path: "/mine/obtain" });
      }
      //    创业
      if (type == 6) {
        this.$router.forward({ path: "/mine/entrepreneurship" });
      }
      //    其他业务
      if (type == 7) {
        this.$router.forward({ path: "/mine/otherBusiness" });
      }
      //    劳动咨询
      if (type == 8) {
        this.$router.forward({ path: "/mine/labor" });
      }
    },
    /**
     * 格式化时间
     */
    formatTime: formatTime,
    /**
     * 获取社保、公积金状态
     */
    getState(id) {
      if (id == 1) return "待确认";
      else if (id == 2) return "待完成";
      else if (id == 3) return "缴纳中";
      else if (id == 4) return "停保";
    },
    //获取档案状态
    getState_(id) {
      if (id == 1) return "关闭";
      else if (id == 2) return "待确认";
      else if (id == 3) return "待入库";
      else if (id == 4) return "在库";
      else if (id == 5) return "已提取";
    },
    //获取户口状态
    getState_re(id) {
      if (id == 0) return "发起缴费";
      else if (id == 1) return "确认支付";
      else if (id == 2) return "待确认";
      else if (id == 3) return "待入库";
      else if (id == 4) return "在库";
      else if (id == 5) return "迁出";
      else if (id == 6) return "关闭";
    }
  }
};
</script>