<!--
 * @Description: 政策列表模板
 * @Autor: bjp
 -->
<template>
  <div>
    <ul class="content" v-if="list.length > 0">
      <!-- 常见问题 -->
      <template v-if="id == 3">
        <li
          class="question" 
          v-for="item in list" 
          :key="item.id"
          @click="isShow(item)">
          <div class="desc">
            <div class="desc2">
              <div class="label">
                <span
                :class="`btn btn${item.state}`"
                v-if="label != 1 && item.state && item.type == 1"
              >{{getState(item.state)}}</span>
              <span
                :class="`btnType btnType${item.state}`"
                v-if="label != 1 && item.state && item.type == 2"
              >{{getState2(item.state)}}</span>
              </div>
              <div class="ques">{{item.title}}</div>
            </div>
            <img src="@/assets/views/question/arrow.png" alt="">
          </div>
        </li>
      </template>
      <!-- 资讯 -->
      <li @click="$router.forward({path:'/information/detail',query:{id:item.id}})" v-else v-for="item in list" :key="item.id" :class="item.imgurl?'isImg':'noImg'">
        <div class="desc">
          <div class="title">{{item.title}}</div>
          <div class="con" v-html="item.introduce"></div>
          <div class="btnOrTime">
            <span
              :class="`btn btn${item.state}`"
              v-if="label != 1 && item.state && item.type == 1"
            >{{getState(item.state)}}</span>
            <span
              :class="`btnType btnType${item.state}`"
              v-if="label != 1 && item.state && item.type == 2"
            >{{getState2(item.state)}}</span>
            <span class="time">{{formatTime(item.publishtime)}}</span>
          </div>
        </div>
        <img v-if="item.imgurl" :src="item.imgurl" alt />
      </li>
    </ul>
    <!-- 答案弹窗 -->
    <div class="popup" ref="answer">
      <div class="answer" >
        <div class="title">
          <span>{{question}}</span>
          <img src="@/assets/views/graduate/close.png" alt="" class="close" @click="close()">
        </div>
        <div class="answerContent" v-html="answer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatTime } from "@/lib/base.js";
// import noData from "@/views/nonepage/norecord";
export default {
    // components:{noData},
  props: {
    list: {
      type: Array,
      default: () => {}
    },
    label: {
      type: String,
      default: "1"
    },
    id: {
      default: "1"
    }
  },
  data() {
    return {
      heights:'',
      question:'',
      answer:''
    };
  },
  mounted(){
    this.heights = this.$refs.answer.offsetHeight
  },
  methods: {
    formatTime: formatTime,
    // 1养老2医疗3工伤4生育5失业6社保卡
    getState(state) {
      switch (state) {
        case 1:
          return "养老";
        case 2:
          return "医疗";
        case 3:
          return "工伤";
        case 4:
          return "生育";
        case 5:
          return "失业";
        case 6:
          return "社保卡";
        case 7:
          return "其他";
        default:
          break;
      }
    },
    // 1养老2医疗3工伤4生育5失业6社保卡
    getState2(state) {
      switch (state) {
        case 1:
          return "贷款";
        case 2:
          return "提取";
        case 3:
          return "其他";
        default:
          break;
      }
    },
    isShow(item){
      this.$emit('tempMask',true)
      this.question = item.title
      this.answer = item.answer
      this.answer = this.answer.replace(/<img/gi,'<img class="rich-img" ')
      this.$refs.answer.style.transform = 'translateY(0px)'
      document.querySelector('body').setAttribute('style', 'overflow:hidden')
    },
    close(){
			this.$emit('tempMask',false)
      this.$refs.answer.style.transform = `translateY(${this.heights *2}px)`
      document.querySelector('body').removeAttribute('style')
    },
  }
};
</script>