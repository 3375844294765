<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>毕业户口何去何从？档管家让你户口无忧。 </p>
                <span>在校大学生或毕业年度的大学生可申请办理落户，大学生在青落户可享受本市购房政策，符合条件的可申请人才公寓，还有诸多住房补贴，就业补贴等</span>
            </div>
        </div>

    </div>
</template>
<script>
 import "@/sass/views/creatorder/business_introduce.scss";
    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

