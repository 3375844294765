<!-- 劳动关系咨询-->
<template>
  <!-- <v-refresh> -->
    <div class="laber-index">
      <template v-if="otherData.length>0">
        <div class="re-per-con" v-for="item in otherData" :key="item.id">
          <div class="item">
            <div class="userinfo">
              <span class="name">劳动关系咨询</span>
              <!-- <span class="state">完成</span> -->
            </div>
            <div class="infos">
              <span class="lable">{{formatTime(item.createtime,'yyyy-MM-dd hh:mm:ss')}}</span>
            </div>
          </div>
          <div class="grayline"></div>
        </div>
      </template>
      <div v-else>
        <no-data></no-data>
      </div>
    </div>
  <!-- </v-refresh> -->
</template>
<script>
import "@/sass/views/mine/laber.scss";
// import refresh from "@/components/plug-in/pulldown";
import { formatTime } from "@/lib/base.js";
import no_data from "../nonepage/noorder";
import { mapGetters } from "vuex";
export default {
  name: "mineresidence",
  data() {
    return {
      otherData: []
    };
  },
  mounted() {
    this.getMyBusiness();
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  components: {
    // "v-refresh": refresh,
    "no-data": no_data
  },
  methods: {
    /**
     * 获取业务信息
     */
    getMyBusiness() {
      this.$siaxios
        .get("/mybusiness/getBusiness/" + this.userInfo.id)
        .then(res => {
          if (res.sjgjConsults) {
            this.otherData = res.sjgjConsults;
          }
        });
    },
    formatTime: formatTime,
    //下拉刷新
    // onRefresh(done) {
    //   this.getMyBusiness();
    //   done(); // call done
    // },
    // // 上拉加载
    // onInfiniteLoad(done) {
    //   this.showData.pageNum++;
    //   this.getMyBusiness().then(res => {
    //     done(res);
    //   });
    // }
  }
};
</script>