<template>
  <div class="socialsecurity-index">
    <div class="pay-money">
      <div class="title">缴费金额</div>
      <div class="money">
        {{allmoney}}
        <span>&nbsp;元/月</span>
      </div>
    </div>
    <div class="tips">超过当月{{baseInfos.latestpayment}}号,只能办理下月社保</div>
    <div class="order-content">
      <div class="info-line-city" @click="selectCity">
        <div>缴纳城市</div>
        <div class="right">
          <div class="selectCity">{{param.sjgjOrderShebaoGongjijin.city}}</div>
          <img class="right" src="@/assets/right.png" alt />
        </div>
      </div>
      <div class="info-line">
        <div>户籍性质</div>
        <div
          class="select-style"
          ref="hukoucharacter"
        >{{param.sjgjOrderShebaoGongjijin.cityid==''?'请先选择城市':hukouCharacterName}}</div>
        <img class="right" src="@/assets/right.png" alt />
      </div>
      <div class="info-line">
        <div>社保基数</div>
        <div class="zuidi" @click="param.sjgjOrderShebaoGongjijin.paybase = baseInfos.lowshebao">
          <img
            v-if="param.sjgjOrderShebaoGongjijin.paybase == baseInfos.lowshebao"
            src="@/assets/checked.png"
            alt
          />
          <img v-else src="@/assets/nochecked.png" alt />
          <div>最低</div>
        </div>
        <div class="inputcon">
          <input
            class="iptmoney"
            type="number"
            :placeholder="(this.baseInfos.lowshebao||0)+'.00至'+(this.baseInfos.highshebao||0)+'.00'"
            v-model="param.sjgjOrderShebaoGongjijin.paybase"
            @click="sblow = false"
            oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
            @blur="isHighshebaoOrGj()"
          />元
        </div>
      </div>
    </div>
    <div class="showserviceitems" v-if="insuranceInfo != ''">
      <div class="item" v-for="item in insuranceInfo" v-bind:key="item.id">
        <span class="pull-left">{{item.name}}</span>
        <span
          class="pull-right"
        >{{getMumber(param.sjgjOrderShebaoGongjijin.paybase*item.company+param.sjgjOrderShebaoGongjijin.paybase*item.people)}}元/月</span>
      </div>
      <div class="item">
        <span class="pull-left">个税</span>
        <span class="pull-right">{{this.getMumber(tax)}}元/月</span>
      </div>
    </div>
    <comintroduce />
    <div class="bottom-menu">
      <div class="consult" @click="showConsult()">
        <img src="@/assets/zaixian.png" class="consult-icon" alt />咨询
      </div>
      <div class="order-btn" @click="nextStemp()">下一步</div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/creatorder/socialsecurity.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import comintroduce from "../business-introduction/saletypeid-1/introduce"; //社保详情
import { mapGetters } from "vuex";
import { getMumber } from "@/lib/base";
export default {
  name: "Socialsecurity",
  data() {
    return {
      showType: 1, //1购买流程 2平台优势 3常见问题 4温馨提示
      showInfo: "",
      baseInfos: {
        //基本信息
        sjgjHouseHoldRegisters: []
      },
      natureSelect: null, //户籍性质下拉
      hukouCharacterName: "", //户籍性质下拉显示名称
      insuranceInfo: "", //社保比例详情
      tax: "", //个税
      param: {
        saletypeid: 1,
        userid: "",
        sjgjOrderShebaoGongjijin: {
          paymonth: "", //缴纳月份
          cityid: "",
          cityname: "",
          startmonth: "", //开始月份
          overmonth: "", //结束月份
          hukouid: "", //户口id
          paybase: "", //社保基数
          reason: "", //缴费原因
          whetherfirst: 0, //是否是首次 0是 1否
          remark: "" //备注
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    }),
    allmoney() {
      let allmoney = 0;
      if (this.insuranceInfo != "") {
        let temppaybase = 0;
        if (!this.param.sjgjOrderShebaoGongjijin.paybase) {
          temppaybase = this.baseInfos.lowshebao;
        } else {
          temppaybase = this.param.sjgjOrderShebaoGongjijin.paybase;
        }
        this.insuranceInfo.forEach(element => {
          allmoney += getMumber(
            temppaybase * element.company + temppaybase * element.people
          );
        });
      }
      return Number(allmoney + this.tax).toFixed(2);
    }
  },
  watch: {
    "param.sjgjOrderShebaoGongjijin.hukouid"() {
      this.getTax();
    },
    "param.sjgjOrderShebaoGongjijin.paybase"() {
      this.getTax();
    }
  },
  created() {
    if (this.userInfo.cityid) {
      this.param.sjgjOrderShebaoGongjijin.cityid = this.userInfo.cityid;
      this.param.sjgjOrderShebaoGongjijin.city = this.userInfo.cityname;
    }
    this.param.userid = this.userInfo.id;
    this.param.sjgjOrderShebaoGongjijin.tel = this.userInfo.tel;
    this.getDescribe();
    this.getBaseInfo();
  },
  components: {
    comintroduce
  },
  methods: {
    /**
     * 初始下拉框
     */
    iniSelect() {
      this.baseInfos.sjgjHouseHoldRegisters.forEach(element => {
        element.value = element.name;
      });
      if (this.natureSelect) {
        this.natureSelect.updateWheel(0, this.baseInfos.sjgjHouseHoldRegisters);
        this.hukouCharacterName = this.baseInfos.sjgjHouseHoldRegisters[0].name;
        this.param.sjgjOrderShebaoGongjijin.hukouid = this.baseInfos.sjgjHouseHoldRegisters[0].id;
        this.getScaleDetail();
      } else {
        this.hukouCharacterName = this.baseInfos.sjgjHouseHoldRegisters[0].name;
        this.param.sjgjOrderShebaoGongjijin.hukouid = this.baseInfos.sjgjHouseHoldRegisters[0].id;
        this.getScaleDetail();
        this.natureSelect = new MobileSelect({
          trigger: this.$refs.hukoucharacter,
          title: "户籍性质",
          triggerDisplayData: false,
          wheels: [{ data: this.baseInfos.sjgjHouseHoldRegisters }],
          callback: (indexArr, data) => {
            this.hukouCharacterName = data[0].name;
            this.param.sjgjOrderShebaoGongjijin.hukouid = data[0].id;
            this.getScaleDetail();
          }
        });
      }
    },
    getMumber: getMumber,
    /**
     * 获取业务介绍
     */
    getDescribe() {
      this.$siaxios.get(`/homepage/detail/1`).then(res => {
        this.showInfo = res;
      });
    },
    //获取城市配置
    getBaseInfo() {
      if (this.param.sjgjOrderShebaoGongjijin.cityid)
        this.$siaxios
          .get(
            `/order/getCityBase/${this.param.sjgjOrderShebaoGongjijin.cityid}`
          )
          .then(res => {
            this.baseInfos = res;
            this.param.sjgjOrderShebaoGongjijin.paybase = this.baseInfos.lowshebao
            this.iniSelect();
            this.getShebaoState();
          });
    },
    //选择城市
    selectCity() {
      this.$cityopenselect({
        ywid: 1,
        callback: (id, name) => {
          if (this.param.sjgjOrderShebaoGongjijin.cityid != id) {
            this.param.sjgjOrderShebaoGongjijin.paybase = "";
          }

          this.param.sjgjOrderShebaoGongjijin.cityid = id;
          this.param.sjgjOrderShebaoGongjijin.city = name;
          this.getBaseInfo();

          let tempUserInfo = JSON.parse(JSON.stringify(this.userInfo));
          tempUserInfo.cityid = id;
          tempUserInfo.cityname = name;
          this.$store.commit("setMyUserInfo", tempUserInfo);
        }
      });
    },
    /**
     * 展示顾问
     */
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return
      }
      this.$consult({ userid: this.userInfo.id })
    },
    /**
     * 获取开始时间
     */
    getShebaoState() {
      // 如果没认证则不需要调用此接口
      if (this.userInfo.renzhengstate != 1) return;
      this.$siaxios
        .get(`/order/getShebaoState/${this.userInfo.id}`)
        .then(res => {
          if (!res) {
            let now = new Date();
            let year = now.getFullYear();
            let month = now.getMonth() + 1;
            if (now.getDate() > this.baseInfos.latestpayment) month++;
            if (month > 12) {
              month = 1;
              year++;
            }
            this.param.sjgjOrderShebaoGongjijin.startmonth =
              year + "-" + (Array(2).join(0) + month).slice(-2);
          } else {
            this.param.sjgjOrderShebaoGongjijin.startmonth = res;
          }
        });
    },
    /**
     * 根据户籍性质获取社保比例详情
     */
    getScaleDetail() {
      this.$siaxios
        .get(`/order/getBaoXian/${this.param.sjgjOrderShebaoGongjijin.hukouid}`)
        .then(res => {
          this.insuranceInfo = res;
        });
    },
    /**
     * 根据户籍性质和基数获取个税
     */
    getTax() {
      if (
        this.param.sjgjOrderShebaoGongjijin.hukouid &&
        this.param.sjgjOrderShebaoGongjijin.paybase
      )
        this.$siaxios
          .get(
            `order/getgeshui?hid=${this.param.sjgjOrderShebaoGongjijin.hukouid}&base=${this.param.sjgjOrderShebaoGongjijin.paybase}`
          )
          .then(res => {
            this.tax = res;
          });
    },
    isHighshebaoOrGj() {
      if (
        this.param.sjgjOrderShebaoGongjijin.paybase != 0 &&
        (this.param.sjgjOrderShebaoGongjijin.paybase >
          this.baseInfos.highshebao ||
          this.param.sjgjOrderShebaoGongjijin.paybase <
            this.baseInfos.lowshebao)
      ) {
        this.$tips({
          title: "提示",
          describe:
            this.baseInfos.lowshebao +
            ".00元至" +
            this.baseInfos.highshebao +
            ".00元"
        });
        this.param.sjgjOrderShebaoGongjijin.paybase = "";
      }
    },
    nextStemp() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return
      }
      if (this.userInfo.renzhengstate != 1) {
        this.$confirm({
          describe: "办理业务请先认证，是否跳转认证页面",
          callback: boolean => {
            if (boolean) this.$router.forward("/mine/authentication");
          }
        });
        return;
      }
      if (this.param.sjgjOrderShebaoGongjijin.cityid == "") {
        this.$tips({ title: "提示", describe: "请选择缴纳城市" });
        return;
      }
      if (this.param.sjgjOrderShebaoGongjijin.hukouid == "") {
        this.$tips({ title: "提示", describe: "请选择户口性质" });
        return;
      }
      if (this.param.sjgjOrderShebaoGongjijin.paybase == "") {
        this.$tips({ title: "提示", describe: "请填写社保基数" });
        return;
      }
      this.$store.commit("setOrderInfo", this.param);
      this.$router.forward("/creatorder/creatsocialsecurity");
    }
  }
};
</script>
