<!--
 * @Description: 服务内容
 * @Autor: bjp
 -->
<template>
    <div class="graduateContents">
        <div class="desc">
            <div class="files" v-if="fileShow || (!fileShow && !hukouShow)">
                <div class="title">档案办理</div>
                <div class="contents">
                    <p>档案是个人身份、学历、工作经历等方面的证据，与个</p>
                    <p>人工资待遇、社会劳动保障、组织关系紧密挂钩，在个</p>
                    <p>人职称申报、办理养老保险、考取公务员以及开具考研</p>
                    <p>等相关证明时，都需要使用档案。</p>
                </div>
            </div>
            <div class="files" v-if="hukouShow || (!fileShow && !hukouShow)">
                <div class="title">户口办理</div>
                <div class="contents">
                    <p>在校大学生或毕业年度的大学生可申请办理落户，大学</p>
                    <p>生在青落户可享受本市购房政策，符合条件的可申请人</p>
                    <p>才公寓，还有诸多住房补贴，就业补贴等</p>
                </div>
            </div>
            <div class="files" v-if="fileShow || !hukouShow || (!fileShow && !hukouShow)">
                <div class="title">三方协议</div>
                <div class="contents">
                    <p>三方协议是《全国普通高等学校毕业生就业协议书》的简称，它是明确毕业生、用人单位和学校三方在毕业生就业工作中的权利和义务的书面表现形式，能解决应届毕业生户籍、档案、保险、公积金等一系列相关问题。</p>
                </div>
            </div>
            <div class="files">
                <div class="title">劳动关系</div>
                <div class="contents">
                    <p>熟悉和了解相关的劳动法律法规及政策，维护自身合法</p>
                    <p>利益，依法维权也是职场人必备的技能之一。在线劳动</p>
                    <p>法律咨询，私人劳动法律顾问，打造您的劳权宝典。</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "@/sass/views/graduate/contens.scss";
export default {
    props: {
        fileFlag: {
            type: Boolean,
            default: true
        },
        hukouFlag: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            fileShow: true,
            hukouShow: true,
        };
    },
    watch: {
        'fileFlag'(val) {
            this.fileShow = val
        },
        'hukouFlag'(val) {
            this.hukouShow = val
        }
    },
    methods: {}
};
</script>
