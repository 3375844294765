<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>国家创业贴息贷款是根据国家有关促进就业政策，针对符合条件的创业人员，特别是社会弱势群体创业所提供的金融信贷服务。它是由各级政府设立小额贷款担保基金，经指定担保机构承诺担保，通过银行发放并由财政部门给予一定贴息扶持的政策性贷款岛。 </p>


            </div>
        </div>

    </div>
</template>
<script>
  import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

