<template>
  <div class="yearEnd calculator">
    <div class="top-money">
      <label>年终奖金额</label>
      <span>¥</span>
      <input type="number" v-model="yearEnd.income" @input="change">
    </div>
    <div class="group-con white-bg">
      <div class="group">
        <label>录入月份</label>
        <span ref="month" class="month">{{yearEnd.monthNum}}月</span>
      </div>
      <div class="group no-right-bg">
        <label>个人所得税</label>
        ￥{{yearEnd.tax}}
      </div>
    </div>
    <div class="info white-bg">
      <div class="chart-con" :class="{noheight:yearEnd.tax==0}">
        <div class="text">
          <label>税后年终奖</label>
          ¥{{yearEnd.income - yearEnd.tax>0?(yearEnd.income - yearEnd.tax).toFixed(2):0}}
        </div>
        <div class="chart" id="chart"></div>
      </div>

      <div class="show-table">
        <table>
          <thead>
            <tr>
              <th width="40%">年终奖一次性收入</th>
              <th>税率（月度）</th>
              <th>速算扣除数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableTax" :key="item.low">
              <td>{{item.low==0?'不超过'+item.hign:'超过'+item.low+'元'+(item.hign!=Infinity?'至'+item.hign+'元的部分':'')}}</td>
              <td>{{item.tax*100}}%</td>
              <td>{{item.deduNum}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <dl class="description-text">
        <dt>扣除说明</dt>
        <dd>
          <ul class="list">
            <li>2022年1月1日前，年终奖可按照一次性奖金收入计算纳税：<br><span style="color:#333">应纳税额=全年一次性奖金收入*适用税率-速算扣除数；</span></li>
            <li>2021年1月1日后，年终奖需并入当年综合所得计算纳税：<br><span style="color:#333">纳税金额=年度综合所得*（综合个税）适用税率-速算扣除数</span></li>
          </ul>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import "@/sass/views/calculator/main.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import "@/unit/calculator";
import Vue from 'vue'
export default {
  data() {
    return {
      yearEnd: {
        monthNum: 1,
        income: '',
        tax: 0
      },
      tableTax: [{
        low: 0,
        hign: 36000,
        tax: 0.03,
        deduNum: 0
      }, {
        low: 36000,
        hign: 144000,
        tax: 0.1,
        deduNum: 210
      }, {
        low: 144000,
        hign: 300000,
        tax: 0.2,
        deduNum: 1410
      }, {
        low: 300000,
        hign: 420000,
        tax: 0.25,
        deduNum: 2660
      }, {
        low: 420000,
        hign: 660000,
        tax: 0.3,
        deduNum: 4410
      }, {
        low: 660000,
        hign: 960000,
        tax: 0.35,
        deduNum: 7160
      }, {
        low: 960000,
        hign: Infinity,
        tax: 0.45,
        deduNum: 15160
      }],
      monthList: [],
      workList: [{ incomeList: [] }]
    }
  },
  created() {
    for (var i = 1; i < 13; i++) {
      this.monthList.push({ id: i, value: i + '月' })
    }
  },
  mounted() {
    if (!(typeof Vue.getData('workList')==='undefined')) {
      this.workList = Vue.getData('workList')
      this.workList.forEach(ele => {
        let arr = ele.incomeList.filter(item => { return item.type == 3 })
        if (arr.length > 0) {
          this.yearEnd = {
            income: arr[0].income,
            tax: arr[0].tax,
            monthNum: arr[0].month,
          }
        }
      });
    } else {
      for (let i = 0; i < 12; i++) {
        this.workList[0].incomeList.push({
          type: 1, //1工资，2劳务，3年终奖
          month: i + 1,
          income: this.income,
          security: 0,
          fund: 0,
          tax: 0
        })
      }
    }
    this.drawpie();
    this.selectMonth();
  },
  methods: {
    change() {
      if (!!this.yearEnd.income && this.yearEnd.income > 0) {
        this.tableTax.some(ele => {
          if (this.yearEnd.income > ele.low && this.yearEnd.income <= ele.hign) {
            this.yearEnd.tax = (this.yearEnd.income * ele.tax - ele.deduNum).toFixed(2);
            return true
          }
        });
        this.workList.forEach(ele => {
          ele.incomeList.some((item, index) => {
            if (item.type == 3) {
              ele.incomeList.splice(index, 1);
              return true
            }
          })
          ele.incomeList.some((item, index) => {
            if (item.type == 1 && item.month == this.yearEnd.monthNum) {
              let arr1 = {
                type: 3, //1工资，2劳务，3年终奖
                month: this.yearEnd.monthNum,
                income: this.yearEnd.income,
                security: 0,
                fund: 0,
                tax: this.yearEnd.tax
              }
              ele.incomeList.splice(index + 1, 0, arr1);
              return true
            }
          })
        })
      } else {
        // this.$alert('请输入正确的年终奖金额！');
        this.workList.forEach(ele => {
          ele.incomeList.some((item, index) => {
            if (item.type == 3) {
              ele.incomeList.splice(index, 1)
              return true
            }
          });
        });
      }
      Vue.setData('workList', this.workList)
    },
    selectMonth() {
      new MobileSelect({
        trigger: this.$refs.month,
        title: "开始扣除月份",
        wheels: [{ data: this.monthList }],
        callback: (indexArr, data) => {
          this.yearEnd.monthNum = data[0].id;
          this.change();
        }
      });

    },
    drawpie() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("chart"));
      // 绘制图表
      myChart.setOption({
        color: ["#4884ff", '#44dd7a', '#d973ff', '#ff7a7a', '#ffb849'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          //图表的位置
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          containLabel: true,
        },
        legend: {
          orient: 'vertical',
          top: '60%',
          left: '50%',
          right: 0,
          bottom: 0,
          data: ['税后劳务所得', '个人所得税']
        },
        series: [
          {
            type: 'pie',
            radius: ['60%', '90%'],
            center: ['20%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            itemStyle: {
              borderWidth: 3,
              borderColor: '#fdfeff'
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                formatter: '{c}%'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: (this.yearEnd.tax/this.yearEnd.income*100).toFixed(2), name: '个人所得税' },
              { value: ((this.yearEnd.income - this.yearEnd.tax)/this.yearEnd.income*100).toFixed(2), name: '税后劳务所得' }
            ]
          }
        ]
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.calculator .group-con .group span.month {
  padding-left: 30px;
  display: inline-block;
}
.yearEnd {
  background: #fff;
  min-height: 100vh;
  .top-money {
    background: #fc7474;
  }
  .info {
    padding: 30px;
    border-top: 20px solid #f5f5f5;
    position: relative;
    .show-table table th {
      background: #fc7474;
    }
    .chart-con.noheight {
      height: 0;
      overflow: hidden;
      opacity: 0;
    }
    .text {
      position: absolute;
      left: 50%;
      top: 30px;
      font-size: 46px;
      color: #4884ff;
      label {
        color: #333;
        font-size: 26px;
        display: block;
        margin-bottom: 20px;
      }
    }
  }
}
</style>