<template>
  <div class="danganbro-creatorder">
   <div :class="$route.query.id==1?'banner-con2':'banner-con'">
      <div class="banner">
        <span>档案借阅</span>
      </div>
    </div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content" v-if='$route.query.id == 1'>
        <span>因职称评审或政审等需要借阅档案的，可办理借阅手续</span>
      </div>
      <div class="content" v-else>
        <span>档案借阅有严格的规章制度和申请流程，因工作和个人需要借阅档案里的某项材料，但因手续繁琐或者业务繁忙不能亲自到人事档案保管单位办理借阅手续，档管家可权威为您代办，让您省心省力，安全有保障</span>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
        <span class="intro-title">业务流程</span>
      </div>
      <div class="process">
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/da-dc1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>APP提交借阅资料内容</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/fukuan.jpg" alt />
          </div>
          <div class="procss-text">
            <span>支付押金</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>填写邮寄地址信息</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进办理</span>
          </div>
        </div>
      </div>
      <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>
      </div>
      <div class="process" style="margin-top: 20px;margin-right:3px;">
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu2.jpg" alt />
          </div>
          <div class="procss-text">
            <span>邮寄到指定地址</span>
          </div>
        </div>
        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/police (3).jpg" alt />
          </div>
          <div class="procss-text">
            <span>查收</span>
          </div>
        </div>
        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/shuoming.jpg" alt />
        <span class="intro-title">温馨提示</span>
      </div>
      <div class="content" style="margin-top:20px;">
        <div class="isthone">
          <div class="thone">
            <span>1、</span>
          </div>
          <div class="thone-text">
            <span>借阅前如有欠费请及时缴纳续费。</span>
          </div>
        </div>
        <div class="isthone">
          <div class="thone">
            <span>2、</span>
          </div>
          <div class="thone-text">
            <span>借阅期限为一个月，请在有效期内及时归还，归还后我方将押金予以退还，选择邮寄归还的请将材料邮寄到我方指定地址。</span>
            <p>收件人：李青青</p>
            <div class="isthone">
              <div class="thone" style="width:70px;">
                <span>联系电话：</span>
              </div>
              <div class="thone" style="width:200px;">
                <span>0532-85873655转813或815</span>
                <p>18661887826</p>
              </div>
            </div>
            <p>联系地址：青岛市市南区高雄路18号海洋大厦5层</p>
          </div>
        </div>
        <div class="isthone">
          <div class="thone">
            <span>3、</span>
          </div>
          <div class="thone-text">
            <span>需要我方邮寄的请一定要确认好邮寄的地址，联系人、联系方式、如在邮寄前需要临时更改邮寄地址的请提前与我们的顾问联系。</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/cailiao.jpg" alt />
        <span class="intro-title">所需材料</span>
      </div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">身份证复印件</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">押金</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text" style="width:250px">委托他人另需代办人的身份证及委托证书（附委托书模板）</div>
          </div>
        </div>
        <div class="needs">
          <div class="word">
            <div class="word-icon">
              <img class="title-img" src="@/assets/views/mine/house.png" alt />
            </div>
            <div class="word-text">
              <span>委托书模板.doc</span>
            </div>
            <div class="word-btn" @click="uploadFile()">
              <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
              <button>下载</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if='!$route.query.id'>
      <div class="order-gray-line"></div>
      <div class="cont-use" style="padding-top:20px">
        <div class="use-cont">
          <div class="use-introduce">
            <img src="@/assets/views/mine/dangan-quest_03.jpg" alt style="width:15px;height:15px" />
            <span>常见问题</span>
          </div>
        </div>
        <div class="use-cont">
          <div class="active-title">1. 档案的材料借走可以不归还吗？</div>
          <div class="active-cont">不可以，档案里的某项材料如果不归还，您的档案会一直显示缺材料，提走档案必须将借走的材料归还之后档案室才允许提出, 如果材料缺失新接收的人才档案也会拒绝接收档案。</div>
        </div>
        <div class="small-ine"></div>
        <div class="use-cont">
          <div class="active-title">2. 借阅超过期限怎么办？</div>
          <div class="active-cont">借阅期限自借阅之日起一个月内要及时归还，如因特殊原因需要延期的，可联系我们的顾问适当做延期处理。</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import "@/sass/views/gallery/danganbro.scss";

export default {
  name: "danganbro",
  data() {
    return {
      exportApi: ""
    };
  },

  methods: {
    //委托书模板下载
    uploadFile() {
      let url = this.$axios.defaults.baseURL + "common/downloadFile/1";
      this.$copyText(`${url}`).then(
        () => {
          this.$tips({
            describe: "已成功复制链接，请通过浏览器下载该文档"
          });
        },
        () => {
          this.$tips({
            describe: "复制失败"
          });
        }
      );
    }
  }
};
</script>

