<template>
  <div class="paybackorder-index">
    <v-refresh :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad">
      <div v-if="showData.length>0">
        <div
          class="orderlist-item"
          v-for="item in showData"
          :key="item.id"
          @click="$router.forward({path:'/creatorder/orderdetail',query: { id: item.id }})"
        >
          <div class="order-top">
            <div class="order-num">订单号:{{item.ordernumber}}</div>
            <div class="order-state">{{getStateName(item.states)}}</div>
          </div>
          <div class="order-info">
            <img src class="pro-img" alt />
            <div class="pro-info">
              <div class="order-title">{{item.saletypename}}</div>
              <div class="money">
                <div class="price">￥{{item.orderamount}}</div>
                <div class="all-price">
                  <span class="lable">合计:</span>
                  ￥{{item.orderamount}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <no-data></no-data>
      </div>
    </v-refresh>
  </div>
</template>
<script>
import "@/sass/views/creatorder/orderlist.scss";
import refresh from "@/components/plug-in/pulldown";
import { mapGetters } from "vuex";
import { orderType } from "@/lib/typelist";
import no_data from "../nonepage/norecord";
export default {
  name: "Paybackorder",
  data() {
    return {
      param: {
        states: "", // 订单状态0待支付 1已支付 3关闭 4进行中 5待确认
        isbujiao: 1, // 0正常1补缴
        userid: "", // 用户id
        pageamount: "", // 分页条数
        page: 1 //当前页
      },
      showData: []
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  activated() {
    this.getList();
  },
  mounted() {
    this.getList();
  },
  components: {
    "v-refresh": refresh,
    "no-data": no_data
  },
  methods: {
    //查询列表
    async getList() {
      let tempList = await this.$siaxios.get(
        `/order/getOrderList/${this.param.page}?userid=${this.userInfo.id}&states=${this.param.states}&isbujiao=${this.param.isbujiao}`
      );
      if (tempList.length > 0) {
        tempList.forEach(element => {
          this.showData.push(element);
        });
      }
      return tempList.length;
    },
    getStateName(id) {
      let name;
      for (let i = 0; i < orderType.length; i++) {
        if (id == orderType[i].id) {
          name = orderType[i].name;
          break;
        }
      }
      return name;
    },
    //下拉刷新
    onRefresh(done) {
      this.getList().then(() =>{
        done(); // call done
      })
    },
    // 上拉加载
    onInfiniteLoad(done) {
      this.showData.pageNum++;
      this.getList().then(res => {
        done(res);
      });
    }
  }
};
</script>
