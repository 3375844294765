<template>
  <div class="danganbanli-creatorder">
    <div class="banner-con">
      <div class="banner">
        <span>档案办理</span>
      </div>
    </div>

    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <div class="cont-box">
          <div class="content-num">1.</div>
          <div
            class="content-text"
          >档案是个人身份、学历、工作经历等方面的证据，与个人工资待遇、社会劳动保障、组织关系紧密挂钩，在个人职称申报、办理养老保险、考取公务员一级开具考研等相关证明，都需要使用档案。</div>
        </div>
        <div class="cont-box">
          <div class="content-num">2.</div>
          <div class="content-text">根据我国现行政策，档案属于国家法定、强制执行、归口管理的公共信息，个人不得截留、持有，必须由具有人事档案管理权的单位保存。</div>
        </div>
        <div class="cont-box">
          <div class="content-num">3.</div>
          <div
            class="content-text"
          >档管家可为员工及个人提供人事档案的收集，整理，保管，借阅，传递等服务。严格的保密制度、统一的存放管理，为您的个人档案提供安全的存放、管理业务。</div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
        <span class="intro-title">业务流程</span>
      </div>
      <div class="process">
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/da-dc1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>APP注册账号</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/fukuan.jpg" alt />
          </div>
          <div class="procss-text">
            <span>付款成功</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>填写相关信息</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进办理</span>
          </div>
        </div>
      </div>
      <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>
      </div>
      <div class="process" style="margin-top: 20px;margin-right:3px;">
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/banshi (2).jpg" alt />
          </div>
          <div class="procss-text">
            <span>网签</span>
          </div>
        </div>
        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu2.jpg" alt />
          </div>
          <div class="procss-text">
            <span>毕业后学校邮寄档案到档案室</span>
          </div>
        </div>
        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/kehu.jpg" alt />
          </div>
          <div class="procss-text">
            <span>档案办理入库手续</span>
          </div>
        </div>
        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/shuoming.jpg" alt />
        <span class="intro-title">业务说明</span>
      </div>
      <div class="content">
        <div class="isthone">
          <div class="thone-text">
            <span>毕业生毕业之前办理网签手续后，待毕业后学校会将档案以机要形式陆续发到我方档案室，具体发出时间各学校会有差异，请耐心等待</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/cailiao.jpg" alt />
        <span class="intro-title">所需材料</span>
      </div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">毕业证复印件</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">学位证复印件</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text" style="width:250px">报到证复印件</div>
          </div>
        </div>
      </div>
    </div>
    <div class="showmessage"></div>
  </div>
</template>
<script>
import "@/sass/views/gallery/danganbanli.scss";

export default {
  name: "danganbro",
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.banner-con {
  background: url("../../assets/views/mine/dangan-banner.jpg") 100% 100%;
  background-size: cover;
}
.introduce {
  .title {
    .title-img {
      width: 4%;
    }
    .intro-title {
      font-size: 28px;
    }
  }
}
</style>

