/**
 * @description 订单类型
 */
export const orderType = [
  {id:0,name:'待支付'},
  {id:1,name:'已支付'},
  {id:3,name:'关闭'},
  {id:4,name:'进行中'},
  {id:5,name:'待确认'},
]
