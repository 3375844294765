<template>
  <div class="nonepage">
    <div class="showcontent">
      <img src="@/assets/views/nonepage/recordtype.png" class="showimg" alt />
      <div class="tips">{{message}}</div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/nonepage.scss";
export default {
  props: {
    message: {
      type: String
    }
  }
};
</script>
<style lang="scss" scoped>
.order-btn{
    position: fixed;
    left: 15%;
    bottom: 20%;
    height: 80px;
    width: 70%;
    text-align: center;
    line-height: 80px;
    color: white;
    font-size: 32px;
    background: #3367ff;
}
.nonepage .showcontent{
    margin-top: 100px;
}
</style>
