<template>
    <v-refresh ref="getState" :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad">
        <div class="paybackorder-index">
            <div class="payback-order-title" :style="{paddingTop: $statusBarHeight + 'px' }">
                <div class="item" @click="setStates()" :class="{'active':  param.states === ''}">全部</div>
                <div class="item" @click="setStates(0)" :class="{'active': param.states === 0}">待付款</div>
                <div class="item" @click="setStates(3)" :class="{'active': param.states === 3}">已结束</div>
            </div>
            <template v-if="showData.length > 0">
                <div class="orderlist-item" v-for="item in showData" :key="item.id"
                    @click="$router.replace({path:'/creatorder/orderdetail',query: { id: item.id }})">
                    <div class="order-top">
                        <div class="order-num">订单号:{{item.ordernumber}}</div>
                        <div class="order-state">{{getStateName(item.states)}}</div>
                    </div>
                    <div class="order-info" v-for="orderChild in item.sjgjOrders" :key="orderChild.id">
                        <img :src="orderChild.url" class="pro-img" alt />
                        <div class="pro-info">
                            <div class="order-title">{{orderChild.saletypename}}</div>
                            <div class="order-money">￥{{orderChild.orderamount}}</div>
                        </div>
                    </div>
                    <div class="order-content">
                        <div class="order-time">{{item.paytime?formatTime(item.paytime,'all'):formatTime(item.createtime,'all')}}</div>
                        <div class="all-price">
                            <span class="lable">合计:</span>
                            ￥{{item.orderamount}}
                        </div>
                    </div>
                    <div class="order-list-pay" v-if="item.states === 0">
                        <div class="order-btn pay">立即支付</div>
                        <div class="order-btn" @click.stop="cancleOrder(item)">取消订单</div>
                    </div>
                </div>
            </template>
            <div v-else-if="!loading">
                <no-data></no-data>
            </div>
        </div>
    </v-refresh>
</template>
<script>
import "@/sass/views/creatorder/orderlist.scss";
import refresh from "@/components/plug-in/pulldown";
import no_data from "../nonepage/noorder";
import { mapGetters } from "vuex";
import { orderType } from "@/lib/typelist";
import { formatTime } from "@/lib/base";
export default {
    name: "Orderlist",
    beforeRouteEnter(to, from, next) {
        if (from.name == "Orderdetail" && to.name == "Orderlist") {
            to.meta.isBack = true;
        } else {
            to.meta.isBack = false;
        }
        next();
    },
    data() {
        return {
            param: {
                states: "", // 订单状态0待支付 1已支付 3关闭 4进行中 5待确认
                isbujiao: "", // 0正常1补缴
                userid: "", // 用户id
                pageamount: "", // 分页条数
                page: 1 //当前页
            },
            showData: [],
            scrollTop: "",
            loading: true
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    activated() {
        if (!this.$route.meta.isBack) {
            this.showData = [];
            this.param.page = 1;
            this.$refs.getState.pullUpState = 0;
            this.getList();
        }
    },
    mounted() {
        this.getList();
        // this.getHeight();
    },
    components: {
        "v-refresh": refresh,
        "no-data": no_data
    },
    methods: {
        /**
         * 设置状态查询列表
         */
        setStates(type) {
            if (type === undefined) {
                type = "";
            }
            document.querySelector(".app-main").scrollTop = 0;
            this.param.states = type;
            this.showData = [];
            this.param.page = 1;
            this.$refs.getState.pullUpState = 0;
            this.loading = true;
            this.getList();
        },
        //查询列表
        async getList() {
            var showDatacopy = [];
            showDatacopy = this.showData;
            let tempList = await this.$siaxios.get(
                `/order/getOrderList/${this.param.page}?userid=${this.userInfo.id}&states=${this.param.states}`
            );
            this.loading = false;
            if (tempList.length > 0) {
                tempList = showDatacopy.concat(tempList);
                this.showData = tempList;
            } else {
                this.showData = showDatacopy;
            }
            return tempList.length;
        },
        // 支付状态判断
        getStateName(id) {
            let name;
            for (let i = 0; i < orderType.length; i++) {
                if (id == orderType[i].id) {
                    name = orderType[i].name;
                    break;
                }
            }
            return name;
        },
        /**
         * 取消订单
         */
        cancleOrder(item) {
            this.$confirm({
                title: "提示",
                describe: "您是否要取消该订单",
                callback: boolean => {
                    if (boolean)
                        this.$siaxios
                            .get(
                                `/order/cancle?userid=${this.userInfo.id}&orderid=${item.id}`
                            )
                            .then(() => {
                                item.states = 3;
                            });
                }
            });
        },
        //下拉刷新
        onRefresh(done) {
            this.showData = [];
            this.param.page = 1;
            this.getList().then(() => {
                done(); // call done
            });
        },
        // 上拉加载
        onInfiniteLoad(done) {
            this.param.page++;
            this.getList().then(res => {
                done(res);
            });
        },
        formatTime: formatTime
    }
};
</script>
