<template>
    <div class="order-services">
        <div class="title">
            <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
        </div>
        <div class="show-content">
            <div
                    class="showtype "
                    style=" background: #f6faff;"
                    v-if="showType == 3"
            >
                <div class="order-title">常见问题</div>
                <div class="showinfo-3">
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                                限购证明需要哪些材料
                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                               <p>已婚：夫妻双方身份证、结婚证、夫妻双方及未成年子女户口簿。</p>
                                <p>单身：身份证、户口簿。</p>
                                <p>丧偶：身份证，户口簿及未成年子女户口簿。</p>
                                <p>现役军人： 除上述需提供资料以外，还需提供军官证或士兵证、身份证（原件）、及部队证明（原件）。</p>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'
    export default {
        name: "Problem",
        data() {
            return {
                showType: 3 //1购买流程 2平台优势 3常见问题 4温馨提示
            };
        }
    };
</script>