<template>
  <div class="proveresidence-index">
    <div :class="$route.query.id==1?'banner-con2':'banner-con'">
      <div class="banner">
        <div class="title-banner">
          <span>户口迁出</span>
        </div>
      </div>
    </div>
    <div class="cont-use">
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>公民离开户口所在地到其他地方经常居住，符合居住地人民政府规定落户的，可办理户口迁移手续</span>
        </div>
      </div>
      <div class="gary-line"></div>
      <!-- 毕业生户口迁出 -->
      <div class="use-cont2" v-if="$route.query.id==1" style="padding-top:20px;">
          <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/da-dc1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>提交迁出申请</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <!-- <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>付款成功</span>
                  </div>
                </div> -->
                 <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img style="width:18px;height:21px;" src="@/assets/views/mine/tiqu2.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>邮寄/送达新接收地出具迁移材料</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                 <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                      <img src="@/assets/views/mine/police (1).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>派出所办理迁出手续</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 20px;">
                <!-- <div class="showinfo-1-item" style="margin-left:10px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div> -->
                <!-- <div class="trastom" style="margin-left:5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div> -->
                <div class="showinfo-1-item" style="margin-left:100px">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:5px">
                  <div class="showinfo-1-img">
                     <img src="@/assets/views/mine/police (3).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>查收</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:6px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:8px">
                  <div class="showinfo-1-img">
                   <img src="@/assets/views/mine/police (2).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>迁出手续邮寄到指定地点</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 户口-户口迁出 -->
      <div class="use-cont2" style="padding-top:20px;" v-else>
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/da-dc1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>提交迁出申请</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>付款成功</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img style="width:18px;height:21px;" src="@/assets/views/mine/tiqu2.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>邮寄/送达新接收地出具迁移材料</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 20px;">
                <div class="showinfo-1-item" style="margin-left:10px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:5px">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/police (3).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>查收</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:5px">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/police (2).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>迁出手续邮寄到指定地点</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:6px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:8px">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/police (1).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>派出所办理迁出手续</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:30px;padding-bottom:20px">
        <div class="use-introduce">
          <img src="@/assets/views/mine/shuoming.jpg" alt style="width:15px;height:15px" />
          <span>{{$route.query.id==1?'业务说明':'温馨提示'}}</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="hot-cont">
          <div class="hot-num">1.</div>
          <div
            class="hot-cont"
          >市内平移户口先借取户口单页，按照新接收地要求准备材料，待新接收地落户完成之后，将新的单页拍照上传我方先办理归还手续，随即我方办理归还手续，随即我方办理迁出。</div>
        </div>
        <div class="hot-cont" style="margin-top:10px;">
          <div class="hot-num">2.</div>
          <div class="hot-cont">办理迁出时如有欠费请将欠费补齐。</div>
        </div>
        <div class="hot-cont" style="margin-top:10px;">
          <div class="hot-num">3.</div>
          <div class="hot-cont">
            <p>如将资料邮寄形式送达请将材料邮寄到我方指定地址</p>
            <p class="tips-cont">收件人：李青青</p>
            <p class="tips-cont">联系电话：0532-85873655转813或815</p>
            <p class="tips-cont" style="margin-left:62px;">18661887826</p>
            <p class="tips-cont">联系地址： 青岛市市南区高雄路18号海洋大厦5层</p>
          </div>
        </div>
      </div>

    <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:20px;" v-if="$route.query.id==1">
        <div class="use-introduce">
          <img src="@/assets/views/mine/cailiao.jpg" alt />
          <span>所需材料</span>
        </div>
        <div class="content">
          <div class="needs">
            <div class="nooed-box">一、省内迁移</div>
            <div class="needs-cont">
              <div class="neds-num">1</div>
              <div class="neds-text">身份证</div>
            </div>
            <div class="needs-cont" style="margin-left:50px">
              <div class="neds-num">2</div>
              <div class="neds-text">押金</div>
            </div>
          </div>
          <div class="needs">
            <div class="nooed-box">二、省外迁移</div>
            <div class="needs-cont" style="float:none">
              <div class="neds-num">1</div>
              <div class="neds-text">准迁证（仅限跨省迁移）</div>
            </div>
            <div class="needs-cont" style="float:none">
              <div class="neds-num">2</div>
              <div class="neds-text">居民户口簿或集体户口</div>
            </div>
            <div class="needs-cont" style="float:none">
              <div class="neds-num">3</div>
              <div class="neds-text">委托书（非本人办理）</div>
            </div>
            <div class="word">
              <div class="word-icon">
                <img class="title-img" src="@/assets/views/mine/house.png" alt />
              </div>
              <div class="word-text">
                <span>委托书模板.doc</span>
              </div>
              <div class="word-btn" @click="uploadFile(1)">
                <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
                <button>下载</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="!$route.query.id">
        <div class="gary-line"></div>
        <div class="cont-use" >
          <div class="use-cont" style='padding-bottom:20px;'>
            <div class="use-introduce">
              <img src="@/assets/views/mine/dangan-quest_03.jpg" alt style="width:15px;height:15px" />
              <span>常见问题</span>
            </div>
          </div>
          <div class="use-cont" style='padding-bottom:20px;'>
            <div class="active-title">1. 户口迁出条件：</div>
            <div
              class="active-cont"
            >公民离开户口所在地到其他地方经常居住，符合居住地人民政府规定落户的，应该办理户口迁移手续。</div>
          </div>
          <div class="use-cont">
            <div class="active-title">2.户口如何集体户怎样迁到本人房子上？</div>
            <div class="active-cont">如果户口现在在集体户，现房产证已办理完毕，可带着房产证  集体户单页  照片 去房子所属派出所办理迁移手续。  </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/residence-emigration.scss";

export default {
  name: "archives-use",
  data() {
    return {
      exportApi: ""
    };
  },
  methods: {
    //委托书模板下载
    uploadFile(type) {
      let url = this.$axios.defaults.baseURL + "common/downloadFile/" + type;
      this.$copyText(`${url}`).then(
        () => {
          this.$tips({
            describe: "已成功复制链接，请通过浏览器下载该文档"
          });
        },
        () => {
          this.$tips({
            describe: "复制失败"
          });
        }
      );
    }
  }
};
</script>

