<template>
  <div class="confirmserviceinfo-wrapper">
    <div class="c-business-info">
      <img :src="orderInfo.listimgurl" class="business-img" alt />
      <div class="business-info">
        <div class="title">{{orderInfo.businessname}}</div>
        <div class="price">￥{{orderInfo.price}}</div>
      </div>
    </div>
    <div class="order-detail-title">基础信息</div>
    <div class="service-info">
      <div class="info-line">
        <div>姓名</div>
        <div class="select-style">
          {{userInfo.name}}
        </div>
      </div>
      <div class="info-line">
        <div>身份证号</div>
        <div class="select-style">
          {{userInfo.idcard}}
        </div>
      </div>
      <div class="info-line">
        <div>城市</div>
        <div class="select-style">
          {{orderInfo.cityname}}
        </div>
      </div>
      <div class="info-line">
        <div>联系电话</div>
        <div
          class="select-style"
          v-if="orderInfo.sjgjOrderDangan && (orderInfo.saletypeid == 19 || this.orderInfo.saletypeid == 41)"
        >
          <input v-model="orderInfo.sjgjOrderDangan.tel" placeholder="请填写联系电话" type="text" />
        </div>
        <div
          class="select-style"
          v-if="orderInfo.sjgjOrderHukou && (orderInfo.saletypeid == 22 || this.orderInfo.saletypeid == 42)"
        >
          <input v-model="orderInfo.sjgjOrderHukou.tel" placeholder="请填写联系电话" type="text" />
        </div>
        <div
          class="select-style"
          v-if="orderInfo.saletypeid != 22 && orderInfo.saletypeid != 19 && this.orderInfo.saletypeid != 41 && this.orderInfo.saletypeid != 42"
        >
          <input v-model="orderInfo.sjgjOrderYiBan.tel" placeholder="请填写联系电话" type="text" />
        </div>
      </div>
      <div class="info-line" v-if="orderInfo.saletypeid == 23">
        <div>新生儿姓名</div>
        <div class="select-style">
          <input v-model="orderInfo.sjgjOrderYiBan.childname" placeholder="请填写新生儿姓名" type="text" />
        </div>
      </div>
    </div>
    <template v-if="orderInfo.saletypeid == 22 || this.orderInfo.saletypeid == 42">
      <div class="order-detail-title">添加随迁人员</div>
      <div class="follow-up">
        <div class="item" v-for="(item,$index) in sjgjOrderHukouPersonLists" :key="$index">
          <div class="topinfo">
            <div class="infos">
              <span class="lable">姓名</span>
              <input v-model="item.name" class="mormal-input namewidth" placeholder="请输入姓名" />
            </div>
            <div class="infos">
              <span class="lable">与本人关系</span>
              <v-radio v-model="item.relationship" v-bind:value="1"></v-radio>配偶
              <v-radio v-model="item.relationship" v-bind:value="2"></v-radio>孩子
              <v-radio v-model="item.relationship" v-bind:value="3"></v-radio>父母
            </div>
            <div class="infos">
              <span class="lable">身份证号码</span>
              <input v-model="item.idcard" class="mormal-input idcardwidth" placeholder="请输入身份证号码" />
            </div>
            <div class="deleteinfo" @click="deleteper($index)">删除</div>
          </div>
        </div>
        <div class="add-per" @click="addper()">添加随迁人员</div>
      </div>
    </template>
    <div class="order-detail-title">其他信息</div>
    <div class="service-info">
      <div class="reason-line pb85">
        <div>备注</div>
        <textarea v-model="orderInfo.remark" name id rows="5" placeholder="请输入备注"></textarea>
      </div>
    </div>
    <div class="next" @click="goOrderDetail()">下一步</div>
  </div>
</template>
<script>
import "@/sass/views/creatorder/creatorder.scss";
import { mapGetters } from "vuex";
export default {
  name: "Creatgeneral",
  data() {
    return {
      sjgjOrderHukouPersonLists: [],
      otherPeopleList:[],//随迁人员
    };
  },
  created() {
    this.orderInfo.userid = this.userInfo.id;
    let tempData = {
      cityid: this.orderInfo.cityid,
      tel: this.userInfo.tel
    };
    if (this.orderInfo.saletypeid == 19 || this.orderInfo.saletypeid == 41)
      this.orderInfo.sjgjOrderDangan = tempData;
    else if (
      this.orderInfo.saletypeid == 22 ||
      this.orderInfo.saletypeid == 42
    ) {
      this.orderInfo.sjgjOrderHukou = tempData;
    } else {
      if (this.orderInfo.taocan) {
        //套餐业务
        tempData.taocan = this.orderInfo.taocan;
      }
      if (this.orderInfo.saletypeid == 35) {
        //一次性创业岗位开发补贴
        tempData.num = this.orderInfo.num;
      }
      if (this.orderInfo.butie) {
        //一次性创业补贴
        tempData.butie = this.orderInfo.butie;
      }
      this.orderInfo.sjgjOrderYiBan = tempData;
    }
  },
  computed: {
    ...mapGetters({
      orderInfo: "getOrder",
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 开始下单
     */
    goOrderDetail() {
      //判断是否有随迁人员
      if(this.otherPeopleList.length != 0){
        for(var  i = 0; i < this.sjgjOrderHukouPersonLists.length;i++){
          var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          if(this.sjgjOrderHukouPersonLists[i].name == ''){
            this.$tips({
              title: "提示",
              describe: "请填写随迁人员姓名"
            });
            return;
          }else if(this.sjgjOrderHukouPersonLists[i].idcard == ''){
            this.$tips({
              title: "提示",
              describe: "请填写随迁人员身份证号"
            });
            return;
          }else if(this.sjgjOrderHukouPersonLists[i].relationship == ''){
            this.$tips({
              title: "提示",
              describe: "请选择与本人关系"
            });
            return;
          }else if(!regIdNo.test(this.sjgjOrderHukouPersonLists[i].idcard)){
            this.$tips({
              title: "提示",
              describe: "请填写正确的身份证号"
            });
            return;
          }
        }
      }
      if (
        this.orderInfo.saletypeid == 23 &&
        !this.orderInfo.sjgjOrderYiBan.childname
      ) {
        this.$tips({
          title: "提示",
          describe: "请填写新生儿姓名"
        });
        return;
      }
      //saveYiBan 一般下单
      //saveDangAn 档案下单
      //saveHuKou 落户
      let url;
      if (this.orderInfo.saletypeid == 19 || this.orderInfo.saletypeid == 41)
        url = "/order/saveDangAn";
      else if (
        this.orderInfo.saletypeid == 22 ||
        this.orderInfo.saletypeid == 42
      ) {
        url = "/order/saveHuKou";
        this.orderInfo.sjgjOrderHukou.sjgjOrderHukouPersonLists = this.sjgjOrderHukouPersonLists;
      } else url = "/order/saveYiBan";
      this.$siaxios.post(url, this.orderInfo, false).then(res => {
        this.$router.forward({
          path: "/creatorder/orderdetail",
          query: { id: res.id }
        });
      });
    },
    /**
     * 删除人员
     */
    deleteper(index) {

      this.sjgjOrderHukouPersonLists.splice(index, 1);
      this.otherPeopleList.splice(index, 1)

      this.$forceUpdate();
    },
    /**
     * 添加人员
     */
    addper() {
      this.sjgjOrderHukouPersonLists.push({
        name: "",
        idcard: "",
        relationship: ""
      });
      this.otherPeopleList.push({
        name: "",
        idcard: "",
        relationship: ""
      });
      this.$forceUpdate();
    }
  }
};
</script>