<template>
  <div class="income">
    <div class="top">
      <label>月度税前收入</label>
      <span>¥</span>
      <input type="number" v-model="monthInfo.income" @input="changeIncome">
    </div>
    <ul class="tax-list">
      <li @click="selectCity">
        <label>所在城市</label>
        {{cityname}}
      </li>
      <li @click="turnTo(1)">
        <label>社保缴纳</label>
        ¥{{monthInfo.security}}
      </li>
      <li @click="turnTo(2)">
        <label>公积金缴纳</label>
        ¥{{monthInfo.fund}}
      </li>
      <li @click="turnTo(3)">
        <label>个税专项扣除</label>
        ¥{{monthInfo.special}}
      </li>
      <li @click="turnTo(4)">
        <label>个人所得税</label>
        ¥{{monthInfo.tax}}
      </li>
    </ul>
    <div class="chart-con">
      <div class="text">
        <label>首月度税后收入</label>
        <template v-if="(monthInfo.income-monthInfo.security-monthInfo.fund-monthInfo.tax)>0">¥{{(monthInfo.income-monthInfo.security-monthInfo.fund-monthInfo.tax).toFixed(2)}}</template>
        <span v-else>您的收入太少了,努力赚钱吧！</span>
      </div>
      <div class="chart" id="chart"></div>
      <button @click="$router.forward('/calculator/incomeTax/year')">查看全年工资税后收入</button>
    </div>
    <no-data :message="message" v-show="nodata"></no-data>
  </div>
</template>
<script>
import "@/unit/calculator";
import Vue from 'vue'
import noData from "../../nonepage/nocalculator";
export default {
  components: { noData },
  data() {
    return {
      allCity: Vue.getData('allCity'),
      cityid: Vue.getData('cityid') ? Vue.getData('cityid') : 171,
      cityname: Vue.getData('cityname') ? Vue.getData('cityname') : '青岛市',
      taxList: Vue.getData('taxList'),
      allInfo: Vue.getData('allInfo'),
      household: '',
      secList: Vue.getData('secList'),
      fundBasicNum: '',
      month: this.$route.query.month,
      workIndex: 0,
      incomeIndex: 0,
      workList: Vue.getData('workList'),
      nodata: false,
      message: '',
      monthInfo: {},
      secBasicNum: 0,
    }
  },
  mounted() {
    Vue.setData('cityid', this.cityid);
    Vue.setData('cityname', this.cityname);
    if (!(typeof this.month === 'undefined')) {
      this.workList.forEach((ele, index) => {
        ele.incomeList.some((item, num) => {
          if (item.month == this.month && item.type == 1) {
            this.workIndex = index;
            this.incomeIndex = num;
            this.monthInfo = item;
          }
        });
      });
    } else {
      this.monthInfo = this.workList[0].incomeList[0];
      // delete this.monthInfo.month;
    }
    this.checkCity();
    if (!(typeof Vue.getData('workerInfo') === 'undefined')) {
      this.countFund();
      this.countSecurity();
      this.countTax();
    }
    this.drawPie();
  },
  methods: {
    changeIncome() {
      this.countFund();
      this.countSecurity();
      this.countTax();
      this.drawPie();
      if (typeof this.month === 'undefined') {
        this.workList.forEach(ele => {
          ele.incomeList.forEach(item => {
            if (item.incomeChange == 1 && item.type == 1) {
              item.fund = this.monthInfo.fund,
                item.fundBasicNum = this.monthInfo.fundBasicNum,
                item.fundScale = this.monthInfo.fundScale,
                item.fundType = this.monthInfo.fundType,
                item.income = this.monthInfo.income,
                item.secBasicNum = this.monthInfo.secBasicNum,
                item.secType = this.monthInfo.secType,
                item.security = this.monthInfo.security,
                item.special = this.monthInfo.special,
                item.tax = this.monthInfo.tax
            }
          });
        });
      } else {
        this.workList[this.workIndex].incomeList[this.incomeIndex]=this.monthInfo;
        this.workList[this.workIndex].incomeList[this.incomeIndex].incomeChange=2;
      }
      Vue.setData('workList', this.workList)
    },
    turnTo(type) {
      if (this.monthInfo.income != '') {
        if (type == 1) {
          this.$router.forward(typeof this.month === 'undefined' ? '/calculator/security/workers' : '/calculator/security/workers?month=' + this.month)
        } else if (type == 2) {
          this.$router.forward(typeof this.month === 'undefined' ? '/calculator/fund' : '/calculator/fund?month=' + this.month)
        } else if (type == 3) {
          this.$router.forward('/calculator/special')
        } else if (type == 4) {
          this.$router.forward('/calculator/tax')
        }
      } else {
        this.$alert('您还未输入税前收入哦')
      }
    },
    // 计算当前工资的个税
    countTax() {
      let money = this.monthInfo.income - this.monthInfo.security - this.monthInfo.fund - this.monthInfo.special;
      let lastMoney = (money - this.taxList[0].taxthreshold).toFixed(2);
      this.taxList.forEach(ele => {
        if (lastMoney <= 0) {
          this.monthInfo.tax = 0.00;
          return;
        } else if (lastMoney > ele.lowtax && lastMoney < ele.hightax) {
          this.monthInfo.tax = ((lastMoney * ele.taxrate).toFixed(2) * 100 / 100 - ele.quickdeduction).toFixed(2);
          return;
        }
      });
    },
    // 计算社保
    countSecurity() {
      if (this.workList[this.workIndex].incomeList[this.incomeIndex].secType == 1) {
        if (this.monthInfo.income < this.allInfo.lowshebao) {
          this.monthInfo.secBasicNum = this.allInfo.lowshebao;
        } else if (this.monthInfo.income > this.allInfo.highshebao) {
          this.monthInfo.secBasicNum = this.allInfo.highshebao
        } else {
          this.monthInfo.secBasicNum = this.monthInfo.income;
        }
      } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].secType == 2) {
        this.monthInfo.secBasicNum = this.allInfo.lowshebao
      } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].secType == 3) {
        this.monthInfo.secBasicNum = 0
      } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].secType == 4) {
        this.monthInfo.secBasicNum = this.workList[this.workIndex].incomeList[this.incomeIndex].secBasicNum;
      }
      console.log(this.monthInfo.secBasicNum)
      let sum = 0;
      this.secList.forEach(ele => {
        sum += ele.people * this.monthInfo.secBasicNum;
      });
      this.monthInfo.security = sum.toFixed(2);
      this.countTax();
    },
    //计算公积金
    countFund() {
      if (this.workList[this.workIndex].incomeList[this.incomeIndex].fundType == 1) {
        if (this.monthInfo.income < this.allInfo.lowgongjijin) {
          this.monthInfo.fundBasicNum = this.allInfo.lowgongjijin;
        } else if (this.monthInfo.income > this.allInfo.highgongjijin) {
          this.monthInfo.fundBasicNum = this.allInfo.highgongjijin
        } else {
          this.monthInfo.fundBasicNum = this.monthInfo.income;
        }
      } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].fundType == 2) {
        this.monthInfo.fundBasicNum = this.allInfo.lowgongjijin;
      } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].fundType == 3) {
        this.monthInfo.fundBasicNum = 0
      } else if (this.workList[this.workIndex].incomeList[this.incomeIndex].fundType == 4) {
        this.monthInfo.fundBasicNum = this.workList[this.workIndex].incomeList[this.incomeIndex].fundBasicNum;
      }
      this.monthInfo.fund = (this.monthInfo.fundBasicNum * this.workList[this.workIndex].incomeList[this.incomeIndex].fundScale).toFixed(2);
    },
    // 验证当前城市是否支持计算
    checkCity() {
      if (this.allCity.filter(item => { return item.id == this.cityid }).length == 0) {
        this.message = "该城市暂不支持查询，请切换其他城市"
        this.nodata = true;
      }
    },
    //选择城市
    selectCity() {
      this.$citycalculatorselect({
        callback: (id, name) => {
          this.cityid = id;
          this.cityname = name;
          Vue.setData('cityid', this.cityid);
          Vue.setData('cityname', this.cityname);
          this.getWorkerInfo()
        }
      });
    },
    getWorkerInfo() {
      this.$siaxios.get("/order/getCityBase/" + this.cityid).then(res => {
        this.allInfo = res;
        Vue.setData('allInfo', res);
        this.household = res.sjgjHouseHoldRegisters[0].id;
        this.getScaleDetail();
        this.countFund();
      })
    },
    /**
     * 根据户籍性质获取社保比例详情
     */
    getScaleDetail() {
      this.$siaxios
        .get(`/order/getBaoXian/${this.household}`)
        .then(res => {
          this.secList = res;
          Vue.setData('secList', res);
          this.countSecurity();
        });
    },
    drawPie() {
      let leftMoney = this.monthInfo.income - this.monthInfo.security - this.monthInfo.fund - this.monthInfo.tax;
      let seriesData = [];
      if (!!leftMoney && leftMoney > 0) {
        let afterTax = (leftMoney / this.monthInfo.income * 100).toFixed(2);
        seriesData = [
          { value: afterTax, name: '税后收入' },
          { value: (this.monthInfo.fund / this.monthInfo.income * 100).toFixed(2), name: '公积金缴纳' },
          { value: (this.monthInfo.security / this.monthInfo.income * 100).toFixed(2), name: '社保缴纳' },
          { value: (this.monthInfo.tax / this.monthInfo.income * 100).toFixed(2), name: '个人所得税' },
        ]
      } else {
        seriesData = [
          { value: 100, name: '暂无数据' }
        ]
      }

      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("chart"));
      // 绘制图表
      myChart.setOption({
        color: ["#4884ff", '#44dd7a', '#ff7a7a', '#ffb849'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          //图表的位置
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          containLabel: true
        },
        legend: {
          left: '0',
          right: 0,
          bottom: 0,
          icon: 'circle',
          itemWidth: 8,
          itemHeight: 8,
          data: ['税后收入', '公积金缴纳', '社保缴纳', '个人所得税']
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '66%'],
            center: ['18%', '35%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            itemStyle: {
              borderWidth: !!leftMoney && leftMoney > 0 ? 3 : 0,
              borderColor: '#fdfeff'
            },
            label: {
              show: !!leftMoney && leftMoney > 0 ? false : true,
              position: 'center',
            },
            emphasis: {
              show: !!leftMoney && leftMoney > 0 ? true : false,
              label: {
                show: true,
                fontSize: '16',
                formatter: !!leftMoney && leftMoney > 0 ? '{c}%' : '{a}'
              }
            },
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      });
      setTimeout(function () {
        window.onresize = function () {
          myChart.resize();
        }
      }, 300)
    },
  }
}
</script>
<style lang="scss" scoped>
.income {
  min-height: 100vh;
  background: #fdfeff;
  .top {
    height: 250px;
    background: #466bd9;
    padding: 70px 40px 0 40px;
    color: #fff;
    position: relative;
    label {
      font-size: 26px;
      margin-bottom: 30px;
      display: block;
    }
    span {
      font-size: 46px;
      line-height: 46px;
      width: 30px;
    }
    input {
      border: none;
      border-bottom: #fff 1px solid;
      background: none;
      padding: 6px 0 24px 34px;
      font-size: 46px;
      line-height: 46px;
      color: #fff;
      position: relative;
      margin-left: -30px;
      width: calc(100% - 40px);
    }
  }
  .tax-list {
    background: #fff;
    margin: -70px 30px 55px 30px;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    padding: 0 40px;
    box-shadow: 0 0px 20px 1px #eaf2ff inset;
    li {
      height: 100px;
      line-height: 100px;
      background: url("../../../assets/right.png") right center no-repeat;
      background-size: 10px auto;
      text-align: right;
      border-bottom: #eeeeee 1px solid;
      padding-right: 25px;
      font-size: 28px;
      color: #333;
      &:last-child {
        border-bottom: none;
      }
      label {
        color: #666;
        font-size: 26px;
        float: left;
      }
    }
  }
  .chart-con {
    padding: 0 40px;
    position: relative;
    .chart {
      height: 240px;
      margin-bottom: 46px;
    }
    .text {
      position: absolute;
      left: 42%;
      top: 30px;
      font-size: 46px;
      color: #4884ff;
      label {
        color: #333;
        font-size: 26px;
        display: block;
        margin-bottom: 20px;
      }
      span {
        font-size: 24px;
        color: #999;
      }
    }
    button {
      height: 80px;
      background: #4884ff url("../../../assets/views/calculator/arrow.png")
        582px center no-repeat;
      background-size: 40px 30px;
      border-radius: 40px;
      color: #fff;
      font-size: 30px;
      width: 100%;
      border: none;
      padding: 0 45px;
      text-align: left;
      outline: none;
    }
  }
}
</style>