<!--
 * @Description: 托管服务
 * @Autor: bjp
 -->
<template>
    <div class="trusteeship">
        <div class="graduateContent" ref="service1">
            <div class="graduateTop">
                <div class="banner">
                    <p>毕业生签约托管服务</p>
                </div>
            </div>
            <!-- 托管服务 -->
            <div class="graduateService">
                <ul class="formList">
                    <li @click="selectCity">
                        <div class="title">
                            <span class="label">*</span>
                            <span class="name">办理城市</span>
                        </div>
                        <div class="arrow">
                            <span class="city">{{param.cityname}}</span>
                            <span>
                                <img src="@/assets/views/graduate/arrow.png" alt />
                            </span>
                        </div>
                    </li>
                    <li class="file pd" @click="changeFileHukou(1)">
                        <div class="title">
                            <span :class="['name',isArchives || !fileCityYw?'colorGrey':'']">档案管理（三方协议+档案+劳动关系）</span>
                        </div>
                        <div class="btn" v-if="!isArchives && fileCityYw">
                            <img :src="require(`@/assets/views/graduate/btn${choiceFile?2:1}.png`)" alt />
                        </div>
                    </li>
                    <li class="hukou pd" @click="changeFileHukou(2)">
                        <div class="title">
                            <span :class="['name',(isRegisteredResidence|| !hukouCityYw)?'colorGrey':'']">户口服务（户口+劳动关系）</span>
                        </div>
                        <div class="btn" v-if="!isRegisteredResidence && hukouCityYw">
                            <img :src="require(`@/assets/views/graduate/btn${choiceHukou?2:1}.png`)" alt />
                        </div>
                    </li>
                    <!-- 手机号 -->
                    <li>
                        <div class="title">
                            <span class="label">*</span>
                            <span class="name">手机号</span>
                        </div>
                        <input type="text" placeholder="请输入手机号码" v-model="param.tel" />
                        <div class="sendNum">
                            <div class="success" @click="getCode()" v-if="!codeTime">获取验证码</div>
                            <div class="hasSend" v-else>已发送{{message}}</div>
                        </div>
                    </li>
                    <li>
                        <div class="title">
                            <span class="label">*</span>
                            <span class="name">验证码</span>
                        </div>
                        <input type="text" class="code" placeholder="请输入验证码" v-model="param.sms" maxlength="6" />
                    </li>
                    <li class="pd" ref="graduateYears">
                        <div class="title">
                            <span class="name">毕业年份</span>
                        </div>
                        <span class="pleaseChange" v-show="!param.graduationyear">请选择毕业年份</span>
                        <div class="arrow">
                            <span class="city" v-if="param.graduationyear">{{param.graduationyear}}</span>
                            <span>
                                <img src="@/assets/views/graduate/arrow.png" alt />
                            </span>
                        </div>
                    </li>
                    <li class="pd" @click="showSchool()">
                        <div class="title">
                            <span class="name">毕业学校</span>
                        </div>
                        <span class="pleaseChange" v-show="!param.school">请选择毕业学校</span>
                        <div class="arrow">
                            <span class="city" v-if="param.school">{{param.school}}</span>
                            <span>
                                <img src="@/assets/views/graduate/arrow.png" alt />
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- topBar -->
            <div class="topBar" ref="parentBar">
                <ul class="bar" ref="topBar">
                    <li v-for="item in topbarList" :class="tabId==item.id?'active':''" :key="item.id" @click="tabId=item.id">{{item.title}}</li>
                    <!-- <div class="line" ref="navLine"></div> -->
                </ul>
            </div>
            <div class="test" ref="test">
                    <!-- 服务内容 -->
                    <contents :fileFlag="fileShow" :hukouFlag="hukouShow" v-show="tabId==1"></contents>
                    <!-- 办理流程 -->
                    <flowChart :fileFlag="fileShow" :hukouFlag="hukouShow" v-show="tabId==2"></flowChart>
                    <!-- 常见问题 -->
                    <questions v-show="tabId==3"></questions>

                <!-- v-show="!this.isArchives || !this.isRegisteredResidence" -->
                <div class="serviceSubmit" @click="submit()">提交</div>
            </div>
        </div>
    </div>
</template>
<script>
import flowChart from "./FlowChart";
import contents from "./Contents";
import questions from "./Questions";
import MobileSelect from "@/components/plug-in/mobile-select";
import { mapGetters } from "vuex";
import "@/sass/views/graduate/graduate.scss";
// import { eventBus } from "@/main";
export default {
    components: {
        flowChart,
        contents,
        questions
    },
    data() {
        return {
            tabId: "1",
            topbarList: [
                { id: 1, title: "服务内容", isClick: true },
                { id: 2, title: "办理流程", isClick: false },
                { id: 3, title: "常见问题", isClick: false }
            ],
            // 托管服务
            time: "",
            message: "",
            codeTime: false,
            param: {
                userid: "",
                settlemodeid: "", //落户模式默认是“先落户后就业”
                tel: "",
                sms: "", //验证码
                school: "", //学校
                graduationyear: "", //毕业年份
                cityid: "",
                cityname: "",
                sjgjOrders: []
            },
            allYears: [], //1990至今年份
            allBusiness: [], //所有业务
            registeredBusiness: [], //户口业务
            archivesBusiness: [], //档案业务
            isRegisteredResidence: false, //是否办理了户口业务
            isArchives: false, //是否办理了档案
            // 点选按钮
            choiceFile: false,
            choiceHukou: false,
            // 开通档案户口的城市
            fileCitys: [],
            hukouCitys: [],
            // 当前城市是否开通档案户口
            hukouCityYw: false,
            fileCityYw: false,
            city41: [],
            city42: [],
            fileShow: "",
            hukouShow: "",
            sectionDom: [],
            barHeight: ""
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        this.sectionDom = Array.from(document.getElementsByTagName("section"));
        console.log(this.sectionDom);
        this.param.userid = this.userInfo.id;
        // 1990至今年份
        this.getYears();
        // 基础信息
        this.getBaseInfo();
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    methods: {
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 滚动条偏移量
            if (this.$refs.topBar) {
                let navList = this.$refs.topBar.getElementsByTagName("li");
                if (this.$refs.parentBar) {
                    this.barHeight = this.$refs.topBar.offsetHeight;
                    if (scrollTop >= this.$refs.parentBar.offsetTop) {
                        if (this.$refs.topBar.className.split(" ")[1] != "fixed") {
                            this.$refs.topBar.classList.add("fixed");
                        }
                    } else if (scrollTop < this.$refs.parentBar.offsetTop) {
                        if (this.$refs.topBar.className.split(" ")[1] == "fixed") {
                            this.$refs.topBar.classList.remove("fixed");
                        }
                    }
                }
                Array.from(document.getElementsByTagName("section")).forEach(
                    (item, index) => {
                        if (
                            scrollTop >= item.offsetTop - 60 &&
                            scrollTop < item.offsetTop + item.clientHeight
                        ) {
                            this.$refs.navLine.style.marginLeft =
                                navList[index].offsetLeft + 50 + "px";
                        }
                    }
                );
            }
            if (this.$refs.service1) {
                if (
                    scrollTop + window.innerHeight >=
                    this.$refs.service1.clientHeight
                ) {
                    // eventBus.$emit("onInfiniteLoad");
                }
            }
        },
        /**
         * 选择学校
         */
        showSchool() {
            this.$schoolselect({
                ywid: 1,
                callback: (id, name) => {
                    this.param.school = name;
                }
            });
        },
        selectCity() {
            this.$cityselect({
                callback: (id, name) => {
                    this.param.cityid = id;
                    this.param.cityname = name;
                    // 判断选择的城市是否开通业务
                    this.getOpenCity(id, 2);
                }
            });
        },
        showTabBar(index) {
            this.tabId = index;
            this.topbarList.forEach((item, i) => {
                let num;
                item.isClick = false;
                if (item.id == index) {
                    item.isClick = true;
                    num = this.sectionDom[i].offsetTop - this.barHeight;
                    window.scrollTo(0, num);
                }
            });
        },
        // -----托管服务------ //
        /**
         * 获取所有业务
         */
        getMyBusiness() {
            this.$siaxios
                .get("/mybusiness/getBusiness/" + this.userInfo.id, false)
                .then(res => {
                    this.allBusiness = res;
                    // 档案
                    if (
                        res.sjgjArchives &&
                        (res.sjgjArchives.state == 2 ||
                            res.sjgjArchives.state == 3 ||
                            res.sjgjArchives.state == 4)
                    ) {
                        this.archivesBusiness = res.sjgjArchives;
                        this.isArchives = true;
                        this.choiceFile = true;
                    }
                    // 户口
                    if (
                        res.sjgjRegisteredResidence &&
                        (res.sjgjRegisteredResidence.state == 2 ||
                            res.sjgjRegisteredResidence.state == 3 ||
                            res.sjgjRegisteredResidence.state == 4)
                    ) {
                        this.registeredBusiness = res.sjgjRegisteredResidence;
                        this.isRegisteredResidence = true;
                        this.choiceHukou = true;
                    }
                    if (this.isArchives && this.isRegisteredResidence) {
                        this.$confirm({
                            title: "业务已办理",
                            describe:
                                "您已办理相关业务，无需重复办理，是否需要去查看当前办理进度？",
                            callback: boolean => {
                                if (boolean) this.$router.forward("/gallery/xxsercive");
                            }
                        });
                        return
                    }
                    // 开通档案户口的城市
                    this.getOpenCity(this.userInfo.cityid, 1);
                });
        },
        // 开通业务的城市
        async getOpenCity(cityid, type) {
            this.fileCitys = [];
            this.hukouCitys = [];
            let arr = [41, 42];
            for (let i = 0; i < arr.length; i++) {
                // 开通毕业生41档案42户口的城市
                this["city" + arr[i]] = await this.$siaxios.get(
                    "region/getYeWuCity/" + arr[i]
                );
                this["city" + arr[i]].forEach(item => {
                    if (item.sjgjRegions && item.sjgjRegions.length > 0) {
                        item.sjgjRegions.forEach(citys => {
                            if (arr[i] == 41) this.fileCitys.push(citys);
                            if (arr[i] == 42) this.hukouCitys.push(citys);
                        });
                    }
                });
                if (i == arr.length - 1) {
                    this.judgeCity(cityid)
                    // 如果该城市两项业务均没有开通，则默认青岛
                    if (this.fileCityYw || this.hukouCityYw) {
                        // 1 定位城市 2手动选择城市
                        if (type == 1) {
                            this.param.cityid = this.userInfo.cityid;
                            this.param.cityname = this.userInfo.cityname;
                        }
                    } else {
                        this.$tips({
                            title: "提示",
                            describe: "当前地区尚未开通此业务"
                        });
                        this.param.cityname = "青岛";
                        this.param.cityid = 171;
                        this.judgeCity(171);
                    }
                    // 获取户口套餐名为先落户后就业的id
                    this.getHukouId();
                }
            }
        },
        judgeCity(cityid) {
            this.fileCityYw = this.fileCitys.some(file => {
                return file.id == cityid;
            });
            this.fileShow = !this.isArchives && this.fileCityYw;

            this.hukouCityYw = this.hukouCitys.some(hukou => {
                return hukou.id == cityid;
            });
            this.hukouShow = !this.isRegisteredResidence && this.hukouCityYw;
        },
        // 获取户口套餐名为先落户后就业的id
        getHukouId() {
            this.$siaxios
                .get("/order/getBiyesheng/" + this.param.cityid)
                .then(res => {
                    if (res.hukou.length > 0) {
                        res.hukou.forEach(taocan => {
                            if (taocan.taocan == "先落户后就业") {
                                this.param.settlemodeid = taocan.id;
                            }
                        });
                    }
                });
        },
        // 选择档案/户口
        changeFileHukou(type) {
            if (type == 1 && !this.isArchives) {
                this.choiceFile = !this.choiceFile;
            } else if (type == 2 && !this.isRegisteredResidence) {
                this.choiceHukou = !this.choiceHukou;
            }
        },
        // 获取验证码
        getCode() {
            var reg2 = 11 && /^((13|14|15|17|18|16)[0-9]{1}\d{8})$/;
            if (this.param.cityid == "") {
                this.$tips({ describe: "请选择所在城市" });
                return false;
            }
            if (this.param.tel == "") {
                this.$tips({ describe: "请输入手机号" });
                return false;
            } else if (!reg2.test(this.param.tel)) {
                this.$tips({ describe: "手机号码格式不正确" });
                this.param.tel = "";
                return false;
            }
            //验证通过之后获取验证码
            this.time = 60;
            this.codeTime = true;
            this.timer();
            this.$axios.get("smscode/" + this.param.tel).then(
                function (response) {
                    if (!response.data.success) {
                        this.$tips({ describe: response.data.errMsg });
                    }
                }.bind(this)
            );
        },
        timer() {
            if (this.time > 0) {
                this.time--;
                this.message = this.time + "s";
                setTimeout(this.timer, 1000);
            } else {
                this.time = 0;
                this.message = "获取验证码";
                this.codeTime = false;
            }
        },
        // 获取从1990-现在年份
        getYears() {
            let year = new Date().getFullYear();
            let section = year - 1990;
            for (let i = 0; i <= section; i++) {
                this.allYears.push(year--);
            }
            if (this.allYears.length > 0) {
                new MobileSelect({
                    trigger: this.$refs.graduateYears,
                    title: "选择毕业年份",
                    wheels: [{ data: this.allYears }],
                    triggerDisplayData: false,
                    position: [0],
                    callback: (indexArr, data) => {
                        this.param.graduationyear = data[0];
                    }
                });
            }
        },
        // 获取个人基础资料详情
        getBaseInfo() {
            this.$axios.get("/userfile/baseinfo/" + this.userInfo.id).then(res => {
                if (res.data.success) {
                    if (res.school) this.param.school = res.school;
                    if (res.graduationyear)
                        this.param.graduationyear = res.graduationyear;
                } else {
                    this.$confirm({
                        title: "提示",
                        describe: res.data.errMsg,
                        callback: boolean => {
                            if (boolean) this.$router.forward("/mine/authentication");
                            else this.$router.go(-1);
                        }
                    });
                    return;
                }

                // 个人下的所有业务
                this.getMyBusiness();
            });
        },
        // 提交
        submit() {
            if (this.userInfo.renzhengstate != 1) {
                this.$confirm({
                    describe: "办理业务请先认证，是否跳转认证页面",
                    callback: boolean => {
                        if (boolean) this.$router.forward("/mine/authentication");
                    }
                });
                return;
            }
            this.param.sjgjOrders = [];
            let fileFlag = !this.isArchives && this.fileCityYw;
            let hukouFlag = !this.isRegisteredResidence && this.hukouCityYw;
            if (fileFlag && this.choiceFile) {
                this.param.sjgjOrders.push({ saletypeid: 41 });
            }
            if (hukouFlag && this.choiceHukou) {
                this.param.sjgjOrders.push({ saletypeid: 42 });
            }
            if (this.param.cityid == "") {
                this.$tips({ describe: "请选择办理城市" });
                return false;
            }
            if (this.param.tel == "") {
                this.$tips({ describe: "请输入手机号" });
                return false;
            }
            if (this.param.sms == "") {
                this.$tips({ describe: "请输入验证码" });
                return false;
            }
            if (!(fileFlag && this.choiceFile) && !(hukouFlag && this.choiceHukou)) {
                this.$tips({
                    title: "请选择要办理的业务",
                    describe: "您尚未勾选业务类型 请选择后提交。"
                });
                return;
            }
            if (this.param.cityid != this.userInfo.cityid) {
                this.$tips({
                    title: "请核对城市",
                    describe: "您当前办理的城市与定位不同，是否继续？",
                    callback: flag => {
                        if (flag) this.submitIt();
                    }
                });
                return;
            }
            this.submitIt();
        },
        submitIt() {
            this.$siaxios
                .post("/order/saveHuKouDangAn", this.param, false)
                .then(res => {
                    this.$tips({
                        describe: "订单提交成功",
                        callback: () => {
                            this.$router.forward({
                                path: "/creatorder/orderdetail",
                                query: { id: res.id }
                            });
                        }
                    });
                });
        }
    }
};
</script>