<!--
  户口归还
-->
<template>
  <div class="mine-creatorder">
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">简介</div>
      <div class="content">户口在集体户，需要借用户口本人单页，但因个人原因不能亲自前往办理借取手续，档管家可全程为您代办，让您省心省力，安全有保障。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">常见问题</div>
      <div class="content">
        <p>
          1.借单页都需要带什么材料:
          身份证，押金，委托书（非本人借取）。
        </p>
        <p>
          2.借走单页后多长时间归还
          15个工作日办理归还手续。
        </p>
      </div>
      <div class="content">
        <p>温馨提示：</p>
        <p>1. 下单前请先咨询我们的专业顾问，资料的邮寄邮寄地址、联系人、联系方式一定要确认好再下单，以免因地址错误给您的后续工作造成不必要的麻烦。</p>
        <p>2.单页用完之后请在要求的时间内及时归还，如有特殊情况可先联系我们的专业顾问。</p>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">材料邮寄信息</div>
      <div class="content">
        <div class="item">
          <div class="lable">地址: 青岛市市南区高雄路18号海洋大厦5层</div>
          <!-- <div class="info">青岛市市南区高雄路18号海洋大厦5层</div> -->
        </div>
        <div class="item">
          <div class="lable">收件人：中海智库人力资源前台</div>
          <!-- <div class="info">中海智库人力资源前台</div> -->
        </div>
        <div class="item">
          <div class="lable">收件电话：0532-85873655</div>
          <!-- <div class="info">0532-85873655</div> -->
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="info-line">
      <div>姓名</div>
      <input class="inputc" v-model="paramInfo.name" placeholder="请填写姓名" type="text" />
    </div>

    <div class="info-line">
      <div>银行名称</div>
      <input class="inputc" v-model="paramInfo.bankname" placeholder="请填写银行名称" type="text" />
    </div>
    <div class="info-line">
      <div>银行卡号</div>
      <input class="inputc" v-model="paramInfo.bankcard" placeholder="请填写银行卡号" type="text" />
    </div>
    <div class="order-gray-line"></div>
    <div class="order-gray-line"></div>
    <div class="order-btn" @click="nextStemp()">归还</div>
  </div>
</template>
<script>
import "@/sass/views/mine/creatorder.scss";
import { mapGetters } from "vuex";
export default {
  name: "residenceBack",
  data() {
    return {
      amount: 0,
      paramInfo: {
        id: this.$route.query.id,
        name: "",
        bankname: "",
        bankcard: ""
      }
    };
  },
  created() {
    // this.getPrice();
    
  },
  mounted(){
  
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 下一步去创建订单
     */
    nextStemp() {
      if (this.paramInfo.name == "") {
        this.$tips({ title: "提示", describe: "请填写姓名" });
        return;
      }
      if (this.paramInfo.bank == "") {
        this.$tips({ title: "提示", describe: "请填写银行" });
        return;
      }
      var reg = /^([1-9]{1})(\d{15}|\d{18})$/;
      if (this.paramInfo.bankcard == "") {
        this.$tips({ title: "提示", describe: "请填写银行卡号" });
        return;
      }else if(!reg.test(this.paramInfo.bankcard)){
        this.$tips({ title: "提示", describe: "请填写正确的银行卡号" });
        this.paramInfo.bankcard = ""
        return;
      }
      this.$siaxios
        .post(`hukou/HuKouGuiHuan`, this.paramInfo, false)
        .then(() => {
          this.$tips({
            title: "提示",
            describe: "归还成功",
            callback:()=>{
              this.$router.go(-1);

            }
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
  .info-line{
    background: #fff;
  }
  .order-btn {
    position: relative !important;
  }
</style>
