<template>
    <div class="tax calculator">
        <div class="top white-bg">
            <label>个税起征点</label>
            ￥{{taxList.length>0?taxList[0].taxthreshold:0}}
        </div>
        <div class="info white-bg">
            <div class="show-table">
                <table>
                    <thead>
                        <tr>
                            <th width="55%">应税所得额</th>
                            <th width="20%">税率</th>
                            <th width="25%">速算扣除数</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in taxList" :key="index">
                            <td>超过{{item.lowtax}}元至{{item.hightax}}元的部分</td>
                            <td>{{item.taxrate*100}}%</td>
                            <td>{{item.quickdeduction}}</td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
        </div>
        <div class="description">
            <div class="title"><span>纳税说明</span></div>
            <dl class="description-text">
                <dt>2019年1月份-不包含专项扣除</dt>
                <dd>
                    例:某员工1月份工资为18000元，个人缴纳的三险一金金额为1000元
                    <ul class="list">
                        <li>应纳税所得额=18000-1000-5000=12000元</li>
                        <li>应缴纳个人所得税=12000*3%=360元</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>2019年2月份-1-不含专项扣除</dt>
                <dd>
                    例:某员工2月份工资为18000元， 1月份和2月份累计工资为=18000*2=36000元，个人累计缴纳的三险一金金额为=1000*2=2000元
                    <ul class="list">
                        <li>1月份-2月份累计个税起征点=5000*2=10000元</li>
                        <li>1月份-2月份累计应纳税所得额=-36000-2000-10000=24000元</li>
                        <li>累计预扣个人所得税=24000*3%=720元</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>2019年2月份-2-包含专项扣除</dt>
                <dd>
                    例:某员工2月份工资为18000元，1月份和2月份累计工资为=18000*2=36000元，个人累计缴纳的三险一金金额为=1000*2=2000元
                    <ul class="list">
                        <li>1月份-2月份累计专项扣除(包括子女教育1000元/月+租房1200元/月+赡养老人2000元/月) = (1000+1200+2000)*2=8400元</li>
                        <li>1月份-2月份累计个税起征点=5000*2=10000元</li>
                        <li>1月份-2月份累计应纳税所得额=36000- 2000-8400-10000=15600元</li>
                        <li>1月份-2月份累计预扣个人所得税=15600*3%=468元</li>
                    </ul>
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            taxList: []
        }
    },
    mounted() {
        if(Vue.getData('taxList')){
            this.taxList=Vue.getData('taxList');
        }else{
            this.getTaxList();
        }
        
    },
    methods: {
        getTaxList() {
            this.$siaxios.get("/citybasic/taxformulalist").then(res => {
                if (res) {
                    this.taxList=res;
                    Vue.setData('taxList', this.taxList);
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.tax {
    background: #fff;
    min-height: 100vh;
    .info {
        padding: 30px;
        border-top: 20px solid #f5f5f5;
    }
    .top {
        padding: 0 30px;
        line-height: 100px;
        text-align: right;
        font-size: 46px;
        color: #3367ff;
        label {
            font-size: 26px;
            color: #666;
            float: left;
        }
    }
}
</style>