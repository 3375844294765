<template>
  <div>
    <div class="showmessage">
      <div class="order-title">重要说明 :（必读）</div>
      <div class="content">以前曾在单位参保过的，第一次下单购买前，请先确认前单位是否已经减员，若因前单位没有减员的，导致购买不成功的，本平台不负责哦</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">社保减员注意事项</div>
      <div class="content">若您停止购买后，本平台通常会于次月截止时间后的一周内操作减员（部分地区可能会有延迟），如果需要提前减员的用户，请提前与我们的客户联系。</div>
      <div class="content">如果您当月申请减员后，又想重新购买当月的社保，请务必先联系我们的顾问撤销减员，避免漏缴</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">购买流程</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
        <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
        <div class="item" @click="showType = 4" :class="{'active': showType == 4}">温馨提示</div>
      </div>
      <div class="show-content">
        <div
          class="showtype"
          style="background: #f6faff;"
          v-if="showType == 1"
        >
          <div class="order-title">购买流程</div>
          <div class="showinfo-1">
            <div class="showinfo-1-">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-1.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>参保人资料登记</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-2.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>相关信息填写</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-3.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>付款成功</span>
                </div>
              </div>
            </div>
            <div class="showinfo-1-">
              <div class="show-img">
                <img src="@/assets/views/creatorder/arrow2.png" alt />
              </div>
            </div>
            <div class="showinfo-1-" style="margin-top: 40px">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-6.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>完成</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-5.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>申报完成</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-4.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>专员跟进</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="showtype showtype3"
          v-if="showType == 3"
        >
          <div class="order-title">常见问题</div>
          <div class="showinfo-3">
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>付款成功后，什么时候可以查询参保情况</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>当月下单后，专员会进行跟进，如有异常会通过信息或者电话通知您；没有通知的则表示正常操作，一班次月的1-15日可查询缴纳情况</span>
                </div>
              </div>
            </div>
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>社保如果没有缴纳成功，可以退款吗</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>可以，如果因您自身原因办理不成功，或者符合申请退款条件的，则予以退款，但是支付通道手续费，需由您自己承担</span>
                </div>
              </div>
            </div>
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>参保职工可以申领多张“社会保障卡”吗</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>社会保障卡采取一人一卡一号管理，参保职工只能申领一张社会保障卡</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="showtype"
          v-if="showType == 4"
        >
          <div class="order-title">温馨提示</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item" style="height:70px">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>避免因错过截止时间忘记下单，造成社保断交的，建议您一次性购买多个月份，若有新的工作单位，未缴费的月份可申请退款，若申请退款，请于该月份截止时间前联系顾问</span>
              </div>
            </div>
            <div class="showinfo-2-item" style="height:50px">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>每年政府会调整社保基数，我们会在第一时间通知您，若因政府调整滞后导致划扣金额不准确的，我们会多退少补</span>
              </div>
            </div>
            <div class="showinfo-2-item" style="height:80px">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>带有“预收费用”的城市，因社保局已确定条幅，但上调金额未定，固预收一定的费用，待社保局确定上调金额后，我们会统一清算，多退少补（如青岛市一般于6月份公布，单须当年1月开始执行）</span>
              </div>
            </div>
            <div class="showinfo-2-item" style="height:40px">
              <div class="num">
                <span>4</span>
              </div>
              <div class="item-cont">
                <span>本套餐适合于18岁至法定退休年龄之间的用户购买</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'
export default {
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>