<template>
  <div class="hkguanli-jzz">
    <div :class="isShow ? 'Mask' : '' "></div>
    <div class="banner-con">
      <div class="banner">
        <span>居住证</span>
      </div>
    </div>

    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <span>青岛居住证是吸引人才的重要手段，拥有青岛居住证后可享受与青岛户口相同都买车，子女上学，办理出入境等权益</span>
      </div>
      <div class="content" style="margin-top:10px;">
        <span>档管家为您提供在线办理居住证服务，让你无需奔波即可办理，第一时间享受青岛福利</span>
      </div>
      <div class="introduce showmessage" style="padding-left: 0">
        <div class="title">
          <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
          <span class="intro-title">业务流程</span>
        </div>

        <div class="process">
          <div class="process-con">
            <div class="proess-img">
              <img src="@/assets/views/mine/jinan5.png" alt />
            </div>
            <div class="procss-text">
              <span>APP注册账号</span>
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/views/mine/da-arrow.jpg" alt />
          </div>
          <div class="process-con">
            <div class="proess-img">
              <img src="@/assets/views/mine/fukuan.jpg" alt />
            </div>
            <div class="procss-text">
              <span>付款成功</span>
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/views/mine/da-arrow.jpg" alt />
          </div>
          <div class="process-con">
            <div class="proess-img">
              <img src="@/assets/views/mine/guihuan1.jpg" alt />
            </div>
            <div class="procss-text">
              <span>填写相关信息</span>
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/views/mine/da-arrow.jpg" alt />
          </div>
          <div class="process-con">
            <div class="proess-img">
              <img src="@/assets/views/mine/jifen.jpg" alt />
            </div>
            <div class="procss-text">
              <span>递交相关信息</span>
            </div>
          </div>
          
        </div>

        <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>

        <div class="process" style="margin-top: 20px">
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进办理</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/police (1).jpg" alt />
          </div>
          <div class="procss-text">
            <span>派出所办理</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>

    <div class="order-gray-line"></div>

    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/gallery/gfzm/cl.png" alt />
        <span class="intro-title">所需材料</span>
      </div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">身份证</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">2</div>
            <div class="neds-text" style="width:250px">租房合同、房产证</div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>

    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img img" src="@/assets/views/gallery/gfzm/cjwt.png" alt />
        <span class="intro-title">常见问题</span>
      </div>
    </div>

    <div class="bottom" @click="problem(1)">
      1.办理流动人口居住都需要什么条件？
      <div>></div>
    </div>
    <div class="bottom" @click="problem(2)">
      2.办理青岛居住证能享受哪些权益？
    </div>
    <div class="order-gray-line"></div>

    <div class="clickBottom" ref="clickBottom">
      <img src="@/assets/views/graduate/close.png" alt="" class="close" @click="close">
      <ul class="specific1">
        <li>公民离开常住户口所在地，到其他城市居住半年以上，符合有合法稳定就业，合法稳定住所，连续就读条件之一的，可以申领居住证</li>
      </ul>
    </div>

    <div class="clickBottom" ref="clickBottom1">
      <img src="@/assets/views/graduate/close.png" alt="" class="close" @click="close">
      <ul class="specific1">
        <li>1.&nbsp;公共就业服务机构提供的职业指导、职业介绍、就业失业登记、就业信息查询等服务；</li>
        <li>2.&nbsp;参加社会保险，享受相应的保障；</li>
        <li>3.&nbsp;国家规定的传染病防治、儿童计划免疫等基本公共卫生服务；</li>
        <li>4.&nbsp;国家规定的对育龄流动人口的计划生育基本项目技术服务；</li>
        <li>5.&nbsp;法律服务和法律援助；</li>
        <li>6.&nbsp;按照规定参加居住地专业技术职务资格评定或考试、职业（执业）资格考试、职业（执业）资格登记；</li>
        <li>7.&nbsp;居住地政府规定的住房保障政策；</li>
        <li>8.&nbsp;在居住地申领机动车驾驶证、办理机动车注册登记手续；</li>
        <li>9.&nbsp;在居住地办理出入港澳地区的商务签注手续；</li>
        <li>10.&nbsp;具有本省户籍且在居住地居住满一年的，可以在居住地申请普通护照、大陆居民往来台湾通行证及签注、来往港澳通行证及团队旅游签注；</li>
        <li>11.&nbsp;依法参加居住地社会组织和有关社会事务管理。</li>
        <li>12.&nbsp;符合居住地人民政府规定条件的流动人口随迁子女在流入地接受学前教育、义务教育享受与常住户口学生同等对待；</li>
        <li>13.&nbsp;流动人口符合居住地落户条件的，可以申请常住户口</li>
      </ul>
    </div>
    <div class="showService" @click="showConsult">咨询顾问</div>


  </div>
</template>

<script>
import "@/sass/views/gallery/hkguanli-jzz.scss";
import { mapGetters } from "vuex";
export default {
  data(){
    return {
      isShow:false,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods:{
    problem(type){
      this.isShow = true;
      if(type == '1'){
        this.$refs.clickBottom.style.transform = "translate3d(0px, 0px, 0px)";
        this.$refs.clickBottom.style.transitionDuration = "500ms";
      }else if(type == "2"){
        this.$refs.clickBottom1.style.transform = "translate3d(0px, 0px, 0px)";
        this.$refs.clickBottom1.style.transitionDuration = "500ms";
      }

    },
    close(){
      this.isShow = false;
      this.$refs.clickBottom.style.transform = "translate3d(0px, 700px, 0px)";
      this.$refs.clickBottom.style.transitionDuration = "500ms";
      this.$refs.clickBottom1.style.transform = "translate3d(0px, 700px, 0px)";
      this.$refs.clickBottom1.style.transitionDuration = "500ms";
    },
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return
      }
      this.$consult({ userid: this.userInfo.id })
    },
  }
}
</script>

<style>

</style>