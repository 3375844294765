<template>
    <div class="order-services">
        <div class="title">
            <div class="item" @click="showType = 1" :class="{'active': showType == 1}">购买流程</div>
            <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
        </div>
        <div class="show-content">
            <div
                    class="showtype" style="background: #f6faff;"
                    v-if="showType == 1"
            >
                <div class="order-title">购买流程</div>
                <div class="showinfo-1">
                    <div class="showinfo-1-">
                        <div class="showinfo-1-item" style="margin-left:20px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-1.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>办理人填写信息资料</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-2.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>付款成功</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-3.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>专员跟进</span>
                            </div>
                        </div>
                    </div>
                    <div class="showinfo-1-">
                        <div class="show-img">
                            <img src="@/assets/views/creatorder/arrow2.png" alt="">
                        </div>
                    </div>
                    <div class="showinfo-1-" style="margin-top: 40px">
                        <div class="showinfo-1-item" style="margin-left:150px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-5.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>业务成功</span>
                            </div>
                        </div>
                        <div class="trastom">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-4.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>申报完成</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                    class="showtype "
                    style=" background: #f6faff;"
                    v-if="showType == 3"
            >
                <div class="order-title">常见问题</div>
                <div class="showinfo-3">
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                               什么是灵活就业人员
                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                              灵活就业人员是一种非常特殊的人员，他们没有稳定的企业给他们缴纳社保，按照社会保险法的规定，无雇工的个体工商户、非全日制用工的劳动者、灵活就业人员可以自己承担相应的基本养老保险和医疗保险费用。
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'
    export default {
        name: "Problem",
        data() {
            return {
                showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
            };
        }
    };
</script>
