<template>
  <div>
    <div class="daguanli">
      <div class="head">
        <img src="@/assets/views/gallery/daguanli/background.png" alt="">
        <div class="title">专业档案托管服务平台</div>
        <div class="titlehx"></div>
        <div class="headfk">
          <ul class="headfkul1" @click="$router.forward({path:'/creatorder/general',query:{id:19,businessname:'档案托管'}})">
            <li><img class="headimg" src="@/assets/views/gallery/daguanli/bz1.png" alt=""></li>
            <li>档案托管</li>
          </ul>
          <ul class="headfkul2" @click="$router.forward({path:'/mine/document-query'})">
            <li><img class="headimg" src="@/assets/views/gallery/daguanli/bz2.png" alt=""></li>
            <li>档案查询</li>
          </ul>
          <ul class="headfkul3" @click="$router.forward('/gallery/prove-profile')">
            <li><img class="headimg" src="@/assets/views/gallery/daguanli/bz3.png" alt=""></li>
            <li>档案补办</li>
          </ul>
          <ul class="headfkul4" @click="isRecord()">
            <li><img class="headimg" src="@/assets/views/gallery/daguanli/bz4.png" alt=""></li>
            <li>档案整理</li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card1">
          <div class="card1fk"></div>
          <div class="card1content">档案用途</div>
        </div>
        <div class="card2" @click="$router.forward({path:'/gallery/archives-use',query:{id:1}})">
          <div class="card2fk">退休办理</div>
          <div class="card2content"></div>
          <img class="kf" src="@/assets/views/gallery/daguanli/kf.png" alt="">
        </div> 
        <div class="card3" >
          <ul class="card3Modular1" @click="$router.forward({path:'/gallery/archives-use',query:{id:2}})">
            <img class="glimg" src="@/assets/views/gallery/daguanli/gl.png" alt="">
            <li>工龄计算</li>
          </ul>
          <ul class="card3Modular2" @click="$router.forward({path:'/gallery/archives-use',query:{id:3}})">
            <img class="glimg" src="@/assets/views/gallery/daguanli/zc.png" alt="">
            <li>职称评审</li>
          </ul>
          <ul class="card3Modular3" @click="$router.forward({path:'/gallery/archives-use',query:{id:4}})">
            <img src="@/assets/views/gallery/daguanli/gwy.png" alt="">
            <li>公务员政策</li>
          </ul>
        </div>
        <div class="card4" @click="$router.forward({path:'/gallery/archives-use',query:{id:5}})">
          <div class="card4fk"></div>
          <div class="card4content">户籍调动</div>
          <img src="@/assets/views/gallery/daguanli/hj.png" alt="">
          <div class="card4sx"></div>
        </div>
        <div class="card5" @click="$router.forward({path:'/gallery/archives-use',query:{id:6}})">
          <div class="card5fk"></div>
          <div class="card5content">人事手续办理</div>
          <img src="@/assets/views/gallery/daguanli/rs.png" alt="">
        </div>
      </div>
      <div class="bszn">
        <div class="bszn1">
          <div class="bszn1fk"></div>
          <div class="bszn1content">办事指南</div>
        </div>
        <div class="drawer1">
          <ul @click="$router.forward({path:'/gallery/dangancd'})">
            <li><img src="@/assets/views/gallery/daguanli/gb1.png" alt=""></li>
            <li>存档业务</li>
          </ul>
          <ul @click="$router.forward({path:'/gallery/dangantd'})">
            <li><img src="@/assets/views/gallery/daguanli/gb2.png" alt=""></li>
            <li>提档业务</li>
          </ul>
        </div>
        <div class="drawer2">
          <ul @click="$router.forward({path:'/gallery/danganbro'})">
            <li><img src="@/assets/views/gallery/daguanli/gb3.png" alt=""></li>
            <li>档案借阅</li>
          </ul>
          <ul @click="$router.forward({path:'/gallery/danganreturn'})">
            <li><img src="@/assets/views/gallery/daguanli/gb4.png" alt=""></li>
            <li>档案归还</li>
          </ul>
        </div>
        <div class="drawer3">
          <ul @click="$router.forward('/gallery/prove')" >
            <li><img src="@/assets/views/gallery/daguanli/gb5.png" alt=""></li>
            <li>档案证明</li>
          </ul>
          <ul @click="$router.forward('/gallery/prove-find')">
            <li><img src="@/assets/views/gallery/daguanli/gb6.png" alt=""></li>
            <li>档案找回</li>
          </ul>
        </div>
        <div class="drawer4" @click="$router.forward('/gallery/prove-active')">
          <ul >
            <li><img src="@/assets/views/gallery/daguanli/gb7.png" alt=""></li>
            <li>档案激活</li>
          </ul>
        </div>
      </div>
      <div class="center">
        <img src="@/assets/views/gallery/daguanli/background2.png" alt="">
        <div class="center1">从户口到档案</div>
        <div class="center2">毕业不用手忙脚乱</div>
        <div class="center3"></div>
      </div>
      <div class="zw"></div>
      <div class="Consultation">
        <div class="Consultationfk"></div>
        <div class="ConsultationContent">资讯</div>
        <div class="ConsultationContent1" @click="Jumpinfo">更多资讯</div>
      </div>
      <xzJump :type="type"></xzJump>
      <div class="bottomButton">
        <div class="buttonbox">
          <div class="buttonbox1" @click="$router.forward({path:'/mine/service'})">
            <div class="buttonbox1content">咨询顾问</div>
            <img src="@/assets/views/gallery/daguanli/ej.png" alt="">
          </div>
          <div class="buttonbox2" @click="showConsult">
            <img class="bigimg" src="@/assets/views/gallery/daguanli/dibu.png" alt="">
            <img class="smallimg" src="@/assets/views/gallery/daguanli/fdj.png" alt="">
            <div class="buttonbox2content">档案查询</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/gallery/daguanli.scss";
import { mapGetters } from "vuex";
import xzJump from "./xzJump"
export default {
   computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  data(){
    return {
      type:3
    }
  },
  created(){
    
  },
  components:{
    xzJump,
  },
  methods:{
   isRecord(){
     this.$router.forward('/gallery/prove-zhengli')
  //    this.$siaxios.get('order/getState/3/' + this.userInfo.id)
  //    .then(res=>{
  //      if(res == false){
  //        this.$confirm({
  //           title: "提示",
  //           describe: "您还没有办理业务，快去办理吧",
  //           callback: boolean => {
  //             if (boolean)
  //               this.$router.forward({ path: "/gallery/daguanli" });
  //           }
  //         });
  //      }else{
  //        this.$router.forward('/mine/archives')
  //      }
       
  //    })
   },
    Jumpinfo(){
      this.$router.forward({path:'/information/list',query:{id:3}});
    },
  
    /**
     * 展示顾问
     */
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return;
      }
      this.$router.forward({path:'/mine/document-query'})
    },
  }
}
</script>

<style>

</style>