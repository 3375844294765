<template>
    <div class="salary calculator">
        <div class="banner">
            <img src="@/assets/views/calculator/banner.jpg" alt="">
        </div>
        <div class="type">
            <div class="item" @click="$router.forward('/calculator/incomeTax/month')">
                <div class="title">工资月度计算</div>
                <button>开始计算</button>
            </div>
            <div class="item" @click="$router.forward('/calculator/incomeTax/year')">
                <div class="title">工资年度计算</div>
                <button>开始计算</button>
            </div>
        </div>
        <ul class="list">
            <li @click="$router.forward('/calculator/special')">
                <img src="@/assets/views/calculator/special.png" alt="">
                专项扣除
            </li>
            <li @click="$router.forward('/calculator/yearEnd')">
                <img src="@/assets/views/calculator/year_end.png" alt="">
                年终奖
            </li>
            <li @click="$router.forward('/calculator/laobor')">
                <img src="@/assets/views/calculator/labour.png" alt="">
                劳务所得
            </li>
            <li @click="$router.forward('/calculator/incomeTax/notes')">
                <img src="@/assets/views/calculator/income.png" alt="">
                收入记录
            </li>
        </ul>
    </div>
</template>
<script>
import Vue from 'vue'
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
export default {
    data() {
        return {
            cityid: Vue.getData('cityid') ? Vue.getData('cityid') : 171,
            workList: [
                {
                    incomeList: []
                }
            ],
            taxList: [],
            allCity: [],
            household:'',
            secList:[],
            secBasicNum:'',
            income:5000
        }
    },
    created() {
        if (!Vue.getData('allCity')) {
            this.getCityList();
        }
    },
    mounted() {
        Vue.setData('cityid', this.cityid);
        if (!Vue.getData('workList')) {
            for (let i = 0; i < 12; i++) {
                this.workList[0].incomeList.push({
                    type: 1, //1工资，2劳务，3年终奖
                    month: i + 1,
                    income: 5000,
                    special:0,
                    incomeChange:1,
                    secChange:1,
                    fundChange:1
                })
            }            
            this.getWorkerInfo();
        }
    },
    methods: {
        // 计算当前工资的个税
        countTax() {
            let money = this.income - this.workList[0].incomeList[0].security - this.workList[0].incomeList[0].fund;
            let lastMoney = (money - this.taxList[0].taxthreshold).toFixed(2);
            let tax=0;
            this.taxList.forEach(ele => {
                if (lastMoney <= 0) {
                    tax = 0.00;
                    return;
                } else if (lastMoney > ele.lowtax && lastMoney < ele.hightax) {
                    tax = ((lastMoney * ele.taxrate).toFixed(2) * 100 / 100 - ele.quickdeduction).toFixed(2);
                    return;
                }
            });            
            this.workList[0].incomeList.map(item=>{item.tax=tax});
            Vue.setData('workList', this.workList);
        },
        // 计算社保
        countSecurity() {
            let sum = 0;
            this.secList.forEach(ele => {
                sum += ele.people * this.secBasicNum;
            });
            this.workList[0].incomeList.map(item=>{item.security=Number(sum.toFixed(2))})
        },
        //获取个税税率
        getTaxList() {
            this.$siaxios.get("/citybasic/taxformulalist").then(res => {
                if (res) {
                    this.taxList = res;
                    this.countTax();
                    Vue.setData('taxList', this.taxList);
                }
            });
        },
        //获取所有支持计算的城市
        getCityList() {
            this.$siaxios.get("citybasic/calculatorcities").then(res => {
                if (res) {
                    for (let a = 0; a < res.length; a++) {
                        if (res[a].sjgjRegions.length != 0) {
                            this.allCity = this.allCity.concat(res[a].sjgjRegions)
                        }
                    }
                    Vue.setData('allCity', this.allCity);
                }
            });
        },
        getWorkerInfo() {
            this.$siaxios.get("/order/getCityBase/" + this.cityid).then(res => {
                this.allInfo = res;
                if (this.income < res.lowshebao) {
                    this.secBasicNum = res.lowshebao;
                } else if (this.income > res.hignshebao) {
                    this.secBasicNum = res.hignshebao
                } else {
                    this.secBasicNum = this.income;
                }
                this.workList[0].incomeList.map(item=>{item.secBasicNum=this.secBasicNum;item.secType=1})
                this.household = res.sjgjHouseHoldRegisters[0].id;
                this.getScaleDetail();
                // 计算公积金
                let fundBasicNum=5000;
                if (this.income < res.lowgongjijin) {
                    fundBasicNum = res.lowgongjijin;
                } else if (this.income > res.highgongjijin) {
                    fundBasicNum = res.highgongjijin
                } else {
                    fundBasicNum = this.income;
                }
                console.log()
                this.workList[0].incomeList.map(item=>{item.fundBasicNum=fundBasicNum;item.fundType=1;item.fundScale=res.accumulationpeople;})
                Vue.setData('allInfo', res);
                this.workList[0].incomeList.map(item=>{item.fund=fundBasicNum * res.accumulationpeople.toFixed(2)})

            })
        },
        /**
         * 根据户籍性质获取社保比例详情
         */
        getScaleDetail() {
            this.$siaxios
                .get(`/order/getBaoXian/${this.household}`)
                .then(res => {
                    this.secList = res;
                    Vue.setData('secList', res);
                    this.countSecurity();
                    this.getTaxList();
                });
        },
    }
}
</script>
<style lang="scss" scoped>
.salary {
    padding: 30px;
    background: #fff;
    height: 100vh;
    box-sizing: border-box;
    .banner {
        img {
            width: 100%;
            border-radius: 16px;
        }
    }
    .type {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .item {
            width: 328px;
            height: 163px;
            background: #fff url("../../../assets/views/calculator/month.png")
                230px 45px no-repeat;
            background-size: 78px auto;
            border-radius: 16px;
            padding: 0 30px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 0 0 20px 0px #eeeeee;
            &:last-of-type {
                background-image: url("../../../assets/views/calculator/year.png");
                button {
                    background: #e8fff0;
                    color: #4db585;
                }
            }
            .title {
                font-size: 30px;
                margin-bottom: 16px;
            }
            button {
                width: 120px;
                height: 36px;
                border-radius: 18px;
                background: #eaf3ff;
                color: #4372d2;
                font-size: 18px;
                border: none;
            }
        }
    }
    .list {
        li {
            height: 130px;
            background: #f5f5f5
                url("../../../assets/views/calculator/right.png") 630px center
                no-repeat;
            background-size: 30px;
            border-radius: 10px;
            margin-bottom: 20px;
            padding: 30px 0 0 30px;
            line-height: 68px;
            font-size: 28px;
            color: #333333;
            img {
                width: 68px;
                height: 60px;
                margin-right: 20px;
                float: left;
            }
        }
    }
}
</style>