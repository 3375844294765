<template>
  <div class="express-index">
    <div class="express-nav">
      <div class="item" :class="{'active':paramInfo.state===''}" @click="getList('')">全部</div>
      <div class="item" :class="{'active':paramInfo.state===0}" @click="getList(0)">待签收</div>
      <div class="item" :class="{'active':paramInfo.state===1}" @click="getList(1)">已签收</div>
    </div>
    <div class="express-list">
      <div
        class="item"
        v-for="item in showData.list"
        :key="item.id"
        @click="$router.forward({path:'/expressmail/detail',query:{id:item.id}})"
      >
        <div class="icon-con">
          <img src="@/assets/views/expressmail/send.png" alt class="send-img" />
        </div>
        <div class="info">
          <div class="title">{{item.expressname}}</div>
          <div class="state" :class="{'recve':item.state==1}">{{item.state==0?'待签收':'已签收'}}</div>
        </div>
        <img src="@/assets/right.png" alt class="more-img" />
      </div>
    </div>
    <div class="bottom-menu">
      <div class="consult" @click="$router.forward('/expressmail/address')">
        <img src="@/assets/local2.png" class="add-icon" alt />我的地址
      </div>
      <div class="order-btn" @click="$router.forward('/expressmail/send')">资料邮寄</div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/expressmail/index.scss";
import { mapGetters } from "vuex";
export default {
  name: "Expressmail",
  data() {
    return {
      paramInfo: {
        id: "", //用户id
        page: 1,
        state: "" //状态0待签收1已签收
      },
      showData: []
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },

  activated() {
    this.getList("");
  },
  mounted() {
    this.getList("");
  },
  methods: {
    /**
     * 列表
     */
    getList(index) {
      this.paramInfo.state = index;
      this.$siaxios
        .get(
          `/filepost/list/${this.userInfo.id}/${this.paramInfo.page}?state=${this.paramInfo.state}`
        )
        .then(res => {
          this.showData = res;
        });
    },
    //下拉刷新
    onRefresh(done) {
      this.showData = [];
      this.paramInfo.page = 1;
      this.getList().then(() =>{
        done(); // call done
      })
    },
    // 上拉加载
    onInfiniteLoad(done) {
      let pastData = [];
      pastData = this.showData;
      this.paramInfo.page++;
      this.getList().then(res => {
        if (res == 0) {
          this.showData = pastData;
        }

        done(res);
      });
    }
  }
};
</script>

