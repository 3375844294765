<!-- 就业-->
<template>
  <!-- <v-refresh> -->
    <div v-if="otherData.length>0" class="otherbussiness-index">
      <div class="re-per-con" v-for="item in otherData" :key="item.id">
        <div class="item">
          <div class="userinfo">
            <span class="name">{{item.businessname}}</span>
            <span class="state">{{getType(item.state)}}</span>
          </div>
          <div class="infos">
            <span class="lable">{{formatTime(item.createtime)}}</span>
          </div>
        </div>
        <div class="grayline"></div>
      </div>
    </div>
    <div v-else>
      <no-data></no-data>
    </div>
  <!-- </v-refresh> -->
</template>
<script>
import "@/sass/views/mine/otherbussiness.scss";
// import refresh from "@/components/plug-in/pulldown";
import { formatTime } from "@/lib/base.js";
import no_data from '../nonepage/norecord'
import { mapGetters } from "vuex";
export default {
  name: "mineresidence",
  data() {
    return {
      otherData: []
    };
  },
  created() {
    this.getMyBusiness();
  },
  components: {
    // "v-refresh": refresh,
    'no-data':no_data
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    //获取创业信息
    getMyBusiness() {
      this.$siaxios
        .get("/mybusiness/getBusiness/" + this.userInfo.id)
        .then(res => {
          if (res.jiuye) {
            this.otherData = res.jiuye;
          }
        });
    },
    //状态
    getType(state) {
      if (state == 1) {
        return "待确认";
      } else if (state == 2) {
        return "进行中";
      } else if (state == 3) {
        return "完成";
      } else if (state == 4) {
        return "下款";
      } else if (state == 5) {
        return "关闭";
      }
    },
    formatTime: formatTime,
    //下拉刷新
    onRefresh(done) {
      this.getMyBusiness();
      done(); // call done
    },
    // 上拉加载
    onInfiniteLoad(done) {
      this.showData.pageNum++;
      this.getMyBusiness().then(res => {
        done(res);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
</style>