<template>
  <div class="nonepage">
    <div class="showcontent">
      <img src="@/assets/views/nonepage/recordtype.png" class="showimg" alt />
      <template >
        <div class="title-">暂无{{!isMessage?'记录':isMessage}}</div>
        <div class="tips">您还没有相关{{isMessage == ''?'缴费':isMessage}}记录</div>
      </template>
    </div>
  </div>
</template>

<script>
import "@/sass/views/nonepage.scss";
export default {
  props: {
    isMessage: {
      type: String
    }
  },
  data() {
    return {};
  },
  created() {
  }
};
</script>
