<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>档案无处放？这里可托管。 </p>
                <span>档案是个人身份、学历、工作经历等方面的证据，与个人工资待遇、社会劳动保障、组织关系紧密挂钩，在个人职称申报、办理养老保险、考取公务员以及开具考研等相关证明时，都需要使用档案。

</span>


            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

