<template>
  <div class="proveactive-index">
    <div class="banner-con">
      <div class="banner">
        <div class="title-banner">档案激活</div>
      </div>
    </div>
    <div class="cont-use">
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>
            因为工作流动、违约跳槽、下海经商、单位改制、破产等原因，一直没有管理自己的档案，有些毕业生没有找到接收单位索性把档案放在自家的抽屉里，多年的
            经历、评价全部是空白，像这些‘无人问津
            的档案称之为“死档案”
          </span>
          <p
            style="margin:5px 0"
          >在机关事业单位、国有企业，对人事档案还是相当看重的。很多以档案为依据的一些个人证明等手续还是绕不开档案这一关。比如工龄计算、职称评审、考公务员、转正定级、出国定居、办理养老保险、办理退休、个人经历证明、个人学习情况证明一级个人诚信证明等都离不开档案。</p>
          <span>如果你面临“死档案”问题，档管家可为提供档案激活服务，解决你面临的难题。</span>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style='padding-top:20px;'>
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img" style="margin-left:10px;">
                    <img src="@/assets/views/mine/zixun-.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>咨询专业顾问</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img" style="margin-left:10px;">
                    <img src="@/assets/views/mine/tigong.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>提供合理解决方案</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:28px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>付款成功</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img-">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 15px;">
                <div class="showinfo-1-item" style="margin-left:22px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:20px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:25px;">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:22px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:28px;">
                  <div class="showinfo-1-img" style="margin-left:5px;">
                    <img src="@/assets/views/mine/tijiao.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>提交材料</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gary-line"></div>
    <!--        常见问题-->
    <div class="cont-use" >
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/dangan-quest_03.jpg" alt style="width:15px;height:15px" />
          <span>常见问题</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="active-title">1. 什么是死档？</div>
        <div class="active-cont">死档意思就是静止的，不流通的档抄案，大部分都是在个人手里，或者档案在非存档权威机构保管</div>
      </div>
      <div class="small-ine"></div>
      <div class="use-cont">
        <div class="active-title">2. 怎么才能激活档案？</div>
        <div class="active-cont">1.个人去人才交流服务中心补办相关手续，将档案激活</div>
        <div class="active-cont">2. 委托有资质的人力资源公司代办，把档案放到相应的管 理部门，避免档案记录出现“断层”，已经遗失了档案的个人应尽快补办档案。</div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/prove-active.scss";

export default {
  name: "archives-use",
  data() {
    return {};
  }
};
</script>

