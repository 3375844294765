/**
 * @param {*} date 时间戳
 * @param {*} type 显示时间格式 yyyy-MM-dd hh:mm:ss
 * @description 根据时间戳获取时间字符串
 */
export const formatTime = (date, type) => {
  if (!date || date == "") {
    return
  }
  if (type == 'all') {
    type = 'yyyy-MM-dd hh:mm'
  } else if (type == undefined) {
    type = 'yyyy-MM-dd'
  }
  date = new Date(date)
  if (/(y+)/.test(type)) {
    type = type.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  // 遍历这个对象
  for (let k in o) {
    if (new RegExp(`(${k})`).test(type)) {
      let str = o[k] + '';
      type = type.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
    }
  }
  return type;
}

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) return false
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true
  /* eslint-disable-next-line */
  else return !keysArr1.some(key => obj1[key] != obj2[key])
}


/**
 * @param {*} dateTimeStamp 时间戳
 * @description js计算时间为刚刚、几分钟前、几小时前、几天前··
 */
export const timeago = (dateTimeStamp) => {//dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
  var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;
  var day = hour * 24;
  var week = day * 7;
  // var halfamonth = day * 15;
  var month = day * 30;
  var now = new Date().getTime();   //获取当前时间毫秒
  var result = '';
  var diffValue = now - dateTimeStamp;//时间差

  if (diffValue < 0) {
    return;
  }
  var minC = diffValue / minute;  //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour;
  var dayC = diffValue / day;
  var weekC = diffValue / week;
  var monthC = diffValue / month;
  if (monthC >= 1 && monthC <= 3) {
    result = " " + parseInt(monthC) + "月前"
  } else if (weekC >= 1 && weekC < 4) {
    result = " " + parseInt(weekC) + "周前"
  } else if (dayC >= 1 && dayC < 7) {
    result = " " + parseInt(dayC) + "天前"
  } else if (hourC >= 1 && hourC < 24) {
    result = " " + parseInt(hourC) + "小时前"
  } else if (minC >= 1 && minC < 60) {
    result = " " + parseInt(minC) + "分钟前"
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚"
  } else {
    var datetime = new Date();
    datetime.setTime(dateTimeStamp);
    var Nyear = datetime.getFullYear();
    var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
    var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
    result = Nyear + "-" + Nmonth + "-" + Ndate
  }
  return result;
}

/**
 * 获取地址栏参数
 * @param {*} name   //参数名称  
 */
export const getUrlParms = (name) => {
  var reg = new RegExp("(^|&?)" + name + "=([^&]*)(&|$)");
  var r = window.location.href.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

/**
 * 判断是否是图片
 * @param {*} type 
 */
export const isImg = (type) => {
  const isJPG = type === 'image/jpeg';
  const isPNG = type === 'image/png';
  const isBMP = type === 'image/bmp';
  const isGIF = type === 'image/gif';
  const isWEBP = type === 'image/webp';
  const isPic = isJPG || isPNG || isBMP || isGIF || isWEBP;
  return isPic
}

/**
 * 保留两位小数
 * @param {*} type  需要转换的数字
 */
export const getMumber = (numbers) => {
  return Math.floor(numbers * 100) / 100
}
