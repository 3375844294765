<template>
    <div class="laplor calculator">
        <div class="top-money">
            <label>劳动所得金额</label>
            <span>¥</span>
            <input type="tel" pattern="[0-9]*" v-model="laobar.income" @input="change">
        </div>
        <div class="group-con white-bg">
            <div class="group">
                <label>录入月份</label>
                <span ref="month" class="month">{{laobar.monthNum}}月</span>
            </div>
            <div class="group no-right-bg">
                <label>个人所得税</label>
                ￥{{laobar.tax}}
            </div>
        </div>
        <div class="info white-bg">
            <div class="chart-con" :class="{noheight:laobar.tax==0}">
                <div class="text">
                    <label>年度税后收入</label>
                    ¥{{(laobar.income - laobar.tax).toFixed(2)}}
                </div>
                <div class="chart" id="chart"></div>
            </div>

            <div class="show-table">
                <table>
                    <thead>
                        <tr>
                            <th width="40%">劳务收入</th>
                            <th>应纳税所得额</th>
                            <th>税率(月度)</th>
                            <th>速算扣除数</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tableTax" :key="index">
                            <td>{{item.low==0?'不超过'+item.hign:'超过'+item.low+'元'+(item.hign!=Infinity?'至'+item.hign+'元的部分':'')}}</td>
                            <td>{{item.basicNum>1?'劳动收入-'+item.basicNum:'劳动收入*'+item.basicNum*100+'%'}}</td>
                            <td>{{item.tax*100}}%</td>
                            <td>{{item.deduNum}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <dl class="description-text">
                <dt>扣除说明</dt>
                <dd>
                    劳务收入按月统计不累计，每月单独计算
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>
import "@/sass/views/calculator/main.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            laobar: {
                income: '',
                monthNum: 1,
                tax: 0.00
            },
            tableTax: [{
                low: 0,
                hign: 4000,
                basicNum: 800,
                tax: 0.2,
                deduNum: 0
            }, {
                low: 4000,
                hign: 20000,
                basicNum: 0.8,
                tax: 0.2,
                deduNum: 0
            }, {
                low: 20000,
                hign: 50000,
                basicNum: 0.8,
                tax: 0.3,
                deduNum: 2000
            }, {
                low: 50000,
                hign: Infinity,
                basicNum: 0.8,
                tax: 0.4,
                deduNum: 7000
            }],
            monthList: [],
            workList: [{ incomeList: [] }],
            month: this.$route.query.month ? this.$route.query.month : '',
        }
    },
    created() {
        for (var i = 1; i < 13; i++) {
            this.monthList.push({ id: i, value: i + '月' })
        }
    },
    mounted() {
        if (Vue.getData('workList')) {
            this.workList = Vue.getData('workList');
            this.workList.forEach(ele => {
                let arr = ele.incomeList.filter(item => { return item.month == this.month && item.type == 2 });
                if (arr.length > 0) {
                    this.laobar = {
                        income: arr[0].income,
                        monthNum: arr[0].month,
                        tax: arr[0].tax
                    }
                } else {
                    let arr1 = {
                        type: 2, //1工资，2劳务，3年终奖
                        month: 1,
                        income: '',
                        security: 0,
                        fund: 0,
                        tax: 0
                    }
                    ele.incomeList.splice(1, 0, arr1);
                }
            });
        } else {
            for (let i = 0; i < 12; i++) {
                this.workList[0].incomeList.push({
                    type: 1, //1工资，2劳务，3年终奖
                    month: i + 1,
                    income: this.income,
                    security: 0,
                    fund: 0,
                    tax: 0
                })
                if (i == 0) {
                    this.workList[0].incomeList.push({
                        type: 2, //1工资，2劳务，3年终奖
                        month: 1,
                        income: '',
                        security: 0,
                        fund: 0,
                        tax: 0.00
                    })
                }
            }
        }
        this.drawpie();
        this.selectMonth();
    },
    methods: {
        change() {
            if (!!this.laobar.income && this.laobar.income > 0) {
                this.tableTax.some(ele => {
                    if (this.laobar.income > ele.low && this.laobar.income <= ele.hign) {
                        this.laobar.tax = (ele.basicNum > 1 ? (this.laobar.income - ele.basicNum > 0 ? this.laobar.income - ele.basicNum : 0) * ele.tax - ele.deduNum : this.laobar.income * ele.basicNum * ele.tax - ele.deduNum).toFixed(2);
                        return true
                    }
                });
                this.drawpie();
                this.workList.forEach(ele => {
                    let arr = ele.incomeList.filter(item => { return item.month == this.laobar.monthNum && item.type == 2 });
                    if (arr.length > 0) {
                        ele.incomeList.some((item) => {
                            if (item.month == this.laobar.monthNum && item.type == 2) {
                                item.income = this.laobar.income;
                                item.tax = this.laobar.tax;
                                return true
                            }
                        });
                    } else {
                        ele.incomeList.some((item, index) => {
                            if (item.month == this.laobar.monthNum && item.type == 1) {
                                let arr2 = {
                                    income: this.laobar.income,
                                    month: this.laobar.monthNum,
                                    tax:  this.laobar.tax,
                                    type: 2,
                                    security: 0,
                                    fund: 0,
                                }
                                ele.incomeList.splice(index + 1, 0, arr2);
                                return true
                            }
                        });
                    }

                });
            } else {
                // this.$alert('请输入正确的劳务所得！');
                this.workList.forEach(ele => {
                    ele.incomeList.some((item, index) => {
                        if (item.month == this.laobar.monthNum && item.type == 2) {
                            ele.incomeList.splice(index, 1)
                            return true
                        }
                    });
                });
            }
            Vue.setData('workList', this.workList)
        },
        selectMonth() {
            new MobileSelect({
                trigger: this.$refs.month,
                title: "开始扣除月份",
                wheels: [{ data: this.monthList }],
                callback: (indexArr, data) => {
                    this.workList.forEach(ele => {
                        let arr = ele.incomeList.filter(item => { return item.type == 2 && item.month == data[0].id })
                        if (arr.length > 0) {
                            this.laobar = {
                                income: arr[0].income,
                                monthNum: data[0].id,
                                tax: arr[0].tax
                            }
                        } else {
                            this.laobar = {
                                income: '',
                                tax: 0.00,
                                monthNum: data[0].id
                            }
                        }
                    });

                }
            });

        },
        drawpie() {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById("chart"));
            // 绘制图表
            myChart.setOption({
                color: ["#4884ff", '#44dd7a', '#d973ff', '#ff7a7a', '#ffb849'],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow"
                    }
                },
                grid: {
                    //图表的位置
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    containLabel: true
                },
                legend: {
                    orient: 'vertical',
                    top: '60%',
                    left: '50%',
                    right: 0,
                    bottom: 0,
                    data: ['税后劳务所得', '个人所得税']
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['60%', '90%'],
                        center: ['20%', '50%'],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        itemStyle: {
                            borderWidth: 3,
                            borderColor: '#fdfeff'
                        },
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '16',
                                formatter: '{c}%'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: (this.laobar.tax / this.laobar.income * 100).toFixed(2), name: '个人所得税' },
                            { value: ((this.laobar.income - this.laobar.tax) / this.laobar.income * 100).toFixed(2), name: '税后劳务所得' }
                        ]
                    }
                ]
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.calculator .group-con .group span.month {
    padding-left: 30px;
    display: inline-block;
}
.laplor {
    background: #fff;
    min-height: 100vh;
    .top-money {
        background: #2da07e;
    }
    .info {
        padding: 30px;
        border-top: 20px solid #f5f5f5;
        position: relative;

        .show-table table th {
            background: #2da07e;
        }
        .chart-con.noheight {
            height: 0;
            overflow: hidden;
            opacity: 0;
        }
        .text {
            position: absolute;
            left: 50%;
            top: 30px;
            font-size: 46px;
            color: #4884ff;
            label {
                color: #333;
                font-size: 26px;
                display: block;
                margin-bottom: 20px;
            }
        }
    }
}
</style>