<template>
    <div class="income-detail">
        <dl class="work" v-for="(value,key) in workList" :key="key">
            <dt>工作{{chinaNum[key]}} <small>(左滑可置空或删除)</small></dt>
            <dd v-for="(item,index) in value.incomeList" data-type="0" class="list-item" :key="index">
                <div class="main" @touchstart.capture="touchStart" @touchend.capture="touchEnd" @touchmove.capture="touchMove" :class="{laobor:item.type==2,bonus:item.type==3}">
                    <div class="month">
                        <label>{{item.month}}月</label>
                        <button @click="edit(item)">修改</button>
                    </div>
                    <div class="income">
                        <div class="cur">
                            <label>{{item.type==1?'工资税前收入':(item.type==2?'劳务所得税前收入':'年终奖税前收入')}}</label>¥{{item.income}}
                        </div>
                        <div class="sum">
                            <label>累计税前收入</label>¥{{countSum(key,index)}}
                        </div>
                    </div>
                </div>
                <div class="del" @click="emptyItem(key,index)" v-if="item.type==1">
                    <img src="@/assets/views/calculator/empty.png" alt=""><br>
                    置空
                </div>
                <div class="del" @click="deleteItem(key,index)" v-else>
                    <img src="@/assets/views/calculator/del.png" alt=""><br>
                    删除
                </div>
            </dd>
        </dl>
        <div class="foot-type">
            <button @click="changeShow=true">换工作</button>
            <button @click="$router.forward('/calculator/yearEnd')">+年终奖</button>
            <button @click="$router.forward('/calculator/laobor')">+劳务所得</button>
        </div>
        <!-- 换工作弹窗 -->
        <div class="poput" v-if="changeShow">
            <div class="main">
                <div class="content">
                    <div class="add" @click="addNewWork"><img src="@/assets/views/calculator/add.png">添加新工作记录</div>
                    <div class="del">清除所有换工作记录</div>
                </div>
                <div class="btn" @click="changeShow=false">
                    取消
                </div>
            </div>
        </div>
        <!-- 添加新工作弹窗 -->
        <div class="poput" v-if="changeShow">
            <div class="main">
                <div class="content" v-if="btnShow">
                    <div class="add" @click="addNewWork"><img src="@/assets/views/calculator/add.png">添加新工作记录</div>
                    <div class="del">清除所有换工作记录</div>
                </div>
                <div class="content" v-if="selectMonthShow">
                    <div class="add">请选择新工作月份</div>
                    <div class="month-list clearfix">
                        <span v-for="item in 12" :key="item" @click="addWork(item)">{{item}}月</span>
                    </div>
                </div>
                <div class="btn" @click="changeShow=false">
                    取消
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            calculatorInfo: JSON.parse(localStorage.getItem('calculator')),
            workList: [
                {
                    incomeList: []
                }
            ],

            changeShow: false,
            btnShow: true,
            selectMonthShow: false,
            income: Vue.getData('income') ? Vue.getData('income') : 5000,
            startX: false,
            ddIndex: 0,
            endX: 0,
            chinaNum: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二']
        }
    },
    watch:{
        'workList':{
            handler(newVal) {
                Vue.setData('yearIncome', this.countSum(newVal.length-1,newVal[newVal.length-1].incomeList.length-1))
                Vue.setData('workList', newVal)
            },
            deep:true
        }
    },
    mounted() {
        if (this.calculatorInfo.workList) {
            this.workList = this.calculatorInfo.workList;
        } else {
            for (let i = 0; i < 12; i++) {
                this.workList[0].incomeList.push({
                    type: 1, //1工资，2劳务，3年终奖
                    month: i + 1,
                    income: this.income
                })
            }
        }
    },
    methods: {
        edit(item){
            if(item.type==1){
                this.$router.forward({
                    path:'/calculator/incomeTax/month',
                    query:{month:item.month}
                })
            }else if(item.type==3){
                this.$router.forward({
                    path:'/calculator/yearEnd',
                    query:{month:item.month}
                })
            }else if(item.type==2){
                this.$router.forward({
                    path:'/calculator/laobor',
                    query:{month:item.month}
                })
            }
        },
        addWork(num){
            this.workList.forEach((ele,key) => {
                ele.incomeList.forEach((item,index) => {
                    if(item.month==num){
                        console.log(11,key,index)
                        let arr=JSON.parse(JSON.stringify(ele.incomeList));
                        ele.incomeList.splice(index, ele.incomeList.length-index+1);
                        arr.splice(0,index);
                        this.workList.splice(key+1,0,{incomeList:arr})
                        return
                    }
                });
            });
            this.changeShow=false
        },
        countSum(key, index) {
            let sum = 0;
            for (let i = 0; i <= index; i++) {
                sum += Number(this.workList[key].incomeList[i].income);
            }
            return sum;
        },
        addNewWork() {
            this.btnShow = false;
            this.selectMonthShow = true;
        },
        touchStart: function (e) {
            // 记录初始位置
            this.startX = e.touches[0].clientX;
        },
        // touchMove(e) {

        // },
        //滑动结束
        touchEnd: function (e) {
            // 当前滑动的父级元素
            let parentElement = e.currentTarget.parentElement;
            // 记录结束位置
            this.endX = e.changedTouches[0].clientX;
            // 左滑
            if (parentElement.dataset.type == 0 && this.startX - this.endX > 30) {
                this.restSlide();
                parentElement.dataset.type = 1;
            }
            // 右滑
            if (parentElement.dataset.type == 1 && this.startX - this.endX < -30) {
                this.restSlide();
                parentElement.dataset.type = 0;
            }
            this.startX = 0;
            this.endX = 0;
        },
        //判断当前是否有滑块处于滑动状态
        checkSlide: function () {
            let listItems = document.querySelectorAll('.list-item');
            for (let i = 0; i < listItems.length; i++) {
                if (listItems[i].dataset.type == 1) {
                    return true;
                }
            }
            return false;
        },
        //复位滑动状态
        restSlide: function () {
            let listItems = document.querySelectorAll('.list-item');
            console.log(listItems)
            // 复位
            for (let i = 0; i < listItems.length; i++) {
                listItems[i].dataset.type = 0;
            }
        },
        emptyItem(key,index) {
            this.restSlide();
            this.workList[key].incomeList[index].income = 0;
        },
        //删除
        deleteItem(key,index) {
            // 复位
            this.restSlide();
            // 删除
             this.workList[key].incomeList.splice(index, 1);
        }
    }
}
</script>
<style lang="scss" scoped>
.income-detail {
    padding-bottom: 80px;
    .poput {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(#000, 0.5);
        z-index: 100;
        .main {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            padding: 30px;
            box-sizing: border-box;
            .content {
                background: #fff;
                border-radius: 16px;
                padding: 0 30px;
                margin-bottom: 30px;
                .add {
                    border-bottom: #eeeeee 1px solid;
                    color: #333;
                    line-height: 100px;
                    text-align: center;
                    font-size: 28px;
                    img {
                        width: 24px;
                        margin-right: 14px;
                        vertical-align: middle;
                    }
                }
                .del {
                    color: #ff5151;
                    line-height: 100px;
                    text-align: center;
                    font-size: 28px;
                }
                .month-list{
                    margin: 0 -15px;
                    padding: 25px 0;
                    span{
                        width: 135px;
                        height: 60px;
                        margin: 15px;
                        color: #999;
                        border-radius: 8px;
                        border: #ccc 1px solid;
                        text-align: center;
                        line-height: 60px;
                        font-size: 28px;
                        display: inline-block;
                        box-sizing: border-box;
                    }
                }
            }
            .btn {
                background: #fff;
                border-radius: 16px;
                text-align: center;
                line-height: 100px;
                color: #333;
            }
        }
    }
    .work {
        padding: 6px 30px;
        overflow: hidden;
        dt {
            font-size: 26px;
            color: #333;
            line-height: 90px;
            small{
                opacity: 0.7;
            }
        }
        dd {
            margin-bottom: 30px;
            position: relative;
            height: 180px;
            transition: all 0.2s;
            &.list-item[data-type="0"] {
                transform: translate3d(0, 0, 0);
                overflow: hidden;
            }

            &.list-item[data-type="1"] {
                transform: translate3d(-100px, 0, 0);
            }

            .del {
                position: absolute;
                right: -100px;
                width: 100px;
                height: 100%;
                font-size: 24px;
                padding-top: 30px;
                text-align: center;
                color: #333;
                img {
                    width: 44px;
                    margin-bottom: 20px;
                }
            }
            .main {
                background: #3e5379
                    url("../../../assets/views/calculator/work.png") right
                    bottom no-repeat;
                background-size: 149px auto;
                border-radius: 12px;
                padding: 0 40px 0 30px;
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 70px);
                z-index: 2;
                &.laobor {
                    background: #2da07e
                        url("../../../assets/views/calculator/laobor.png") right
                        bottom no-repeat;
                    background-size: 149px auto;
                }
                &.bonus {
                    background: #e78585
                        url("../../../assets/views/calculator/bonus.png") right
                        bottom no-repeat;
                    background-size: 115px auto;
                }
                .month {
                    width: 160px;
                    padding: 44px 0 0 0;
                    text-align: center;
                    margin-right: 20px;
                    display: inline-block;
                    label {
                        display: block;
                        font-size: 40px;
                        line-height: 40px;
                        margin-bottom: 18px;
                    }
                    button {
                        width: 100px;
                        height: 40px;
                        border: #fff 1px solid;
                        border-radius: 10px;
                        background: none;
                        outline: none;
                        font-size: 22px;
                        color: #fff;
                    }
                }
                .income {
                    width: calc(100% - 180px);
                    display: inline-block;
                    vertical-align: top;
                    > div {
                        line-height: 90px;
                        text-align: right;
                        font-size: 30px;
                        &.cur {
                            border-bottom: 1px solid rgba(#fff, 0.3);
                        }
                        label {
                            font-size: 28px;
                            opacity: 0.8;
                            float: left;
                        }
                    }
                }
            }
        }
    }
    .foot-type {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 80px;
        background: #fff;
        padding: 20px 7.5px 0 7.5px;
        z-index: 10;
        button {
            margin: 0 22.5px;
            float: left;
            width: 200px;
            height: 60px;
            color: #fff;
            background-color: #3e5379;
            outline: none;
            font-size: 24px;
            border-radius: 30px;
            border: none;
            &:nth-child(2) {
                background-color: #e78585;
            }
            &:nth-child(3) {
                background-color: #2da07e;
            }
        }
    }
}
</style>