<template>
    <div class="illness calculator">
        <div class="top-money">
            <button @click="clearData">清除数据</button>
            <label>本人年度扣除金额</label>
            <span>¥</span>
            <input type="number" v-model="illness.total" readonly>
        </div>
        <div class="group-con white-bg">
            <div class="group">
                <label>未成年子女</label>
                <div class="change-num">
                    <span class="reduce" @click="reduce"></span>
                    <input type="number" v-model="illness.listNum" class="number" readonly>
                    <span class="add" @click="add"></span>
                </div>
            </div>
        </div>
        <div class="form-con border-top">
            <div class="tab-con">
                <div class="tab">
                    <span :class="{on:tabIndex==-2}" @click="tabIndex=-2">本人</span>
                    <span :class="{on:tabIndex==-1}" @click="tabIndex=-1">配偶</span>
                    <span :class="{on:tabIndex==index}" v-for="(item,index) in illness.listNum" :key="index" @click="changeTab(index)">未成年子女{{index+1}}</span>
                </div>
            </div>
            <div v-if="tabIndex==index-2">
                <div class="form-group" v-for="(item,index) in illness.list" :key="index">
                    <label>扣除人</label>
                    <div class="group-right">
                        <div class="radio-con">
                            <v-radio v-model="item.deducter" value="1"></v-radio><span>本人扣除</span>
                        </div>
                        <div class="radio-con">
                            <v-radio v-model="item.deducter" value="2"></v-radio><span>配偶扣除</span>
                        </div>
                    </div>
                    <div class="input-con">
                        <label>请输入年度自付医疗金额</label>
                        <span>¥</span>
                        <input type="number" v-model="item.money">
                    </div>
                </div>

            </div>
        </div>
        <div class="description">
            <div class="title"><span>大病医疗抵扣说明</span></div>
            <dl class="description-text">
                <dt>扣除范围</dt>
                <dd>
                    在一个纳税年度内，纳税人发生的与基本医保相关的医药费用支出，扣除医保报销后个人负担（指医保目录范围内的自付部分）累计超过15000元的部分，由纳税人在办理年度汇算清缴时，在80000元限额内据实扣除
                </dd>
            </dl>
            <dl class="description-text">
                <dt>扣除方式</dt>
                <dd>
                    每年汇算清缴
                </dd>
            </dl>
            <dl class="description-text">
                <dt>适用范围</dt>
                <dd>
                    <ul class="list">
                        <li>纳税人发生的医药费用支出可以选择由本人或者其配偶扣除；未成年子女发生的医药费用支出可以选择由其父母一方扣除</li>
                        <li>在扣除限额上，个人全年医疗收费电子票据中“个人自付”列示金额超过15000元的部分，可以进行扣除，纳税人的配偶、未成年子女选择在纳税人一方进行扣除的，每人分别扣除15000元后，总扣除限额为80000元</li>
                        <li>商业医疗保险不影响扣除</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>证明材料</dt>
                <dd>
                    个人申报+政府相关数据，税务部门核实
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            illness: {
                total: 0,
                listNum: 0,
                list: [{
                    deducter: '',
                    money: '0.00'
                }, {
                    deducter: '',
                    money: '0.00'
                }]
            },
            tabIndex: -2
        }
    },
    watch: {
        "illness": {
            handler() {
                let sum = 0;
                this.illness.list.forEach(ele => {
                    if (ele.deducter == 1) {
                        if (ele.money > 15000) {
                            sum += ele.money - 15000
                        }
                    }
                });
                this.illness.total = sum > 80000 ? 80000 : sum;
                Vue.setData('special.illness', this.illness);
            },
            deep: true
        }
    },
    mounted() {
        if (!!Vue.getData('special') && !!Vue.getData('special.illness')) {
            this.illness = Vue.getData('special.illness');
        }
    },
    methods: {
        clearData() {
            this.illness = {
                total: 0,
                listNum: 1,
                list: [{
                    deducter: '',
                    money: ''
                }, {
                    deducter: '',
                    money: ''
                }, {
                    deducter: '',
                    money: ''
                }]
            }
        },
        changeTab(index) {
            this.tabIndex = index;
        },
        reduce() {
            this.illness.listNum > 0 ? this.illness.listNum-- : this.illness.listNum = 0;
            if (this.tabIndex > this.illness.listNum - 1) {
                this.tabIndex = this.illness.listNum - 1;
            }
            this.illness.list.length = this.illness.listNum + 2;
        },
        add() {
            this.illness.listNum < 3 ? this.illness.listNum++ : this.illness.listNum = 3;
            this.illness.list.push({
                deducter: '',
                money: '0.00'
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.calculator {
    background: #fff;
    min-height: 100vh;
}
.top-money {
    background-image: linear-gradient(to right, #249bff, #2c9eff);
}
.tab-con {
    width: 100%;
    padding: 0 30px;
    overflow-x: auto;
    .tab {
        padding: 0;
        white-space: nowrap;
    }
}
.calculator .form-con .tab span {
    margin-right: 60px;
}
</style>