<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>留学归国，学历需尽快认证 。</p>
                <span>补贴适用范围：</span>
                <p>
                    1、国外留学回国取得相应证书。
                </p>
                <p>
                    2、中外合作办学项目/机构，但在境外学习时间超过6个月(180天)。
                </p>
                <p>
                    3、港澳台地区文凭证书。
                </p>

            </div>
        </div>

    </div>
</template>
<script>
     import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

