<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>一次性创业岗位开发补贴（济南，青岛） 200元/人*人数 </p>
                <span>为提升创业带动就业，鼓励企业招用失业人员，大中专毕业生等，现企业招用符合条件的人员即给予一次性创业岗位开发补贴每个岗位2000元。
档管家，为您提供最有效的申请方式，让您最大化享受福利政策。
</span>

            </div>
        </div>

    </div>
</template>
<script>
   import "@/sass/views/creatorder/business_introduce.scss";
    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

