<template>
  <div class="children calculator">
    <div class="top-money">
      <button @click="clearData">清除数据</button>
      <label>本人年度扣除金额</label>
      <span>¥</span>
      <input type="number" v-model="loan.total" readonly>
    </div>
    <div class="form-con">
      <div class="form-group">
        <label>扣除人</label>
        <div class="group-right">
          <div class="radio-con">
            <v-radio v-model="loan.deducter" value="1"></v-radio><span>本人100%</span>
          </div>
          <div class="radio-con">
            <v-radio v-model="loan.deducter" value="2"></v-radio><span>配偶100%</span>
          </div>
          <!-- <div class="radio-con">
                        <v-radio v-model="loan.deducter" value="3"></v-radio><span>夫妻各50%</span>
                    </div> -->
        </div>
      </div>
      <div class="form-group">
        <label>扣除方式</label>
        <div class="group-right">
          <div class="radio-con">
            <v-radio v-model="loan.deductType" value="1"></v-radio><span>每月</span>
          </div>
          <div class="radio-con">
            <v-radio v-model="loan.deductType" value="2"></v-radio><span>每年</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="right-select" ref="month">
          {{loan.monthNum}}月
        </div>
        <label>开始扣除月份</label>
      </div>
    </div>
    <div class="description">
      <div class="title"><span>住房贷款利息抵扣说明</span></div>
      <dl class="description-text">
        <dt>扣除范围</dt>
        <dd>
          <ul class="list">
            <li>每月1000元/每年12000元</li>
            <li>限其中一方扣除</li>
          </ul>
        </dd>
      </dl>
      <dl class="description-text">
        <dt>适用范围</dt>
        <dd>
          <ul class="list">
            <li>本人或配偶首套住房贷款利息</li>
            <li>贷款合同约定开始还款的当月到贷款全部归还或贷款合同种植的当月；扣除期限最长不得超过240个月</li>
            <li>只限于中国境内住房</li>
          </ul>
        </dd>
      </dl>
      <dl class="description-text">
        <dt>证明材料</dt>
        <dd>
          个人申报+政府相关数据，税务部门核实
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import MobileSelect from "@/components/plug-in/mobile-select";
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
import Vue from 'vue'
export default {
  data() {
    return {
      loan: {
        total: 0,
        deducter: '',
        deductType: '',
        monthNum: 1
      },
      monthList: []
    }
  },
  watch: {
    "loan": {
      handler(newVal) {
        let sum = 0;
        if (newVal.deducter == 1) {
          sum = (12000 / 12 * (13 - newVal.monthNum)).toFixed(2) * 100 / 100;
        } else {
          sum = 0
        }
        this.loan.total = sum;
        Vue.setData('special.loan', this.loan);
      },
      deep: true
    }
  },
  created() {
    for (var i = 1; i < 13; i++) {
      this.monthList.push({ id: i, value: i + '月' })
    }
  },
  mounted() {
    if (!!Vue.getData('special') && !!Vue.getData('special.loan')) {
      this.loan = Vue.getData('special.loan');
    }
    this.selectMonth()
  },
  methods: {
    clearData() {
      this.loan = {
        total: 0,
        deducter: '',
        deductType: '',
        monthNum: 1
      }
    },
    selectMonth() {
      new MobileSelect({
        trigger: this.$refs.month,
        title: "开始扣除月份",
        wheels: [{ data: this.monthList }],
        callback: (indexArr, data) => {
          this.loan.monthNum = data[0].id;
        }
      });

    }
  }

}
</script>
<style lang="scss" scoped>
.calculator {
  background: #fff;
  min-height: 100vh;
}
.top-money {
  background-image: linear-gradient(to right, #2fa0ff, #61b6ff);
}
</style>