<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>线上办理 快捷方便。 </p>
                <span>改派是在学校上报就业方案和主管部门核发报到证后，毕业生正式到用人单位报到前进行单位及地区调整的一种做法，通俗地说，就是指将派到原单位的报到证、户口迁移证和档案等人事关系重新派到新的用人单位或其上级人事主管部门：</span>

            </div>
        </div>

    </div>
</template>
<script>
     import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

