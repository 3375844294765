<template>
  <div class="express-send">
    <div class="ex-add">
      <!-- <div class="nopeole" v-if="!address.shoujianren">添加收件人信息</div> -->
      <div class="add-info">
        <div class="express-copy" @click="copyToClip()">
          <img class="copy-icon" src="@/assets/views/expressmail/copy.png" alt />复制
        </div>
        <div class="fine-icon">收</div>
        <div class="user-info">
          <div class="name">{{address.shoujianren}}</div>
          <div class="tel">{{address.shoujiantel}}</div>
          <div class="add">{{address.add}}</div>
        </div>
      </div>
    </div>
    <div class="ex-detail">
      <div class="item">
        <div class="lable">快递公司</div>
        <img class="right" src="@/assets/right.png" alt />
        <div class="select-style" ref="expressCom">
          <span class="placehold">{{paramInfo.expressname?paramInfo.expressname:'请选择'}}</span>
        </div>
      </div>
      <div class="item">
        <div class="lable">快递单号</div>
        <input
          type="text"
          v-model="paramInfo.expressnum"
          oninput="value=value.replace(/[^\w_]/g,'')"
          placeholder="请输入快递单号"
          class="input-s"
        />
      </div>
      <div class="item">
        <div class="lable">邮寄时间</div>
        <img class="right" src="@/assets/right.png" alt />
        <time-select v-model="paramInfo.posttime"></time-select>
      </div>
    </div>
    <div class="fieldInfo">
      <div class="title">内含项</div>
      <div class="items">
        <div
          class="item"
          :class="{'childwidth':item.id == 10,'active':item.isSelect}"
          @click="changeType(item)"
          v-for="item in fileList"
          :key="item.id"
        >
          <img :src="item.isSelect?item.aimg:item.img" class="int-img" alt />
          {{item.name}}
        </div>
      </div>
      <div class="otherinfo" v-if="showOtherText">
        <input type="text" v-model="paramInfo.otherInfo" placeholder="请输入其他项目名称" class="input-s" />
      </div>
    </div>
    <div class="ex-submit" @click="saveInfo()">确认邮寄</div>
  </div>
</template>
<script>
import "@/sass/views/expressmail/send.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import { mapGetters } from "vuex";

import b1 from "@/assets/views/expressmail/b1.png";
import b2 from "@/assets/views/expressmail/b2.png";
import b3 from "@/assets/views/expressmail/b3.png";
import b4 from "@/assets/views/expressmail/b4.png";
import b5 from "@/assets/views/expressmail/b5.png";
import b6 from "@/assets/views/expressmail/b6.png";
import b7 from "@/assets/views/expressmail/b7.png";
import b8 from "@/assets/views/expressmail/b8.png";
import b9 from "@/assets/views/expressmail/b9.png";
import b10 from "@/assets/views/expressmail/b10.png";
import b11 from "@/assets/views/expressmail/b11.png";
import b1_a from "@/assets/views/expressmail/b1-a.png";
import b2_a from "@/assets/views/expressmail/b2-a.png";
import b3_a from "@/assets/views/expressmail/b3-a.png";
import b4_a from "@/assets/views/expressmail/b4-a.png";
import b5_a from "@/assets/views/expressmail/b5-a.png";
import b6_a from "@/assets/views/expressmail/b6-a.png";
import b7_a from "@/assets/views/expressmail/b7-a.png";
import b8_a from "@/assets/views/expressmail/b8-a.png";
import b9_a from "@/assets/views/expressmail/b9-a.png";
import b10_a from "@/assets/views/expressmail/b10-a.png";
import b11_a from "@/assets/views/expressmail/b11-a.png";

export default {
  name: "Expresssend",
  data() {
    return {
      address: {
        shoujianren: "李青青",
        shoujiantel: "0532-85873655转811或816,18661887826",
        add: "青岛市市南区高雄路海洋大厦5层",
        detail:`收件人:李青青 联系电话:0532-85873655转811或816,18661887826 地址:青岛市市南区高雄路海洋大厦5层`
      },
      paramInfo: {
        userid: "", //用户id
        expressname: "", //快递公司
        expressnum: "", //单号
        type: 1, //类型1用户邮寄2公司邮寄   （用户传1）
        posttime: "", //邮寄时间
        files: [], //文件项目列表
        otherInfo: ""
      },
      fileList: [
        { id: 1, name: "身份证", img: b1, aimg: b1_a, isSelect: false },
        { id: 2, name: "户口本", img: b2, aimg: b2_a, isSelect: false },
        { id: 3, name: "毕业证", img: b3, aimg: b3_a, isSelect: false },
        { id: 4, name: "学位证", img: b4, aimg: b4_a, isSelect: false },
        { id: 5, name: "报到证", img: b5, aimg: b5_a, isSelect: false },
        { id: 6, name: "准迁证", img: b6, aimg: b6_a, isSelect: false },
        { id: 7, name: "结婚证", img: b7, aimg: b7_a, isSelect: false },
        { id: 8, name: "技能证", img: b8, aimg: b8_a, isSelect: false },
        { id: 9, name: "职称证", img: b9, aimg: b9_a, isSelect: false },
        {
          id: 10,
          name: "孩子出生证明",
          img: b10,
          aimg: b10_a,
          isSelect: false
        },
        { id: 11, name: "其他", img: b11, aimg: b11_a, isSelect: false }
      ],
      expressCpmpany: [
        "顺丰",
        "EMS",
        "圆通",
        "申通",
        "中通",
        "韵达",
        "邮政包裹",
        "百世",
        "天天",
        "其他"
      ],
      showOtherText:false,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.paramInfo.userid = this.userInfo.id;
    this.getDetail();
    this.iniSelect();
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    iniSelect() {
      this.natureSelect = new MobileSelect({
        trigger: this.$refs.expressCom,
        title: "快递公司",
        wheels: [{ data: this.expressCpmpany }],
        callback: (indexArr, data) => {
          this.paramInfo.expressname = data[0];
        }
      });
    },
    /**
     * 编辑状态下获取详情
     */
    getDetail() {
      if (this.$route.query.id)
        this.$siaxios.get(`filepost/info/${this.$route.query.id}`).then(res => {
          this.iniField(res);
          this.paramInfo = res;
        });
    },
    iniField(data) {
      data.files.forEach(element1 => {
        this.fileList.forEach(element2 => {
          if (element1.typeid == element2.id) element2.isSelect = true;
        });
        if (element1.typeid == 11) data.otherInfo = element1.filename;
      });
    },
    /**
     * 复制代码
     */
    copyToClip() {
      var aux = document.createElement("input");
      aux.setAttribute("value", this.address.detail);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$tips({
        title: "提示",
        describe: "已复制到剪切板"
      });
    },
    /**
     * 改变内含项状态
     */
    changeType(item) {
      if (item.isSelect) {
        for (let i = 0; i < this.paramInfo.files.length; i++) {
          if (this.paramInfo.files[i].typeid == item.id) {
            this.paramInfo.files.splice(i, 1);
            break;
          }
        }
      } else {
        this.paramInfo.files.push({
          typeid: item.id,
          filename: item.name
        });
      }
      item.isSelect = !item.isSelect;
      if(item.id == 11) {
        this.showOtherText = item.isSelect
      }
    },
    /**
     * 保存信息
     */
    saveInfo() {
      if (this.paramInfo.expressname == "") {
        this.$tips({
          title: "提示",
          describe: "请选择快递公司"
        });
        return;
      }
      if (this.paramInfo.expressnum == "") {
        this.$tips({
          title: "提示",
          describe: "请填写快递单号"
        });
        return;
      }
      if (this.paramInfo.posttime == "") {
        this.$tips({
          title: "提示",
          describe: "请选择邮寄时间"
        });
        return;
      }
      if (this.paramInfo.files.length < 1) {
        this.$tips({
          title: "提示",
          describe: "请至少选择一个内含项"
        });
        return;
      }
      for (let i = 0; i < this.paramInfo.files.length; i++) {
        if (this.paramInfo.files[i].typeid == 11) {
          this.paramInfo.files[i].filename = this.paramInfo.otherInfo;
        }
      }
      let url;
      if (this.paramInfo.id) url = "/filepost/edit";
      else url = "/filepost/send";
      this.$axios.post(url, this.paramInfo).then(res => {
        if (res.data.success) {
          this.$tips({
            title: "提示",
            describe: res.data.errMsg,
            callback: () => {
              this.$router.go(-1);
            }
          });
        } else {
          this.$tips({
            title: "提示",
            describe: res.data.errMsg
          });
        }
      });
    }
  }
};
</script>
