<template>
  <v-refresh ref="getState" :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad">
    <div class="paybackorder-index" style="margin-top:0" v-if="showData.length > 0">
      <div v-for="(item,index) in showData" :key="index">
        <div
          class="orderlist-item"
          @click="$router.forward({path:'/mine/recordDetail',query: { id: item.id ,type:item.type,ywtype:1}})"
        >
          <div class="order-top">
            <div class="order-num">档案{{getStype(item.type)}}</div>
            <div class="order-state">{{getState(item.state)}}</div>
          </div>
          <div class="order-info">
            <div class="price">{{item.sname}}</div>
            <div class="all-price">{{formatTime(item.time)}}</div>
          </div>
        </div>
        <div class="inline"></div>
      </div>
    </div>
    <div v-else>
      <no-data></no-data>
    </div>
  </v-refresh>
</template>
<script>
import "@/sass/views/mine/record.scss";
import { formatTime } from "@/lib/base.js";
import refresh from "@/components/plug-in/pulldown";
import no_data from "../nonepage/norecord";
import { mapGetters } from "vuex";
export default {
  name: "borrowedRecord",
  beforeRouteEnter(to, from, next) {
    if (from.name == "recordDetail" && to.name == "borrowedRecord") {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  data() {
    return {
      param: {
        userid: "", // 用户id
        ywtype: 1, // 类型id
        page: 1 //当前页
      },
      showData: []
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.getList();
  },
  activated() {
    if (!this.$route.meta.isBack) {
      this.showData = [];
      this.getList(); //获取我的业务
    }
  },
  components: {
    "no-data": no_data,
    "v-refresh": refresh
  },
  methods: {
    //获取借阅记录
    async getList() {
      var listData = [];
      listData = this.showData;
      this.param = {
        userid: this.userInfo.id,
        ywtype: this.param.ywtype,
        page: this.param.page
      };
      let tmpList = await this.$siaxios.post(
        "dangan/getRecord",
        this.param,
        false
      );
      if (tmpList.length > 0) {
        tmpList = listData.concat(tmpList);
        this.showData = tmpList;
      } else {
        this.showData = listData;
      }
    },
    //获取档案类型
    getStype(type) {
      if (type == 1) {
        return "借阅";
      } else if (type == 2) {
        return "归还";
      } else if (type == 3) {
        return "提取";
      }
    },
    //获取状态值
    getState(state) {
      if (state == 1) {
        return "待办理";
      } else if (state == 2) {
        return "待邮寄";
      } else if (state == 3) {
        return "完成";
      }
    },
    formatTime: formatTime,
    //下拉刷新
    onRefresh(done) {
      this.showData = [];
      this.param.page = 1;
      this.getList().then(() => {
        done(); // call done
      });
    },
    // 上拉加载
    onInfiniteLoad(done) {
      this.param.page++;
      this.getList().then(res => {
        done(res);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
