<template>
    <div class="school-index" >
        <div class="banner-con">
            <div class="banner">
                <span>外来务工人员子女入小学条件</span>
                <p>（2019年济南历城区为例）</p>
            </div>
        </div>
        <!-- 申请条件 -->
        <div class="title">
            <img src="@/assets/views/mine/jinan7.png" alt/>
            <span class="title-">申请条件</span>
        </div>
        <div class="cont-frist" style="height:185px">
            <div class="con-cont" style="height:30px">
                <div class="con-introduce">
                    <span><span style="color:#00c289">年满6周岁，</span>2013年8月31日出生</span>
                </div>
            </div>
            <div class="con-cont" style="height:52px">
                <div class="con-introduce">
                    <span>监护人双方，<span style="color:#00c289">在历城区长期居住且取得居住证一年以上；在济南合法务工(或依法经营)一年以上</span> </span>
                </div>
            </div>
            <div class="con-cont">
                <div class="con-introduce">
                    <span>随迁子女监护人居住证登记不在历城辖区，或居住证(含申请期)不满一年，或居住证不在有效期的，不具备申请入学条件</span>
                </div>
            </div>
        </div>
        <div class="top-back"></div>
        <!-- 受理流程 -->
        <div class="title">
            <img src="@/assets/views/mine/jinan6.png" alt/>
            <span class="title-">受理流程</span>
        </div>
        <div class='small-title'>
            <span>网上提交资料</span>
        </div>
        <div class="cont-school">
            <div class="con-cont">
                <div class="con-introduce">
                    <span>提交方式</span>
                    <p style="color: #666666">登录历城区政府网站<span style="color:#00c289">(www.licheng.gov.cn),</span>点进"义务教育入学服务平台"，选择"外来务工人员子女信息采集"填写
                    </p>
                </div>
            </div>
            <div class="con-cont">
                <div class="con-introduce" style="margin-top:-10px">
                    <span>时间安排</span>
                    <p style="color: #666666"><span style="color:#00c289">6月17日9:00--6月22日17:00;</span>监护人如有特殊原因无法完成网上填写的，<span
                            style="color:#00c289">6月29日16:30前现场提交</span>(提交地点见后);预期未提交申请者，将无法提供入学服务</p>
                </div>
            </div>
        </div>
        <div class='small-title' style="margin-top:-5px">
            <span>信息核对</span>
        </div>
        <div class="cont-school" style="border:0;height: 500px;">
            <div class="con-cont">
                <div class="con-introduce">
                    <div>
                        <span>核对资料</span>
                    </div>
                    <div>
                        <div style="float: left">
                            <p>1. </p>
                        </div>
                        <div style="float: left">
                             <span>户籍资料</span><span style="color: #666666">：<span style="color:#00c289">原户口簿原件及复印件,</span>(首页、索引页、父母页、儿童页);<span
                                     style="color:#00c289">监护人身份证、居住证原件及复印件</span></span>
                        </div>

                    </div>
                    <div>
                        <p>2. 工作情况：
                        <div style="color: #666666"><span>在济务工人员需提供,<span style="color:#00c289">单位缴纳"五金"单据或个人缴纳社保单据</span></span></div>
                        <div style="color: #666666"><span>经商人员人员需提供带<span style="color:#00c289">二维码的营业执照副本及纳税(或免税)凭证</span></span></div>
                    </div>
                    <div>
                        <div style="float: left">
                            <p>3. </p>
                        </div>
                        <div style="float: left">
                            <span >居住情况： <span style="color: #666666">以居住证为准。<span style="color:#00c289">随迁子女监护人居住证登记属于历城辖区满一年以上</span>(含申请期)，且在有效期</span></span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="con-cont" >
                <div class="con-introduce" style="margin-top:-5px">
                    <span>核对时间</span>
                    <p><span style="color:#00c289">6月29日--6月30日(周六、周日)、上午：8:30--11:00 下午：14:00--16:30</span></p>
                </div>
                <div class="con-introduce" style="margin-top:-5px">
                    <span>核对地点</span>
                    <p style="color: #666666">为方便适龄儿童监护人提交材料，区教育局安排辖区内15所小学提供入学资料收集、核对服务，与随迁子女是否就读该学校无关。届时可由监护人(不带适龄儿童)按照约定的时间(网站预约时间)到以下服务学校提交。</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import "@/sass/views/gallery/school.scss";
    export default {
        name: "index",
        data() {
            return {

            };
        },
    };
</script>
<style lang="scss" scoped>
    .banner-con{
        background: url(../../assets/views/mine/jinan-school.jpg);
        background-size: cover;
        p{
            margin-top:-150px;
            color: #e2e3e6;
        }
    }
    .title{
        img{
            width:35px;
        }
        span{
            margin-left:10px;
        }
    }
    .small-title {
        color: #ff9e2c;
        margin-left: 85px;
        width: 216px;
        font-size: 26px;
        margin-top: 10px;

        &::before {
            position: absolute;
            content: "";
            width: 26px;
            height: 26px;
            background: url("../../assets/views/mine/point.jpg");
            background-size: 100%;
            margin-top: 6px;
            margin-left: -50px;
        }
    }

    .cont-school {
        height: 480px;
        border-left: 1px dashed #ff9e2c;
        width: 678px;
        margin-left: 47px;
        margin-top: -16px;

        .con-cont {
            margin-left: 35px;
            width: 647px;
            .con-introduce {
                padding-top: 30px;

                p {
                    margin-left: -25px;
                }
            }
        }

    }
</style>