<template>
  <div  class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">重要说明 :（必读）</div>
      <div
        class="content"
      >档案是个人身份、学历、工作经历等方面的证据，与个人工资待遇、社会劳动保障、组织关系紧密挂钩，在个人职称申报、办理养老保险、考取公务员以及开具考研等相关证明时，都需要使用档案。</div>
      <div class="content">根据我国现行政策，档案属于国家法定、强制执行、归口管理的公共信息，个人不得截留、持有，必须由具有人事档案管理权的单位保存。</div>
      <div class="content">档管家可为员工及个人提供人事档案的收集，整理，保管，借阅，传递等服务。严格的保密制度、统一的存放管理，为您的个人档案提供安全的存放、管理业务。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">办理流程</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
        <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
        <div class="item" @click="showType = 4" :class="{'active': showType == 4}">温馨提示</div>
      </div>
      <div class="show-content">
        <div class="showtype" style="background: #f6faff;" v-if="showType == 1">
          <div class="order-title">办理流程</div>
          <div class="showinfo-1">
            <div class="showinfo-1-">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-1.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>办理人登记填写信息资料</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-3.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>付款成功</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-4.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>专员跟进</span>
                </div>
              </div>
            </div>
            <div class="showinfo-1-">
              <div class="show-img">
                <img src="@/assets/views/creatorder/arrow2.png" alt />
              </div>
            </div>
            <div class="showinfo-1-" style="margin-top: 40px">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-6.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>完成</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-5.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>存入档案室</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-2.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>调取档案</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showtype" v-if="showType == 2">
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showtype showtype3" v-if="showType == 3">
          <div class="order-title">常见问题</div>
          <div class="showinfo-3">
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>档案现在在我手里，怎么办理存档</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>档案直接邮寄给我方即可</span>
                </div>
              </div>
            </div>
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>档案现在在某人才档案室，怎样转到贵方</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>我方开取调档函， 持身份证，调档函到人才市场办理提取手续，人才机要邮寄我方档案室</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showtype" v-if="showType == 4">
          <div class="order-title">温馨提示</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item" style="height:70px">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>避免因错过截止时间忘记下单，造成社保断交的，建议您一次性购买多个月份，若有新的工作单位，未缴费的月份可申请退款，若申请退费，请于该月份截止时间前联系顾问</span>
              </div>
            </div>
            <div class="showinfo-2-item" style="height:50px">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>本套餐适用于18至法定退休年龄之间的用户购买</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>