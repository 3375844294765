<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>最高补贴2000元/人 </p>
                <span>补贴条件：在读期间通过青岛市职业技能鉴定中心取得相应职业资格证书，2018.12.15文件实施之日起</span>

            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

