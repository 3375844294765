<template>
  <div>
    <div class="graduatefw">
      <div class="Img">
        <img src="@/assets/views/gallery/graduatefw/HEAD.png" alt />
        <div class="Img1">从户口到档案</div>
        <div class="Img2">毕业不用手忙脚乱</div>
        <div class="Img3"></div>
        <div class="Img4"></div>
      </div>
      <ul class="three">
        <li @click="$router.forward({path:'/graduate/trusteeshipService'})">
          <img src="@/assets/views/gallery/graduatefw/head1.png" alt />
          <div>签约托管</div>
        </li>
        <li @click="getPayMoney()">
          <img src="@/assets/views/gallery/graduatefw/head4.png" alt />
          <div>缴费续费</div>
        </li>
        <li @click="goSearchInfo()">
          <img src="@/assets/views/gallery/graduatefw/head3.png" alt />
          <div>信息查询</div>
        </li>
        <li @click="$router.forward({path:'/graduates/serviceGuide'})">
          <img src="@/assets/views/gallery/graduatefw/head2.png" alt />
          <div>办事指南</div>
        </li>
      </ul>
      <div class="cente">
        <img src="@/assets/views/gallery/graduatefw/head5.png" alt />
        <!-- <div>破解毕业迷茫期</div>
        <div>为即将毕业的你明晰方向&nbsp;赢得先机</div>-->
      </div>
      <div class="xbk">
        <div class="xbkfk"></div>
        <div class="xbkcontent">毕业小百科</div>
        <div class="xbkbottom">毕业季那些搞不懂的事，为你逐一分析利弊各个突破</div>
      </div>
      <div class="card">
        <div class="card2" @click="$router.forward({path:'/gallery/small-encyclopedia',query:{id:1}})">
          <div class="card2fk">毕业生</div>
          <div class="card2rs">人事代理</div>
          <div class="card2content"></div>
          <img class="kf" src="@/assets/views/gallery/daguanli/kf.png" alt />
        </div>
        <div class="card3" >
          <ul class="card3Modular1" @click="$router.forward({path:'/gallery/small-encyclopedia',query:{id:2}})">
            <img class="glimg" src="@/assets/views/gallery/graduatefw/xz1.png" alt />
            <li>报到证</li>
          </ul>
          <ul class="card3Modular2" @click="$router.forward({path:'/gallery/small-encyclopedia',query:{id:3}})">
            <img class="glimg" src="@/assets/views/gallery/graduatefw/xz2.png" alt />
            <li>档案</li>
          </ul>
          <ul class="card3Modular3" @click="$router.forward({path:'/gallery/small-encyclopedia',query:{id:4}})">
            <img src="@/assets/views/gallery/graduatefw/xz3.png" alt />
            <li>劳动合同</li>
          </ul>
        </div>
        <div class="card4" @click="$router.forward({path:'/gallery/small-encyclopedia',query:{id:6}})">
          <div class="card4fk"></div>
          <div class="card4content">改派</div>
          <img src="@/assets/views/gallery/graduatefw/xz4.png" alt />
          <div class="card4sx"></div>
        </div>
        <div class="card5" @click="$router.forward({path:'/gallery/small-encyclopedia',query:{id:5}})">
          <div class="card5fk"></div>
          <div class="card5content">三方协议</div>
          <img src="@/assets/views/gallery/graduatefw/xz5.png" alt />
        </div>
        <div
          class="card6"
          @click="$router.forward({path:'/creatorder/accumulationfund',query:{type:1}})"
        >
          <div class="card4fk"></div>
          <div class="card4content">住房公积金</div>
          <img src="@/assets/views/gallery/graduatefw/xz6.png" alt />
          <div class="card4sx"></div>
        </div>
        <div
          class="card7"
          @click="$router.forward({path:'/creatorder/socialsecurity',query:{type:0}})"
        >
          <div class="card5fk"></div>
          <div class="card5content">社保</div>
          <img src="@/assets/views/gallery/graduatefw/xz7.png" alt />
        </div>
      </div>

      <div class="Identification">
        <div></div>
        <div>政策解读</div>
      </div>

      <div class="look">看看毕业季&nbsp;可获得哪些好福利</div>

      <div class="swiper-container swiper-container-initialized swiper-container-horizontal banner">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            :style="right"
            @click="$router.forward({path:'/classify/employment'})"
          >
            <img src="@/assets/views/gallery/graduatefw/banner1.png" alt />

            <div class="ljps">
              <img src="@/assets/views/gallery/graduatefw/ljps1.png" alt />
            </div>
            <div class="policy">就业政策</div>

            <div class="See" @click="$router.forward({path:'/classify/employment'})">查看详情&nbsp;></div>
            <ul class="Newadd">
              <li>住房补贴：最高1500元/月</li>
              <li class="heng"></li>
              <li>安家费：10万&nbsp;15万</li>
            </ul>
            <ul class="Newadd1">
              <li>就业补贴：400元36个月</li>
              <li class="heng"></li>
              <li>就业能力提升补贴等</li>
            </ul>
          </div>
          <div
            class="swiper-slide"
            :style="right"
            @click="$router.forward({path:'/classify/entrepreneurship'})"
          >
            <img src="@/assets/views/gallery/graduatefw/banner2.png" alt />
            <div class="ljps">
              <img src="@/assets/views/gallery/graduatefw/lsps2.png" alt />
            </div>
            <div class="policy">创业政策</div>
            <div class="See" @click="$router.forward({path:'/classify/entrepreneurship'})">查看详情&nbsp;></div>
            <ul class="Newadd">
              <li>一次性创业补贴：1万元</li>
              <li class="heng"></li>
              <li>创业贷款：45万元</li>
            </ul>
            <ul class="Newadd1">
              <li>小微创业补贴：2万元</li>
              <li class="heng"></li>
              <li>其他万元级创业补贴</li>
            </ul>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
          <span
            class="swiper-pagination-bullet"
            tabindex="0"
            role="button"
            aria-label="Go to slide 1"
          ></span>
          <span
            class="swiper-pagination-bullet swiper-pagination-bullet-active"
            tabindex="0"
            role="button"
            aria-label="Go to slide 2"
          ></span>
        </div>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
      </div>

      <div class="interval"></div>

      <div class="xbk1">
        <div class="xbkfk"></div>
        <div class="xbkcontent">档管家</div>
        <div class="xbkbottom">毕业季的好伙伴，与你一起赢得未来</div>
      </div>

      <!-- <div class="shipin"></div> -->

      <div class="daohang">
        <div @click="Transformation1" :class="{'single':changNum == 0}">服务内容</div>
        <div @click="Transformation2" :class="{'single':changNum == 1}">办理流程</div>
        <div @click="Transformation3" :class="{'single':changNum == 2}">企业优势</div>
        <div @click="Transformation4" :class="{'single':changNum == 3}">用户评价</div>
      </div>

      <div
        class="swiper-container swiper-container-initialized swiper-container-horizontal banner2"
      >
        <div class="swiper-wrapper" style="transition-duration:300ms;" ref="shiyan">
          <div class="swiper-slide swiper-slide-active" style="width: 768px;">
            <div class="slidbox">
              <div class="slidtop">
                <img class="slidimg" src="@/assets/views/gallery/graduatefw/sgsm.png" alt />
                <div class="slidcontent">我们提供优质的服务</div>
                <div class="slidheng"></div>
              </div>
              <ul class="zzc1"></ul>
              <ul class="zzc2"></ul>
              <div class="slidbottom cg">
                <div>
                  <span class="span1">1、</span>
                  <span class="span2">毕业生签约、三方协议签订</span>
                </div>
                <div class="adddiv">
                  <span class="span1">2、</span>
                  <span class="span2">档案托管及档案材料的收集、鉴别、整理和保管；</span>
                </div>
                <div class="adddiv">
                  <span class="span1">3、</span>
                  <span class="span2">档案查阅，以档案材料为依据的各类政审、证明材料出具；</span>
                </div>
                <div class="adddiv">
                  <span class="span1">4、</span>
                  <span class="span2">为升学、就业的人事代理毕业生办理档案转递、人事手续调动</span>
                </div>
                <div class="adddiv">
                  <span class="span1">5、</span>
                  <span class="span2">各类证明出具：档案存档证明、政审证明、无业证明、考研工作证明等</span>
                </div>
                <div>
                  <span class="span1">6、</span>
                  <span class="span2">为符合条件的毕业生办理职称报告及评定服务；</span>
                </div>
                <div>
                  <span class="span1">7、</span>
                  <span class="span2">提供人事政策、法律法规咨询</span>
                </div>
                <div>
                  <span class="span1">8、</span>
                  <span class="span2">办理青岛落户手续</span>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="width: 768px;">
            <div class="slidbox">
              <div class="slidtop">
                <img class="slidimg" src="@/assets/views/gallery/graduatefw/sgsm2.png" alt />
                <div class="slidcontent">简单快捷的办事流程</div>
                <div class="slidheng"></div>
              </div>
              <div class="slidbottom2">
                <div class="lc1">
                  <ul class="lc3"></ul>
                  <div class="bz1">1</div>
                  <div class="bz2">2</div>
                  <div class="bz3">3</div>
                  <div class="bz4">4</div>
                  <div class="bz5">5</div>
                  <div class="bz6">6</div>
                  <span class="bzjs1">注册账号</span>
                  <span class="bzjs2">付款成功</span>
                  <span class="bzjs3">填写相关信息</span>
                  <span class="bzjs4">专员跟进办理</span>
                  <span class="bzjs5">网签</span>
                  <span class="bzjs6">毕业后学校邮寄到档案室</span>
                </div>
                <div class="lc2">
                  <div class="bz7">7</div>
                  <div class="bz8">8</div>
                  <span class="bzjs7">档案办理入库手续</span>
                  <span class="bzjs8">完成</span>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="width: 768px;">
            <div class="slidbox">
              <div class="slidtop">
                <img class="slidimg" src="@/assets/views/gallery/graduatefw/sgsm3.png" alt />
                <div class="slidcontent">用心为您服务</div>
                <div class="slidheng"></div>
              </div>
              <div class="slidbottom3">
                <ul class="slidbottom3box">
                  <li class="slidbottom3li1">
                    <img class="li1img" src="@/assets/views/gallery/graduatefw/sgsm4.png" alt />
                  </li>
                  <li class="slidbottom3li2">十余年服务</li>
                  <li class="slidbottom3li3">十年用心服务&nbsp;铸造档管家好口碑</li>
                </ul>
                <ul class="slidbottom3box1">
                  <li class="slidbottom3li1">
                    <img class="li1img" src="@/assets/views/gallery/graduatefw/sgsm5.png" alt />
                  </li>
                  <li class="slidbottom3li2">学长/学姐选择</li>
                  <li class="slidbottom3li3">已服务10万+用户，毕业生的不二之选</li>
                </ul>
                <ul class="slidbottom3box2">
                  <li class="slidbottom3li1">
                    <img class="li1img" src="@/assets/views/gallery/graduatefw/sgsm6.png" alt />
                  </li>
                  <li class="slidbottom3li2">一站式办理</li>
                  <li class="slidbottom3li3">网签、档案、户口一站式办理，无忧服务</li>
                </ul>
                <div class="slidbottom3box3">
                  <li class="slidbottom3li1">
                    <img class="li1img" src="@/assets/views/gallery/graduatefw/sgsm7.png" alt />
                  </li>
                  <li class="slidbottom3li2">快捷方便</li>
                  <li class="slidbottom3li3">没有什么是线上解决不了的，专人对接，全程代办</li>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide swiper-slide-next" style="width: 768px;">
            <div class="slidbox">
              <div class="slidtop">
                <img class="slidimg" src="@/assets/views/gallery/graduatefw/sgsm1.png" alt />
                <div class="slidcontent">用过的都说好，你还等什么？</div>
                <div class="slidheng"></div>
              </div>
              <div class="slidbottom1">
                <div class="slidbottom1box">
                  <div class="slidbottom1tx">
                    <img
                      src="@/assets/views/gallery/graduatefw/tx.png"
                      style="widht:100%;height:100%"
                      alt
                    />
                  </div>
                  <div class="slidbottom1xm">张成龙</div>
                  <div class="slidbottom1dx">青岛大学&nbsp;2017级</div>
                  <div
                    class="slidbottom1xq"
                  >当时毕业之前没找到工作，听同学介绍后找到了档管家，解决了网签和档案问题。后来用到档案的时候，借取都很方便。工作人员服务也很周到、细致，主动提醒一些问题，很认真负责</div>
                </div>
                <div class="slidbottom1box">
                  <div class="slidbottom1tx">
                    <img
                      src="@/assets/views/gallery/graduatefw/tx1.png"
                      style="widht:100%;height:100%"
                      alt
                    />
                  </div>
                  <div class="slidbottom1xm">郭晓倩</div>
                  <div class="slidbottom1dx">青岛科技大学&nbsp;2018级</div>
                  <div
                    class="slidbottom1xq"
                  >我当时毕业的时候单位不能解决落户问题，一直很着急。后来找到档管家，顺利帮我把户口落在了青岛，小姐姐还主动告诉我可以申请青岛的住房补贴，每个月有500元呢，真的是太感谢档管家了。服务好、细致还周到，值得推荐。</div>
                </div>
                <div class="slidbottom1box">
                  <div class="slidbottom1tx">
                    <img
                      src="@/assets/views/gallery/graduatefw/tx2.png"
                      style="widht:100%;height:100%"
                      alt
                    />
                  </div>
                  <div class="slidbottom1xm">王浩翔</div>
                  <div class="slidbottom1dx">青岛大学</div>
                  <div
                    class="slidbottom1xq"
                  >我去年大学毕业后二次考研成功。考研需要政审盖章，我找到了当时托管档案的档管家，很快的帮我出具了证明，盖章，顺利完成了政审。档管家服务很细致，态度好，办事效率也很高。</div>
                </div>
                <div class="slidbottom1box">
                  <div class="slidbottom1tx">
                    <img
                      src="@/assets/views/gallery/graduatefw/tx3.png"
                      style="widht:100%;height:100%"
                      alt
                    />
                  </div>
                  <div class="slidbottom1xm">张玉叶</div>
                  <div class="slidbottom1dx">青岛理工大学</div>
                  <div
                    class="slidbottom1xq"
                  >毕业后出国留学一直都是把档案和户口托管在档管家的。放在那里很放心，有需要的时候打个电话证实一下，也很顺利给予帮忙解决。档管家，很值得推荐的毕业生代理服务单位。</div>
                </div>
                <div class="slidbottom1box">
                  <div class="slidbottom1tx">
                    <img
                      src="@/assets/views/gallery/graduatefw/tx4.png"
                      style="widht:100%;height:100%"
                      alt
                    />
                  </div>
                  <div class="slidbottom1xm">彭晓</div>
                  <div class="slidbottom1dx">中国石油大学</div>
                  <div
                    class="slidbottom1xq"
                  >毕业几年后考了本专业的事业单位，因为要办理人事手续调动，给档管家打了个电话后立马帮忙出具相关证明协助解决。效率很高，工作人员也没有不耐烦，一直都很耐心的提供协助，很感谢档管家。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="false" class="swiper-pagination swiper-pagination-bullets">
          <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
          <span class="swiper-pagination-bullet"></span>
          <span class="swiper-pagination-bullet"></span>
          <span class="swiper-pagination-bullet"></span>
        </div>
      </div>
      <div class="spot">
        <ul class="spotul">
          <li @click="Transformation1" :class="{'addli':changNum == 0}"></li>
          <li @click="Transformation2" :class="{'addli':changNum == 1}"></li>
          <li @click="Transformation3" :class="{'addli':changNum == 2}"></li>
          <li @click="Transformation4" :class="{'addli':changNum == 3}"></li>
        </ul>
      </div>
      <div class="bottomh"></div>
      <div class="Consultation">
        <div class="Consultationfk"></div>
        <div class="ConsultationContent">资讯</div>
        <div class="ConsultationContent1" @click="Jumpinfo">更多资讯</div>
      </div>
      <xzJump :type="type"></xzJump>
      <div class="bottomh2"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "@/sass/views/gallery/graduatefw.scss";
import { mapGetters } from "vuex";
import xzJump from "./xzJump"
export default {
  name: "Graduatefw",
  data() {
    return {
      left: "",
      right: "",
      data: ["服务内容", "办理流程", "企业优势", "用户评价"],
      index: null,
      changindex: 0,
      changindex1: 0,
      changNum: 0,
      a: null,
      isShow: true,
      type:6,
    };
  },
  mounted() {
  },
  components:{
    xzJump,
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  created() {
    this.$nextTick(() => {
      this.a = new Swiper(".banner2", {
        // direction: 'vertical',
        pagination: {
          el: ".swiper-pagination"
        },
        initialSlide: this.changindex,
        on: {
          slideChange: () => {
            this.changNum = this.a.activeIndex;
          }
        }
      });
      new Swiper(".banner", {
        // loop:true,
        slidesPerView: "auto",
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        initialSlide: this.changindex1
      });
      setTimeout(() => {
        this.right = "margin-right:15px";
      }, 100);
    });
  },
  methods: {
    
    Transformation1() {
      this.changNum = 0;
      this.$refs.shiyan.style.transform = "translate3d(0px, 0px, 0px)";
      this.$refs.shiyan.style.transitionDuration = "300ms";
    },
    Transformation2() {
      this.$refs.shiyan.style.transform = "translate3d(-100%, 0px, 0px)";
      this.changNum = 1;
      this.$refs.shiyan.style.transitionDuration = "300ms";
    },
    Transformation3() {
      this.$refs.shiyan.style.transform = "translate3d(-200%, 0px, 0px)";
      this.changNum = 2;
      this.$refs.shiyan.style.transitionDuration = "300ms";
    },
    Transformation4() {
      this.$refs.shiyan.style.transform = "translate3d(-300%, 0px, 0px)";
      this.changNum = 3;
      this.$refs.shiyan.style.transitionDuration = "300ms";
    },
    Jumpinfo(){
      this.$router.forward({path:'/information/list',query:{id:6}});
    },
    goSearchInfo() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return
      }
      if (this.userInfo.renzhengstate != 1) {
        this.$confirm({
          describe: "办理业务请先认证，是否跳转认证页面",
          callback: boolean => {
            if (boolean) this.$router.forward("/mine/authentication");
          }
        });
        return;
      }
      this.$router.forward({path:'/gallery/xxsercive'})
    },
    getPayMoney() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return
      }
      this.getMyBusiness().then(flag => {
        if (flag) {
          this.$router.forward({ path: "/mine/renew" });
        } else {
          this.$confirm({
            title: "提示",
            describe: "您还没有办理业务，快去办理吧",
            callback: boolean => {
              if (boolean)
                this.$router.forward({ path: "/graduate/trusteeshipService" });
            }
          });
          
        }
      });
    },
    // 获取所有业务
    async getMyBusiness() {
      let dangan = false; //未办理
      let hukou = false;
      let data = await this.$siaxios.get(
        "/mybusiness/getBusiness/" + this.userInfo.id,
        false
      );
      // 档案
      if (
        data.sjgjArchives &&
        (data.sjgjArchives.state == 2 ||
          data.sjgjArchives.state == 3 ||
          data.sjgjArchives.state == 4)
      ) {
        dangan = true;
      }
      // 户口
      if (
        data.sjgjRegisteredResidence &&
        (data.sjgjRegisteredResidence.state == 2 ||
          data.sjgjRegisteredResidence.state == 3 ||
          data.sjgjRegisteredResidence.state == 4)
      ) {
        hukou = true;
      }
      return dangan || hukou;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>