<template>
  <div class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">业务介绍：</div>
      <div class="content">
        当参保人的姓名、民族、身份证号码错误，或者户口性质、户口所在地、学历、手机号码等发生变化时，应当及时变更参保基本信息，否则可能对其社保的缴纳、查询以及享受社保的权益带来影响。
      </div>
      <div class="content">档管家可为在本平台代理社保的企业员工提供快捷的参保信息变更业务，包括：手机号码、户口性质、户籍地址、学历以及姓名、身份证号码、民族等基本信息的变更。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
      </div>
      <div class="show-content">
        <div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>