<!--
 * @Description: 
 * @Autor: bjp
 -->
<template>
    <div class="graduateQuestions">
        <v-refresh :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad">
            <ul class="question">
                <li v-for="(item,index) in showData.list" :key="item.id" @click="isShow(item)">
                    <div class="ques">
                        <div class="quesImg">Q{{index + 1}}</div>
                        <span>{{item.title}}</span>
                    </div>
                    <img src="@/assets/views/graduate/arrow.png" alt="" class="arrow">
                </li>
            </ul>
        </v-refresh>
        <div class="popup" ref="answer" v-if="show">
            <div class="answer">
                <div class="title">
                    <span>{{question}}</span>
                    <img src="@/assets/views/graduate/close.png" alt="" class="close" @click="close()">
                </div>
                <div class="answerContent" v-html="answer"></div>
            </div>
        </div>
    </div>
</template>
<script>
import "@/sass/views/graduate/questions.scss"
import refresh from "@/components/plug-in/pulldown";
export default {
    components: {
        "v-refresh": refresh,
    },
    data() {
        return {
            loadingFullscreen: "",
            showData: {
                pageNum: 1,
                list: []
            },
            listLength: '',
            question: '',
            answer: '',
            show:false
        }
    },
    mounted() {
        this.getQuestion()
    },
    methods: {
        async getQuestion() {
            let temp = await this.$siaxios.get('/question/list/' + this.showData.pageNum + "?type=6&state=")
            if (this.showData.pageNum > 1) {
                this.showData.list.push(...temp.list)
            } else {
                this.showData = temp
            }
            this.listLength = temp.list.length
            return temp.list.length
        },
        isShow(item) {
            this.show = true;
            this.question = item.title
            this.answer = item.answer
        },
        close() {
            this.show = false;
        },
        //下拉刷新
        onRefresh(done) {
            this.getQuestion().then(() => {
                done(); // call done
            })
        },
        // 上拉加载
        onInfiniteLoad(done) {
            this.showData.pageNum++;
            this.getQuestion().then(res => {
                done(res);
            });
        }
    }
}
</script>