<template>
  <div class="style-index">
    <div class="banner-con">
      <div class="banner">
        <div class="title-banner">落户青岛更便捷 定居青岛不是梦</div>
      </div>
    </div>
    <div class="main-business">
      <div class="bus-nav">
        <div class="navBarUl" ref="navBarUl">
          <span
            class="item"
            @click="getListByType(item.id)"
            :class="{'active': cttrType == item.id}"
            v-for="item in typedata"
            :key="item.id"
          >{{item.name}}</span>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <template v-if="cttrType == 1">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/need-re.png" alt />
            <span>满足条件</span>
          </div>
          <div class="back use-introduce">
            <div class="use-title">50周岁以下</div>
            <div class="use-content">取得博士学位或硕士学位的人员</div>
          </div>
          <div class="use-introduce back">
            <div class="use-title">45周岁以下</div>
            <div class="use-content">具有全日制本科学历或学士学位的人员</div>
          </div>
          <div class="use-introduce back">
            <div class="use-title">40周岁以下</div>
            <div class="use-content">具有全日制专科学历的人员</div>
          </div>
        </div>
      </div>
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img2" src="@/assets/views/mine/type.jpg" alt />
            <span>办理所需材料</span>
          </div>
          <div class="use-introduce back">
            <div class="schoolnum">1</div>
            <div class="schooltext" style='width:100px;'>学历证明</div>
            <div class="schoolnum" >2</div>
            <div class="schooltext" style='width:100px;'>身份证</div>
          </div>
          <div class="use-introduce back">
            <div class="schoolnum">3</div>
            <div class="schooltext">户口簿</div>
          </div>
          <div class="use-introduce back">
            <div class="schoolnum">4</div>
            <div class="schooltext">合法固定住所凭证或亲属关系证明</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 2">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/need-re.png" alt />
            <span>满足条件</span>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">1.</div>
            <div class="use-content">50周岁以下具有高级技师职业资格(或相应职业技能等级),或在国家级一、二类职业技能竞赛中获奖的人员</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">2.</div>
            <div class="use-content">有接收单位，近3年获山东省一类职业技能竞赛一等奖或青岛市一类职业技能竞赛前10名的人员</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">3.</div>
            <div class="use-content">有接收单位，45周岁以下，具有技师职业资格(或相应职技能等级)的人员</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">4.</div>
            <div
              class="use-content"
            >有接收单位，40周岁以下，取得高级工职业资格(或相应职业技能等级)后且在青岛市按规定连续缴纳社会保险满1年的人员；有接收单位，35周岁以下，取得中级职业资格（或相应职业技能等级）后且在青岛市按规定连续缴纳社会保险满2年的人员。其中，在新区落户取消缴纳社会保险年限限制</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 3">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/need-re.png" alt />
            <span>基本条件</span>
          </div>
          <div class="use-introduce" style="margin:0;padding:0 0 10px 0">
            <span>需同时符合以下四项基本条件</span>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">1.</div>
            <div class="use-content">在本市有合法固定住所</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">2.</div>
            <div class="use-content">持本市有效的山东省居住证满1年</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">3.</div>
            <div class="use-content">在本市就业或创业并正常连续缴纳社会保险满1年</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">4.</div>
            <div class="use-content">无犯罪记录</div>
          </div>
        </div>
      </div>
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/luohujifen.jpg" alt />
            <span>积分条件</span>
          </div>
          <div class="use-introduce2">
            <div class="use-content" style="margin-left:0">
              根据青岛经济社会发展情况建立积分指标体系，对申请人申请落户的条件进行量化评分，当申请人累计积分达到规定分值
              <span
                style="color:#3367ff;margin-left:0;font-weight:normal"
              >100</span>分时，经公示无意义的，获得落户资格
            </div>
          </div>
          <div class="use-introduce2">
            <div
              class="use-content"
              style="margin-left:0"
            >2019年青岛的积分落户工作已由人社部门划转至市公安局，具体申办工作安排以公安部门公布为准</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 4">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/need-re.png" alt />
            <span>满足条件</span>
          </div>
          <div class="use-introduce2">
            <div class="use-title3">城区：市南区、市北区、李沧区、崂山区、城阳区</div>
            <div
              class="use-content"
              style="margin-left:0"
            >在城区购买单套商品住宅建筑面积达到90平方米以上，取得 不动产权证的，本人及其配偶、未婚子女可申请落户（没有 一二手房和有无贷款限制、没有社保要求、没有年龄限制）</div>
          </div>
          <div class="use-introduce2" style="margin-top:10px">
            <div class="use-title3">新区：青岛西海岸新区、红岛经济区、即墨区</div>
            <div
              class="use-content"
              style="margin-left:0"
            >在该区域内购买单套商品住宅建筑面积达到60平方米以上，取得不动产权证的本人及其配偶、未婚子女可在居住地申请落户</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 5">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/need-re.png" alt />
            <span>办理条件</span>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">1.</div>
            <div class="use-content">婴儿父母双方均为本市家庭户的，自愿选择随父或随母登记户口。</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">2.</div>
            <div class="use-content">婴儿父母双方一方为集体户，另一方为家庭户的，应将新生婴儿户口登记在家庭户之中。</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">3.</div>
            <div class="use-content">婴儿父母双方均为集体户，且确无亲友可以投靠又没有合法固定住所的，可按随父或随母原则暂时办理出生登记。</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">4.</div>
            <div class="use-content">高等学校集体户的学生所生子女户口登记，按照人口计生委、教育部、公安部《关于高等学校在校学生计划生育问题的意见》办理。</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">5.</div>
            <div class="use-content">父母一方为现役军人的，婴儿在家庭户一方登记户口;父母双方均为现役军人的，婴儿可以自愿选择随父或随母登记户口。</div>
          </div>
        </div>
      </div>
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img2" src="@/assets/views/mine/type.jpg" alt />
            <span>办理所需材料</span>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">1</div>
            <div class="schooltext">婴儿父母双方的户口簿、身份证、结婚证</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">2</div>
            <div class="schooltext">出生医学证明</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">3</div>
            <div class="schooltext">计划生育服务手册或生育证</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">4</div>
            <div
              class="schooltext"
            >在港、澳、台及国外出生的婴儿，应当提供国外或境外医疗机构出具的出生证明原件、复印件及翻译机构出具的出生证明翻译件,我驻外使领馆签发的婴儿父母与婴儿的《中华人民共和国旅行证》或《护照》原件及复印件</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">5</div>
            <div class="schooltext">拟落户地父母一方系集体户的，应当提供集体户所在单位证明</div>
          </div>
          <div class="use-introduce back">
            <div class="schoolnum">6</div>
            <div class="schooltext">拟落户地父母一方系村民的，应当提供村委会接收证明。</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 6">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/need-re.png" alt />
            <span>办理条件</span>
          </div>
          <div class="use-introduce2">
            <div
              class="use-content"
              style="margin-left:0"
            >本市以外常住户口居民与本市常住户口居民登记结婚，可随时办理投靠落户。其中，被投靠人已办理过夫妻投靠的，再次申请投靠，婚姻存续期须满2年。被投靠人应当具有合法固定住所。</div>
          </div>
        </div>
      </div>
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img2" src="@/assets/views/mine/type.jpg" alt />
            <span>办理所需材料</span>
          </div>
          <div class="use-introduce back" style="padding-bottom:0px;">
            <div class="schoolnum">1</div>
            <div class="schooltext">被投靠人申请</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">2</div>
            <div class="schooltext">合法固定住所凭证</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">3</div>
            <div class="schooltext">被投靠人和投靠人的户口簿</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">4</div>
            <div class="schooltext">被投靠人和投靠人民身份证</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">5</div>
            <div class="schooltext">结婚证</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">6</div>
            <div class="schooltext">随迁人员的亲属关系凭证</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">7</div>
            <div class="schooltext">随迁达到法定婚龄未婚子女的未婚声明</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">8</div>
            <div
              class="schooltext"
            >未成年子女随迁的，应当提供其父母结婚证;亲生父母离婚的，应当提供离婚证和离婚协议，或者已经生效的含有抚养权内容的离婚判决书或者裁决书</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">9</div>
            <div class="schooltext">父母离婚的未成年子女，抚养权人死亡需要与另一方父亲或者母亲办理投靠随迁落户的，除提供上述材料外，还应当提供抚养权人注销户口证明</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">10</div>
            <div class="schooltext">丧偶再婚携带未成年子女投靠再婚配偶的，应当提供结婚证和原配偶注销户口证明</div>
          </div>
          <div class="use-introduce back">
            <div class="schoolnum">11</div>
            <div class="schooltext">被投靠人不是户主、合法固定住所产权人的，应当提供户主和产权人的身份证、产权人的不动产权证及同意落户声明</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 7">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/need-re.png" alt />
            <span>办理条件</span>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">1.</div>
            <div class="use-content">被投靠的父母中一方为青岛家庭户籍，而且有合法固定的住所</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">2.</div>
            <div class="use-content">子女未成年或成年未婚（无婚史）</div>
          </div>
        </div>
      </div>
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img2" src="@/assets/views/mine/type.jpg" alt />
            <span>办理所需材料</span>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">1</div>
            <div class="schooltext">被投靠人申请</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">2</div>
            <div class="schooltext">合法固定住所凭证</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">3</div>
            <div class="schooltext">达到法定婚龄未婚子女的未婚声明</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">4</div>
            <div class="schooltext">被投靠人和投靠人的户口簿</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">5</div>
            <div class="schooltext">被投靠人和投靠人的身份证</div>
          </div>
          <div class="use-introduce back" style="padding-bottom:0">
            <div class="schoolnum">6</div>
            <div class="schooltext">亲属关系凭证</div>
          </div>
          <div class="use-introduce back" >
            <div class="schoolnum">7</div>
            <div class="schooltext">父母现婚姻状况凭证</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 8">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <img class="img1" src="@/assets/views/mine/need-re.png" alt />
            <span>办理条件</span>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">1.</div>
            <div
              class="use-content"
            >本市户籍人口(不含大中专院校学生集体户)在本市城镇范围内有合法固定住所的，本人、配偶、未婚子女、父母可以将户口迁移至合法固定住所地</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">2.</div>
            <div
              class="use-content"
            >夫妻之间投靠、未婚子女投靠父母、父母投靠成年子女、学龄前儿童投靠祖父母或者外祖父母、无合法固定住所的已婚(含离婚和丧偶)成年子女投靠父母(不含大中专院校学生集体户)</div>
          </div>
          <div class="use-introduce2">
            <div class="use-title2">3.</div>
            <div
              class="use-content"
            >夫妻一方服兵役、出国(境)定居或者死亡的，另一方确与配偶父母共同居住生活的，可以投靠配偶父母落户</div>
          </div>
           <div class="use-introduce2">
            <div class="use-title2">4.</div>
            <div
              class="use-content"
            >驻青党政机关、人民团体、企事业单位集体户人员，因工作变动迁往现工作单位集体户或者人才集体户。</div>
          </div>
        </div>
        
      </div>
    </template>
  </div>
</template>
<script>
import "@/sass/views/gallery/hkguanli-style.scss";
export default {
  name: "archives-use",
  data() {
    return {
      typedata: [
        {
          id: 1,
          name: "人才落户"
        },
        {
          id: 2,
          name: "技工落户"
        },
        {
          id: 3,
          name: "积分落户"
        },
        {
          id: 4,
          name: "购房落户"
        },
        {
          id: 5,
          name: "新生儿落户"
        },
        {
          id: 6,
          name: "夫妻投靠"
        },
        {
          id: 7,
          name: "子女投靠"
        },
        {
          id: 8,
          name: "户口平迁"
        }
      ],
      cttrType: ""
    };
  },
  mounted() {
    this.iniBusiness();
    this.getListByType(1);
    this.cttrType = this.$route.query.id;
  },
  methods: {
    /**
     * 初始化业务大类滚动
     */
    /* eslint-disable */
    iniBusiness() {
      this.$nextTick(() => {
        let winWidth = document.documentElement.clientWidth;

        let navBarUl = this.$refs.navBarUl;
        let oLi = navBarUl.getElementsByClassName("item");
        navBarUl.style.width = "3000px";
        var allListWidth = 0;
        for (let i = 0; i < oLi.length; i++) {
          allListWidth += oLi[i].offsetWidth;
        }
        navBarUl.style.width = allListWidth + 180 + "px";
        let startX = 0;
        let cuttrX = 0;
        navBarUl.style.transition = "all .5s";
        navBarUl.addEventListener("touchstart", e => {
          //e就是事件
          startX = e.targetTouches[0].pageX;
          cuttrX = navBarUl.offsetLeft;
        });
        if (this.cttrType >= 5 && this.cttrType != 8) {
          navBarUl.style.marginLeft = "-410" + "px";
        }else if(this.cttrType == 8){
           navBarUl.style.marginLeft = "-440" + "px";
        }
        navBarUl.addEventListener("touchmove", e => {
          let leftpull = winWidth - allListWidth;
          if (navBarUl.style.transition.split(" ")[0] != "none") {
            navBarUl.style.transition = "none";
          }
          //移动的差值
          let tempRutn = e.targetTouches[0].pageX - startX;
          //nav距左位置
          let mlNum = cuttrX + tempRutn;
          if ((mlNum >= 0 && tempRutn > 0) || leftpull > 0) {
            navBarUl.style.marginLeft = "0";
          } else if (tempRutn < 0 && navBarUl.offsetLeft <= leftpull) {
            navBarUl.style.marginLeft = leftpull - 180 + "px";
          } else {
            navBarUl.style.marginLeft = cuttrX + tempRutn + "px";
          }
          navBarUl.addEventListener("touchend", e => {
            //e就是事件
            navBarUl.style.transition = "all .5s";
          });
        });
      });
    },
    getListByType(id) {
      this.cttrType = id;
    }
  }
};
</script>

