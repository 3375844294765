<template>
  <div class="dangan-creatorder">
    <div class="banner-con">
      <div class="banner">
        <span>档案借阅</span>
      </div>
    </div>
    <div class="info-line">
      <div>收件人</div>
      <input
        class="inputc"
        v-model="paramInfo.sjgjOrderDangan.name"
        placeholder="请填写收件人"
        type="text"
      />
    </div>
    <div class="info-line">
      <div>收件电话</div>
      <input
        class="inputc"
        v-model="paramInfo.sjgjOrderDangan.phone"
        placeholder="请填写收件电话"
        type="text"
      />
    </div>
    <div class="info-line">
      <div>邮寄地址</div>
      <input
        class="inputc"
        v-model="paramInfo.sjgjOrderDangan.address"
        placeholder="请填写邮寄地址"
        type="text"
      />
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <span>因职称评审或政审等需要借阅档案的，可办理借阅手续</span>
      </div>
    </div>
    <div class="introduce showmessage" style="padding-top: 0">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
        <span class="intro-title">业务流程</span>
      </div>
      <div class="process">
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/da-dc1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>APP提交借阅资料内容</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/fukuan.jpg" alt />
          </div>
          <div class="procss-text">
            <span>支付押金</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>填写邮寄地址信息</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进办理</span>
          </div>
        </div>
      </div>
      <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>
      </div>
      <div class="process" style="margin-top: 20px">
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu2.jpg" alt />
          </div>
          <div class="procss-text">
            <span>邮寄到指定地址</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/police (3).jpg" alt />
          </div>
          <div class="procss-text">
            <span>查收</span>
          </div>
        </div>
        <div class="arrow" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/shuoming.jpg" alt />
        <span class="intro-title">业务说明</span>
      </div>
      <div class="content">
        <div class="isthone">
          <div class="thone">
            <span>1、</span>
          </div>
          <div class="thone-text">
            <span>借阅前如有欠费请及时缴纳续费。</span>
          </div>
        </div>
        <div class="isthone">
          <div class="thone">
            <span>2、</span>
          </div>
          <div class="thone-text">
            <span>借阅期限为一个月，请在有效期内及时归还，归还后我方将押金予以退还，选择邮寄归还的请将材料邮寄到我方指定地址。</span>
            <p>收件人：李青青</p>
            <div class="isthone">
              <div class="thone" style="width:70px;">
                <span>联系电话：</span>
              </div>
              <div class="thone" style="width:200px;">
                <span>0532-85873655转813或815</span>
                <p>18661887826</p>
              </div>
            </div>
            <p>联系地址：青岛市市南区高雄路18号海洋大厦5层</p>
          </div>
        </div>
        <div class="isthone">
          <div class="thone">
            <span>3、</span>
          </div>
          <div class="thone-text">
            <span>需要我方邮寄的请一定要确认好邮寄的地址，联系人、联系方式、如在邮寄前需要临时更改邮寄地址的请提前与我们的顾问联系。</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/cailiao.jpg" alt />
        <span class="intro-title">所需材料</span>
      </div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">身份证复印件</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">押金</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text" style="width:250px">委托他人另需代办人的身份证及委托证书（附委托书模板）</div>
          </div>
        </div>
        <div class="needs">
          <div class="word">
            <div class="word-icon">
              <img class="title-img" src="@/assets/views/mine/house.png" alt />
            </div>
            <div class="word-text">
              <span>委托书模板.doc</span>
            </div>
            <div class="word-btn" @click="uploadFile()">
              <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
              <button>下载</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="showmessage"></div>
    <div class="order-btn" @click="nextStemp()">支付押金￥{{amount}}</div>
  </div>
</template>
<script>
import "@/sass/views/mine/dangan.scss";
import { mapGetters } from "vuex";
export default {
  name: "backarchive",
  data() {
    return {
      amount: 0,
      paramInfo: {
        userid: "",
        saletypeid: 19,
        remark: "",
        sjgjOrderDangan: {
          name: "",
          phone: "",
          address: ""
        }
      },
      exportApi: ""
    };
  },
  created() {
    this.getPrice();
  },
  mounted() {
    this.paramInfo.userid = this.userInfo.id;
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 获取金额
     */
    getPrice() {
      this.$siaxios.get(`order/getDangAnPrice/1`).then(res => {
        this.amount = res.amount;
      });
    },
    /**
     * 下一步去创建订单
     */
    nextStemp() {
      if (this.paramInfo.sjgjOrderDangan.name == "") {
        this.$tips({ title: "提示", describe: "请填写收件人" });
        return;
      }
      var reg2 = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (this.paramInfo.sjgjOrderDangan.phone == "") {
        this.$tips({ title: "提示", describe: "请填写手机号" });
        return;
      } else if (!reg2.test(this.paramInfo.sjgjOrderDangan.phone)) {
        this.$tips({ describe: "手机号码格式不正确" });
        this.paramInfo.sjgjOrderDangan.phone = "";
        return;
      }
      if (this.paramInfo.sjgjOrderDangan.address == "") {
        this.$tips({ title: "提示", describe: "请填写邮寄地址" });
        return;
      }
      this.$siaxios
        .post(`order/saveDangAnJieYue`, this.paramInfo, false)
        .then(res => {
          this.$tips({
            title: "提示",
            describe: "借阅成功，去支付",
            callback: () => {
              this.$router.push({
                path: "/creatorder/orderdetail",
                query: { id: res.id }
              });
            }
          });
        });
    },
    //委托书模板下载
    uploadFile() {
      let url = this.$axios.defaults.baseURL + "common/downloadFile/1";
      this.$copyText(`${url}`).then(
        () => {
          this.$tips({
            describe: "已成功复制链接，请通过浏览器下载该文档"
          });
        },
        () => {
          this.$tips({
            describe: "复制失败"
          });
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.banner-con {
  background: url("../../assets/views/mine/dangan-banner.jpg") 100% 100%;
  background-size: cover;
}
.introduce {
  .title {
    .title-img {
      width: 4%;
    }
    .intro-title {
      font-size: 28px;
    }
  }
}
</style>

