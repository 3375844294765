<template>
  <div class="express-address">
    <div class="express-from">
      <div class="item">
        <div class="lable">收件人</div>
        <input type="text" v-model="paramInfo.shoujianren" placeholder="请输入收件人姓名" class="input-s" />
      </div>
      <div class="item">
        <div class="lable">收件人电话</div>
        <input type="text" v-model="paramInfo.shoujiantel" placeholder="请输入收件人电话" class="input-s" />
      </div>
      <div class="item">
        <div class="lable">所在地区</div>
        <img class="right" src="@/assets/right.png" alt />
        <div class="select-style" ref="region">{{paramInfo.provinceid?paramInfo.regionname:'请选择'}}</div>
      </div>
      <div class="item">
        <textarea v-model="paramInfo.xiangxidizhi" placeholder="请输入详细地址" class="textarea-s"></textarea>
      </div>
    </div>
    <div class="submit" @click="submit()">提交</div>
  </div>
</template>
<script>
import "@/sass/views/expressmail/address.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import { mapGetters } from "vuex";
export default {
  name: "Expressadd",
  data() {
    return {
      paramInfo: {
        shoujianren: "", //收件人
        shoujiantel: "", //收件人手机号
        provinceid: "", //省id
        cityid: "", //市id
        areaid: "", //区id
        xiangxidizhi: "", //详细地址
        regionname: ""
      },
      regionList: [], //地区容器
      natureSelect: "" //城市选择下拉框
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  watch: {
    "paramInfo.provinceid"(newval) {
      if (newval) this.getProvince(2, newval);
    },
    "paramInfo.cityid"(newval) {
      if (newval) this.getProvince(3, newval);
    }
  },
  mounted() {
    this.getDetail();
    this.getProvince(1);
  },
  methods: {
    getDetail() {
      this.$siaxios
        .get("filepost/addressinfo/" + this.userInfo.id)
        .then(res => {
          if (res.provinceid)
            res.regionname = `${res.provincename} ${res.cityname} ${res.areaname}`;
          if (res.shoujianren == null && this.userInfo.name)
            res.shoujianren = this.userInfo.name;
            if (res.shoujiantel == null && this.userInfo.tel)
            res.shoujiantel = this.userInfo.tel;
          this.paramInfo = res;
        });
    },
    /**
     * 初始下拉框
     */
    iniSelect() {
      this.natureSelect = new MobileSelect({
        trigger: this.$refs.region,
        title: "城市选择",
        wheels: this.regionList,
        callback: (indexArr, data) => {
          this.paramInfo.provinceid = data[0].id;
          this.paramInfo.cityid = data[1].id;
          this.paramInfo.areaid = data[2].id;
        },
        transitionEnd: (indexArr, data) => {
          this.paramInfo.provinceid = data[0].id;
          this.paramInfo.cityid = data[1].id;
          this.paramInfo.areaid = data[2].id;
        }
      });
    },
    /**
     * 保存信息
     */
    submit() {
      if (this.paramInfo.shoujian == "") {
        this.$tips({ describe: "请输入收件人" });
        return;
      }
      var reg2 = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (this.paramInfo.shoujiantel == "") {
        this.$tips({ describe: "请输入手机号" });
        return;
      } else if (!reg2.test(this.paramInfo.shoujiantel)) {
        this.$tips({ describe: "手机号码格式不正确" });
        this.registerData.tel = "";
        return;
      }
      if (this.paramInfo.provinceid == "") {
        this.$tips({ describe: "请选择所在地区" });
        return;
      }
      if (this.paramInfo.xiangxidizhi == "") {
        this.$tips({ describe: "请输入详细地址" });
        return;
      }
      this.$axios.post("/filepost/saveaddress", this.paramInfo).then(res=>{
        if (res.data.success) {
          this.$tips({
            title: "提示",
            describe: res.data.errMsg,
            callback: () => {
              this.$router.go(-1);
            }
          });
        } else {
          this.$tips({
            title: "提示",
            describe: res.data.errMsg
          });
        }
      })
    },
    /**
     * 获取省
     */
    async getProvince(level, id) {
      let provinceList, cityList, district;
      if (level < 2) {
        provinceList = await this.$siaxios.get(`/region/province`);
        this.fomateName(provinceList);
        this.regionList[0] = { data: provinceList };
      }
      if (level < 3) {
        let url;
        if (level == 2) url = `/region/cityOfProvince/${id}/2`;
        else url = `/region/cityOfProvince/${provinceList[0].id}/2`;
        cityList = await this.$siaxios.get(url);
        this.fomateName(cityList);

        if (this.natureSelect) this.natureSelect.updateWheel(1, cityList);
        else this.regionList[1] = { data: cityList };
      }
      if (level < 4) {
        let url;
        if (level == 3) url = `/region/cityOfProvince/${id}/3`;
        else url = `/region/cityOfProvince/${cityList[0].id}/3`;
        district = await this.$siaxios.get(url);
        this.fomateName(district);

        if (this.natureSelect) this.natureSelect.updateWheel(2, district);
        else this.regionList[2] = { data: district };
      }
      if (!this.natureSelect) this.iniSelect();
    },
    /**
     * 格式化名字
     */
    fomateName(list) {
      list.forEach(element => {
        element.value = element.regionName;
      });
    }
  }
};
</script>

