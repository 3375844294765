<template>
  <div>
    <div class="showmessage">
      <div class="order-title">业务介绍：</div>
      <div class="content">失业人员办理失业登记之后才能领取失业保险金，还可以享受免费公共就业服务。简言之，想要领取失业金保险，就必须要办理失业登记手续。</div>
      <div class="content">只需在档管家平台提交资料，就会有专人为您办理失业登记。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">常见问题</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
      </div>
      <div class="show-content">
        <div class="showtype showtype3" v-if="showType == 1">
          <div class="order-title">常见问题</div>
          <div class="showinfo-3">
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>办理失业登记有没有有效期</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>
                    <p>符合享受失业保险金条件的人员，应自解除或终止劳动合同之日起60日办理失业登记；逾期办理失业登记的，本次应享受的失业保险金予以封存</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>