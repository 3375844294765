<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>高校毕业生住房补贴： 300元/次 青岛 </p>
                <span>补贴适用范围：</span>
                <p>
                    1、派遣期内国内普通高校统招全日制研究生。
                </p>
                <p>
                    2、2018年及以后毕业，派遣期内国内普通高校统招全日制本科学历毕业生。
                </p>
                <p>
                    3、2016年以后首次来青就业并取得教育部留学服务中心国（境）外学历学位认证的留学回国研究生。
                </p>
                <p>
                    4、2018年及以后毕业且首次来青就业，并取得教育部留学服务中心国（境）外学历学位认证的留学回国本科学历毕业生。
                </p>
                <span>
                    条件：青岛行政区域内就业，取得全日制研究生、本科学历且获得博士、硕士、学士学位，具有本市户籍的毕业生。
                </span>

            </div>
        </div>

    </div>
</template>
<script>
   import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

