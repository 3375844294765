<template>
    <div class="login-con">
        <div class="logo">
            <img src="@/assets/logo-dgj.png" class="icon" alt />
        </div>
        <div class="from-con">
            <div class="input-con">
                <img class="input-icon" src="@/assets/views/login/acc.png" />
                <input class="in-phone" maxlength="11" type="text" placeholder="手机号码" v-model="param.mobile" />
            </div>
            <div class="input-con">
                <img class="input-icon" src="@/assets/views/login/code.png" />
                <input class="in-phone phone-code-width" onkeyup="this.value=this.value.replace(/\D/g,'')"
                    onafterpaste="this.value=this.value.replace(/\D/g,'')" type="text" maxlength="6" placeholder="短信验证码" v-model="param.code" />
                <button class="sendmsg-btn" @click="getcode()" :disabled="count!=60">{{count==60?'获取验证码':`${count}s`}}</button>
            </div>
        </div>
        <div class="agreement">
            <img @click="agree = !agree" :src="agreeimg" alt />
            <div>
                我已阅读并同意<span @click="$router.forward('/login/agreement')">《用户服务协议》</span><span
                    @click="$router.forward('/login/privacyagreement')">《隐私协议》</span>
            </div>
        </div>
        <div class="submit-info" @click="login()">登录</div>

        <!-- <div class="bottom-info">
      <span class="a-link" @click="$router.forward('/login/register')">立即注册</span>
      <span class="a-link right-info" @click="$router.forward('/login/forget')">忘记密码</span>
    </div> -->
    </div>
</template>
<script>
import "@/sass/views/login/login.scss";
import checked from "@/assets/checked.png";
import nochecked from "@/assets/nochecked.png";
import { mapGetters } from "vuex";
export default {
    name: "login",
    data() {
        return {
            param: {
                mobile: "",
                code: "",
                side: "",
            },
            agree: false,
            count: 60,
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        }),
        agreeimg() {
            return this.agree ? checked : nochecked;
        }
    },
    methods: {
        /**
       * 获取验证码
       */
        getcode() {
            if (this.count != 60) return;
            let reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
            if (this.param.mobile == "") {
                this.$tips({ describe: "请输入手机号" });
            } else if (!reg.test(this.param.mobile)) {
                this.$tips({ describe: "手机号码格式不正确" });
            } else {
                this.count = 60;
                this.timer();
                this.$axios.get("/smscode/" + this.param.mobile).then(() => { });
            }
        },
        timer() {
            this.count--;
            if (this.count > 0) {
                setTimeout(this.timer, 1000);
            } else {
                this.count = 60;
            }
        },
        // 登录
        login() {
            let reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
            if (this.param.mobile == "" || this.param.password == "") {
                this.$tips({ describe: "请输入手机号和验证码" });
                return;
            }
            if (!reg.test(this.param.mobile)) {
                this.$tips({ describe: "手机号码格式不正确" });
                return;
            }
            if (!this.agree) {
                this.$tips({ describe: "请阅读并同意档管家服务协议和隐私协议" });
                return;
            }
            this.param.openid = this.userInfo.openid;
            if (this.userInfo.device) {
                this.param.side = this.userInfo.device.side;
                this.param.token = this.userInfo.device.deviceToken;
            }
            this.$siaxios.post("/login/login", this.param, false).then(res => {
                res.device = this.userInfo.device;
                res.cityid = this.userInfo.cityid;
                res.cityname = this.userInfo.cityname;

                this.$store.commit("setMyUserInfo", res);
                this.$router.forward("/index/index");
            });
        }
    }
};
</script>

