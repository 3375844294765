<template>
  <div class="confirmserviceinfo-wrapper">
    <div class="order-detail-title">缴费信息</div>
    <div class="service-info">
      <div class="info-line">
        <div>
          <span>*</span>缴费开始月份
        </div>
        <div class="select-style">{{orderInfo.sjgjOrderShebaoGongjijin.startmonth}}</div>
        <img class="right" src="@/assets/right.png" alt />
      </div>
      <div class="info-line">
        <div>
          <span>*</span>缴纳月份
        </div>
        <div class="select-style" ref="duration" @click="showMonthSelect()">{{paymentTime}}</div>
        <img class="right" src="@/assets/right.png" alt />
      </div>
      <div class="info-line">
        <div>
          <span>*</span>本城市首次参保
        </div>
        <div class="select-style" ref="firstInsured">是</div>
        <img class="right" src="@/assets/right.png" alt />
      </div>
    </div>
    <div class="order-detail-title">其他信息</div>
    <div class="service-info">
      <div class="info-line">
        <div>
          <span>*</span>联系电话
        </div>
        <div class="select-style">
          <input v-model="this.orderInfo.sjgjOrderShebaoGongjijin.tel" type="text" />
        </div>
      </div>
      <div class="reason-line pb85">
        <div>备注</div>
        <textarea v-model="orderInfo.remark" name id rows="5" placeholder="请输入备注"></textarea>
      </div>
    </div>
    <div class="next" @click="goOrderDetail()">下一步</div>
  </div>
</template>
<script>
import "@/sass/views/creatorder/creatorder.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import { mapGetters } from "vuex";
export default {
  name: "Creatsocialsecurity",
  data() {
    return {
      paymentTime: "",
      maxMonth: 12, //最大支付月份
      firstList: [
        { id: "0", value: "是" },
        { id: "1", value: "否" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      orderInfo: "getOrder",
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.isXujiao();
  },
  methods: {
    /**
     * 初始化select
     */
    async iniSelect() {
      new MobileSelect({
        trigger: this.$refs.firstInsured,
        title: "是否首次参保",
        wheels: [{ data: this.firstList }],
        callback: (indexArr, data) => {
          this.orderInfo.sjgjOrderShebaoGongjijin.whetherfirst = data[0].id;
        }
      });
      this.businessCharge = await this.$siaxios.get(
        `common/getBusinessCharge/${this.orderInfo.sjgjOrderShebaoGongjijin.cityid}/${this.orderInfo.saletypeid}`
      );
      this.businessCharge.forEach(element => {
        element.value = `${element.taocan}个月(￥${element.shoufei}元)`;
      });
      let maxIndex;
      if (this.maxMonth > 12) {
        maxIndex = 3;
      } else if (this.maxMonth > 6) {
        maxIndex = 2;
      } else if (this.maxMonth > 3) {
        maxIndex = 1;
      } else {
        maxIndex = 0;
      }
      //默认选中最后一个
      let lastNode = this.businessCharge[maxIndex];
      this.getOveMonth(lastNode.taocan);
      this.paymentTime = lastNode.value;
    },
    /**
     * 获取最大支付月
     */
    getMaxMonth() {
      this.$siaxios
        .get(
          `/order/getShebaoMaxMonth/${this.orderInfo.sjgjOrderShebaoGongjijin.cityid}`
        )
        .then(res => {
          let datearr = res.split("-");
          let endYear = datearr[0];
          let endMonth = datearr[1];
          let date = new Date();
          let cttrYear = date.getFullYear();
          let cttrMonth = date.getMonth() + 1;
          this.maxMonth = (endYear - cttrYear) * 12 + (endMonth - cttrMonth);
          this.iniSelect();
        });
    },
    /**
     * 显示月份选择器
     */
    showMonthSelect() {
      this.$monthselect({
        businessCharge: this.businessCharge,
        maxMonth: this.maxMonth,
        callback: data => {
          this.getOveMonth(data.overmonth);
          this.paymentTime = data.lable;
          this.$forceUpdate();
        }
      });
    },
    /**
     * 获取结束时间
     */
    getOveMonth(month) {
      this.orderInfo.sjgjOrderShebaoGongjijin.paymonth = month;
      let tempdata = this.orderInfo.sjgjOrderShebaoGongjijin.startmonth.split(
        "-"
      );
      tempdata[1] = Number(tempdata[1]) + Number(month) - 1;
      if (tempdata[1] > 12) {
        tempdata[1] = tempdata[1] - 12;
        tempdata[0]++;
      }
      tempdata[1] = (Array(2).join(0) + tempdata[1]).slice(-2);
      this.orderInfo.sjgjOrderShebaoGongjijin.overmonth = tempdata.join("-");
    },
    /**
     * 判断是否是续缴
     */
    isXujiao() {
      this.$siaxios
        .get(`/order/isXujiao?userid=${this.userInfo.id}&type=1`)
        .then(res => {
          if (res == null || (res != null && res.state == 4)) {
            this.orderInfo.sjgjOrderShebaoGongjijin.type = 0;
          } else if (res.state == 1 || res.state == 2 || res.state == 3) {
            //当前结束月++
            let tempdata = res.overmonth.split("-");
            tempdata[1] = Number(tempdata[1]) + 1;

            if (tempdata[1] > 12) {
              tempdata[1] = tempdata[1] - 12;
              tempdata[0]++;
            }
            tempdata[1] = (Array(2).join(0) + tempdata[1]).slice(-2);
            tempdata = tempdata.join("-");

            let tempOrderInfo = {
              userid: this.userInfo.id,
              saletypeid: 1, //this.orderInfo.saletypeid
              sjgjOrderShebaoGongjijin: {
                cityid: res.cityid,
                hukouid: res.hukouid,
                startmonth: tempdata,
                overmonth: "",
                whetherfirst: 1,
                paymonth: "",
                tel: this.userInfo.tel,
                paybase: res.paybase,
                type: 2
              }
            };
            this.$store.commit("setOrderInfo", tempOrderInfo);
          }

          this.getMaxMonth();
        });
    },
    /**
     * 开始下单
     */
    goOrderDetail() {
      if (this.orderInfo.sjgjOrderShebaoGongjijin.paymonth == "") {
        this.$tips({ title: "提示", describe: "请选择缴纳月份" });
        return;
      }
      this.$siaxios
        .post(`/order/saveSheBao`, this.orderInfo, false)
        .then(res => {
          this.$router.forward({
            path: "/creatorder/orderdetail",
            query: { id: res.id }
          });
        });
    }
  }
};
</script>