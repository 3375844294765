<template>
  <div class="special">
    <dl class="list" @click="$router.forward('/calculator/special/children')">
      <dt>
        <span v-show="special.children.length==1">¥{{special.children[0].deductType==1?addMonth(special.children[0].deductType, special.children[0].total, special.children[0].monthNum)+'/月':special.children[0].total+'/年'}}</span>
        <img src="@/assets/views/calculator/children.png" alt="">
        子女教育
      </dt>
      <dd v-for="(item,index) in special.children" v-show="special.children.length>1 && !!item.total" :key="index">
        <span>¥{{item.deductType==1?addMonth(item.deductType, item.total, item.monthNum)+'/月':item.total+'/年'}}</span>
        子女{{index+1}}
      </dd>
    </dl>
    <dl class="list" @click="$router.forward('/calculator/special/continue')">
      <dt>
        <span v-show="special.continueInfo.eduType.length<=1">¥{{special.continueInfo.deductType==1?addMonth(special.continueInfo.deductType, special.continueInfo.total, special.continueInfo.monthNum)+'/月':special.continueInfo.total+'/年'}}</span>
        <img src="@/assets/views/calculator/continue.png" alt="">
        继续教育
      </dt>
      <dd v-show="special.continueInfo.eduType.length>1 && special.continueInfo.eduType.indexOf(1)>-1">
        <span>¥{{special.continueInfo.deductType==1?addMonth(special.continueInfo.deductType, special.continueInfo.total, special.continueInfo.monthNum)+'/月':special.continueInfo.total+'/年'}}</span>
        学历教育
      </dd>
      <dd v-show="special.continueInfo.eduType.length>1 && special.continueInfo.eduType.indexOf(2)>-1">
        <span>¥3600/年</span>
        继续教育
      </dd>
    </dl>
    <dl class="list" @click="$router.forward('/calculator/special/illness')">
      <dt>
        <span>¥{{special.illness.total}}/年</span>
        <img src="@/assets/views/calculator/illness.png" alt="">
        大病医疗
      </dt>
    </dl>
    <dl class="list" @click="choose(1)">
      <dt>
        <span>¥{{special.rent.deductType==1?addMonth(special.rent.deductType, special.rent.total, special.rent.monthNum)+'/月':special.rent.total+'/年'}}</span>
        <img src="@/assets/views/calculator/rent.png" alt="">
        住房租金
      </dt>
    </dl>
    <dl class="list" @click="$router.forward('/calculator/special/old')">
      <dt>
        <span>¥{{special.old.deductType==1?addMonth(special.old.deductType, special.old.total, special.old.monthNum)+'/月':special.old.total+'/年'}}</span>
        <img src="@/assets/views/calculator/old.png" alt="">
        赡养老人
      </dt>
    </dl>
    <dl class="list" @click="choose(2)">
      <dt>
        <span>¥{{special.loan.deductType==1?addMonth(special.loan.deductType, special.loan.total, special.loan.monthNum)+'/月':special.loan.total+'/年'}}</span>
        <img src="@/assets/views/calculator/loan.png" alt="">
        住房贷款利息
      </dt>
    </dl>
  </div>
</template>
<script>
import Vue from 'vue'
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
export default {
  data() {
    return {
      special: {
        monthTotal: 0,
        yearTotal: 0,
        children: [{
          eduType: '',
          deducter: '',
          deductType: '',
          monthNum: 1,
          total: 0
        }],
        continueInfo: {
          total: 0,
          eduType: [],
          deductType: '',
          deducter: '',
          monthNum: 1
        },
        illness: {
          total: 0,
          listNum: 1,
          list: [{
            deducter: '',
            money: '',
          }, {
            deducter: '',
            money: '',
          }, {
            deducter: '',
            money: '',
          }]
        },
        loan: {
          total: 0,
          deducter: '',
          deductType: '',
          monthNum: 1
        },
        old: {
          total: 0,
          type: '',
          scale: '',
          deductType: '',
          monthNum: 1
        },
        rent: {
          total: 0,
          deducter: '',
          deductType: '',
          type: '',
          city: '',
          isSame: '',
          monthNum: 1
        }
      }
    }
  },
  mounted() {
    if (Vue.getData('special')) {
      this.special = Vue.getData('special');
      this.special.monthTotal = this.addMonth(this.special.loan.deductType, this.special.loan.total, this.special.loan.monthNum) +
        this.addMonth(this.special.old.deductType, this.special.old.total, this.special.old.monthNum) +
        this.addMonth(this.special.rent.deductType, this.special.rent.total, this.special.rent.monthNum);
      if (this.special.children.length > 1) {
        this.special.children.forEach(ele => {
          this.special.monthTotal += this.addMonth(ele.deductType, ele.total, ele.monthNum)
        });
      } else {
        this.special.monthTotal += this.addMonth(this.special.children[0].deductType, this.special.children[0].total, this.special.children[0].monthNum)
      }
      if (this.special.continueInfo.eduType.indexOf(1) > -1) {
        this.special.monthTotal += this.addMonth(this.special.continueInfo.deductType, this.special.continueInfo.total, this.special.continueInfo.monthNum)
      }

      this.special.yearTotal = this.addYear(this.special.loan.deductType, this.special.loan.total) +
        this.addYear(this.special.old.deductType, this.special.old.total) +
        this.addYear(this.special.rent.deductType, this.special.rent.total) + this.special.illness.total;
      if (this.special.children.length > 1) {
        this.special.children.forEach(ele => {
          this.special.yearTotal += this.addYear(ele.deductType, ele.total)
        });
      } else {
        this.special.yearTotal += this.addYear(this.special.children[0].deductType, this.special.children[0].total)
      }
      if (this.special.continueInfo.eduType.indexOf(1) > -1) {
        this.special.yearTotal += this.addYear(this.special.continueInfo.deductType, this.special.continueInfo.total)
      }
      if (this.special.continueInfo.eduType.indexOf(2) > -1) {
        this.special.yearTotal += 3600
      }
      Vue.setData('special', this.special);
      this.setWorkInfo();
    } else {
      Vue.setData('special', this.special);
    }
  },
  methods: {
    // 租房买房贷款二选一
    choose(type) {
      if ((type == 1 && !!this.special.loan.total) || (type == 2 && !!this.special.rent.total)) {
        this.$confirm({
          title: "提示",
          describe: "您好，【住房租金】和【住房贷款利息】专项扣除只能二选一。选择这项后，另一项会被清空，是否继续？",
          callback: boolean => {
            if (boolean) {
              if (type == 1) {
                this.special.loan = {
                  total: 0,
                  deducter: '',
                  deductType: '',
                  monthNum: 1
                }
                this.$router.forward('/calculator/special/rent');
              } else if (type == 2) {
                this.special.rent = {
                  total: 0,
                  deducter: '',
                  deductType: '',
                  type: '',
                  city: '',
                  isSame: '',
                  monthNum: 1
                }
                this.$router.forward('/calculator/special/loan');
              }
            }
          }
        });
      } else if (type == 1) {
        this.$router.forward('/calculator/special/rent');
      } else if (type == 2) {
        this.$router.forward('/calculator/special/loan');
      }
    },
    addMonth(type, total, monthNum) {
      switch (Number(type)) {
        case 1: {
          return (total / (13 - monthNum)).toFixed(2) * 100 / 100;
        }
        case 2: {
          return 0;
        }
        default: {
          return 0;
        }
      }
    },
    addYear(type, total) {
      switch (Number(type)) {
        case 1: {
          return 0;
        }
        case 2: {
          return total;
        }
        default: {
          return 0;
        }
      }
    },
    setWorkInfo() {
      let workList = Vue.getData('workList');
      workList.forEach(ele => {
        ele.incomeList.forEach(item => {
          item.special = 0;
          if (item.type == 1 && this.special.continueInfo.eduType.indexOf(1) > -1 && this.special.continueInfo.deductType == 1 && item.month >= this.special.continueInfo.monthNum) {
            item.special += this.special.continueInfo.total / (13 - this.special.continueInfo.monthNum);
          }
          if (item.type == 1 && this.special.loan.deductType == 1 && item.month >= this.special.loan.monthNum) {
            item.special += this.special.loan.total / (13 - this.special.loan.monthNum)
          }
          if (item.type == 1 && this.special.old.deductType == 1 && item.month >= this.special.old.monthNum) {
            item.special += this.special.old.total / (13 - this.special.old.monthNum)
          }
          if (item.type == 1 && this.special.rent.deductType == 1 && item.month >= this.special.rent.monthNum) {
            item.special += this.special.rent.total / (13 - this.special.rent.monthNum)
          }
          this.special.children.forEach(value => {
            if (item.type == 1 && value.deductType == 1 && item.month >= value.monthNum) {
              item.special += value.total / (13 - value.monthNum);
            }
          })
          item.special = item.special.toFixed(2)
        });
      });
      Vue.setData('workList', workList);
    }
  }
}
</script>
<style lang="scss" scoped>
.special {
  background: #fff;
  min-height: 100vh;
  padding-top: 30px;
  box-sizing: border-box;
  .list {
    margin: 0 30px 30px 30px;
    box-shadow: 0 0 10px 1px #eeeeee inset;
    padding: 30px;
    border-radius: 16px;
    dt {
      color: #333;
      font-size: 28px;
      line-height: 60px;
      background: url("../../../assets/views/calculator/right.png") right center
        no-repeat;
      background-size: 30px;
      padding-right: 50px;
      span {
        float: right;
      }
      img {
        width: 48px;
        vertical-align: middle;
        margin-right: 16px;
      }
    }
    dd {
      line-height: 60px;
      font-size: 26px;
      // background: url('../../../assets/right.png') left center no-repeat;
      // background-size: 10px auto;
      padding-left: 70px;
      span {
        float: right;
        font-size: 28px;
      }
    }

    li {
      line-height: 120px;
      background: url("../../../assets/views/calculator/right.png") 630px 46px
        no-repeat;
      background-size: 30px;
      box-shadow: 0 0 10px 1px #eeeeee inset;
      margin-bottom: 30px;
      border-radius: 16px;
      padding-left: 30px;
      padding-right: 80px;
      color: #333;
      font-size: 28px;
      span {
        float: right;
      }
      img {
        width: 48px;
        vertical-align: middle;
        margin-right: 16px;
      }
      .detail {
        line-height: 60px;
        padding-left: 68px;
        font-size: 26px;
        &:first-child {
          margin-top: -30px;
          border-bottom: #999 1px dotted;
        }
      }
    }
  }
}
</style>