<template>
    <div class="order-services">
        <div class="title">

            <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>

        </div>
        <div class="show-content">
            <div
                    class="showtype "
                    style=" background: #f6faff;"
                    v-if="showType == 3"
            >
                <div class="order-title">常见问题</div>
                <div class="showinfo-3">
                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
                                青岛高校毕业生住房补贴的标准是什么
                            </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                                <span>
                                     本科毕业生每人每月500元，硕士研究生每人每月800元，博士研究生每人每月1200元；紧缺专业硕士研究生每人每月1200元，紧缺专业博士研究生每人每月1500元。紧缺专业目录由市人力资源社会保障局会同有关部门制定。在紧缺专业目录发布前领取2018年度及以后住房补贴，申领人所学专业列入紧缺专业目录的，住房补贴不足部分予以补差。
                                </span>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'
    export default {
        name: "Introduce",
        data() {
            return {
                showType: 3 //1购买流程 2平台优势 3常见问题 4温馨提示
            };
        }
    };
</script>
