<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>灵活就业社会保险补贴：600元/次   青岛 </p>
                <span>
                    按政策规定，灵活就业人员自己缴纳社会保险的可给予一定的补贴，每人每月可高达500元。
                </span>
                <p>
                    档管家可以代您申请灵活就业社会保险补贴，让你社保缴纳无压力。
                </p>
            </div>
        </div>

    </div>
</template>
<script>
     import "@/sass/views/creatorder/business_introduce.scss";
    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

