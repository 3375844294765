<!--落户-->
<template>
  <div class="residence-index">
    <div class="residence-info">
      <div class="item">
        <div class="lable">落户城市</div>
        <div class="info">{{detailinfo.cityname}}</div>
      </div>
      <div class="item">
        <div class="lable">到日期</div>
        <div class="info">{{formatTime(detailinfo.duedate)}}</div>
      </div>
      <div class="item">
        <div class="lable">落户方式</div>
        <div class="info">{{detailinfo.settlemode}}</div>
      </div>
      <div class="item">
        <div class="lable">派出所</div>
        <div class="info">{{detailinfo.policeoffice}}</div>
      </div>
    </div>
    <div class="grayline"></div>
    <div class="re-per-con">
      <div class="title">在库人员</div>
      <div class="item">
        <div v-for="item in detailinfo.sjgjPersonnelList" :key="item.id">
          <div v-if="item.hukoustate != 3 || item.borrowingstate == 1">
            <div class="userinfo">
              <span class="name">姓名</span>
              <span class="idcard">身份证号</span>
              <span class="state2">借阅</span>
              <span class="state3">状态</span>
            </div>
            <div class="infos">
              <div class="lable">
                <span>{{item.name}}</span>
              </div>
              <span class="idno2">{{item.idcard}}</span>
              <span class="idno3">{{getHUState(detailinfo.state)}}</span>
              <span class="idno">{{getState(item.borrowingstate)}}</span>
            </div>
            <div class="infos">
              <div
                class="btn"
                v-if="(item.borrowingstate==0 || item.borrowingstate==2) && item.hukoustate == 1"
                @click="$router.push({path: '/mine/residenceBorrow',query: {id:item.id}})"
              >借阅</div>
              <div
                class="btn re-blue"
                v-if="item.borrowingstate == 1"
                @click="$router.forward({path: '/mine/residenceBack',query: { id: item.id }})"
              >归还</div>
              <div
                v-if="item.hukoustate == 1"
                class="btn re-red"
                @click="$router.forward({path: '/mine/emigration',query: { id: item.id }})"
              >迁出</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="re-btn"
      v-if="detailinfo.state == 2 || detailinfo.state == 3 || detailinfo.state == 4"
    >
      <div class="record" @click="$router.forward({path:'/mine/borrowedRecord-hukou'})">借阅记录</div>
      <div class="renew" @click="isRecord()">续费</div>
    </div>
    <div class="re-btn" v-else>
      <div class="active_record" @click="$router.forward({path:'/mine/borrowedRecord-hukou'})">借阅记录</div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/mine/residence.scss";
import { mapGetters } from "vuex";
import { formatTime } from "@/lib/base.js";
import money from "@/components/plug-in/money";
export default {
  name: "mineresidence",
  data() {
    return {
      detailinfo: {},
      paramData: {
        cityname: "",
        time: "",
        police: "",
        cityid: "",
        tel: "",
        remark: "",
        userid: "",
        saletypeid: "",
        sjgjOrderHukou: {
          year: ""
        }
      },
      xufeiPrice: "", //单项续费时的费用
      togetherCufei: "" //同时续费
    };
  },
  mounted() {
    this.getDetail();
    this.paramData.userid = this.userInfo.id;
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 获取业务详情
     */
    getDetail() {
      this.$siaxios.get("hukou/getHuKouInfo/" + this.userInfo.id).then(res => {
        this.detailinfo = res;
        this.paramData = {
          cityid: this.detailinfo.cityid, //城市id
          tel: this.detailinfo.phone, //电话
          remark: this.detailinfo.remark,
          userid: this.userInfo.id,
          saletypeid: this.detailinfo.ywtype,
          sjgjOrderHukou: {
            year: ""
          }
          // settlemode:this.detailinfo.settlemode
        };
         this.getCityCharge(this.detailinfo.ywtype)
      });
     
    },
    /**
     * 获取借阅状态
     */
    getState(type) {
      if (type == 0) return "无";
      else if (type == 1) return "借阅中";
      else if (type == 2) return "已归还";
    },
    //判断是否在同一城市
    isRecord() {
      // this.getCityCharge(19); //获取不同城市的续费价格
      // this.getCityCharge(22);
      this.getYear(); //获取续费时长
      this.$siaxios
        .get("order/getDandAnHuKou/" + this.userInfo.id)
        .then(res => {
          if (res) {
            //在同一城市 进入限时优惠
            this.fristBool = true
            this.getDetail()
          } else {
            //不在同一城市  进入单项续费
            money.installConfirm({
              title: "户口续费",
              money: this.xufeiPrice,
              param: this.paramData,
              hmoney: this.togetherCufei,
              isHuKou: false
            });
          }
        });
    },
    //获取不同城市的续费价格
    getCityCharge(saletypeid) {
      this.$siaxios
        .get(
          "common/getBusinessCharge/" +
            this.detailinfo.cityid +
            "/" +
            saletypeid
        )
        .then(res => {
          //档案价格
          if (saletypeid == 19 || saletypeid == 41) {
            this.xufeiPrice = res[0].xufei;
          } else {
            for (var i = 0; i < res.length; i++) {
              if (this.detailinfo.settlemode == res[i].taocan) {
                this.xufeiPrice = res[i].xufei; //户口单独续费时
                this.togetherCufei = res[i].hdxufei; //同时续费时
                if (this.fristBool) {
                  money.installConfirm({
                    title: "限时优惠",
                    describe:
                      "同时续费档案和落户业务，享" +
                      this.togetherCufei +
                      "元/年",
                    param: this.paramData,
                    hmoney: this.togetherCufei,
                    money: this.xufeiPrice,
                    isHuKou: true
                  });
                }
                this.fristBool = false;
              }
            }
          }
        });
    },
    //获取续费时长
    getYear() {
      this.$siaxios
        .get("order/getYear/" + this.userInfo.id + "/2")
        .then(res => {
          this.paramData.sjgjOrderHukou.year = res;
        });
    },
    /**
     * 获取户口状态
     */
    getHUState(type) {
      if (type == 0) return "发起缴费";
      else if (type == 1) return "确认支付";
      else if (type == 2) return "待确认";
      else if (type == 3) return "待入库";
      else if (type == 4) return "在库";
      else if (type == 5) return "迁出";
      else if (type == 6) return "关闭";
    },
    formatTime: formatTime
  }
};
</script>
<style lang="scss" scoped>
.record {
  background-color: white;
}
</style>