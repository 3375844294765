<template>
  <div  class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">业务介绍：</div>
      <div class="content">
        住房公积金是企业为在职员工缴纳的长期住房储金，主要用于在职员工贷款买房、支付房租等方面，以缓解员工经济压力。住房公积金是企业为在职员工缴纳的长期住房储金，主要用于在职员工贷款买房、支付房租等方面，以缓解员工经济压力。
      </div>
      <div class="content">档管家可为在本平台代理公积金业务员的企业员工提供住房公积金提取业务。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">常见问题</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
      </div>
      <div class="show-content">
        <div class="showtype showtype3" v-if="showType == 1">
          <div class="order-title">常见问题</div>
          <div class="showinfo-3">
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>公积金提取多长时间可以到账</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>
                    <p>公积金提取手续办完完毕之后 打入联名卡的可实时到账，其余借记卡账户的一般不超过3个工作日</p>
                  </span>
                </div>
              </div>
            </div>
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>夫妻以其中一人名义购房，可否提取夫妻双方的公积金？</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>
                    <p>根据规定，职工购买具有产权的自住住房的，本人和配偶可以申请提取住房公积金账户内的存储余额。</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div><div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1
    };
  }
};
</script>