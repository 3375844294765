<template>
  <div class="nohouse-index">
    <div class="banner-con">
      <div class="banner">
        <div class="title-banner">
          <span>无房证明开具</span>
        </div>
      </div>
    </div>
    <div class="cont-use">
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>无房证明是证明家庭没有住房的证件，办理无房证明可以享受到契税优惠政策，落入集体户也需要无房证明，无房证明怎么开？无房证明在哪里呢？档管家可全程为您代办</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>资讯专业顾问</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/guihuan1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>付款成功</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/guihuan1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>提供证件</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 20px;">
                <div class="showinfo-1-item" style='margin-left:150px;' >
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                
                <div class="trastom" >
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" >
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style='padding-top:20px;'>
        <div class="use-introduce">
          <img src="@/assets/views/mine/cailiao.jpg" alt />
          <span>所需材料</span>
        </div>
        <div class="content">
          <div class="needs">
            <div class="needs-cont">
              <div class="neds-num">1</div>
              <div class="neds-text">身份证</div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/no-house.scss";

export default {
  name: "archives-use",
  data() {
    return {};
  }
};
</script>

 