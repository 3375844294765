<template>
  <div class="fund calculator">
    <div class="top">
      <div class="title">
        <div class="location" @click="selectCity">{{cityname}}</div>
        <label>公积金缴纳基数</label>
      </div>
      <div class="money">
        <span @click="submit">开始计算</span>
        <em>¥</em>
        <input type="tel" pattern="[0-9]*" v-model="fundInfo.fundBasicNum">
      </div>
      <div class="btn">
        <button :class="{on:fundInfo.fundType==2}" @click="fundInfo.fundBasicNum=allInfo.lowgongjijin;fundInfo.fundType=2;saveData()">最低</button>
        <button :class="{on:fundInfo.fundType==1}" @click="fundInfo.fundBasicNum=income<allInfo.highgongjijin?income:allInfo.highgongjijin;fundInfo.fundType=1;saveData()" v-show="income">全额</button>
        <button :class="{on:fundInfo.fundType==3}" @click="fundInfo.fundBasicNum=0;fundInfo.fundType=3;saveData();$router.go(-1)">不缴纳</button>
      </div>
    </div>
    <div class="percent white-bg">
      <div class="group">
        <label>公司比例</label>
        <span>{{fundInfo.accumulationcompay*100}}%</span>
        <!-- <input type="number" placeholder="请输入公司比例" v-model="accumulationcompay" @input="fundInfo.accumulationcompay=accumulationcompay/100">% -->
      </div>
      <div class="group">
        <label>个人比例</label>
        <span>{{fundInfo.accumulationpeople*100}}%</span>
        <!-- <input type="number" placeholder="请输入个人比例" v-model="accumulationpeople" @input="fundInfo.accumulationpeople=accumulationpeople/100">% -->
      </div>
    </div>
    <div class="show">
      <div class="show-table">
        <table class="show-table">
          <thead>
            <tr>
              <th>缴纳方</th>
              <th>缴纳金额</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>个人</td>
              <td>{{tableData.peoplePay}}</td>
            </tr>
            <tr>
              <td>公司</td>
              <td>{{tableData.companyPay}}</td>
            </tr>
            <tr>
              <td>合计</td>
              <td>{{tableData.sum}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
export default {
  data() {
    return {
      fundInfo: {
        fundBasicNum: 5000,
        fundType: 4, //1全额，2最低，3不缴纳，4固定额
        accumulationcompay: 0.05,
        accumulationpeople: 0.05,
        fundTotal: 0
      },
      allCity: [],
      userInfo: JSON.parse(localStorage.getItem('rsgjUserInfo')),
      cityid: Vue.getData('cityid') ? Vue.getData('cityid') : 171,
      cityname: Vue.getData('cityname') ? Vue.getData('cityname') : '青岛市',
      placeholderText: '',
      tableData: { peoplePay: '', companyPay: '', sum: '' },
      month: this.$route.query.month,
      workIndex: 0,
      incomeIndex: 0,
      income: '',
      workList: Vue.getData('workList') ? Vue.getData('workList') : [],
      allInfo: Vue.getData('allInfo') ? Vue.getData('allInfo') : {},
    }
  },
  mounted() {
    Vue.setData('cityid', this.cityid);
    Vue.setData('cityname', this.cityname);
    if (this.allCity.length > 0) {
      this.checkCity();
    } else {
      this.getCityList();
    }
    if (Object.keys(this.allInfo).length == 0) {
      this.getFundInfo();
    }else{
      this.fundInfo.accumulationcompay = this.allInfo.accumulationcompay;
      this.fundInfo.accumulationpeople = this.allInfo.accumulationpeople;
    }
    if (this.workList.length > 0) {
      this.workList.forEach((ele, index) => {
        if (typeof this.month === 'undefined') {
          let arr = ele.incomeList.filter(item => { return item.fundChange == 1 && item.type == 1 });
          this.income = arr[0].income;
          this.fundInfo = {
            fundBasicNum: arr[0].fundBasicNum,
            fundType: arr[0].fundType, //1全额，2最低，3不缴纳，4固定额
            fundTotal: arr[0].fund,
            accumulationcompay: this.allInfo.accumulationcompay,
            accumulationpeople: this.allInfo.accumulationpeople,
          };
        } else {
          ele.incomeList.some((item, num) => {
            if (!!this.month && item.month == this.month && item.type == 1) {
              this.workIndex = index;
              this.incomeIndex = num;
              this.income = item.income;
              this.fundInfo = {
                fundBasicNum: item.fundBasicNum,
                fundType: item.fundType, //1全额，2最低，3不缴纳，4固定额
                fundTotal: item.fund,
                accumulationcompay: this.allInfo.accumulationcompay,
                accumulationpeople: this.allInfo.accumulationpeople,
              };
            }
          });
        }

      });
    }
    this.changeTable();
  },
  methods: {
    // 开始计算
    submit() {
      this.fundInfo.fundType = 4;
      if (this.fundInfo.fundBasicNum < this.allInfo.lowgongjijin) {
        this.$alert('缴纳基数不能小于最低缴纳基数');
        this.fundInfo.fundBasicNum = this.allInfo.lowgongjijin;
      } else if (this.fundInfo.fundBasicNum > this.allInfo.highgongjijin) {
        this.$alert('缴纳基数不能大于最高缴纳基数');
        this.fundInfo.fundBasicNum = this.allInfo.highgongjijin;
      } else if (this.income != '' && this.fundInfo.fundBasicNum > this.income) {
        this.$alert('缴纳基数不能大于月度税前收入');
        this.fundInfo.fundBasicNum = this.income;
      }
      this.saveData();
    },
    // 存数据
    saveData() {
      this.changeTable();
      this.fundInfo.fundTotal = (this.fundInfo.fundBasicNum * this.fundInfo.accumulationpeople).toFixed(2);
      if (this.workList.length > 0) {
        if (typeof this.month === 'undefined') {
          this.workList.forEach(ele => {
            ele.incomeList.forEach(item => {
              if (item.type == 1 && item.fundChange == 1) {
                item.fundBasicNum = this.fundInfo.fundBasicNum;
                item.fundType = this.fundInfo.fundType;
                item.fund = this.fundInfo.fundTotal;
                item.fundScale = this.fundInfo.accumulationpeople;
              }
            });
          });
        } else {
          this.workList[this.workIndex].incomeList[this.incomeIndex].fundBasicNum = this.fundInfo.fundBasicNum;
          this.workList[this.workIndex].incomeList[this.incomeIndex].fundType = this.fundInfo.fundType;
          this.workList[this.workIndex].incomeList[this.incomeIndex].fund = this.fundInfo.fundTotal;
          this.workList[this.workIndex].incomeList[this.incomeIndex].fundScale = this.fundInfo.accumulationpeople;
          this.workList[this.workIndex].incomeList[this.incomeIndex].fundChange = 2;
        }
        Vue.setData('workList', this.workList)
      }
    },
    getCityList() {
      this.$siaxios.get("citybasic/calculatorcities").then(res => {
        if (res) {
          for (let a = 0; a < res.length; a++) {
            if (res[a].sjgjRegions.length != 0) {
              this.allCity = this.allCity.concat(res[a].sjgjRegions)
            }
          }
          Vue.setData('allCity', this.allCity);
          this.checkCity();
        }
      });
    },
    checkCity() {
      if (this.allCity.filter(item => { return item.id == this.cityid }).length == 0) {
        this.message = "该城市暂不支持查询，请切换其他城市"
        this.nodata = true;
      }
    },
    changeTable() {
      this.tableData.peoplePay = (this.fundInfo.fundBasicNum * this.fundInfo.accumulationpeople).toFixed(2);
      this.tableData.companyPay = (this.fundInfo.fundBasicNum * this.fundInfo.accumulationcompay).toFixed(2);
      this.tableData.sum = (Number(this.tableData.peoplePay) + Number(this.tableData.companyPay)).toFixed(2);
    },
    selectCity() {
      this.$citycalculatorselect({
        callback: (id, name) => {
          this.cityid = id;
          this.cityname = name;
          Vue.setData('cityid', this.cityid);
          Vue.setData('cityname', this.cityname);
          this.getFundInfo();
        }
      });
    },
    getFundInfo() {
      this.$siaxios.get("/order/getCityBase/" + this.cityid).then(res => {
        this.allInfo = res;
        Vue.setData('allInfo', res);
        this.fundInfo.accumulationcompay = res.accumulationcompay;
        this.fundInfo.accumulationpeople = res.accumulationpeople;
        if (this.fundInfo.fundType == 1) {
          if (this.income < res.lowgongjijin) {
            this.fundInfo.fundBasicNum = res.lowgongjijin;
          } else if (this.income > res.highgongjijin) {
            this.fundInfo.fundBasicNum = res.highgongjijin
          } else {
            this.fundInfo.fundBasicNum = this.income
          }
        } else if (this.fundInfo.fundType == 2) {
          this.fundInfo.fundBasicNum = res.lowgongjijin;
        } else if (this.fundInfo.fundType == 3) {
          this.fundInfo.fundBasicNum = 0;
        } else if (this.fundInfo.fundType == 4) {
          if (this.fundInfo.fundBasicNum < res.lowgongjijin) {
            this.fundInfo.fundBasicNum = res.lowgongjijin;
          } else if (this.fundInfo.fundBasicNum > res.highgongjijin) {
            this.fundInfo.fundBasicNum = res.highgongjijin
          }
        }
        this.changeTable();
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.fund {
  background: #fff;
  min-height: 100vh;
  .top {
    height: 304px;
    box-sizing: border-box;
    padding: 50px 30px 0 30px;
    background: #4884ff;
    color: #fff;
    .title {
      font-size: 28px;
      line-height: 48px;
      margin-bottom: 30px;
      .location {
        float: right;
        padding: 0 30px;
        background: url("../../../assets/arrow_down_white.png") right center
          no-repeat;
        background-size: 18px 10px;
      }
      small {
        opacity: 0.6;
      }
    }
    .money {
      position: relative;
      height: 86px;
      border-bottom: #fff 1px solid;
      margin-bottom: 30px;
      input {
        width: 60%;
        font-size: 46px;
        line-height: 46px;
        background: none;
        border: none;
        color: #fff;
        // &::placeholder{
        //     font-size: 30px;
        // }
      }
      span {
        float: right;
        width: 160px;
        height: 60px;
        text-align: center;
        background: #fff;
        color: #4884ff;
        font-size: 26px;
        line-height: 60px;
        border-radius: 30px;
      }
      em {
        font-size: 46px;
        font-style: normal;
      }
    }
    .btn {
      text-align: right;
      button {
        outline: none;
        height: 48px;
        border-radius: 24px;
        border: #fff 1px solid;
        padding: 0 40px;
        background: none;
        margin-left: 40px;
        color: #fff;
        font-size: 26px;
        &.on {
          background: #fff;
          color: #4884ff;
        }
      }
    }
  }
  .percent {
    padding: 0 30px;
    .group {
      line-height: 100px;
      border-bottom: #eee 1px solid;
      text-align: right;
      font-size: 28px;
      color: #333;
      &:last-of-type {
        border-bottom: none;
      }
      label {
        font-size: 26px;
        color: #666;
        float: left;
      }
      input {
        border: none;
        text-align: right;
        margin-right: 10px;
        font-size: 28px;
        color: #333;
      }
    }
  }
  .show {
    background: #fff;
    padding: 30px;
    border-top: 20px solid #f5f5f5;
    .show-table {
      border-radius: 14px;
      border: #ccc 1px solid;
      overflow: hidden;
      padding: 0;
      margin: 0;
      table {
        border-collapse: collapse;
        border-spacing: 0;
        font-size: inherit;
        width: 100%;
        border: none;
        th {
          background: #4884ff;
          border-right: #ccc 1px solid;
          border-bottom: #ccc 1px solid;
          height: 90px;
          color: #fff;
          font-size: 26px;
          text-align: center;
          &:last-child {
            border-right: none;
          }
        }
        td {
          height: 88px;
          border-right: #ccc 1px solid;
          border-bottom: #ccc 1px solid;
          text-align: center;
          font-size: 26px;
          &:last-child {
            border-right: none;
          }
        }
        tr {
          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
</style>