<template>
    <div class="children calculator">
        <div class="top-money">
            <button @click="clearData">清除数据</button>
            <label>本人年度扣除金额</label>
            <span>¥</span>
            <input type="number" v-model="continueInfo.total" readonly>
        </div>
        <div class="form-con">
            <div class="form-group">
                <label>教育类型(可多选)</label>
                <div class="group-right">
                    <div class="radio-con">
                        <div class="checkbox-con">
                            <input type="checkbox" v-model="continueInfo.eduType" :value="1">
                            <label></label>
                        </div>
                        <span>学历教育</span>
                    </div>
                    <div class="radio-con">
                        <div class="checkbox-con">
                            <input type="checkbox" v-model="continueInfo.eduType" :value="2">
                            <label></label>
                        </div>
                        <span>继续教育</span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-show="continueInfo.eduType.indexOf(1)>-1">
                <label>扣除方式</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="continueInfo.deductType" value="1"></v-radio><span>每月</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="continueInfo.deductType" value="2"></v-radio><span>每年</span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-show="continueInfo.eduType.indexOf(1)>-1">
                <label>扣除人</label>
                <div class="group-right">
                    <div class="radio-con">
                        <v-radio v-model="continueInfo.deducter" value="1"></v-radio><span>本人</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="continueInfo.deducter" value="2"></v-radio><span>父亲</span>
                    </div>
                    <div class="radio-con">
                        <v-radio v-model="continueInfo.deducter" value="3"></v-radio><span>母亲</span>
                    </div>
                </div>
            </div>
            <div class="form-group" v-show="continueInfo.eduType.indexOf(1)>-1">
                <div class="right-select" ref="month">
                    {{continueInfo.monthNum}}月
                </div>
                <label>开始扣除月份</label>
            </div>
        </div>
        <div class="description">
            <div class="title"><span>继续教育抵扣说明</span></div>
            <dl class="description-text">
                <dt>扣减金额</dt>
                <dd>
                    <ul class="list">
                        <li>继续教育，取的证书当年抵扣3600元</li>
                        <li>学历教育，每月400元/每年4800元</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>继续教育</dt>
                <dd>
                    技能人员、专业技术人员职业资格继续教育
                </dd>
            </dl>
            <dl class="description-text">
                <dt>学历教育</dt>
                <dd>
                    <ul class="list">
                        <li>义务教育（小学和初中教育）</li>
                        <li>高中阶段教育（普通高中、中等职业教育）</li>
                        <li>高等教育（大学专科、大学本科、硕士研究生、博士研究生教育）</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>适用范围</dt>
                <dd>
                    <ul class="list">
                        <li>学历（学位）继续教育：入学的当月至教育结束的当月，按照每月400元定额扣除</li>
                        <li>统一学历（学位）继续教育的扣除期限最长不超过48个月</li>
                        <li>如果子女已就业，且正在接受本科以下学历教育，可以由父母选择按照子女教育扣除，也可以由子女本人选择按照继续教育扣除，不能同时扣除</li>
                    </ul>
                </dd>
            </dl>
            <dl class="description-text">
                <dt>证明材料</dt>
                <dd>
                    个人申报+政府相关数据，税务部门核实
                </dd>
            </dl>
        </div>
    </div>
</template>
<script>
import MobileSelect from "@/components/plug-in/mobile-select";
import "@/sass/views/calculator/main.scss";
import "@/unit/calculator";
import Vue from 'vue'
export default {
    data() {
        return {
            continueInfo: {
                total: 0,
                eduType: [],
                deductType: '',
                deducter: '',
                monthNum: 1
            },
            monthList: []
        }
    },
    created() {
        for (var i = 1; i < 13; i++) {
            this.monthList.push({ id: i, value: i + '月' })
        }
    },
    watch: {
        "continueInfo": {
            handler(newVal) {
                let sum = 0;
                if (newVal.eduType.indexOf(1) > -1 && newVal.deducter == 1) {
                    sum += 4800 / 12 * (13 - newVal.monthNum)
                }
                if (newVal.eduType.indexOf(2) > -1) {
                    sum += 3600
                }
                this.continueInfo.total = sum;
                console.log(11)
                Vue.setData('special.continueInfo', this.continueInfo);
            },
            deep: true
        }
    },
    mounted() {
        if (!!Vue.getData('special') && !!Vue.getData('special.continueInfo')) {
            this.continueInfo = Vue.getData('special.continueInfo');
        }
        this.selectMonth();
    },
    methods: {
        clearData() {
            this.continueInfo = {
                total: 0,
                eduType: [],
                deductType: '',
                deducter: '',
                monthNum: 1
            }
        },
        selectMonth() {
            new MobileSelect({
                trigger: this.$refs.month,
                title: "开始扣除月份",
                wheels: [{ data: this.monthList }],
                callback: (indexArr, data) => {
                    this.continueInfo.monthNum = data[0].id;
                }
            });

        }
    }

}
</script>
<style lang="scss" scoped>
.calculator {
    background: #fff;
    min-height: 100vh;
}
.top-money {
    background-image: linear-gradient(to right, #40c78d, #2ebd7f);
}
.checkbox-con {
    position: relative;
    width: 29px;
    height: 29px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

.checkbox-con input[type="checkbox"] {
    opacity: 0;
    width: 29px;
    height: 29px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    margin: 0;
}

.checkbox-con input[type="checkbox"] + label {
    display: inline-block;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background: none;
    border: 1px solid #999;
    background-size: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}
.checkbox-con input[type="checkbox"]:checked + label {
    position: absolute;
    top: 0;
    left: 0;
    width: 29px;
    height: 29px;
    background: #2e7bfe;
    border: 1px solid #2e7bfe;
}
.checkbox-con input[type="checkbox"]:checked + label::before {
    position: absolute;
    content: "";
    display: block;
    margin-top: 15px;
    margin-left: 4px;
    width: 8px;
    height: 2px;
    transform: rotate(45deg);
    background: white;
}
.checkbox-con input[type="checkbox"]:checked + label::after {
    position: absolute;
    content: "";
    display: block;
    margin-top: 13px;
    margin-left: 8px;
    width: 15px;
    height: 2px;
    transform: rotate(-45deg);
    background: white;
}
</style>