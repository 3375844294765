<template>
    <div class="otherbussiness-index">
        <div class="intop-nav">
            <div
                    v-for="item in businessType "
                    :key="item.id"
                    class="item"
                    @click="getListByType(item.id)"
                    :class="{'active':showType==item.id}"
            >{{item.name }}
            </div>
        </div>
        <div class="re-per-con" v-if="sjgjOtherData.length>0">
            <div class="item" v-for="item in sjgjOtherData" :key="item.id">
                <div class="userinfo">
                    <span class="name">{{item.businessname}}</span>
                    <span class="state">{{styleData(item.state)}}</span>
                </div>
                <div class="infos">
                    <span if="showType==4" class="lable">{{formatTime(item.creattime)}}</span>
                    <span else-if="showType==3" class="lable">{{formatTime(item.createtime)}}</span>
                    <span else class="lable">{{formatTime(item.createdtime)}}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <no-data></no-data>
        </div>
    </div>
</template>
<script>
    import "@/sass/views/mine/otherbussiness.scss";
    import no_data from "../nonepage/noorder";
    import {formatTime} from "@/lib/base.js";
    import {mapGetters} from "vuex";

    export default {
        name: "mineresidence",
        data() {
            return {
                otherData: "",
                sjgjOtherData: [],
                isDataBool: false,
                showType: 1,
                businessType: [
                    {name: "社保", id: 1},
                    {name: "公积金", id: 2},
                    {name: "档案", id: 3},
                    {name: "户口", id: 4},
                    {name: "毕业生", id: 5}
                ]
            };
        },
        created() {
            this.getMyBusiness();
        },
        components: {
            "no-data": no_data
        },
        computed: {
            ...mapGetters({
                userInfo: "getUserInfo"
            })
        },
        methods: {
            // 选项卡切换
            getListByType(id) {
                if (id) {
                    this.showType = id;
                }
                if (this.showType == 1) {
                    this.sjgjOtherData = this.otherData.sjgjshebaoother;
                } else if (this.showType == 2) {
                    this.sjgjOtherData = this.otherData.sjgjgongjijinother;
                } else if (this.showType == 3) {
                    this.sjgjOtherData = this.otherData.sjgjdanganother;
                } else if (this.showType == 4) {
                    this.sjgjOtherData = this.otherData.sjgjhukouother;
                } else if (this.showType == 5) {
                    this.sjgjOtherData = this.otherData.sjgjstudentother;
                }
            },
            /**
             * 获取业务信息
             */
            getMyBusiness() {
                this.$siaxios
                    .get("/mybusiness/getBusiness/" + this.userInfo.id)
                    .then(res => {
                        if (res.shebaogongjijinother)
                            this.otherData = res.shebaogongjijinother;
                        this.getListByType()
                    });
            },
            // 状态值判断
            styleData(state) {
                if (this.showType == 1) {
                    if (state == 1) {
                        return "财务确认";
                    } else if (state == 2) {
                        return "待提交";
                    } else if (state == 3) {
                        return "待完成";
                    } else if (state == 4) {
                        return "完成";
                    } else if (state == 5) {
                        return "关闭";
                    }
                } else if (this.showType == 2) {
                    if (state == 1) {
                        return "财务确认";
                    } else if (state == 2) {
                        return "待提交";
                    } else if (state == 3) {
                        return "待完成";
                    } else if (state == 4) {
                        return "完成";
                    } else if (state == 5) {
                        return "关闭";
                    }
                } else if (this.showType == 3) {
                    if (state == 0) {
                        return "财务确认";
                    } else if (state == 1) {
                        return "待处理";
                    } else if (state == 2) {
                        return "待完成";
                    } else if (state == 3) {
                        return "完成";
                    }
                } else if (this.showType == 4) {
                    if (state == 0) {
                        return "发起缴费";
                    } else if (state == 1) {
                        return "财务确认";
                    } else if (state == 2) {
                        return "待提交/待处理";
                    } else if (state == 3) {
                        return "待完成/待入库";
                    } else if (state == 4) {
                        return "完成";
                    }
                } else if (this.showType == 5) {
                    if (state == 1) {
                        return "待提交";
                    } else if (state == 1) {
                        return "待完成";
                    } else if (state == 2) {
                        return "完成";
                    }
                }
            },
            formatTime: formatTime,
            //下拉刷新
            onRefresh(done) {
                this.getMyBusiness();
                done(); // call done
            },
        }
    };
</script>
<style lang="scss" scoped>
    .main-business {
        margin-left: 90px;
        margin-top: 0;
        .bus-nav {
            margin-top: 0;
            padding: 30px 0;
        }
    }
    .intop-nav {
        position: fixed;
        background: white;
        width: 100%;
    }
    .re-per-con {
        margin-top: 120px;
    }
</style>