<template>
  <div  class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">业务介绍：</div>
      <div class="content">
        参加生育保险男职工的配偶无工作单位，其生育符合国家计划生育政策规定的，男职工可享受生育补助金
      </div>
      <div class="content">档管家可为您提供快捷的办理手续。让您无需奔波，在线即可轻松办理</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
      </div>
      <div class="show-content">
        <div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>