<template>
    <div class="house-index" >
        <div class="banner-con">
            <div class="banner">
                <span>—&nbsp;&nbsp;济南买房政策详细解读&nbsp;&nbsp;—</span>
            </div>
        </div>
        <div class="title">
            <img src="@/assets/views/mine/jinan3.png" alt/>
            <span class="title-">本市户籍家庭买房</span>
        </div>
        <div class="cont" style="height:200px">
            <div class="con-cont">
                根据济南市政府出台的加强房地产市场调控的相关规定，济南的限购区域升级，除了原有的限购区域外，长清区、章丘区也划入了限购范围
            </div>
            <div class="con-cont" style="margin-top:30px">
                <div class="con-introduce">
                    <span> <span style="color:#00c289">本地无房家庭</span>在济南限购区域限购<span style="color:#00c289">2套住宅</span> ，本市户籍家庭购买<span
                            style="color:#00c289">首套房</span>，首付比例为<span style="color:#00c289">30%。</span> </span>
                </div>
            </div>
            <div class="con-cont" style="margin-top:5px">
                <div class="con-introduce">
                    <span> <span style="color:#00c289">本市户籍家庭</span>已有一套住宅或有房贷记录，再次购买商品房是，其首付比例为<span
                            style="color:#00c289">60%</span> ，商业贷款年限最长为<span style="color:#00c289">25年</span> </span>
                </div>
            </div>
        </div>
        <div class="top-back" style="margin-top:13px"></div>
        <div class="title" >
            <img src="@/assets/views/mine/jinan4.png" alt/>
            <span class="title-">非本市户籍家庭买房</span>
        </div>
        <div class="cont" style="height:140px">
            <div class="con-cont" style="margin-top:10px">
                <div class="con-introduce">
                    <span>如果是<span style="color:#00c289">非本市户籍家庭，</span>想要在济南限购区域买房 ，需要在买房之日起<span
                            style="color:#00c289">前3年内，连续缴纳24个月的社保或个税，社保和个税是不能补缴的。</span> </span>
                </div>
            </div>
            <div class="con-cont" style="margin-top:30px">
                <div class="con-introduce">
                    <span> <span style="color:#00c289">非本市户籍家庭限购一套住宅</span>如果申请住房贷款买房，其首付比例为<span
                            style="color:#00c289">60%。</span>  </span>
                </div>
            </div>
        </div>
        <div class="top-back" style="margin-top:13px"></div>
        <div class="title" >
            <img src="@/assets/views/mine/jinan5.png" alt/>
            <span class="title-">外地人员买房</span>
        </div>
        <div class="cont" style="height:125px">
            <div class="con-cont" >
                <div class="con-introduce">
                    <span>如果外地人员具有<span style="color:#00c289">本科及以上学历，</span>并在济南签订 <span
                            style="color:#00c289">正式劳动合同，连续缴纳了半年以上的社保，</span>或者是外地人员为济南
                        <span  style="color:#00c289">引进的高层次人才，凭市人次出具的证明，</span>可享受本市户籍家庭的购房政策 </span>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import "@/sass/views/gallery/buhouse.scss";
    export default {
        name: "index",
        data() {
            return {

            };
        },

    };
</script>
<style lang="scss" scoped>
    .banner-con{
        background: url(../../assets/views/mine/jinan-buyhouse.jpg);
        background-size: cover;
    }
  
    .cont {
        margin-top: 0px;
        background: white;
    }
    .con-introduce {
        margin-left: 18px !important;
        width: 100% !important;
    }
   
    
</style>