<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>用人单位吸纳就业社保补贴和岗位补贴（ 1个月500，3个月1000，6个月1800, 12个月2500）青岛 </p>
                <span>补贴范围：</span>
                <p>
                    行政区域内各类用人单位 (不含机关事业单位、劳务派遣机构和劳动人事代理机构)>。
                </p>
                <span>补贴标准：</span>
                <p>
                    每人每月1085.9元/人。
                </p>
                 <span>补贴期限：</span>
                <p>
                    一般3年，最长5年。
                </p>

            </div>
        </div>

    </div>
</template>
<script>
       import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

