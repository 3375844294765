<template>
  <div class="nonepage">
    <div class="showcontent">
      <img src="@/assets/views/nonepage/ordertype.png" class="showimg" alt />
      <div class="title-">暂无{{text?text:'订单'}}</div>
      <div class="tips">您还没有相关{{text?text:'订单'}}</div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/nonepage.scss";
export default {
  data(){
    return {
      text:this.$route.query.text
    }
  }
};
</script>
