<template>
  <div class="register">
    <div class="title">
      <p class="top">您好</p>
      <p class="bottom">欢迎来到档管家 立即注册</p>
    </div>

    <div class="from-con">
      <div class="input-con">
        <input class="in-phone" type="text" maxlength="11" placeholder="手机号码" v-model="param.tel" />
      </div>
      <div class="input-con">
        <input
          class="in-phone phone-code-width"
          onkeyup="this.value=this.value.replace(/\D/g,'')"
          onafterpaste="this.value=this.value.replace(/\D/g,'')"
          type="text"
          maxlength="6"
          placeholder="短信验证码"
          v-model="param.code"
        />
        <span class="sendmsg-btn" @click="getcode()">{{count==60?'获取验证码':`${count}s后重新发送`}}</span>
      </div>
      <div class="input-con">
        <input
          class="in-phone"
          type="password"
          autocomplete="new-password"
          placeholder="设置登录密码"
          v-model="param.password"
        />
      </div>
      <div class="input-con">
        <input class="in-phone" type="password" placeholder="确认密码" v-model="password2" />
      </div>
    </div>

    <div class="agreement">
      <img @click="agree = !agree" :src="agreeimg" alt />
      <div>
        我已阅读并同意
        <span @click="$router.forward('/login/registeragreement')">《档管家注册协议》</span>
      </div>
    </div>
    <div class="submit-info" @click="register()">注册</div>
    <div class="bottom-info">
      <div class="left-info" @click="contact">联系顾问</div>
      <div class="right-info">
        已有账号,
        <span class="a-link" @click="toLogin">立即登录</span>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/login/register.scss";
import checked from "@/assets/checked.png";
import nochecked from "@/assets/nochecked.png";
import { mapGetters } from "vuex";
import { getUrlParms } from "@/lib/base";
export default {
  name: "register",
  data() {
    return {
      type: 1, //登录类型 1 密码登录 2手机验证码登录 3 手机一键登录 4 微信登录
      param: {
        code: "",
        name: "",
        tel: "",
        password: "",
        openid: "",
        headimageurl: "",
        sex: "",
        nickname: "",
        salemanid: ""
      },
      password2: "",
      count: 60,
      agree: true
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    }),
    agreeimg() {
      return this.agree ? checked : nochecked;
    }
  },
  mounted() {
    this.param.openid = this.userInfo.openid;
    this.param.headimageurl = this.userInfo.headimageurl;
    this.param.sex = this.userInfo.sex;
    this.param.nickname = this.userInfo.nickname;
  },
  methods: {
    register() {
      //手机号
      if (this.param.tel == "") {
        this.$tips({ describe: "请输入手机号" });
        return;
      }
      //验证码
      if (this.param.code == "") {
        this.$tips({ describe: "验证码不能为空" });
        return;
      }
      //密码
      if (this.param.password == "") {
        this.$tips({ describe: "密码不能为空" });
        return;
      }
      let pattern = /^[\w_-]{6,10}$/;
      if (!pattern.test(this.param.password)) {
        this.$tips({
          describe: "请输入6到10位的大小写字母、数字、下划线和减号"
        });
        return;
      }
      //二次密码
      if (this.param.password != this.password2) {
        this.$tips({ describe: "两次输入密码不一致" });
        return;
      }
      //是否勾选协议
      if (!this.agree) {
        this.$tips({ describe: "请勾选协议--《档管家服务协议》" });
        return;
      }
      let salemanid = getUrlParms('salemanid');
      if (salemanid) this.param.salemanid = salemanid;
      this.param.openid = this.userInfo.openid;
      this.param.headimageurl = this.userInfo.headimageurl;
      this.param.nickname = this.userInfo.nickname;
      this.param.sex = this.userInfo.sex;
      this.$siaxios.post("/login/register", this.param, false).then(res => {
        this.$store.commit("setMyUserInfo", res);
        this.$tips({
          describe: "注册完成",
          callback: () => {
            this.$router.forward("/login/login");
          }
        });
      });
    },
    toLogin() {
      this.$router.forward("/login/login");
    },
    contact() {
      this.$tips({
        describe: `拨打电话：0532-85873655`,
        btn: "拨打",
        showtel: true
      });
    },
    linkxieyi() {
      this.$router.forward({ path: "/login/agreement" });
    },
    // 获取验证码
    getcode() {
      if (this.count != 60) return;
      //1.检测手机号
      var reg2 = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (this.param.tel == "") {
        this.$tips({ describe: "请输入手机号" });
        return false;
      } else if (!reg2.test(this.param.tel)) {
        this.$tips({ describe: "手机号码格式不正确" });
        this.param.tel = "";
        return false;
      }
      this.$axios.get("/login/check?num=" + this.param.tel).then(res => {
        if (res.data.errCode == -1) {
          this.$tips({ describe: res.data.errMsg });
          this.param.tel = "";
        } else {
          //2.验证通过之后获取验证码
          this.$siaxios.get("smscode/" + this.param.tel).then(() => {
            this.timer();
          });
        }
      });
    },
    timer() {
        this.count--;
      if (this.count > 0) {
        setTimeout(this.timer, 1000);
      } else {
        this.count = 60;
      }
    }
  }
};
</script>

