import { render, staticRenderFns } from "./socialIns.vue?vue&type=template&id=8c8143e8&scoped=true&"
import script from "./socialIns.vue?vue&type=script&lang=js&"
export * from "./socialIns.vue?vue&type=script&lang=js&"
import style0 from "./socialIns.vue?vue&type=style&index=0&id=8c8143e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c8143e8",
  null
  
)

export default component.exports