<template>
    <div class="house-index">
        <div class="banner-con">
            <div class="banner">
                <span>—&nbsp;&nbsp;青岛买房政策详细解读&nbsp;&nbsp;—</span>
            </div>
        </div>
        <div class="title">
            <img src="@/assets/views/mine/qingdao2.png" alt/>
            <div class="title-">限购区域</div>
        </div>
        <div class="cont" style="background: white">
            <div class="map">
                <img src="@/assets/views/mine/qd-buyhouse.jpg" alt="">
            </div>
            <div class="map-right">
                <div class="right-">
                    <div class="map-title">
                        限购范围
                    </div>
                    <div class="map-cont">
                        市南区、市北区、李沧区、 崂山区、黄岛区、城阳区 (包括高新区)。
                    </div>
                </div>
                <div class="right-" style="padding-top: 28px">
                    <div class="map-title">
                        限售范围
                    </div>
                    <div class="map-cont">
                        <p>除以上地区限售</p> <span>
                        还要加上即墨区、莱西市、 胶州市、平度市
                    </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="title" >
            <img src="@/assets/views/mine/qingdao1.png" alt/>
            <span class="title-">无购房资格人群</span>
        </div>
        <div class="cont orange-">
            <div class="con-title orange">
                <span>本市户籍家庭</span>
            </div>
            <div>
                <span>在限购区域内名下已有2套及以上住房</span>
            </div>
        </div>
        <div class="cont red-">
            <div class="con-title red">
                <span>非本市户籍家庭</span>
            </div>
            <div class="con-cont">
                <div class="red-line"></div>
                <div class="red-cont">
                    在限购区域内名下已有1套及以上住房
                </div>
                <div class="red-line" style="margin-top:20px;"></div>
                <div class="red-cont" >
                    <p>
                        无法提供2年内在本市连续缴纳12个月及以上的
                    </p>
                    <span>
                         个税或社保,补缴的不予认可
                    </span>

                </div>
            </div>
        </div>
        <div class="title" >
            <img src="@/assets/views/mine/business2.png" alt/>
            <span class="title-">可购买首套房人群</span>
        </div>
        <div class="cont bule-">
            <div class="con-title bule">
                <span>本市户籍家庭</span>
            </div>
            <div class="con-cont bule">
                <span>在限购区域内名下只有1套住房</span>
            </div>
        </div>
        <div class="cont green-">
            <div class="con-title green">
                <span>非本市户籍家庭</span>
            </div>
            <div class="con-cont ">
                <div class="red-line" style="background: #00c289"></div>
                <div class="green-cont  ">
                    <span>在在限购区域内名下无房</span>
                    <p>且能提供购房前2年内在本市</p>
                    <span>
                        连续缴纳12个月及以上的个税或社保
                    </span>
                    <p>
                        补缴的不予认可
                    </p>

                </div>
            </div>
        </div>
        <div class="title" >
            <img src="@/assets/views/mine/jinan2.png" alt/>
            <span class="title-">可购买二套房人群</span>
        </div>
        <div class="cont bule-">
            <div class="con-title bule">
                <span>本市户籍家庭</span>
            </div>
            <div class="con-cont bule">
                <span>在限购区域内名下无住房</span>
            </div>
        </div>
    </div>
</template>
<script>
    import "@/sass/views/gallery/buhouse.scss";
    export default {
        name: "index",
        data() {
            return {};
        },


    };
</script>
<style lang="scss" scoped>
    .banner-con {
        background: url("../../assets/views/mine/qingdao3.jpg") 100% 100%;
        background-size: cover;

    }
</style>