<template>
  <div class="provezhengli-index">
    <div class="banner-con">
      <div class="banner">
        <div class="title-banner">
          <span>档案整理</span>
          <p>保证档案的完整性、规范化 用到时才不至于手忙脚乱</p>
        </div>
      </div>
    </div>
    <div class="cont-use">
        <div class="gary-line"></div>
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>档案室对收集来的档案分门别类组成有序体系的一项业务，是档案管理中的一项基础工作， 按照一定原则对档案实体进行系统分类、组合、排列、编号和基本边牧，使之有序化，档管家提供专人一对一的档案整理服务，让您的档案更清晰专业有条理。</span>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-" style="margin-left:20px;">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img" style="margin-left:7px;">
                    <img src="@/assets/views/mine/da-dc1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>APP注册账号</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow" style="margin-left:-10px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:23px;">
                  <div class="showinfo-1-img" style="margin-left:9px;">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>付款成功</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow" style="margin-left:-5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:20px;">
                  <div class="showinfo-1-img" style="margin-left:10px;">
                    <img src="@/assets/views/mine/guihuan1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>办理人登记填写信息资料</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 10px;">
                <div class="showinfo-1-item" style="margin-left:30px;">
                  <div class="showinfo-1-img" style="margin-left:10px;">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:-5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:20px;">
                  <div class="showinfo-1-img" style="margin-left:8px;">
                    <img src="@/assets/views/mine/yewujieshao.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>整理入册</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:-3px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:23px;">
                  <div class="showinfo-1-img" style="margin-left:7px;">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/need-re.png" alt />
          <span>所需材料</span>
        </div>
        <div class="use-cont">
          <span>1、需要整理的档案材料</span>
        </div>
      </div>
    </div>
    <div class="gary-line"></div>
    <div class="prove-btn" @click="showConsult()">
      <button>咨询顾问</button>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/prove-zhengli.scss";
import { mapGetters } from "vuex";
export default {
  name: "prove-zhengli",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 展示顾问
     */
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return;
      }
      this.$consult({ userid: this.userInfo.id });
    }
  }
};
</script>

