<!--
  户口借阅
-->
<template>
  <div class="mine-creatorder">
    <div class="showmessage">
      <div class="order-title">简介</div>
      <div class="content">户口在集体户，需要借用户口本人单页，但因个人原因不能亲自前往办理借取手续，档管家可全程为您代办，让您省心省力，安全有保障。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">常见问题</div>
      <div class="content">
        <p>
          1. 借单页都需要带什么材料:
          身份证，押金，委托书（非本人借取）。
        </p>
        <p>
          2. 借走单页后多长时间归还
          15个工作日办理归还手续。
        </p>
      </div>
      <div class="content">
        <p>温馨提示：</p>
        <p>1. 下单前请先咨询我们的专业顾问，资料的邮寄邮寄地址 联系人 联系方式一定要确认好再下单，以免因地址错误给您的后续工作造成不必要的麻烦。</p>
        <p>2.单页用完之后请在要求的时间内及时归还，如有特殊情况可先联系我们的专业顾问。</p>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">押金说明</div>
      <div class="content"> 为方便管理，借取档案资料或集体户口单页每人需缴纳500元押金，待资料还回时将押金予以退还。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="info-line">
      <div>收件人</div>
      <input
        class="inputc"
        v-model="paramInfo.sjgjOrderHukou.name"
        placeholder="请填写收件人"
        type="text"
      />
    </div>
    <div class="info-line">
      <div>收件电话</div>
      <input
        class="inputc"
        v-model="paramInfo.sjgjOrderHukou.phone"
        placeholder="请填写收件电话"
        type="text"
      />
    </div>
    <div class="info-line">
      <div>邮寄地址</div>
      <input
        class="inputc"
        v-model="paramInfo.sjgjOrderHukou.address"
        placeholder="请填写邮寄地址"
        type="text"
      />
    </div>
    <div class="order-btn" @click="nextStemp()">支付押金￥{{amount}}</div>
  </div>
</template>
<script>
import "@/sass/views/mine/creatorder.scss";
import { mapGetters } from "vuex";
export default {
  name: "residenceBorrow",
  data() {
    return {
      amount: 0,
      paramInfo: {
        userid: "",
        saletypeid: 22,
        remark: "",
        sjgjOrderHukou: {
          name: "",
          phone: "",
          address: "",
          sid: this.$route.query.id
        }
      }
    };
  },
  created() {
    this.getPrice();
  },
  mounted() {
    this.paramInfo.userid = this.userInfo.id;
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 获取金额
     */
    getPrice() {
      this.$siaxios.get(`order/getDangAnPrice/1`).then(res => {
        this.amount = res.amount;
      });
    },
    /**
     * 下一步去创建订单
     */
    nextStemp() {
      if (this.paramInfo.sjgjOrderHukou.name == "") {
        this.$tips({ title: "提示", describe: "请填写收件人" });
        return;
      }
      var reg2 = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (this.paramInfo.sjgjOrderHukou.phone == "") {
        this.$tips({ title: "提示", describe: "请填写手机号" });
        return;
      }else if(!reg2.test(this.paramInfo.sjgjOrderHukou.phone)){
        this.$tips({ describe: "手机号码格式不正确" });
        this.paramInfo.sjgjOrderHukou.phone = "";
        return;
      }
      if (this.paramInfo.sjgjOrderHukou.address == "") {
        this.$tips({ title: "提示", describe: "请填写邮寄地址" });
        return;
      }
      this.$siaxios
        .post(`/order/saveHuKouJieYue`, this.paramInfo, false)
        .then(res => {
          this.$tips({
            title: "提示",
            describe: "借阅成功，去支付",
            callback:()=>{
              this.$router.forward({
                path: "/creatorder/orderdetail",
                query: {id: res.id}
              });
            }
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
  .info-line{
    background: #fff;
  }
  .order-btn{
    position: relative!important;
  }
</style>
