<template>
  <div>
    <div class="hkguanli">
      <div class="background1">
        <img src="@/assets/views/gallery/hkguanli/background1.jpg" alt="">
        <div class="back1">专业户口业务服务平台</div>
        <div class="back2"></div>
        <div class="card1">
          <div class="zn1">
            <div class="zn1hz" @click="$router.forward({path:'/gallery/hkguanli-style',query:{id:1}})">
              <div class="bgimg1" style="background:#4272ff">
                <img src="@/assets/views/gallery/hkguanli/BZ1.png" alt="">
              </div>
              <div class="zn1title">人才落户</div>
            </div>
            <div class="zn1hz" @click="$router.forward({path:'/gallery/hkguanli-style',query:{id:2}})">
              <div class="bgimg1" style="background:#34c47c">
                <img src="@/assets/views/gallery/hkguanli/BZ2.png" alt="">
              </div>
              <div class="zn1title">技工落户</div>
            </div>
            <div class="zn1hz" @click="$router.forward({path:'/gallery/hkguanli-style',query:{id:3}})">
              <div class="bgimg3" style="background:#ff9351">
                <img src="@/assets/views/gallery/hkguanli/BZ3.png" alt="">
              </div>
              <div class="zn1title">积分落户</div>
            </div>
            <div class="zn1hz" @click="$router.forward({path:'/gallery/hkguanli-style',query:{id:4}})">
              <div class="bgimg1" style="background:#c96cff">
                <img src="@/assets/views/gallery/hkguanli/BZ4.png" alt="">
              </div>
              <div class="zn1title">购房落户</div>
            </div>
          </div>
          <div class="zn1" >
            <div class="zn1hz" @click="$router.forward({path:'/gallery/hkguanli-style',query:{id:5}})">
              <div class="bgimg2" style="background:#34c47c">
                <img src="@/assets/views/gallery/hkguanli/BZ5.png" alt="">
              </div>
              <div class="zn1title">新生儿落户</div>
            </div>
            <div class="zn1hz" @click="$router.forward({path:'/gallery/hkguanli-style',query:{id:6}})">
              <div class="bgimg1" style="background:#c96cff">
                <img src="@/assets/views/gallery/hkguanli/BZ6.png" alt="">
              </div>
              <div class="zn1title">夫妻投靠</div>
            </div>
            <div class="zn1hz" @click="$router.forward({path:'/gallery/hkguanli-style',query:{id:7}})">
              <div class="bgimg1" style="background:#4272ff">
                <img src="@/assets/views/gallery/hkguanli/BZ7.png" alt="">
              </div>
              <div class="zn1title">子女投靠</div>
            </div>
            <div class="zn1hz" @click="$router.forward({path:'/gallery/hkguanli-style',query:{id:8}})">
              <div class="bgimg1" style="background:#ff9351">
                <img src="@/assets/views/gallery/hkguanli/BZ8.png" alt="">
              </div>
              <div class="zn1title">户口平迁</div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-container swiper-container-initialized swiper-container-horizontal banner">
        <div class="swiper-wrapper">
          <div @click="$router.forward({path:'/gallery/hkguanli-gfprove'})" class="swiper-slide swiper-slide-active">
            <img src="@/assets/views/gallery/hkguanli/banner1.png" alt="">
            <div class="bannerconte1">购房证明</div>
            <div class="bannerconte2">在青岛，买房需先出具购房资格证明</div>
          </div>
          <div @click="$router.forward({path:'/gallery/hkguanli-jzz'})" class="swiper-slide swiper-slide-next">
            <img src="@/assets/views/gallery/hkguanli/banner2.png" alt="">
            <div class="bannerconte1">居住证</div>
            <div class="bannerconte3">在青岛居住半年以上可申请居住证</div>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination swiper-pagination-bullets"><span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span></div>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
      <div class="yongt">
        <div class="yongtfk"></div>
        <div class="yongtbt">户口用途</div>
      </div>
      <div class="yongt2">
        <div class="yongt2top">
          <div class="mk1" @click="$router.forward({path:'/gallery/hkguanli-item',query:{id:1}})">
            <div class="mk1title">购房</div>
            <div class="mk1hx"></div>
            <img src="@/assets/views/gallery/hkguanli/DB1.png" alt="">
          </div>
          <div class="mk2" >
            <div class="mk2one" @click="$router.forward({path:'/gallery/hkguanli-item',query:{id:2}})">
              <img src="@/assets/views/gallery/hkguanli/DB2.png" alt="">
              <div class="mk2onetitle">孩子上学</div>
            </div>
            <div class="mk2two" @click="$router.forward({path:'/gallery/hkguanli-item',query:{id:3}})">
              <img src="@/assets/views/gallery/hkguanli/DB3.png" alt="">
              <div class="mk2onetitle">公租房</div>
            </div>
          </div>
          <div class="mk3">
            <div class="mk3one" @click="$router.forward({path:'/gallery/hkguanli-item',query:{id:5}})">
              <img src="@/assets/views/gallery/hkguanli/DB4.png" alt="">
              <div class="mk3onetitle">人才公寓</div>
            </div>
            <div class="mk3two" @click="$router.forward({path:'/gallery/hkguanli-item',query:{id:4}})">
              <img src="@/assets/views/gallery/hkguanli/DB5.png" alt="">
              <div class="mk3twotitle">经济适用房</div>
            </div>
          </div>
        </div>
        <div class="mk4" @click="$router.forward({path:'/gallery/hkguanli-item',query:{id:6}})" >
          <div class="mk4title">住房补贴</div>
          <div class="mk4heng"></div>
          <img src="@/assets/views/gallery/hkguanli/DB6.jpg" alt="">
        </div>
        <div class="mk5" @click="$router.forward({path:'/gallery/hkguanli-item',query:{id:7}})">
          <div class="mk4title">创业补贴</div>
          <div class="mk4heng"></div>
          <img src="@/assets/views/gallery/hkguanli/DB7.png" alt="">
        </div>
        <div class="mk6" @click="$router.forward({path:'/gallery/hkguanli-item',query:{id:8}})">
          <div class="mk4title">共有产权房</div>
          <div class="mk4heng"></div>
          <img src="@/assets/views/gallery/hkguanli/DB8.png" alt="">
        </div>
      </div>
      <div class="yongt">
        <div class="yongtfk"></div>
        <div class="yongtbt">办理流程</div>
      </div>
      <div class="liucheng">
        <img class="lct1" src="@/assets/views/gallery/hkguanli/lc1.png" alt="">
        <img class="lct2" src="@/assets/views/gallery/hkguanli/lc2.png" alt="">
        <img class="lct3" src="@/assets/views/gallery/hkguanli/lc3.png" alt="">
        <img class="lct4" src="@/assets/views/gallery/hkguanli/lc4.png" alt="">
        <img class="lct5" src="@/assets/views/gallery/hkguanli/lc5.png" alt="">
        <img class="lct6" src="@/assets/views/gallery/hkguanli/lc6.png" alt="">
        <img class="jt1" src="@/assets/views/gallery/hkguanli/jt1.png" alt="">
        <img class="jt2" src="@/assets/views/gallery/hkguanli/jt1.png" alt="">
        <img class="jt3" src="@/assets/views/gallery/hkguanli/jt2.png" alt="">
        <img class="jt4" src="@/assets/views/gallery/hkguanli/jt3.png" alt="">
        <img class="jt5" src="@/assets/views/gallery/hkguanli/jt3.png" alt="">
        <div class="content1">1.咨询顾问</div>
        <div class="content2">2.定制专属落</div>
        <div class="content3">户方案</div>
        <div class="content4">3.专员跟进</div>
        <div class="content5">4.递交相关材料</div>
        <div class="content6">5.派出所落户</div>
        <div class="content7">6.完成</div>
      </div>
      <div class="bszn">
        <div class="bszn1">
          <div class="bszn1fk"></div>
          <div class="bszn1content">办事指南</div>
        </div>
        <div class="drawer1">
          <ul @click="$router.forward({path:'/gallery/residence-emigration'})">
            <li><img src="@/assets/views/gallery/hkguanli/zn1.png" alt=""></li>
            <li>户口迁出</li>
          </ul>
          <ul @click="$router.forward({path:'/gallery/cut-from'})">
            <li><img src="@/assets/views/gallery/hkguanli/zn2.png" alt=""></li>
            <li>单页借取</li>
          </ul>
        </div>
        <div class="drawer2">
          <ul @click="$router.forward({path:'/gallery/page-return'})">
            <li><img src="@/assets/views/gallery/hkguanli/zn3.png" alt=""></li>
            <li>单页归还</li>
          </ul>
          <ul @click="$router.forward({path:'/gallery/page-add'})">
            <li><img src="@/assets/views/gallery/hkguanli/zn4.png" alt=""></li>
            <li>单页补办</li>
          </ul>
        </div>
        <div class="drawer4">
          <ul @click="$router.forward({path:'/gallery/no-house'})">
            <li><img src="@/assets/views/gallery/hkguanli/zn5.png" alt=""></li>
            <li>无房证明</li>
          </ul>
        </div>
      </div>
      <div class="bottomimg">
        <img src="@/assets/views/gallery/hkguanli/back.jpg" alt="">
        <div class="bottomtitle1">十余年青岛落户经验</div>
        <div class="bottomtitle2">助您落户青岛</div>
      </div>
      <div class="zw"></div>
      <div class="Consultation">
        <div class="Consultationfk"></div>
        <div class="ConsultationContent">资讯</div>
        <div class="ConsultationContent1" @click="Jumpinfo">更多资讯</div>
      </div>
      <xzJump :type="type"></xzJump>
      <!-- <div class="bottomButton">
        <div class="buttonbox">
          <div class="buttonbox1" @click="$router.forward({path:'/mine/service'})">
            <div class="buttonbox1content">咨询顾问</div>
            <img src="@/assets/views/gallery/daguanli/ej.png" alt="">
          </div>
          
          <div class="buttonbox2" @click="showConsult()">
            <img class="bigimg" src="@/assets/views/gallery/daguanli/dibu.png" alt="">
            <img class="smallimg" src="@/assets/views/gallery/daguanli/fdj.png" alt="">
            <div class="buttonbox2content">档案查询</div>
          </div>
        </div>
      </div> -->
    </div>
      
  </div>
</template>

<script>
import Swiper from "swiper";
import "@/sass/views/gallery/hkguanli.scss";
import xzJump from "./xzJump"
export default {
  data(){
    return {
      type:4
    }
  },
  created(){
    this.$nextTick(() => {
      new Swiper(".banner" , {
        pagination: {
          el: ".swiper-pagination"
        },
      })
    })
  },
  components:{
    xzJump,
  },
  methods:{
    Jumpinfo(){
      this.$router.forward({path:'/information/list',query:{id:4}});
    },
    
    /**
     * 展示顾问
     */
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return;
      }
      this.$router.forward({path:'/mine/service'})
    },
  
  }
}
</script>

<style>

</style>