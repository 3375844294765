<template>
    <div class="mine-detail-userinfo">
        <div class="mine-nav" :style="{paddingTop: $statusBarHeight + 'px' }">
            <div class="item" :class="{'active':type == 1}" @click="setType(1)">全部</div>
            <div class="item" :class="{'active':type == 2}" @click="setType(2)">电子资料</div>
        </div>
        <div class="type1" v-show="type == 1">
            <div class="title">个人信息</div>
            <div class="infolist">
                <div class="item user-face">
                    <div class="lable">个人照片</div>
                    <div class="content">
                        <div class="userimg" @click="choiseUploadFace" v-if="!basicsInfo.personphotourl">
                            <div class="dips">上传照片</div>
                            <input type="file" @change="uploadFileFace" style="display: none" />
                        </div>
                        <div class="userimgtrue" @click="choiseUploadFace" v-else>
                            <img :src="basicsInfo.personphotourl" />
                            <input type="file" @change="uploadFileFace" style="display: none" />
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="lable">账号</div>
                    <div class="content">{{basicsInfo.tel}}</div>
                </div>
                <div class="item">
                    <div class="lable">姓名</div>
                    <div class="content">{{basicsInfo.name}}</div>
                </div>
                <div class="item">
                    <div class="lable">身份证号</div>
                    <div class="content">{{basicsInfo.idcard}}</div>
                </div>
            </div>
            <div class="title">学校信息</div>
            <div class="infolist">
                <div class="item">
                    <div class="lable">性别</div>
                    <div class="content">
                        <img class="right" src="@/assets/right.png" alt />
                        <div class="select-style" :class="{'placehold':getSexName(basicsInfo.sex)=='请选择'}" ref="sexSelect">
                            {{getSexName(basicsInfo.sex)}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="lable">政治面貌</div>
                    <div class="content">
                        <img class="right" src="@/assets/right.png" alt />
                        <div class="select-style" :class="{'placehold': getPoliticalstatusName(basicsInfo.politicalstatus)=='请选择'}"
                            ref="politicalstatusSelect">{{getPoliticalstatusName(basicsInfo.politicalstatus)}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="lable">学历</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.education" placeholder="请输入学历" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">学校</div>
                    <div class="content">
                        <img class="right" src="@/assets/right.png" alt />
                        <div @click="showSchool()" class="select-style" :class="{'placehold':!basicsInfo.school}" ref="schoolSelect">
                            {{basicsInfo.school?basicsInfo.school:'请选择'}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="lable">院系</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.college" placeholder="请输入院系" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">专业</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.major" placeholder="请输入专业" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">毕业年份</div>
                    <div class="content">
                        <img class="right" src="@/assets/right.png" alt />
                        <div class="select-style" :class="{'placehold':!basicsInfo.graduationyear}" ref="yearSelect">
                            {{basicsInfo.graduationyear?basicsInfo.graduationyear:'请选择'}}</div>
                    </div>
                </div>
                <div class="item">
                    <div class="lable">QQ/微信</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.wxqq" placeholder="请输入QQ/微信" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">婚姻状况</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.maritalstatus" placeholder="请输入婚姻状况" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">户口所在地</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.hukouadd" placeholder="户口所在地" type="text" />
                    </div>
                </div>
            </div>
            <div class="title">联系方式</div>
            <div class="infolist">
                <div class="item">
                    <div class="lable">紧急联系人1</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.contactsone" placeholder="请填写联系人姓名" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">紧急联系电话1</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.contactstwo" placeholder="请填写联系人电话" maxlength="11"
                            oninput="value=value.replace(/[^\d.]/g,'')" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">紧急联系人2</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.onetel" placeholder="请填写联系人姓名" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">紧急联系电话2</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.twotel" placeholder="请填写联系人电话" oninput="value=value.replace(/[^\d.]/g,'')"
                            maxlength="11" type="text" />
                    </div>
                </div>
            </div>
            <div class="title">账号信息</div>
            <div class="infolist">
                <div class="item">
                    <div class="lable">高校信息网用户账号</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.gaoxiaoname" placeholder="请输入(非必填)" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">高校信息网用户密码</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.gaoxiaopwd" placeholder="请输入(非必填)" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">人才网用户名</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.rencainame" placeholder="请输入(非必填)" type="text" />
                    </div>
                </div>
                <div class="item">
                    <div class="lable">人才网密码</div>
                    <div class="content">
                        <input class="content-input" v-model="basicsInfo.rencaipwd" placeholder="请输入(非必填)" type="text" />
                    </div>
                </div>
            </div>
        </div>
        <div class="type2" v-show="type == 2">
            <div class="imgcon" v-for="item in userFileList" :key="item.id">
                <div class="top-info">
                    <div class="title">{{item.itemname}}</div>
                    <div class="tips">请上传</div>
                    <div class="upload-btn" @click="choiseUploadFile(item)">
                        <img class="upload-icon" src="@/assets/views/mine/upload-icon.png" alt />上传照片
                        <input type="file" :ref="'upload'+item.id" @change="uploadFile" style="display: none" />
                    </div>
                </div>
                <div class="img-list">
                    <div class="items" v-if="item.fileVos == 0">
                        <div class="item" v-if="item.id == 3">
                            <img class="yangli" src="@/assets/yangli/byz.jpg" alt />
                        </div>

                        <div class="item" v-if="item.id == 4">
                            <img class="yangli" src="@/assets/yangli/xwz.jpeg" alt />
                        </div>

                        <div class="item" v-if="item.id == 6">
                            <img class="yangli" src="@/assets/yangli/bdzz.png" alt />
                            <img class="yangli" src="@/assets/yangli/bdzf.jpg" alt />
                        </div>

                        <div class="item" v-if="item.id == 13">
                            <img class="yangli" src="@/assets/yangli/jhz.jpg" alt />
                        </div>

                        <div class="item" v-if="item.id == 16">
                            <img class="yangli" src="@/assets/yangli/hkbgry.png" alt />
                            <img class="yangli" src="@/assets/yangli/hkbsy.png" alt />
                            <img class="yangli" src="@/assets/yangli/hkbsyy.jpg" alt />
                        </div>

                        <div class="item" v-if="item.id == 31">
                            <img class="yangli" src="@/assets/yangli/csyxzm.jpg" alt />
                        </div>
                    </div>
                    <div class="items" ref="overflow" v-else>
                        <div class="item" v-for="child in item.fileVos" :key="child.id">
                            <div class="delete-icon" @click="deleteInfo(child)"></div>
                            <img class="yangli" :src="child.url" alt />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="userinfo-submit" v-show="type == 1" @click="sumbitUserInfo()">提交</div>
    </div>
</template>

<script>
import "@/sass/views/mine/userDetailInfo.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import { mapGetters } from "vuex";
export default {
    name: "userDetailInfo",
    data() {
        return {
            type: 1, //选项卡当前位置
            cttrId: "",
            basicsInfo: {}, //基础信息
            userFileList: [] //用户电子图片
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    mounted() {
        this.iniSelect();
        this.setType(1);
    },
    methods: {
        /**
         * 初始化下拉列表
         */
        iniSelect() {
            new MobileSelect({
                trigger: this.$refs.politicalstatusSelect,
                title: "性别",
                wheels: [
                    {
                        data: [
                            { id: 1, value: "预备党员" },
                            { id: 2, value: "党员" },
                            { id: 3, value: "团员" },
                            { id: 4, value: "群众" }
                        ]
                    }
                ],
                callback: (indexArr, data) => {
                    this.basicsInfo.politicalstatus = data[0].id;
                }
            });
            new MobileSelect({
                trigger: this.$refs.sexSelect,
                title: "性别",
                wheels: [
                    {
                        data: [
                            { id: 0, value: "男" },
                            { id: 1, value: "女" }
                        ]
                    }
                ],
                callback: (indexArr, data) => {
                    this.basicsInfo.sex = data[0].id;
                }
            });
            let date = Number(new Date().getFullYear() + 5);
            let yearList = [];
            for (let i = 0; i < 50; i++) {
                yearList.push(date--);
            }
            new MobileSelect({
                trigger: this.$refs.yearSelect,
                title: "性别",
                wheels: [
                    {
                        data: yearList
                    }
                ],
                callback: (indexArr, data) => {
                    this.basicsInfo.graduationyear = data[0];
                }
            });
        },
        /**
         * 获取详情信息
         */
        getDetail() {
            this.$siaxios.get(`/userfile/baseinfo/${this.userInfo.id}`).then(res => {
                this.basicsInfo = res;
            });
        },
        /**
         * 获取政治面貌名称名称
         */
        getPoliticalstatusName(id) {
            if (id === 1) return "预备党员";
            else if (id === 2) return "党员";
            else if (id === 3) return "团员";
            else if (id === 4) return "群众";
            else return "请选择";
        },
        /**
         * 获取性别名称
         */
        getSexName(id) {
            if (id === 0) return "男";
            else if (id === 1) return "女";
            else return "请选择";
        },
        /**
         * 获取电子资料
         */
        getField() {
            this.$siaxios
                .get(`/userfile/userFileList/${this.userInfo.id}`)
                .then(res => {
                    this.userFileList = res;
                    this.$nextTick(() => {
                        if (this.$refs.overflow) {
                            this.$refs.overflow.forEach(element => {
                                let length = element.getElementsByClassName("item").length;
                                element.style.width = (310 * length) / 7.5 + "vw";
                            });
                        }
                    });
                });
        },
        /**
         * 选项卡设置
         */
        setType(type) {
            this.type = type;
            if (type == 1) this.getDetail();
            else if (type == 2) this.getField();
        },
        /**
         * 选中上传图片
         */
        choiseUploadFile(item) {
            this.cttrId = item.id;
            this.$refs["upload" + item.id][0].click();
        },
        uploadFile(event) {
            let _this = this;
            let myTaget = event.currentTarget;
            let myfild = myTaget.files[0];

            if (!this.isImg(myfild.type)) {
                this.$tips({
                    title: "提示",
                    describe: "只能上传图片格式"
                });
                event.currentTarget.value = "";
                return;
            }
            // 压缩
            if (myfild.size <= 1 * 1024 * 1024) {
                //判断图片是否小于1M,是就直接上传
                this.postImg(myTaget, myfild);
            } else {
                // 压缩图片
                let reader = new FileReader();
                reader.readAsDataURL(myfild);
                reader.onloadend = function () {
                    let img = new Image();
                    img.src = this.result;
                    let canvas = document.createElement("canvas");
                    let drawer = canvas.getContext("2d");
                    img.onload = function () {
                        let width = img.width;
                        let height = img.height;
                        if ((width * height) / 1000000 > 1) {
                            width = 720;
                            height = width * (img.height / img.width);
                        }
                        canvas.width = width;
                        canvas.height = height;
                        drawer.drawImage(img, 0, 0, canvas.width, canvas.height);
                        // img.src = canvas.toDataURL("image/jpg", 0.7); //0.7图片质量
                        canvas.toBlob(blob => {
                            myfild = new File([blob], `${myfild.name.substring(0, 10)}.jpg`);
                            _this.postImg(myTaget, myfild);
                        });
                    };
                };
            }
        },
        postImg(event, myfild) {
            const formData = new FormData();
            formData.append("file", myfild);
            this.$siaxios
                .post("/uploadFile/uploadFile/2", formData, false)
                .then(res => {
                    let param = {
                        userid: this.userInfo.id,
                        itemid: this.cttrId,
                        fileid: res.fileid
                    };
                    this.$siaxios.post("userfile/add", param, false).then(() => {
                        this.getField();
                    });
                });
            event.value = "";
        },
        /**
         * 验证是否是图片
         * @param {*} type  图片类型
         */

        isImg(type) {
            const isJPG = type === "image/jpeg";
            const isPNG = type === "image/png";
            const isBMP = type === "image/bmp";
            const isGIF = type === "image/gif";
            const isWEBP = type === "image/webp";
            const isPic = isJPG || isPNG || isBMP || isGIF || isWEBP;
            return isPic;
        },
        /**
         * 选择学校
         */
        showSchool() {
            this.$schoolselect({
                ywid: 1,
                callback: (id, name) => {
                    this.basicsInfo.school = name;
                }
            });
        },
        /**
         * 选中上传图片
         */
        choiseUploadFace(event) {
            let myTaget = event.currentTarget;
            let myfild = myTaget.getElementsByTagName("input");
            myfild[0].click();
        },
        uploadFileFace(event) {
            let _this = this;
            let myTaget = event.currentTarget;
            let myfild = myTaget.files[0];
            // 压缩
            if (myfild.size <= 1 * 1024 * 1024) {
                //判断图片是否小于1M,是就直接上传
                this.postImgFace(myTaget, myfild);
            } else {
                // 压缩图片
                let reader = new FileReader();
                reader.readAsDataURL(myfild);
                reader.onloadend = function () {
                    let img = new Image();
                    img.src = this.result;
                    let canvas = document.createElement("canvas");
                    let drawer = canvas.getContext("2d");
                    img.onload = function () {
                        let width = img.width;
                        let height = img.height;
                        if ((width * height) / 1000000 > 1) {
                            width = 720;
                            height = width * (img.height / img.width);
                        }
                        canvas.width = width;
                        canvas.height = height;
                        drawer.drawImage(img, 0, 0, canvas.width, canvas.height);
                        // img.src = canvas.toDataURL("image/jpg", 0.7); //0.7图片质量
                        canvas.toBlob(blob => {
                            myfild = new File([blob], `${myfild.name.substring(0, 10)}.jpg`);
                            _this.postImgFace(myTaget, myfild);
                        });
                    };
                };
            }
        },
        postImgFace(event, myfild) {
            const formData = new FormData();
            formData.append("file", myfild);
            this.$siaxios
                .post("/uploadFile/uploadFile/1", formData, false)
                .then(res => {
                    this.basicsInfo.personphoto = res.fileid;
                    this.basicsInfo.personphotourl = res.fileurl;
                });
            event.value = "";
        },
        /**
         * 删除电子文件
         */
        deleteInfo(item) {
            let tempdata = {
                id: item.id,
                isdel: 1
            };
            this.$siaxios.post("userfile/edit", tempdata, false).then(() => {
                this.getField();
            });
        },
        /**
         * 修改个人信息
         */
        sumbitUserInfo() {
            this.$confirm({
                title: "资料提交",
                describe:
                    "资料仅用于办理业务中使用，资料严格保密，请确保填写信息准确，勿随意修改。",
                callback: boolean => {
                    if (boolean) {
                        this.$axios.post(`/userfile/addbase`, this.basicsInfo).then(res => {
                            if (res.data.success) {
                                this.$router.forward("/mine/index");
                            } else {
                                this.$tips({ title: "提示", describe: res.data.errMsg });
                            }
                        });
                    }
                }
            });
        }
    }
};
</script>