<template>
  <div class="dangantd-creatorder">
    <div class="banner-con">
      <div class="banner">
        <span>档案提档</span>
      </div>
    </div>

    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <div class="cont-box">
          <div
            class="content-text"
          >因手续繁琐或业务繁忙等原因不能亲自到人事档案保管单位提取档案，只需按照流程提供相关证明，档管家可全程为您代办档案提取业务，让您省心省力，安全有保障。</div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
        <span class="intro-title">业务流程</span>
      </div>
      <div class="process">
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/da-dc1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>APP申请归还</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/fukuan.jpg" alt />
          </div>
          <div class="procss-text">
            <span>付款</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>邮寄提档材料</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进办理</span>
          </div>
        </div>
      </div>
      <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>
      </div>
      <div class="process" style="margin-top: 20px;margin-right:3px;">
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/laodongguanxi.png" alt />
          </div>
          <div class="procss-text">
            <span>档案邮寄到指定地点</span>
          </div>
        </div>
        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/kehu.jpg" alt />
          </div>
          <div class="procss-text">
            <span>查收</span>
          </div>
        </div>
        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/shuoming.jpg" alt />
        <span class="intro-title">温馨提示</span>
      </div>
      <div class="content">
        <div class="cont-box">
          <div class="content-num">1.</div>
          <div
            class="content-text"
          >档案是个人身份、学历、工作经历等方面的证据，与个人工资待遇、社会劳动保障、组织关系紧密挂钩，在个人职称申报、办理养老保险、考取公务员一级开具考研等相关证明，都需要使用档案。</div>
        </div>
        <div class="cont-box">
          <div class="content-num">2.</div>
          <div class="content-text">提档前请一定要确认好档案接收地址及邮寄形式（机要，非机要）以免造成退档</div>
        </div>
        <div class="cont-box">
          <div class="content-num">3.</div>
          <div class="isthone">
            <div class="thone-text">
              <span>请选择邮寄调档材料到我方的请邮寄到指定地址。</span>
              <p>收件人：李青青</p>
              <div class="isthone">
                <div class="thone" style="width:70px;">
                  <span>联系电话：</span>
                </div>
                <div class="thone" style="width:200px;">
                  <span>0532-85873655转813或815</span>
                  <p>18661887826</p>
                </div>
              </div>
              <p>联系地址：青岛市市南区高雄路18号海洋大厦5层</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/cailiao.jpg" alt />
        <span class="intro-title">所需资料</span>
      </div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">调档函原件</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">2</div>
            <div class="neds-text">身份证复印件</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text">委托书（他人借取）附模板</div>
          </div>
        </div>
        <div class="needs">
          <div class="word">
            <div class="word-icon">
              <img class="title-img" src="@/assets/views/mine/house.png" alt />
            </div>
            <div class="word-text">
              <span>委托书模板.doc</span>
            </div>
            <div class="word-btn" @click="uploadFile(1)">
              <iframe :src="exportApi" frameborder="0" style="width:0;height:0"></iframe>
              <button>下载</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="cont-use" style='padding-top:20px;'>
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/dangan-quest_03.jpg" alt style="width:15px;height:15px" />
          <span>常见问题</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="active-title">1.提档需要多长时间？</div>
        <div class="active-cont">我们每周定期去档案室提取档案，只要您的提档材料到达后，7个工作日内可办理档案寄出，如有紧急提档需求，可单独与顾问联系可加急办理。</div>
      </div>
      <div class="small-ine"></div>
      <div class="use-cont">
        <div class="active-title">2.机要邮寄一般多长时间可到达？</div>
        <div class="active-cont">机要邮寄周期长，一般需要15个工作日方可送达</div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/dangantd.scss";

export default {
  name: "dangantd",
  data() {
    return {
      exportApi: ""
    };
  },
  methods: {
    //委托书模板下载
    uploadFile(type) {
      let url = this.$axios.defaults.baseURL + "common/downloadFile/" + type;
      this.$copyText(`${url}`).then(
        () => {
          this.$tips({
            describe: "已成功复制链接，请通过浏览器下载该文档"
          });
        },
        () => {
          this.$tips({
            describe: "复制失败"
          });
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
</style>

