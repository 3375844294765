<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>熟悉和了解相关的劳动法律法规及政策，维护自身合法利益，依法维权也是职场人必备的技能之一。在线劳动法律咨询，私人劳动法律顾问，打造您的劳权宝典。 </p>
            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

