<template>
  <div class="businessIntro-index">
    <div class="showmessage">
      <div class="order-title">重要说明 :（必读）</div>
      <div class="content">户口在集体户，户口单页不慎丢失或毁坏，档管家可全程为您代办，让您省心省力，安全有保障。</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="showmessage">
      <div class="order-title">公积金封存注意事项</div>
      <div class="content">若您停止购买后，本平台通常会于次月截止时间后一周操作封存公积金（部分地区可能会有延迟），需要提前封存的用户，请提前联系顾问</div>
      <div class="content">如果您当月申请封存后，又想重新购买当月的公积金，请务必先联系顾问撤销封存，避免漏缴</div>
    </div>
    <div class="order-gray-line"></div>
    <div class="order-services">
      <div class="title">
        <div class="item" @click="showType = 1" :class="{'active': showType == 1}">办理流程</div>
        <div class="item" @click="showType = 2" :class="{'active': showType == 2}">平台优势</div>
        <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
      </div>
      <div class="show-content">
        <div
          class="showtype"
          style="background: #f6faff;"
          v-if="showType == 1"
        >
          <div class="order-title">办理流程</div>
          <div class="showinfo-1">
            <div class="showinfo-1-">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-1.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>办理人登记填写信息资料</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-3.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>付款成功</span>
                </div>
              </div>
              <div class="showinfo-1-arrow">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-4.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>专员跟进</span>
                </div>
              </div>
            </div>
            <div class="showinfo-1-">
              <div class="show-img">
                <img src="@/assets/views/creatorder/arrow2.png" alt />
              </div>
            </div>
            <div class="showinfo-1-" style="margin-top: 40px">
              <div class="showinfo-1-item" style="margin-left:20px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-6.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>完成</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-5.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>专人办理</span>
                </div>
              </div>
              <div class="trastom">
                <img src="@/assets/views/creatorder/arrow.png" alt />
              </div>
              <div class="showinfo-1-item" style="margin-left:15px">
                <div class="showinfo-1-img">
                  <img src="@/assets/views/creatorder/orde-2.png" alt />
                </div>
                <div class="showinfo-1-cont">
                  <span>收集材料</span>
                </div>
              </div>
            </div>
          </div>
        </div><div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div><div
          class="showtype"
          v-if="showType == 2"
        >
          <div class="order-title">平台优势</div>
          <div class="showinfo-2">
            <div class="showinfo-2-item">
              <div class="num">
                <span>1</span>
              </div>
              <div class="item-cont">
                <span>中海智库人力服务已创立十年之久,十余年坚守品质，初心依旧。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>2</span>
              </div>
              <div class="item-cont">
                <span>您的口碑才是我们的品牌,不断超越，争当人力服务行业领军先锋。</span>
              </div>
            </div>
            <div class="showinfo-2-item">
              <div class="num">
                <span>3</span>
              </div>
              <div class="item-cont">
                <span>有序、严谨、专业、靠谱、成熟的运营机制，让您享受价格的优势，高端的服务</span>
              </div>
            </div>
            <div class="showinfo-2-item2">
              <div class="item2">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage1.png" alt />
                </div>
                <div class="item-text">
                  <span>一站式服务</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage2.png" alt />
                </div>
                <div class="item-text">
                  <span>十年品牌</span>
                </div>
              </div>
              <div class="item2" style="margin-left:50px">
                <div class="item-img">
                  <img src="@/assets/views/creatorder/advantage3.png" alt />
                </div>
                <div class="item-text">
                  <span>安心之选</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="showtype showtype3"
          v-if="showType == 3"
        >
          <div class="order-title">常见问题</div>
          <div class="showinfo-3">
            <div class="showinfo-3-item">
              <div class="showinfo-3-quest">
                <div class="show3-num">
                  <span>Q</span>
                </div>
                <div class="shou3-cont">
                  <span>补办户口单页需要带什么材料?</span>
                </div>
              </div>
              <div class="item-line"></div>
              <div class="showinfo-3-cont">
                <div class="show3-num">
                  <span>A</span>
                </div>
                <div class="shou3-cont">
                  <span>身份证、补办户口单页申请书、委托书(非本人补办）。-15日查询缴纳情况</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";
export default {
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>