<!--
 * @Description: 
 * @Autor: bjp
 -->
<template>
  <div class="policyList" v-loading.fullscreen="loadingFullscreen" ref="policyList">
    <div :class="isShowMask?'mask':''"></div>
    <template v-if="refresh">
      <v-refresh :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad" ref="refresh">
        <div class="bar">
          <ul>
            <li
              v-for="item in barList"
              :key="item.id"
              @click="changeTabBar(item.id)"
              :class="item.isShow?'active':''"
            >{{item.title}}
            </li>
          </ul>
        </div>
        <my-temp :list="showData.list" :label="ifLabel" :id="submitData.zixuntype" @tempMask="tempMask"></my-temp>
      </v-refresh>
    </template>
    <!-- 无下拉刷新 -->
    <template v-else>
      <div class="bar" >
        <ul>
          <li
            v-for="item in barList"
            :key="item.id"
            @click="changeTabBar(item.id)"
            :class="item.isShow?'active':''"
          >{{item.title}}
          </li>
        </ul>
      </div>
      <my-temp :list="showData.list" :label="ifLabel" :id="submitData.zixuntype" @tempMask="tempMask"></my-temp>
    </template>
  </div>
</template>
<script>
import "@/sass/views/question/policy.scss";
import export_temp from './Temp'
import refresh from "@/components/plug-in/pulldown";
export default {
  props:{
    //分类1社保2公积金3档案4户口5就业创业
    type:{
      type:String,
      default:''
    },
    //小类1养老2医疗3工伤4生育5失业6社保卡
    state:{
      type:String,
      default:''
    },
    // 显示数据数量
    judge:{
      type:String,
      default:''
    },
    //是否有上拉刷新
    refresh:{
      type:Boolean,
      default:true
    },
    // 跳转url
    url:{
      type:String,
      default:'/'
    },
    ifShow:{
      type:String,
      default:'1'
    },
    ifLabel:{
      type:String,
      default:'1'
    }
  },
  components: {
    "v-refresh": refresh,
    'my-temp':export_temp
  },
  data() {
    return {
      loadingFullscreen: "",
      barList: [
        { id: 1, title: "政策", isShow: true },
        { id: 2, title: "资讯", isShow: false },
        { id: 3, title: "常见问题", isShow: false }
      ],
      showData: {
        pageNum: 1,
        list:[]
      },
      submitData:{
        type: "",
        state: "",
        //资讯类型1政策2资讯3常见问题
        zixuntype: '1',
      },
      isShowMask:false,
    };
  },
  watch:{
    'submitData.zixuntype'(old,newval){
      if(old != newval){
        this.showData.pageNum = 1
        this.showData.list = []
        this.$parent.pullUpState = 0
        if(this.refresh) this.$refs.refresh.pullUpState = 0
      }
    },
    'isShowMask'(val){
      if(val){
        document.body.classList.add('maskFixed');
      } else {
        document.body.classList.remove('maskFixed');
      }
    }
  },
  mounted() {
    this.submitData.type = this.type
    this.submitData.state = this.state
    this.showData.list = []
    // 浮动点击跳转
    if(this.$route.query.zixuntype) {
      this.submitData.zixuntype = this.$route.query.zixuntype
      this.changeTabBar(this.submitData.zixuntype)
    } else {
      this.getList()
    }
  },
  methods: {
    async getList(id) {
      let flag = id || this.submitData.zixuntype
      let param = this.showData.pageNum +"?type=" +this.submitData.type +"&state=" +this.submitData.state
      let url;
      if(flag == 3){
        url = '/question/list/' + param
      } else{
        url = '/information/list/' + param + "&zixuntype=" + this.submitData.zixuntype
      }
      let temp = {list:[]}
      temp = await this.$siaxios.get(url)
        if(temp.list.length > 0){
          temp.list.forEach(item =>{
            this.showData.list.push(item)
          })
          if(this.judge){
            this.showData.list = this.showData.list.splice(0,this.judge)
          }
        }
        return temp.list.length
    },
    changeTabBar(i) {
      this.showData.list = []
      this.barList.forEach((item) => {
        item.isShow = false;
        if (i == item.id) {
          item.isShow = true;
          this.submitData.zixuntype = item.id
          this.showData.pageNum = 1
          this.getList(item.id)
        }
      });
      if(!this.refresh && this.judge == 1){
        this.$router.forward({path:this.url,query:{isShow:1,zixuntype:this.submitData.zixuntype}})
      }
    },
		tempMask(flag){
			this.isShowMask = flag
		},
    //下拉刷新
    onRefresh(done) {
      this.showData.pageNum = 1
      this.showData.list = []
      this.getList().then(() =>{
        done(); // call done
      })
    },
    // 上拉加载
    onInfiniteLoad(done) {
      this.showData.pageNum++;
      this.getList().then(res => {
        done(res);
      });
    }
  }
};
</script>