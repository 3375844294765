<template>
  <div class="hukoubanli-creatorder">
    <div :class="$route.query.id==1?'banner-con2':'banner-con'">
      <div class="banner">
        <span>户口办理</span>
      </div>
    </div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/banshi.jpg" alt />
        <span class="intro-title">业务介绍</span>
      </div>
      <div class="content">
        <div class="cont-box">
          <div
            class="content-text"
          >在校大学生或毕业年度的大学生可申请办理落户，大学生在青落户可享受本市购房政策，符合条件的可申请人才公寓，还有诸多住房补贴，就业补贴等。</div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce " >
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/liucheng.jpg" alt />
        <span class="intro-title">业务流程</span>
      </div>
      <div class="process">
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/da-dc1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>APP注册账号</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/fukuan.jpg" alt />
          </div>
          <div class="procss-text">
            <span>付款成功</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/guihuan1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>填写相关信息</span>
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/views/mine/da-arrow.jpg" alt />
        </div>
        <div class="process-con">
          <div class="proess-img">
            <img src="@/assets/views/mine/tiqu1.jpg" alt />
          </div>
          <div class="procss-text">
            <span>递交相关信息</span>
          </div>
        </div>
      </div>
      <div class="process2">
        <div class="arrow-up">
          <img src="@/assets/views/mine/arrow-up.jpg" alt />
        </div>
      </div>
      <div class="process" style="margin-top: 20px;margin-right:3px;">
        <div class="process-con" style="float: right;">
          <div class="proess-img">
            <img src="@/assets/views/mine/bangongshi.jpg" alt />
          </div>
          <div class="procss-text">
            <span>专员跟进办公室</span>
          </div>
        </div>

        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/police (1).jpg" alt />
          </div>
          <div class="procss-text">
            <span>派出所落户</span>
          </div>
        </div>
        <div class="arrow2" style="float: right;">
          <img src="@/assets/views/mine/da-arrow2.jpg" alt />
        </div>
        <div class="process-con" style="float: right;margin-right: 10px;">
          <div class="proess-img">
            <img src="@/assets/views/mine/jinan6.png" alt />
          </div>
          <div class="procss-text">
            <span>完成</span>
          </div>
        </div>
      </div>
    </div>
    
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/shuoming.jpg" alt />
        <span class="intro-title">业务说明</span>
      </div>
      <div class="content">
        <div class="isthone">
          <div class="thone-text">
            <span>毕业生毕业之前办理网签手续后，待毕业后学校会将档案以机要形式陆续发到我方档案室，具体发出时间各学校会有差异，请耐心等待</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-gray-line"></div>
    <div class="introduce showmessage">
      <div class="title">
        <img class="title-img" src="@/assets/views/mine/cailiao.jpg" alt />
        <span class="intro-title">所需材料</span>
      </div>
      <div class="content">
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">1</div>
            <div class="neds-text">毕业证复印件</div>
          </div>
          <div class="needs-cont" style="margin-left: 50px">
            <div class="neds-num">2</div>
            <div class="neds-text">学位证复印件</div>
          </div>
        </div>
        <div class="needs" style='width:100%;'>
          <div class="needs-cont">
            <div class="neds-num">3</div>
            <div class="neds-text">报到证</div>
          </div>
          <div class="needs-cont" style="margin-left: 90px">
            <div class="neds-num">4</div>
            <div class="neds-text">学历注册本案表（学信网导出）</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">5</div>
            <div class="neds-text">1寸彩色白底照片</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
            <div class="neds-num">6</div>
            <div class="neds-text">户口本/户籍证明/户口迁移证</div>
          </div>
        </div>
        <div class="needs">
          <div class="needs-cont">
           注：户口在家的是户口本，  户口在青岛院校的毕业时学校 出具的是户籍证明， 户口在青岛以外院校的出具户口迁移证
          </div>
        </div>
      </div>
    </div>
    <div class="showmessage"></div>
  </div>
</template>
<script>
import "@/sass/views/gallery/hukoubanli.scss";

export default {
  name: "danganbro",
  data() {
    return {};
  }
};
</script>


