<template>
  <div class="provefiles-index">
    <div class="banner-con">
      <div class="banner">
        <div class="title-banner">
          <span>档案补办</span>
          <p>档案丢了？资料缺失？不知道怎么办？</p>
          <p>快速帮您搞定这些问题</p>
        </div>
      </div>
    </div>
    <div class="cont-use">
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>档案丢了不知道怎样补？流程繁琐？耽误时间？档管家可以全程为您提供代办服务，让您省心省力</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-" style="margin-left:7px;">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img" style="margin-left:7px;">
                    <img src="@/assets/views/mine/da-dc1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>APP注册账号</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow" style="margin-left:-10px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:23px;">
                  <div class="showinfo-1-img" style="margin-left:9px;">
                    <img src="@/assets/views/mine/fukuan.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>付款成功</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow" style="margin-left:-5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:20px;">
                  <div class="showinfo-1-img" style="margin-left:10px;">
                    <img src="@/assets/views/mine/guihuan1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>办理人登记填写信息资料</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 20px;">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img" style="margin-left:10px;">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:-5px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:21px;">
                  <div class="showinfo-1-img" style="margin-left:8px;">
                    <img src="@/assets/views/mine/yewujieshao.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>相关部门进行补办手续</span>
                  </div>
                </div>
                <div class="trastom" style="margin-left:-3px;">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" style="margin-left:22px;">
                  <div class="showinfo-1-img" style="margin-left:7px;">
                    <img src="@/assets/views/mine/tiqu1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>专员跟进办理</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:30px;">
        <div class="use-introduce">
          <img src="@/assets/views/mine/need-re.png" alt />
          <span>所需材料</span>
        </div>
        <div class="use-cont">
          <span>1、身份证复印件</span>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:30px;">
        <div class="use-introduce">
          <img src="@/assets/views/mine/dangan-quest_03.jpg" alt style="width:15px;height:15px" />
          <span>常见问题</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="active-title">1. 个人档案能在自己手里放着吗？</div>
        <div class="active-cont">
          个人档案不能放在自己手里，因为档案属于国家机密文件，是密封状态的，如果私自拆开，问题是很严重的，你的档案就是失效的，本人也是不能自己保管档案的，档案的专递都是需要走机要的
          ，人事档案放在自己手里，组织人事部门将对档案材料的真实性不予认可。
        </div>
      </div>
      <div class="small-ine"></div>
      <div class="use-cont">
        <div class="active-title">2. 学籍档案必补的材料有哪些？</div>
        <div class="active-cont">学籍档案里：毕业生登记表、录取花名册，成绩单等为必补的材料</div>
      </div>
    </div>
    <div class="gary-line"></div>
    <div class="prove-btn" @click="showConsult()">
      <button>咨询顾问</button>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/prove-profile.scss";
import { mapGetters } from "vuex";
export default {
  name: "archives-use",
  data() {
    return {};
  },
   computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 展示顾问
     */
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return;
      }
      this.$consult({ userid: this.userInfo.id });
    },
  }
};
</script>

