<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>400元/人/月，可领36个月 </p>
                <span>条件：毕业生在我市小微企业就业，企业按规定办理就业登记、缴纳城镇职工社会保险的。</span>
            </div>
        </div>

    </div>
</template>
<script>
   import "@/sass/views/creatorder/business_introduce.scss";

    export default {
        name: "Purchase",
        data() {
            return {};
        },

    };
</script>

