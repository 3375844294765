<template>
  <div class="archives-index">
    <div class="archives-info">
      <div class="item">
        <div class="lable">城市</div>
        <div class="info">{{sjgjArchives.cityname}}</div>
      </div>
      <div class="item">
        <div class="lable">到日期</div>
        <div class="info">{{formatTime(sjgjArchives.expiringtime)}}</div>
      </div>
      <div class="item">
        <div class="lable">当前状态</div>
        <div class="info">{{getState(sjgjArchives.state)}}</div>
      </div>
      <div class="item">
        <div class="lable">借阅</div>
        <div class="info">{{getBorrowstate(sjgjArchives.borrowstate)}}</div>
      </div>

      <div
        v-if="sjgjArchives.state==4 && sjgjArchives.borrowstate!=1"
        @click="borrow()"
        class="borrow-btn"
      >借阅</div>
      <div v-if="sjgjArchives.borrowstate==1" @click="borrow()" class="borrow-btn">归还</div>
    </div>
    <div class="grayline"></div>
    <div class="ar-per-con">
      <div class="title" @click="$router.forward({path:'/mine/borrowedRecord'})">
        借阅记录
        <div class="more">
          更多
          <img src="@/assets/right.png" class="icon" alt />
        </div>
      </div>
      <div
        class="item"
        @click="$router.forward({path:'/mine/recordDetail',query: { id: item.id,type:item.type,ywtype:1}})"
        v-for="item in recordList"
        :key="item.id"
      >
        <div class="userinfo">
          <span class="name">{{getNameByType(item.type)}}</span>
          <span class="state">{{getNameByState(item.state)}}</span>
        </div>
        <div class="infos">
          <span class="lable">{{item.name}}</span>
          <span class="info">{{formatTime(item.time)}}</span>
        </div>
      </div>
    </div>
    <!-- 当是已归还是不显示提取和续约 -->
    <div class="re-btn" v-if="sjgjArchives.state == 4">
      <div
        class="record"
        @click="$router.forward({path: '/mine/extractArchives',query: { userid: sjgjArchives.userid,id: sjgjArchives.id}})"
      >提取</div>
      <div class="renew" @click="isRecord()">续费</div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/mine/archives.scss";
import { mapGetters } from "vuex";
import { formatTime } from "@/lib/base.js";
import money from "@/components/plug-in/money";
export default {
  name: "minearchives",
  data() {
    return {
      recordParam: {
        userid: "",
        ywtype: 1,
        page: 1
      },
      paramData: {
        cityname: "",
        remark: "",
        cityid: "",
        tel: "",
        userid: "",
        saletypeid: "",
        sjgjOrderHukou: {
          year: ""
        }
      },
      sjgjArchives: {},
      recordList: [],
      xufeiPrice: "", //单项续费时的费用
      togetherCufei: "",
      settlemode: "",
      hukouPrice: "",
      danganPrice: "",
      fristBool: false
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.recordParam.userid = this.userInfo.id;
    this.paramData.userid = this.userInfo.id;
    this.getRecord(); //获取借阅记录
    this.getMyBusiness(); //获取我的业务
  },
  activated() {
    this.getRecord(); //获取借阅记录
    this.getMyBusiness(); //获取我的业务
  },
  methods: {
    /*
     * 获取我的业务
     */
    getMyBusiness() {
      this.$siaxios
        .get("/dangan/getDanganInfo/" + this.userInfo.id)
        .then(res => {
          this.sjgjArchives = res;
          this.paramData = {
            cityname: this.sjgjArchives.cityname,
            remark: this.sjgjArchives.remark,
            cityid: this.sjgjArchives.cityid,
            tel: this.sjgjArchives.tel,
            saletypeid: this.sjgjArchives.ywtype,
            userid: this.userInfo.id,
            sjgjOrderHukou: {
              year: ""
            }
          };
          this.getCityCharge(this.sjgjArchives.ywtype); //获取不同城市的续费价格
        });
    },
    /**
     * 获取户口
     */
    getDetail() {
      this.$axios.get("hukou/getHuKouInfo/" + this.userInfo.id).then(res => {
        if (res.data.success) {
          this.settlemode = res.data.data.settlemode;
          this.getCityCharge(res.data.data.ywtype);
          // this.getCityCharge(22); //获取户口缴费标准
        } else {
          this.$tips({
            title: "提示",
            describe: "您还没有办理相关户口业务"
          });
        }
      });
    },
    /**
     * 根据状态获取名称   0确认支付1待办理2待发送/待完成3完成
     */
    getNameByState(state) {
      if (state == 0) return "确认支付";
      else if (state == 1) return "待办理";
      else if (state == 2) return "待完成";
      else if (state == 3) return "完成";
    },
    /**
     * 获取类型名称  1借阅2归还3提取/迁出
     */
    getNameByType(type) {
      if (type == 1) return "借阅";
      else if (type == 2) return "归还";
      else if (type == 3) return "提取";
    },
    /**
     * 获取借阅记录
     */
    getRecord() {
      this.$siaxios
        .post("/dangan/getRecord", this.recordParam, false)
        .then(res => {
          this.recordList = res;
        });
    },
    /**
     * 借阅归还
     */
    borrow() {
      if (this.sjgjArchives.state == 2) {
        this.$tips({
          title: "提示",
          describe: "您还有正在借阅中和归还中的业务,请等待完成后申请!"
        });
      }
      if (this.sjgjArchives.borrowstate == 1) {
        this.$router.forward({
          path: "/mine/backarchives",
          query: { id: this.sjgjArchives.id }
        });
      } else {
        this.$router.forward({
          path: "/mine/creatarchives"
        });
      }
    },
    /**
     * 获取借阅状态
     */
    getBorrowstate(type) {
      if (type == 0) return "无";
      if (type == 1) return "借阅中";
      else if (type == 2) return "已归还";
    },
    /**
     * 获取档案状态
     */
    getState(type) {
      if (type == 1) return "关闭";
      else if (type == 2) return "待确认";
      else if (type == 3) return "待入库";
      else if (type == 4) return "在库";
      else if (type == 5) return "已提取";
    },
    //判断是否在同一城市
    isRecord() {
      // this.getDetail(); //获取户口信息--获取户口的续费金额

      this.getYear(); //获取续费时长
      this.$siaxios
        .get("order/getDandAnHuKou/" + this.userInfo.id)
        .then(res => {
          if (res) {
            this.fristBool = true;
            this.getDetail();
          } else {
            //不在同一城市  进入单项续费
            money.installConfirm({
              title: "档案续费",
              money: this.danganPrice, //默认费用
              param: this.paramData,
              isDangan: false,
              hmoney: this.togetherCufei
            });
          }
        });
    },
    //获取档案不同城市的续费价格
    getCityCharge(saletypeid) {
      this.$siaxios
        .get(
          "common/getBusinessCharge/" +
            this.sjgjArchives.cityid +
            "/" +
            saletypeid
        )
        .then(res => {
          //档案价格
          if (saletypeid == 19 || saletypeid == 41) {
            this.danganPrice = res[0].xufei;
          } else {
            for (var i = 0; i < res.length; i++) {
              if (this.settlemode == res[i].taocan) {
                this.hukouPrice = res[i].xufei; //户口单独续费时
                this.togetherCufei = res[i].hdxufei; //同时续费时
                if (this.fristBool) {
                  money.installConfirm({
                    title: "限时优惠",
                    describe:
                      "同时续费档案和落户业务，享" +
                      this.togetherCufei +
                      "元/年",
                    money: this.danganPrice, //默认费用
                    param: this.paramData,
                    isDangan: true,
                    hmoney: this.togetherCufei
                  });
                }
                this.fristBool = false;
              }
            }
          }
        });
    },
    //获取续费时长
    getYear() {
      this.$siaxios
        .get("order/getYear/" + this.userInfo.id + "/1")
        .then(res => {
          this.paramData.sjgjOrderHukou.year = res;
        });
    },
    formatTime: formatTime
  }
};
</script>