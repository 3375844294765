<template>
    <div class="orderdetail-index" :class="{'isPay':detail.states == 0}">
        <div class="detail-info">
            <div class="pay-title" v-if="detail.states == 1">支付完成</div>
            <div class="pay-title" v-if="detail.states == 3">订单已关闭</div>

            <div class="order-top">
                <img src="@/assets/views/creatorder/order-detail.png" alt class="order-top-icon" />
                订单{{detail.states == 0?'支付':'详情'}}
            </div>
            <div class="order-info">
                <div class="item" v-for="item in detail.sjgjOrders" :key="item.id">
                    <img :src="item.listimg" class="pro-img" alt />
                    <div class="pro-info">
                        <div class="order-title">{{item.saletypename}}</div>
                        <div class="order-detail">
                            <div class="order-count">×1</div>
                            <div class="order-money">￥{{getChildAmount(item)}}</div>
                        </div>
                    </div>
                </div>
                <div class="item" v-if="detail.consult">
                    <img src="@/assets/consult-product.png" class="pro-img" alt />
                    <div class="pro-info">
                        <div class="order-title">劳动咨询</div>
                        <div class="order-detail">
                            <div class="order-count">×{{detail.consult.count}}</div>
                            <div class="order-money">￥{{detail.consult.price}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-detail-title">订单信息</div>
        <!-- 单个订单显示通用信息-->
        <div class="item-info" v-if="detail.sjgjOrders.length==1">
            <div class="item">
                <div class="lable">订单号</div>
                <div class="info">{{detail.ordernumber}}</div>
            </div>
            <template
                v-if="detailOrder.saletypeid == 1 || detailOrder.saletypeid == 13 || detailOrder.saletypeid == 19 || detailOrder.saletypeid == 22 || detailOrder.saletypeid == 23">
                <div class="item">
                    <div class="lable">城市</div>
                    <div class="info">{{detailOrder.xiangqing.cityname}}</div>
                </div>
            </template>
            <template v-else>
                <div class="item">
                    <div class="lable">款项</div>
                    <div class="info">￥{{detailOrder.orderamount}}</div>
                </div>
            </template>
            <div class="item" v-if="detailOrder.hname && detailOrder.saletypeid == 1">
                <div class="lable">户籍性质</div>
                <div class="info">{{detailOrder.hname}}</div>
            </div>
            <div class="item" v-if="detailOrder.saletypeid == 1 || detailOrder.saletypeid == 13">
                <div class="lable">{{detailOrder.saletypeid == 1?'社保':'公积金'}}基数</div>
                <div class="info">{{detailOrder.xiangqing.paybase}}</div>
            </div>
            <div class="item" v-if="detailOrder.saletypeid == 1 || detailOrder.saletypeid == 13">
                <div class="lable">缴费月份</div>
                <div class="info">{{getPayMounth(detailOrder.xiangqing.startmonth,detailOrder.xiangqing.overmonth)}}</div>
            </div>
            <div class="item" v-if="detailOrder.saletypeid == 23">
                <div class="lable">新生儿姓名</div>
                <div class="info">{{detailOrder.xiangqing.childname}}</div>
            </div>
            <div class="item">
                <div class="lable">下单时间</div>
                <div class="info">{{formatTime(detailOrder.createtime)}}</div>
            </div>
            <template v-if="detailOrder.saletypeid == 22">
                <div v-for="(item,$index) in detailOrder.xiangqing.sjgjOrderHukouPersonLists" :key="item.id">
                    <div class="item">
                        <div class="lable">落户人{{$index+1}}</div>
                        <div class="info">{{item.name}}</div>
                    </div>
                    <div class="item">
                        <div class="lable">身份证号</div>
                        <div class="info">{{item.idcard}}</div>
                    </div>
                </div>
            </template>
            <div class="item" v-if="detailOrder.paytype">
                <div class="lable">支付方式</div>
                <div class="info">{{getPayType(detailOrder.paytype)}}</div>
            </div>
            <div class="item" v-if="detailOrder.remark">
                <div class="lable">备注信息</div>
                <div class="remark-info">{{detailOrder.remark}}</div>
            </div>
        </div>
        <!-- 多个订单显示通用信息-->
        <div class="item-info" v-if="detail.sjgjOrders.length>1">
            <div class="item">
                <div class="lable">姓名</div>
                <div class="info">{{detail.sjgjOrders[0].sjgjUser.name}}</div>
            </div>
            <div class="item">
                <div class="lable">身份证号</div>
                <div class="info">{{detail.sjgjOrders[0].sjgjUser.idcard}}</div>
            </div>
            <div class="item">
                <div class="lable">电话</div>
                <div class="info">{{detail.sjgjOrders[0].sjgjUser.tel}}</div>
            </div>
        </div>

        <!-- 未支付时 -->
        <template v-if="detail.states == 0">
            <template>
                <div class="order-detail-title">支付方式</div>
                <v-pay ref="payTypedetail" v-model="payType"></v-pay>
            </template>
            <div class="bottom-menu">
                <div class="consult" @click="$router('/consult/onlineConsultation')">
                    共计：
                    <span class="money">￥{{detail.orderamount}}</span>
                </div>
                <div class="order-btn" @click="goPay()">付款</div>
            </div>
        </template>
        <template v-if="detail.states == 1 &&  detailOrder.saletypeid != 46">
            <div class="pay-bottom">
                <!-- <div class="lable">全部信息</div> -->
                <div class="hetong" @click="isXieYi(detailOrder.saletypeid)">查看合同</div>
                <div class="wanshan" @click="$router.forward('/mine/userdetailinfo')">完善业务信息</div>
            </div>
        </template>
    </div>
</template>
<script>
import "@/sass/views/creatorder/orderdetail.scss";
import md5 from "md5";
import { orderType } from "@/lib/typelist";
import { mapGetters } from "vuex";
import { formatTime } from "@/lib/base";
export default {
    name: "index",
    data() {
        return {
            states: "",
            payType: 1,
            detail: {
                sjgjOrders: []
            },
            detailOrder: [],
            isSubmitBool: true,
            order: {
                userid: "",
                order: this.$route.query.id, //订单id
                amount: "", //实际缴费金额
                sign: "",
                ip: "144.123.37.126",
                source: "MWEB",
                body: 1,
                url: ""
            },
            timer: null
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    mounted() {
        //设置app支付
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            this.order.source = 'JSAPI'
        } else if (this.$isPlus && this.userInfo.device.source == 'android') {
            this.order.source = "APP";
        } else {
            this.order.source = 'MWEB'
        }
        this.order.userid = this.userInfo.id;
        this.getDetail();
    },
    destroyed() {
        clearInterval(this.timer);
    },
    methods: {
        /**
         * 获取详情    //consult-product
         */
        getDetail(type) {
            this.$siaxios.get(`/order/getOrderInfo/${this.order.order}`).then(res => {
                let consult = {
                    count: 0,
                    price: 0
                };
                res.sjgjOrders.forEach(item => {
                    if (item.zxtype == 1) {
                        item.realAmount == item.orderamount - item.zxprice;
                        consult.count += item.zxnum;
                        consult.price += item.zxprice;
                    }
                });
                if (consult.count > 0) {
                    res.consult = consult;
                }
                this.detail = res;
                this.states = this.detail.states;
                if (type != 1) {
                    //待支付
                    if (this.detail.states == 0) {
                        if (this.detail.sjgjOrders && this.detail.sjgjOrders.map(item => item.saletypeid).length > 0) {
                            this.$nextTick(() => {
                                this.$refs.payTypedetail.getPayType(
                                    this.detail.sjgjOrders.map(item => item.saletypeid)
                                );
                            });
                        }
                    }
                }
                if (this.detail.sjgjOrders.length == 1) {
                    this.detailOrder = this.detail.sjgjOrders[0];
                }
                this.order.amount = res.orderamount;
                this.order.body = res.sjgjOrders[0].saletypename;
                if (res.sjgjOrders > 1) {
                    this.order.body += "等";
                }
                this.createSign();
            });
        },
        /**
         * 创建验证
         */
        createSign() {
            if (this.detail.states != 0) return;
            this.order.url = "/creatorder/orderdetail?id=" + this.order.order;
            let mysign =
                "SING=ZHZR&amount=" +
                this.order.amount +
                "&order=" +
                this.order.order +
                "&userid=" +
                this.userInfo.id;
            this.order.sign = md5(mysign.toUpperCase());
        },
        formatTime: formatTime,
        /**
         * 获取支付月
         */
        getPayMounth(startmonth, overmonth) {
            if (startmonth == overmonth) {
                return startmonth;
            } else {
                return startmonth + "至" + overmonth;
            }
        },
        /**
         * 获取子业务金额
         */
        getChildAmount(item) {
            if (item.zxtype == 1) return item.orderamount - item.zxprice;
            return item.orderamount;
        },
        /**
         * 支付类型判断去哪支付
         */
        goPay() {
            if (this.payType == 1) {
                //微信支付
                this.getPrepayid();
            }
            // else if(this.payType == 2) {}
            else if (this.payType == 3) {
                this.$siaxios.get(`/offlinpay/submit/${this.detail.id}/3`).then(() => {
                    this.$router.forward("/creatorder/success");
                });
            }
        },
        getPayType(id) {
            if (id == 1) return "微信";
            else if (id == 2) return "支付宝";
            else if (id == 3) return "线下支付";
        },
        getStateName(id) {
            let name;
            for (let i = 0; i < orderType.length; i++) {
                if (id == orderType[i].id) {
                    name = orderType[i].name;
                    break;
                }
            }
            return name;
        },
        /**
         * 获取支付id
         */
        getPrepayid() {
            this.$siaxios.post("/weixin/getPrepayid", this.order, false).then(res => {
                if (this.order.source == "JSAPI") {
                    this.payWx(res);
                } else if (this.order.source == "APP") {
                    // eslint-disable-next-line
                    uni.postMessage({
                        data: {
                            message: res,
                            type: 'pay'
                        }
                    })
                    this.timer = setInterval(() => {
                        this.getDetail(1);
                        if (this.states == 1) {
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 2000);
                } else {
                    let myUrl = "dgjtest.dangguanjia.cn://" + this.$route.query.id;
                    if(this.$isPlus){
                        window.location.href = res.mweb_url + "&redirect_url=" + encodeURIComponent(myUrl);
                    }else{
                        window.location.href = res.mweb_url
                    }
                    
                    // window.location.href = res.mweb_url;
                    this.timer = setInterval(() => {
                        this.getDetail(1);
                        if (this.states == 1) {
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 2000);
                }
            });
        },
        /**
         * 开始微信支付
         */
        payWx(data) {
            let _this = this;
            function onBridgeReady() {
                // eslint-disable-next-line
                WeixinJSBridge.invoke(
                    "getBrandWCPayRequest",
                    {
                        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: data.appid, //公众号名称，由商户传入
                        timeStamp: data.timestamp, //时间戳，自1970年以来的秒数
                        nonceStr: data.noncestr, //随机串
                        package: data.packages,
                        signType: "MD5", //微信签名方式：
                        paySign: data.sign //微信签名
                    },
                    function (res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            _this.getDetail();
                            // _this.$router.forward("/creatorder/success");
                        } // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
                    }
                );
            }
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener(
                        "WeixinJSBridgeReady",
                        onBridgeReady,
                        false
                    );
                } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                    document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
                }
            } else {
                onBridgeReady();
            }
        },
        isXieYi(saletypeid) {
            if (saletypeid == 1 || saletypeid == 2) {
                this.$router.forward("/gallery/securityabouts");
            } else if (saletypeid == 13 || saletypeid == 14) {
                this.$router.forward("/gallery/GJJabouts");
            } else {
                this.$router.forward("/gallery/otherAbouts");
            }
        }
    }
};
</script>
