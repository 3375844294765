<template>
  <div class="order-services">
    <div class="title">
      <div class="item" @click="showType = 1" :class="{'active': showType == 1}">购买流程</div>

      <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>
    </div>
    <div class="show-content">
      <div class="showtype" style="background: #f6faff;" v-if="showType == 1">
        <div class="order-title">购买流程</div>
        <div class="showinfo-1">
          <div class="showinfo-1-">
            <div class="showinfo-1-item" style="margin-left:20px">
              <div class="showinfo-1-img">
                <img src="@/assets/views/creatorder/orde-1.png" alt />
              </div>
              <div class="showinfo-1-cont">
                <span>下载APP</span>
              </div>
            </div>
            <div class="showinfo-1-arrow">
              <img src="@/assets/views/creatorder/arrow.png" alt />
            </div>
            <div class="showinfo-1-item" style="margin-left:15px">
              <div class="showinfo-1-img">
                <img src="@/assets/views/creatorder/orde-2.png" alt />
              </div>
              <div class="showinfo-1-cont">
                <span>注册成功</span>
              </div>
            </div>
            <div class="showinfo-1-arrow">
              <img src="@/assets/views/creatorder/arrow.png" alt />
            </div>
            <div class="showinfo-1-item" style="margin-left:15px">
              <div class="showinfo-1-img">
                <img src="@/assets/views/creatorder/orde-3.png" alt />
              </div>
              <div class="showinfo-1-cont">
                <span>完成支付</span>
              </div>
            </div>
          </div>
          <div class="showinfo-1-">
            <div class="show-img">
              <img src="@/assets/views/creatorder/arrow2.png" alt />
            </div>
          </div>
          <div class="showinfo-1-" style="margin-top: 50px">
            <div class="showinfo-1-item" style="margin-left:20px">
              <div class="showinfo-1-img">
                <img src="@/assets/views/creatorder/orde-6.png" alt />
              </div>
              <div class="showinfo-1-cont">
                <span>完成</span>
              </div>
            </div>
            <div class="showinfo-1-arrow">
              <img src="@/assets/views/creatorder/arrow.png" alt style="transform: rotate(180deg);" />
            </div>
            <div class="showinfo-1-item" style="margin-left:15px">
              <div class="showinfo-1-img">
                <img src="@/assets/views/creatorder/orde-5.png" alt />
              </div>
              <div class="showinfo-1-cont">
                <span>专员办理</span>
              </div>
            </div>
            <div class="showinfo-1-arrow">
              <img src="@/assets/views/creatorder/arrow.png" alt style="transform: rotate(180deg);" />
            </div>
            <div class="showinfo-1-item" style="margin-left:15px">
              <div class="showinfo-1-img">
                <img src="@/assets/views/creatorder/orde-4.png" alt />
              </div>
              <div class="showinfo-1-cont">
                <span>专员跟进收集材料</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="showtype" style=" background: #f6faff;" v-if="showType == 3">
        <div class="order-title">常见问题</div>
        <div class="showinfo-3">
          <div class="showinfo-3-item">
            <div class="showinfo-3-quest">
              <div class="show3-num">
                <span>Q</span>
              </div>
              <div class="shou3-cont">
                <span>有些国家的高等教育机构没有在中国留学网上公布是否意味着该国学历学位不能进行认证</span>
              </div>
            </div>
            <div class="item-line"></div>
            <div class="showinfo-3-cont">
              <div class="show3-num">
                <span>A</span>
              </div>
              <div class="shou3-cont">
                <span>目前在中国留学网上已经推荐了54个国家的高等教育机构，陆续还会继续推荐其他国家的高等教育机构。暂时没有被推荐的某些国家的高等教育机构所颁发的学历学位证书，如被所在国教育主管部门承认或经所在国政府认可的专业机构评估/认证，也可以申请学历学位认证。</span>
              </div>
            </div>
          </div>
          <div class="showinfo-3-item">
            <div class="showinfo-3-quest">
              <div class="show3-num">
                <span>Q</span>
              </div>
              <div class="shou3-cont">
                <span>申请国（境）外学历学位认证有没有期限要求？获得国（境）外学历学位证书后多长时间内必须申请认证</span>
              </div>
            </div>
            <div class="item-line"></div>
            <div class="showinfo-3-cont">
              <div class="show3-num">
                <span>A</span>
              </div>
              <div class="shou3-cont">
                <span>没有期限要求。国（境）外学历学位认证是申请者的自愿行为，无论国（境）外学历学位证书的获得时间或早或晚，只要符合受理标准，均可申请认证。</span>
              </div>
            </div>
          </div>
          <div class="showinfo-3-item">
            <div class="showinfo-3-quest">
              <div class="show3-num">
                <span>Q</span>
              </div>
              <div class="shou3-cont">
                <span>国外学历学位认证是否必须认证？是属于强制性的吗</span>
              </div>
            </div>
            <div class="item-line"></div>
            <div class="showinfo-3-cont">
              <div class="show3-num">
                <span>A</span>
              </div>
              <div class="shou3-cont">
                <span>国（境）外学历学位认证是申请者的自愿行为，不具强制性。</span>
              </div>
            </div>
          </div>
          <div class="showinfo-3-item">
            <div class="showinfo-3-quest">
              <div class="show3-num">
                <span>Q</span>
              </div>
              <div class="shou3-cont">
                <span>外籍人士可以申请国（境）外学历学位认证吗</span>
              </div>
            </div>
            <div class="item-line"></div>
            <div class="showinfo-3-cont">
              <div class="show3-num">
                <span>A</span>
              </div>
              <div class="shou3-cont">
                <span>可以，申请程序同中国籍申请者，需提交现持有的外籍护照（如留学期间持中国护照则需额外提交留学期间中国护照）。</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/creatorder/introduce.scss";

export default {
  name: "Introduce",
  data() {
    return {
      showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
    };
  }
};
</script>
