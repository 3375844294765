<template>
    <div class="businessIntro-index">
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                限购证明也是购房资格证明，有购房意向的客户首先应该办理的一个证明文件。只有开局了限购证明，才能证明你的购房资格。也是合同网签的必备条件。
            </div>
        </div>

    </div>
</template>
<script>
     import "@/sass/views/creatorder/business_introduce.scss";
    export default {
        name: "Purchase",
        data() {
            return {

            };
        },

    };
</script>
