<template>
    <div class="school-index">
        <div class="banner-con">
            <div class="banner">
                <span>外来务工人员子女入小学条件</span>
                <p>（ 青岛 ）</p>
            </div>
        </div>
        <!-- 入学条件 -->
        <div class="title">
            <img src="@/assets/views/mine/jinan7.png" alt/>
            <span class="title-">入学条件</span>
        </div>
        <div class="cont-frist" style="height:155px">
            <div class="con-cont">
                <div class="con-introduce">
                    <span>父母至少一方与我市用人单位签订劳动合同或办理工商营 业执照，并在我市务工一年以上</span>
                </div>
            </div>
            <div class="con-cont">
                <div class="con-introduce">
                    <span>父母至少一 方持有我市公安部门核发的居住证，并在务工 地有稳定的住所(有自有住所或办理正式租住手续的住所)</span>
                </div>
            </div>
            <div class="con-cont">
                <div class="con-introduce">
                    <span>符合我市规定的入学年龄</span>
                </div>
            </div>
        </div>
        <div class="top-back"></div>
        <!--        入学材料-->
        <div class="title">
            <img src="@/assets/views/mine/yewujieshao.png"  alt/>
            <span class="title-">入学材料</span>
        </div>
        <div class="cont">
            <div class="con-title">
                <span>外来务工人员子女小学入学材料</span>
            </div>
            <div class="con-cont">
                <div class="introduce-title">
                    <span>1</span>
                </div>
                <div class="con-introduce">
                    <span>父母一方在我市劳动合同或工商营业执照</span>
                </div>
            </div>
            <div class="con-cont">
                <div class="introduce-title">
                    <span>2</span>
                </div>
                <div class="con-introduce">
                    <span>父母一方在我市居住证</span>
                </div>
            </div>
            <div class="con-cont">
                <div class="introduce-title">
                    <span>3</span>
                </div>
                <div class="con-introduce">
                    <span>父母一方在我市房屋产权证或房屋租赁证明</span>
                </div>
            </div>
            <div class="con-cont">
                <div class="introduce-title">
                    <span>4</span>
                </div>
                <div class="con-introduce">
                    <span>学生及其父母在原籍户口簿</span>
                </div>
            </div>
            <div class="con-cont">
                <div class="introduce-title">
                    <span>5</span>
                </div>
                <div class="con-introduce">
                    <span>原学校学籍档案(小学一年级除外)</span>
                </div>
            </div>
        </div>
        <!-- 受理流程 -->
        <div class="title-big">
            <img src="@/assets/views/mine/jinan6.png"  alt/>
            <span class="title-" >外来务工子女人员子女小学入学程序</span>
        </div>
        <div class='small-title' >
            <span>申请</span>
        </div>
        <div class="cont-school" style="height:140px;">
            <div class="cont-school-">
                学生父母填写《山东省中小学转学证》,并持有关材料向居住地附近学校提出申请符合我市入学条件但没有联系到接收学校的，可持有关材料向当地教育行政部门提出申请。
            </div>
        </div>
        <div class='small-title' style="margin-top:-10px">
            <span>审查</span>
        </div>
        <div class="cont-school" style="height:190px;">
            <div class="cont-school-">
                <span>学校负责审查外来务工就业人员子女是否符合我市入学条件。符合条件的.经学校签章同意后.分别到原学校和双方学校上级教育行政部门办理转学手续。</span>
                <p>直接向教育行政部门申请的，由教育行政部门审查询合条件的，原则上在其居住地4公里（8站）范围内安排就读学校，按规定办理转学手续。</p>
            </div>
        </div>
        <div class='small-title' style="margin-top:-5px">
            <span>入学</span>
        </div>
        <div class="cont-school" style="height:100px;border:0">
            <div class="cont-school-">
                外来务工就业人员子女持《山东省中小学转学证》和相关材料,到接收学校报到、入学。
            </div>
        </div>
    </div>
</template>
<script>
    import "@/sass/views/gallery/school.scss";

    export default {
        name: "index",
        data() {
            return {};
        },
    };
</script>
<style lang="scss" scoped>
    .banner-con {
        background: url(../../assets/views/mine/Qd-banner_05.jpg);
        background-size: cover;
        p {
            margin-top: -150px;
            color: #e2e3e6;
        }
    }

    .title {
        img {
            width: 35px;
        }
        span {
            margin-left: 10px;
        }
    }
    .cont{
        background: url("../../assets/views/mine/qd-school.png") 100% no-repeat;
        background-size: 100%;
        overflow: hidden;

    }
    .small-title {
        color: #ff9e2c;
        margin-left: 85px;
        width: 216px;
        font-size: 26px;
        margin-top: 10px;
    }

    .cont-school {
        height: 480px;
        border-left: 1px dashed #ff9e2c;
        width: 678px;
        margin-left: 47px;
        margin-top: -16px;
        .con-cont {
            margin-left: 35px;
            width: 647px;
            .con-introduce {
                padding-top: 30px;
                p {
                    margin-left: -25px;
                }
            }
        }

    }
</style>