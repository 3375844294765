<template>
  <div class="pagereturn-index">
    <div :class="$route.query.id==1?'banner-con2':'banner-con'">
      <div class="banner">
        <div class="title-banner">
          <span>单页归还</span>
        </div>
      </div>
    </div>
    <div class="cont-use">
      <div class="use-cont">
        <div class="use-introduce">
          <img src="@/assets/views/mine/banshi.jpg" alt />
          <span>业务介绍</span>
        </div>
        <div class="use-cont">
          <span>借用我方集体户单页请在借取之日起15个工作日内及时归还单页，单页归还后我方将押金予以退还。</span>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style='padding-top:20px'>
        <div class="use-introduce">
          <img src="@/assets/views/mine/liucheng.jpg" alt />
          <span>业务流程</span>
        </div>
        <div class="show-content">
          <div class="showtype">
            <div class="showinfo-1">
              <div class="showinfo-1-">
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/da-dc1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>app申请归还</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/guihuan1.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>填写归还账号等信息</span>
                  </div>
                </div>
                <div class="showinfo-1-arrow">
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item">
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/guihuan2.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>邮寄/送达办理</span>
                  </div>
                </div>
              </div>
              <div class="showinfo-1-">
                <div class="show-img">
                  <img src="@/assets/views/mine/arrow-up.jpg" alt />
                </div>
              </div>
              <div class="showinfo-1-" style="margin-top: 20px;">
                <div class="showinfo-1-item" >
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/jinan6.png" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>完成</span>
                  </div>
                </div>
                <div class="trastom" >
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" >
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/guihuan4.jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>退还押金</span>
                  </div>
                </div>
                <div class="trastom" >
                  <img src="@/assets/views/mine/da-arrow.jpg" alt />
                </div>
                <div class="showinfo-1-item" >
                  <div class="showinfo-1-img">
                    <img src="@/assets/views/mine/police (3).jpg" alt />
                  </div>
                  <div class="showinfo-1-cont">
                    <span>我方收取</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gary-line"></div>
      <div class="use-cont" style="padding-top:30px;">
        <div class="use-introduce">
          <img src="@/assets/views/mine/shuoming.jpg" alt style="width:15px;height:15px" />
          <span>业务说明</span>
        </div>
      </div>
      <div class="use-cont">
        <div class="hot-cont">
         
            <p class="tips-cont">选择邮寄归还的请将材料邮寄到我方指定地址</p>
          
        </div>
        <div class="hot-cont" style="margin-top:5px;">
          <p class="tips-cont">收件人：李青青</p>
          <p class="tips-cont" style='margin-top:5px'>联系电话：0532-85873655转813或815</p>
          <p class="tips-cont" style="margin-left:62px;margin-top:5px">18661887826</p>
          <p class="tips-cont" style='margin-top:5px'>联系地址： 青岛市市南区高雄路18号海洋大厦5层</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/page-return.scss";

export default {
  name: "archives-use",
  data() {
    return {};
  }
};
</script>

 