<template>
    <div class="businessIntro-index">
        <div class="order-gray-line"></div>
        <div class="showmessage">
            <div class="order-title">简介</div>
            <div class="content">
                <p>就业困难人员认定服务：300元/次   青岛</p>
                <span >
                    就业困难人员可享受一系列就业创业扶持政策，在社区从事制定灵活就业项目的可申领灵活就业社会保险补贴;被用人单位招用，按规定签订劳动合同并缴纳社会保险的，用人单位可申领社会保险补贴和岗位补贴，所以，就业困难人员身份的认定就显得极为重要。
                </span>
                <p >
                    档管家为您提供就业困难人员认定服务，让您第一时间享受福利政策。
                </p>
            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/creatorder/business_introduce.scss";
    export default {
        name: "hardJod",
        props: {
            param: {
                type: Object
            },
            showInfo: {
                type: Object
            },
            businessCharge: {
                type: Object
            }
        },
        data() {
            return {};
        },
    };
</script>
