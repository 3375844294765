<template>
    <div class="order-services">
        <div class="title">
            <div class="item" @click="showType = 1" :class="{'active': showType == 1}">购买流程</div>

            <div class="item" @click="showType = 3" :class="{'active': showType == 3}">常见问题</div>

        </div>

        <div class="show-content">
            <div
                    class="showtype" style="background: #f6faff;"
                    v-if="showType == 1"
            >
                <div class="order-title">购买流程</div>
                <div class="showinfo-1">
                    <div class="showinfo-1-">
                        <div class="showinfo-1-item" style="margin-left:20px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-1.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>下载APP</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-2.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>注册成功</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-3.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>完成支付</span>
                            </div>
                        </div>
                    </div>
                    <div class="showinfo-1-">
                        <div class="show-img">
                            <img src="@/assets/views/creatorder/arrow2.png" alt="">
                        </div>
                    </div>
                    <div class="showinfo-1-" style="margin-top: 50px">
                        <div class="showinfo-1-item" style="margin-left:20px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-6.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>完成</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="" style="transform: rotate(180deg);">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-5.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>专员办理</span>
                            </div>
                        </div>
                        <div class="showinfo-1-arrow">
                            <img src="@/assets/views/creatorder/arrow.png" alt="" style="transform: rotate(180deg);">
                        </div>
                        <div class="showinfo-1-item" style="margin-left:15px">
                            <div class='showinfo-1-img'>
                                <img src="@/assets/views/creatorder/orde-4.png" alt="">
                            </div>
                            <div class='showinfo-1-cont'>
                                <span>专员跟进</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                    class="showtype "
                    style=" background: #f6faff;"
                    v-if="showType == 3"
            >
                <div class="order-title">常见问题</div>
                <div class="showinfo-3">

                    <div class="showinfo-3-item">
                        <div class="showinfo-3-quest">
                            <div class="show3-num">
                                <span>Q</span>
                            </div>
                            <div class="shou3-cont">
                            <span>
毕业生办理改派的情况有哪些                           </span>
                            </div>
                        </div>
                        <div class="item-line"></div>
                        <div class="showinfo-3-cont">
                            <div class="show3-num">
                                <span>A</span>
                            </div>
                            <div class="shou3-cont">
                                <span>
合理利用改派机会
1.错派。没有这个用人单位。用人单位已经撤消或用人单位隶属关系发生了变化。
2.调配不当。院校在按单位委托代选毕业生调配过程中，所选择的毕业生所学专业与用人单位要求不一致。
3.毕业生本人遭受不可抗拒的因素或其它特殊原因。

。

                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import '@/sass/views/creatorder/introduce.scss'

    export default {
        name: "Introduce",
        data() {
            return {
                showType: 1 //1购买流程 2平台优势 3常见问题 4温馨提示
            };
        }
    };
</script>
