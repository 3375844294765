<template>
    <v-refresh ref='information' :on-refresh="onRefresh" :on-infinite-load="onInfiniteLoad">
        <div class="bar" :style="{height: $statusBarHeight + 'px' }"></div>
        <div class="information-list">
            <div class="top-bg">
                <div class="bg-title">
                    <p class="show-info">掌握最新资讯</p>
                    <p class="show-info">获取第一手资料</p>
                </div>
            </div>
            <div class="intop-nav" :style="{top: $statusBarHeight + 'px' }">
                <div class="navBarUl" ref="navBarUl">
                    <div class="item" @click="showTypeList(1)" :class="{'active':showType==1}">社保</div>
                    <div class="item" @click="showTypeList(2)" :class="{'active':showType==2}">公积金</div>
                    <div class="item" @click="showTypeList(3)" :class="{'active':showType==3}">档案</div>
                    <div class="item" @click="showTypeList(4)" :class="{'active':showType==4}">户口</div>
                    <div class="item" @click="showTypeList(5)" :class="{'active':showType==5}">就业创业</div>
                    <div class="item" @click="showTypeList(6)" :class="{'active':showType==6}">高校毕业生</div>
                </div>
            </div>
            <!-- <div class="bus-nav">
          <div class="navBarUl" ref="navBarUl">
            <span
              class="item"
              @click="getListByType(item.id)"
              :class="{'active': showType == item.id}"
              v-for="item in typedata"
              :key="item.id"
            >{{item.name}}</span>
            <div class="clear"></div>
          </div>
      </div>-->
            <div class="inmain-content">
                <TempList :list="showData.list"></TempList>
            </div>
        </div>
    </v-refresh>
</template>
<script>
import "@/sass/views/information/list.scss";
import refresh from "@/components/plug-in/pulldown";
import TempList from "./templist";
import { formatTime } from "@/lib/base";
import { mapGetters } from "vuex";
export default {
    name: "InformationList",
    data() {
        return {
            typedata: [
                {
                    id: 1,
                    name: "社保服务"
                },
                {
                    id: 2,
                    name: "公积金"
                },
                {
                    id: 3,
                    name: "档案"
                },
                {
                    id: 4,
                    name: "户口"
                },
                {
                    id: 5,
                    name: "就业"
                },
                {
                    id: 6,
                    name: "高校毕业生"
                },
                {
                    id: 7,
                    name: "创业"
                }
            ],
            showType: 1,
            showData: {
                pageNum: 1,
                list: []
            },
            // 上拉加载的设置
            infiniteLoadData: {
                initialShowNum: 10, // 初始显示多少条
                everyLoadingNum: 10, // 每次加载的个数
                pullUpState: 0, // 子组件的pullUpState状态
                pullUpList: [], // 上拉加载更多数据的数组
                showPullUpListLength: this.initialShowNum // 上拉加载后所展示的个数
            },
            isSlice: false,
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    components: {
        "v-refresh": refresh,
        TempList
    },
    mounted() {
        if (this.$route.query.id) {
            this.showType = this.$route.query.id;
        }
        this.getList();
        this.iniBusiness();
    },
    activated() {
        var id = [];
        id.push(this.$route.query.id);
        if (id.indexOf(4) > -1) {
            this.$options.name = "clist";
            let state = 4;
            this.showTypeList(state);
        } else if (id.indexOf(6) > -1) {
            this.$options.name = "clist";
            let state = 6;
            this.isSlice = true;
            this.showTypeList(state);
            this.iniBusiness();
        } else if (id.indexOf(3) > -1) {
            this.$options.name = "clist";
            let state = 3;
            this.showTypeList(state);
        }
    },

    methods: {
        formatTime: formatTime,
        /**
         * 显示类型
         */
        showTypeList(type) {
            this.showType = type;
            this.showData.list = [];
            this.showData.pageNum = 1;
            this.$refs.information.pullUpState = 0
            this.getList();
        },
        /**
         * 初始化业务大类滚动
         */
        /* eslint-disable */
        iniBusiness() {
            this.$nextTick(() => {
                let winWidth = document.documentElement.clientWidth;
                let navBarUl = this.$refs.navBarUl;
                let oLi = navBarUl.getElementsByClassName("item");
                navBarUl.style.width = "3000px";
                var allListWidth = 0;
                for (let i = 0; i < oLi.length; i++) {
                    allListWidth += oLi[i].offsetWidth;
                }
                if (this.$route.query.id == "6" && this.isSlice) {
                    navBarUl.style.marginLeft = "-70" + "px";
                    this.isSlice = false;
                }
                navBarUl.style.width = allListWidth + 20 + "px";
                let startX = 0;
                let cuttrX = 0;
                navBarUl.style.transition = "all .5s";
                navBarUl.addEventListener("touchstart", e => {
                    //e就是事件
                    startX = e.targetTouches[0].pageX;
                    cuttrX = navBarUl.offsetLeft;
                });
                navBarUl.addEventListener("touchmove", e => {
                    let leftpull = winWidth - allListWidth;
                    if (navBarUl.style.transition.split(" ")[0] != "none") {
                        navBarUl.style.transition = "none";
                    }
                    //移动的差值
                    let tempRutn = e.targetTouches[0].pageX - startX;
                    //nav距左位置
                    let mlNum = cuttrX + tempRutn;
                    if ((mlNum >= 0 && tempRutn > 0) || leftpull > 0) {
                        navBarUl.style.marginLeft = "0";
                    } else if (tempRutn < 0 && navBarUl.offsetLeft <= leftpull) {
                        navBarUl.style.marginLeft = leftpull - 30 + "px";
                    } else {
                        navBarUl.style.marginLeft = cuttrX + tempRutn + "px";
                    }
                    navBarUl.addEventListener("touchend", e => {
                        //e就是事件
                        navBarUl.style.transition = "all .5s";
                    });
                });
                // navBar点击
                for (let i = 0; i < oLi.length; i++) {
                    oLi[i].onclick = function () {
                        let nBLeft = navBarUl.offsetLeft;
                        let nBWidth = navBarUl.offsetWidth;
                        let maxML = -nBWidth + winWidth;
                        let mlDistance = nBLeft - this.offsetLeft + this.offsetWidth / 2;
                        if (mlDistance > 0) {
                            navBarUl.style.marginLeft = 0;
                        } else if (mlDistance <= maxML) {
                            navBarUl.style.marginLeft = maxML + "px";
                        } else {
                            navBarUl.style.marginLeft = mlDistance + "px";
                        }
                    };
                }
            });
        },
        /**
         * 获取资讯列表
         */
        async getList() {
            let tempList = await this.$siaxios.get(
                `/information/list/${this.showData.pageNum}?type=${this.showType}`
            );
            if (tempList.list.length > 0) {
                tempList.list.forEach(element => {
                    if (this.showType != 1 && this.showType != 2) element.type = null;
                    this.showData.list.push(element);
                });
            }
            return tempList.list.length;
        },
        getContent(str) {
            if (str) return str.replace(/<\/?.+?>/g, "");
            else {
                return str;
            }
        },
        //下拉刷新
        onRefresh(done) {
            this.showData.list = [];
            this.showData.pageNum = 1;
            this.getList().then(() => {
                done(); // call done
            })
        },
        // 上拉加载
        onInfiniteLoad(done) {
            this.showData.pageNum++;
            this.getList().then(res => {
                done(res, this.showData.pageNum);
            });
        }
    }
};
</script>
